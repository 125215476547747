<template>
  <div class="container my-5 admin-dashboard">
    <div class="row py-3">
      <div class="col-md-7 pr-md-5">
        <div class="progress-container">
          <SourcesProgress v-if="sources" :sources="sources" />
          <IMStructureProcessingProgress v-if="processingProgress" :processingProgress="processingProgress" />
          <UsersProgress v-if="usersProgress" :usersProgress="usersProgress" />
        </div>
      </div>
      <div class="col-md-5">
        <FiletypeChart v-if="filetypeData" :filetypeData="filetypeData" />
        <SourcesChart v-if="sources" :sources="sources.sources" />
      </div>
    </div>
  </div>
</template>

<script>
import SourcesProgress from './AdminDashboard/SourcesProgress';
import IMStructureProcessingProgress from './AdminDashboard/IMStructureProcessingProgress';
import UsersProgress from './AdminDashboard/UsersProgress';
import FiletypeChart from './AdminDashboard/FiletypeChart';
import SourcesChart from './AdminDashboard/SourcesChart';
import { dashboardService } from '@/services';

export default {
  components: {
    SourcesProgress,
    IMStructureProcessingProgress,
    UsersProgress,
    FiletypeChart,
    SourcesChart,
  },
  data() {
    return {
      sources: null,
      filetypeData: null,
      processingProgress: null,
      usersProgress: null,
    };
  },
  watch: {
    $route(val) {
      this.setup();
    },
  },
  methods: {
    setup() {
      this.sourcesData();
      this.filetypeChartData();
      this.imStructureProcessingStatus();
      this.usersProcessingStatus();
    },
    async sourcesData() {
      try {
        this.sources = await dashboardService.sourcesOverview();
      } catch (error) {}
    },
    async filetypeChartData() {
      try {
        this.filetypeData = await dashboardService.resourcesCountByFiletype();
      } catch (error) {}
    },
    async imStructureProcessingStatus() {
      try {
        this.processingProgress = await dashboardService.processingStatus();
      } catch (error) {}
    },
    async usersProcessingStatus() {
      try {
        this.usersProgress = await dashboardService.usersStatus();
      } catch (error) {}
    },
  },
  mounted() {
    this.setup();
  },
};
</script>

<style>
.admin-dashboard .progress {
  height: 2rem;
}
.admin-dashboard .progress-divider {
  border-bottom: 2px solid #ccc;
}
.admin-dashboard .progress-bar.bg-success {
  background-color: #5f8dda !important;
}
</style>
