<template>
  <div class="save-context-wrapper">
    <div class="create-form">
      <div>
        <div class="dark-color mb-1 input-label">Name</div>
        <b-form-group id="save-filter-form" label-for="input-1">
          <textarea
            class="context-title-field light-bg"
            v-model="title"
            required
            placeholder="Type a name"
            maxlength="80"
          ></textarea>
        </b-form-group>
      </div>
      <div class="button-wrp">
        <!-- <b-button @click.prevent="saveContext" block variant="primary">{{
          this.editing ? "Update" : "Add to favorites"
        }}</b-button> -->
        <Button
          class="w-100"
          :label="this.editing ? 'Update' : 'Add to favorites'"
          :clickHandler="saveContext"
          :showLoader="true"
          :isLoading="isLoading"
          :disabled="isLoading"
        />
      </div>
    </div>
    <div class="view-all">
      <a href="#" @click.prevent="openFavorites" class="dark-color fade-on-hover text-decoration-none dark-color-hover"
        >View all favorites</a
      >
    </div>
    <div>
      <FavoriteContexts ref="favoriteContexts" />
      <b-modal v-model="overrideConfirmation" size="sm" scrollable :hide-footer="true" class="favorite-modal" centered>
        <template v-slot:header>
          <div class="favorite-modal-header">
            <div class="modal-close-btn" @click="overrideConfirmation = false">
              <font-awesome-icon class="close-btn" size="lg" icon="times" />
            </div>
            <div class="favorit-modal-title">Overwrite favorite?</div>
          </div>
        </template>

        <div class="favorite-modal-body">
          <div>
            <div class="mb-5">"{{ this.title }}" favorite already exists, do you want to overwrite this item?</div>
            <div class="d-flex justify-content-center">
              <div class="me-2">
                <Button :clickHandler="() => (overrideConfirmation = false)" label="Cancel" background="light" />
              </div>
              <div>
                <Button
                  :clickHandler="this.confirmOverwrite"
                  label="Overwrite"
                  background="red"
                  :showLoader="true"
                  :isLoading="isLoading"
                  :disabled="isLoading"
                />
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { translateQueryStringToDataObject } from '@/helpers/bookmark';
import Button from '@/components/v2/Button';
import { genericService } from '@/services';
import apiUrls from '../config/apiUrls';

import FavoriteContexts from '@/components/FavoriteContexts.vue';

export default {
  components: {
    Button,
    FavoriteContexts,
  },
  mounted() {
    this.editing = false;
  },
  data() {
    return {
      showForm: false,
      title: '',
      showContextsList: false,
      savedContextsState: {},
      editing: false,
      updatable: false,
      openMenuId: -1,

      overrideConfirmation: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters['loader/getLoadingStatus'];
    },
  },
  methods: {
    openFavorites() {
      this.$refs.favoriteContexts.openFavorites();
    },
    saveContext() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      const insertable = {
        name: this.title,
        contextdata: translateQueryStringToDataObject(),
      };
      const model = genericService.create(apiUrls.USER_SAVED_CONTEXT, {
        hideErrorAlert: true,
        returnResponseOnError: true,
      });
      model(insertable)
        .then((res) => {
          this.$store.dispatch('alert/successWithTimer', {
            message: `Added as new favorite`,
            seconds: 3,
          });
          this.title = '';
          this.showForm = false;
          this.editing = false;
          this.updatable = false;
          this.$store.dispatch('loader/setLoadingStatus', false);
        })
        .catch((er) => {
          const { usersavedcontextid = false } = er.payload;
          if (usersavedcontextid) {
            this.updatable = usersavedcontextid;
            this.overrideConfirmation = true;
          } else {
            this.$store.dispatch('alert/error', er.error || `Unable to save this context environment`);
            this.showForm = false;
            this.editing = false;
            this.updatable = false;
          }
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
    confirmOverwrite() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      const model = genericService.update(apiUrls.USER_SAVED_CONTEXT);
      model({
        usersavedcontextid: this.updatable,
        name: this.title,
        contextdata: translateQueryStringToDataObject(),
      })
        .then((r) => {
          this.$store.dispatch('savedContext/fetchSavedContexts');
          this.title = '';
          this.showForm = false;
          this.editing = false;
          this.updatable = false;
          this.overrideConfirmation = false;
          this.$store.dispatch('alert/success', `Favorite item has been updated`);
          this.$store.dispatch('loader/setLoadingStatus', false);
        })
        .catch((er) => {
          this.updatable = false;
          this.overrideConfirmation = false;
          this.$store.dispatch('alert/error', er || `Unable to overwrite the favorite`);
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
  },
};
</script>

<style scoped>
.modal-close-btn {
  position: absolute;
  right: -5px;
  top: -5px;
  margin-top: -5px;
  color: #222;
  cursor: pointer;
  opacity: 0.6;
  padding: 20px;
}
.modal-close-btn:hover {
  opacity: 1;
}
.favorit-modal-title {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #24125f;
}
.save-context-wrapper #save-filter-form {
  margin-bottom: 4px;
}
.save-context-wrapper .button-wrp {
  margin-top: 10px;
}
.save-context-wrapper .button-wrp .btn {
  border-radius: 0px;
  margin: 0px;
  background-color: #24125f;
  border: none;
  padding: 12px 15px;
}
.save-context-wrapper .fdr_label {
  color: #666;
  padding: 0px;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 5px;
}
.save-context-wrapper .context_form_close_btn {
  position: absolute;
  right: 0;
  top: 0;
  color: #bbb;
  margin-top: 4px;
  margin-right: 24px;
}
.save-context-wrapper .context_form_close_btn:hover {
  color: #999;
}
.create-form {
  padding-bottom: 12px;
}
.context-title-field {
  height: 50px;
  width: 100%;
  resize: none;
  border: none;
  border-radius: 0px;
  outline-color: rgb(233, 233, 233);
  padding: 5px 10px;
}
.context-title-field:focus {
  outline-style: solid;
}
.view-all {
  margin-top: 30px;
  text-align: center;
  margin-bottom: 10px;
}
.view-all a {
  font-size: 16px;
  border-bottom: 2px solid #555081;
}

:global(.modal-header) {
  background-color: #f7f7f7;
  padding: 0;
}

.favorite-modal-header {
  background-color: #f7f7f7;
  padding: 25px;
}

.favorite-modal-body {
  padding: 30px;
}
</style>
