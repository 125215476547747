<template>
  <div class="admin-base-ui">
    <div class="admin-base-container">
      <div class="base-ui-header">
        <div class="base-ui-header-left">
          <div class="base-ui-header-title">
            {{ this.title }}
            <span v-if="this.total" class="count">({{ this.total }})</span>
          </div>
          <p v-if="this.tip" class="base-ui-header-tip">{{ this.tip }}</p>
        </div>
        <div v-if="this.showSearchBox" class="base-ui-header-body">
          <AdminBaseSearch />
        </div>
        <div v-if="this.create" class="base-ui-header-right">
          <Button :label="`Add ${this.title}`" :to="this.create" />
        </div>
        <div v-if="this.handleSubmit !== false" class="base-ui-header-right">
          <Button
            label="Save"
            :clickHandler="this.handleSubmit"
            :showLoader="true"
            :isLoading="isLoading && !showFullpageLoader"
            :disabled="isLoading && !showFullpageLoader"
          />
        </div>
      </div>
      <div v-if="this.showPagination" class="base-ui-paginator-wrapper">
        <BaseUiPaginator />
      </div>
      <div v-else class="mb-3"></div>
      <div class="base-ui-body">
        <div v-if="isLoading && showFullpageLoader" class="base-ui-header-title text-center mt-5">Loading...</div>
        <slot v-else></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/v2/Button';
import AdminBaseSearch from '@/components/admin/v2/base-ui/AdminBaseSearch';
import BaseUiPaginator from '@/components/admin/v2/base-ui/BaseUiPaginator';

export default {
  props: {
    title: {
      type: String,
      default: 'Admin UI',
    },
    tip: {
      type: String,
      default: '',
    },
    showSearchBox: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    searchEndpoint: {
      type: String,
      default: '',
    },
    create: {
      type: String,
      default: '',
    },
    handleSubmit: {
      default: false,
    },
  },
  components: {
    Button,
    AdminBaseSearch,
    BaseUiPaginator,
  },
  computed: {
    response() {
      return this.$store.state.dataTable.data;
    },
    isLoading() {
      return this.$store.getters['loader/getLoadingStatus'];
    },
    showFullpageLoader() {
      return this.$store.getters['loader/getFullpageLoaderStatus'];
    },
    total() {
      const { data } = this.response;
      if (!data) {
        return 0;
      } else {
        const [row] = data;
        if (!row) return 0;
        return row.total_results ? row.total_results : 0;
      }
    },
  },
};
</script>

<style scoped>
.admin-base-ui {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.admin-base-container {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}
.base-ui-header {
  display: flex;
  flex-direction: row;
}
.base-ui-body {
  flex-grow: 1;
  overflow: auto;
}
.base-ui-header-left {
  flex-basis: 400px;
}
.base-ui-header-body {
  flex-grow: 1;
  flex-basis: 300px;
  padding-bottom: 5px;
}
.base-ui-header-left {
  padding: 20px;
  padding-left: 40px;
  padding-bottom: 5px;
}
.base-ui-header-title {
  font-size: 22px;
  font-weight: 500;
  color: #23125f;
}
.base-ui-header-title .count {
  font-size: 20px;
  font-weight: normal;
  color: #23125f;
}
.base-ui-header-tip {
  font-size: 14px;
  color: #23125f;
}
.base-ui-header-right {
  padding: 20px;
  padding-right: 40px;
  flex: 1;
  text-align: right;
  padding-bottom: 5px;
}
</style>
