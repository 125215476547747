<template>
  <div class="intop-data-table-options">
    <b-dropdown size="lg" variant="link" class="text-decoration-none" no-caret dropleft>
      <template #button-content>
        <img src="@/assets/dots.svg" />
      </template>
      <b-dropdown-item :to="`/${this.page}/${this.id}`">Edit</b-dropdown-item>
      <DeleteConfirmation
        class="dropdown-item ps-0"
        displayButtonLabel="Delete"
        :entityLabel="deleteModalEntityName"
        :handleConfirm="handleDelete"
        :hideForm="hideDeleteConfirmationModalForm"
        :formHideMessage="deleteConfirmationModalFormHideMessage"
      />
      <slot></slot>
    </b-dropdown>
  </div>
</template>

<script>
import { genericService } from '@/services';
import DeleteConfirmation from '@/components/admin/imStructure/forms/DeleteConfirmation';

export default {
  props: [
    'id',
    'endpoint',
    'page',
    'deleteModalEntityName',
    'identifier',
    'hideDeleteConfirmationModalForm',
    'deleteConfirmationModalFormHideMessage',
  ],
  components: {
    DeleteConfirmation,
  },
  methods: {
    handleDelete() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      const currentPage = this.$store.state.dataTable.page;
      const existingTotalItems = this.$store.state.dataTable.data.data.length;
      const model = genericService.remove(this.endpoint);
      model({
        [this.identifier]: this.id,
      })
        .then((r) => {
          setTimeout(() => {
            if (currentPage > 1 && existingTotalItems === 1) {
              this.$store.dispatch('dataTable/setPage', currentPage - 1);
            }
            this.$nextTick(() => {
              this.$store.dispatch('dataTable/fetch');
            });
            this.$store.dispatch('loader/setLoadingStatus', false);
          }, 0);
        })
        .catch((e) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('alert/error', e || `Unable to delete the ${deleteModalEntityName}, please try again!`);
        });
    },
  },
};
</script>

<style>
.intop-data-table-options ul.dropdown-menu {
  border: none;
  border-radius: 0px;
  box-shadow: 0px 1px 5px 0px #ddd;
}
.intop-data-table-options button.btn {
  border-radius: 0px;
  padding-top: 1px;
}
</style>
<style scoped>
img {
  width: 10px;
  height: 20px;
}
</style>
