<template>
  <!-- progress Im structure -->
  <div class="py-4 mx-4" v-if="processingProgress">
    <div class="row">
      <div class="col-11 offset-1">
        <div class="row">
          <div class="col-12">
            <h6 class="title">Processing IM Structure</h6>
          </div>
          <div class="col-12">
            <div class="row mb-4 mt-2">
              <div class="col-10">
                <div class="progress rounded-0 bg-white">
                  <div
                    class="progress-bar bg-success"
                    role="progressbar"
                    :style="{
                      width: processingProgress.processingProgress + '%',
                    }"
                    :aria-valuenow="processingProgress.processingProgress"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div class="col-2 pl-4 d-flex justify-content-end align-items-center">
                {{ processingProgress.processingProgress }}%
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <Button
                  class="mb-4 me-2"
                  label="Reprocess now"
                  background="inverse-dark"
                  :clickHandler="processPattern"
                />

                <Button class="mb-4" label="Reset cache" background="inverse-dark" :clickHandler="resetProcessor" />
              </div>
            </div>
          </div>
          <div class="col-12 pt-4">
            <div class="progress-divider"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/v2/Button';
import { backendService } from '@/services';

export default {
  name: 'IMStructureProcessingProgress',
  props: ['processingProgress'],
  components: {
    Button,
  },
  methods: {
    async processPattern() {
      try {
        // process lookup pattern
        await backendService.post({
          name: 'Autoupdate Lookup',
          url: '/processor/v1/admin/update-lookuppatterns',
          method: 'POST',
          service: 'processor',
        });
        // process pattern
        await backendService.post({
          name: 'Process Pattern',
          url: '/processor/v1/admin/process-patterns',
          method: 'POST',
          service: 'processor',
        });

        this.$store.dispatch('alert/successWithTimer', {
          message: 'Resourse processing is send to queue',
          seconds: 3,
        });
      } catch (error) {
        this.$store.dispatch('alert/error', 'Resourse processing failed');
      }
    },
    async resetProcessor() {
      try {
        // reset processor
        const response = await backendService.post({
          name: 'Refresh Access',
          url: '/processor/v1/admin/refresh-access',
          method: 'POST',
          service: 'processor',
        });

        this.$store.dispatch('alert/successWithTimer', {
          message: response.message,
          seconds: 3,
        });
      } catch (error) {
        this.$store.dispatch('alert/error', error || `Request Failed`);
      }
    },
  },
};
</script>
