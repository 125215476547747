<template>
  <div class="taggroups">
    <div class="label-wrapper">
      <div class="_label">Tag Groups</div>
      <div class="searcharea">
        <SearchForm name="Tag Group" />
      </div>
    </div>
    <div class="card-containers">
      <drop
        v-for="(taggroup, index) in tagGroups"
        class="drop"
        :key="index + 'tg'"
        :id="'tg' + taggroup.taggroupid"
        @dragover="handleDragover(taggroup.taggroupid, $event)"
        @dragleave="handleDragleave(taggroup.taggroupid, $event)"
        @drop="handleDrop(taggroup.taggroupid, $event)"
      >
        <ImCard
          :key="index + 'tgCard'"
          :isLoading="droppedTagGroup === taggroup.taggroupid ? isLoading : false"
          :label="taggroup.name"
          variant="blue"
          :fill="false"
          :item="taggroup"
          name="tagGroup"
          :isStickyCard="taggroup.isSticky ? taggroup.isSticky : false"
          :shiftClickActionHandler="(taggroupid) => handleStickyClick(taggroupid)"
        />
      </drop>

      <ImPagination name="tagGroups" />
      <ImAddButton name="tagGroup" />
    </div>
  </div>
</template>

<script>
import ImCard from './ImCard';
import ImAddButton from './ImAddButton';
import SearchForm from './forms/SearchForm';
import ImPagination from './ImPagination';
import { mapActions, mapGetters } from 'vuex';
import Loader from '@/components/v2/Loader';
import { genericService, nodeService } from '@/services';
import apiUrls from '@/config/apiUrls';
import _ from 'lodash';
import { Drop } from '@/components/dragDrop';

export default {
  components: {
    ImCard,
    ImAddButton,
    SearchForm,
    ImPagination,
    Drop,
    Loader,
  },
  data() {
    return {
      over: false,
      droppedTagGroup: null,
      draggedTag: null,
    };
  },
  created() {
    this.$store.dispatch('imStructure/updateTagGroup');
  },
  computed: {
    ...mapGetters({
      isLoading: 'loader/getLoadingStatus',
      tagGroups: 'entities/getTagGroups',
    }),
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'loader/setLoadingStatus',
    }),
    handleDragover(tagGroup, data) {
      this.addDragEffect(tagGroup);
    },
    handleDragleave(tagGroup, data) {
      this.removeDragEffect(tagGroup);
    },
    async handleDrop(tagGroup, data) {
      this.removeDragEffect(tagGroup);
      this.droppedTagGroup = tagGroup;
      this.draggedTag = { ...data };
      await this.createTag();
    },
    addDragEffect(tagGroup) {
      document.getElementById(`tg${tagGroup}`).classList.add('over');
    },
    removeDragEffect(tagGroup) {
      document.getElementById(`tg${tagGroup}`).classList.remove('over');
    },
    async createTag() {
      this.setLoadingStatus(true);
      const model = await genericService.create(apiUrls.DROP_TAG);
      const parentTagId = this.draggedTag.parenttagid ? this.draggedTag.parenttagid : this.draggedTag.tagid;
      model({
        parentTagId: parentTagId,
        taggroupid: this.droppedTagGroup,
      })
        .then(async (r) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          await this.$store.dispatch('imStructure/updateTag', {
            offset: 0,
            limit: this.$store.state.imStructure.tagGroup.length
              ? this.$store.state.entities.tags.data.length + this.$store.state.entities.stickyTags.length
              : this.$store.state.entities.tags.data.length + 1,
          });
          this.$store.dispatch('entities/fetchStickyTagAndTagGroups');
          this.$store.dispatch('alert/successWithTimer', {
            message: `Successfully added linked tag`,
            seconds: 2,
          });
        })
        .catch((e) => {
          this.$store.dispatch('alert/error', e || `Unable to remove the tag group, please try again!`);
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
    handleStickyClick(taggroupid) {
      let tagGroup = _.cloneDeep(this.tagGroups.find((item) => item.taggroupid === taggroupid));
      if (tagGroup.isSticky === true) {
        this.$store.dispatch('entities/removeStickyTagGroup', taggroupid);
      } else {
        tagGroup.isSticky = true;
        this.$store.dispatch('entities/updateStickytagGroup', tagGroup);
      }
    },
  },
};
</script>

<style scoped>
.taggroups {
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
}
._label {
  font-size: 14px;
  line-height: 26px;
  color: #688197;
  margin-right: 30px;
}
.card-containers {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.label-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.drop.over {
  background-color: #2d850b;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
}
</style>
