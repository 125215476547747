<template>
  <div class="dimension-form">
    <div class="formlabel mb-3">
      <div class="labeltext">{{ this.edit ? 'Edit' : 'Create ' }} dimension</div>
      <div class="labelaction">
        <DeleteConfirmation displayButtonLabel="Delete" entityLabel="dimension" :handleConfirm="handleDelete" />
      </div>
    </div>
    <b-form class="form-wrapper">
      <div class="mb-3">
        <InputBox
          variant="white"
          label="Name"
          :required="true"
          :getFunc="() => this.name"
          :setFunc="(name) => (this.name = name)"
          reference="dimensionname"
        />
      </div>
      <div class="mb-3">
        <InputBox
          variant="white"
          :textarea="true"
          label="Description"
          :getFunc="() => this.description"
          :setFunc="(description) => (this.description = description)"
        />
      </div>
      <div class="text-right">
        <Button
          :label="this.edit ? 'Update' : 'Save'"
          background="dark"
          :showLoader="true"
          :isLoading="isLoading"
          :disabled="isLoading || !isFormChanged"
          :clickHandler="handleSubmit"
        />
      </div>
    </b-form>
  </div>
</template>

<script>
import { genericService } from '@/services';
import InputBox from '@/components/admin/v2/InputBox';
import Button from '@/components/v2/Button';
import DeleteConfirmation from './DeleteConfirmation';
import apiUrls from '@/config/apiUrls';

export default {
  data() {
    return {
      dimensionid: null,
      name: '',
      description: '',
      dbName: '',
      dbDescription: '',
      isNameChanged: false,
      isDescriptionChanged: false,
    };
  },
  watch: {
    description(newVal) {
      this.isDescriptionChanged = newVal !== this.dbDescription;
    },
    name(newVal) {
      this.isNameChanged = newVal !== this.dbName;
    },
  },
  created() {
    if (this.edit) {
      const { dimensionid, name, description } = this.editableData;
      this.name = name;
      this.dbName = name;
      this.description = description;
      this.dbDescription = description;
      this.dimensionid = dimensionid;
    }
  },
  components: {
    Button,
    InputBox,
    DeleteConfirmation,
  },
  computed: {
    edit() {
      return this.$store.state.imStructure.form.mode === 'edit';
    },
    editableData() {
      return this.$store.state.imStructure.form.editable;
    },
    isLoading() {
      return this.$store.getters['loader/getLoadingStatus'];
    },
    isFormChanged() {
      return this.edit ? this.isDescriptionChanged || this.isNameChanged : true;
    },
  },
  methods: {
    create() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      const model = genericService.create(apiUrls.IMS_DIMENSION);
      model({
        name: this.name.trim(),
        description: this.description.trim(),
      })
        .then((r) => {
          // this.$store.dispatch('alert/success', `The dimension has been created successfully`)
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('imStructure/updater', {
            form: { showModal: false },
          });
          this.$store.dispatch('entities/fetchList', [{ url: apiUrls.IMS_DIMENSION, entity: 'dimensions' }]);
        })
        .catch((e) => {
          this.$store.dispatch('alert/error', e || `Unable to create the dimension, please try again!`);
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
    modify() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      const model = genericService.update(apiUrls.IMS_DIMENSION);
      model({
        dimensionid: this.dimensionid,
        name: this.name.trim(),
        description: this.description.trim(),
      })
        .then((r) => {
          // this.$store.dispatch('alert/success', `The dimension has been updated successfully`)
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('imStructure/updater', {
            form: { showModal: false },
          });
          this.$store.dispatch('entities/fetchList', [{ url: apiUrls.IMS_DIMENSION, entity: 'dimensions' }]);
        })
        .catch((e) => {
          this.$store.dispatch('alert/error', e || `Unable to update the dimension, please try again!`);
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
    handleSubmit(event) {
      event.preventDefault();
      if (!this.edit) {
        // Create dimension.
        this.create();
      } else {
        // Update a dimension.
        this.modify();
      }
    },
    handleDelete() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      const model = genericService.remove(apiUrls.IMS_DIMENSION);
      model({
        dimensionid: this.dimensionid,
      })
        .then(async (r) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('imStructure/updater', {
            form: { showModal: false },
          });
          if (this.$store.state.imStructure.dimension.length) {
            this.$store.dispatch('imStructure/updater', {
              dimension: this.$store.state.imStructure.dimension.filter((e) => e != this.dimensionid),
            });
          }
          this.$store.dispatch('entities/fetchList', [{ url: apiUrls.IMS_DIMENSION, entity: 'dimensions' }]);
          await this.$store.dispatch('imStructure/updateTagGroup');
          await this.$store.dispatch('imStructure/updateTag');
        })
        .catch((e) => {
          this.$store.dispatch('alert/error', e || `Unable to delete the dimension, please try again!`);
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
    handleCancel() {
      this.$refs.confitmationdropdown.hide(true);
    },
  },
};
</script>

<style scoped>
.dimension-form {
  padding: 30px;
}
.formlabel .labeltext {
  color: #24125f;
  font-size: 22px;
  flex-grow: 1;
}
.formlabel {
  display: flex;
  align-items: center;
}
.labelaction {
  /* border: 1px solid green; */
  cursor: pointer;
}
</style>
