<template>
  <div>
    <Datepicker
      :id="componentId"
      :key="componentId"
      ref="datePickerInput"
      :class="`intop-datepicker ${filter}`"
      :placeholder="placeholder"
      :model-value="value"
      model-type="yyyy-MM-dd"
      @update:modelValue="setValue"
      :clearable="true"
      autoApply
      locale="en"
      :enableTimePicker="false"
      :disabled-dates="notAfterToday"
    >
      <template #dp-input="{ value }">
        <div class="intop-datepicker-input">{{ formattedTitle }}</div>
      </template>
    </Datepicker>
  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';

export default {
  name: 'DateFilter',
  props: ['filter', 'placeholder'],
  components: {
    Datepicker,
  },
  methods: {
    setValue(val) {
      const sqlDate = val ?? '';
      const action = this.filter === 'modifiedBefore' ? 'setModifiedBefore' : 'setModifiedFrom';

      this.$store.dispatch(`filterParams/${action}`, sqlDate);
    },
    formatDate(val) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date(val);
      return date.toLocaleDateString('en-US', options);
    },
    notAfterToday(date) {
      return date > new Date(new Date());
    },
  },
  computed: {
    componentId() {
      return `filter${this.filter}`;
    },
    value() {
      return this.$store.state.filterParams[this.filter];
    },
    formattedTitle() {
      if (!this.value) return this.placeholder;
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date(this.value);
      return date.toLocaleDateString('en-US', options);
    },
  },
};
</script>

<style>
.intop-datepicker {
  width: 284px !important;
}

.intop-datepicker-input {
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  background: #fff;
  padding: 0 0 0 5px;
  width: 100%;
  -webkit-transition: border 0.1s ease;
  transition: border 0.1s ease;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top;
  border: 1px solid #c2c2c263;
  color: #262261;
}
.popup-datepicker {
  width: 284px !important;
  padding-left: 1rem !important;
}
</style>
