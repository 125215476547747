import apiUrls from '../config/apiUrls';
import config from '../config/config';
import { authHeader, handleApiResponse } from '../helpers';

function getWelcomeMessageData() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}${apiUrls.WELCOMEPAGE_DETAILS}`, requestOptions).then(handleApiResponse);
}

function updateWelcomeMessageData(data) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}${apiUrls.WELCOMEPAGE_UPDATE}`, requestOptions).then(handleApiResponse);
}

export const welcomePageService = {
  getWelcomeMessageData,
  updateWelcomeMessageData,
};
