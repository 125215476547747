import { createStore } from 'vuex';

import { alert } from './alert.module';
import { authentication } from './authentication.module';
import { users } from './users.module';
import { filterParams } from './filterParams.module';
import { contexts } from './contexts.module';
import { pagination } from './pagination.module';
import { adminFilters } from './adminFilters.module';
import { welcomePage } from './welcomePage.module';
import { typeAheadSearch } from './typeAheadSearch.module';
import { contextManager } from './contextManager.module';
import { supportPage } from './supportPage.module';
import { savedContext } from './savedContext.module';
import { imStructure } from './imStructure.module';
import { entities } from './entities.module';
import { app } from './app.module';
import { dataTable } from './dataTable.module';
import { resource } from './resource.module';
import { loginConfig } from './loginConfig.module';
import { loader } from './loader.module';

export const store = createStore({
  modules: {
    app,
    alert,
    users,
    contexts,
    dataTable,
    pagination,
    adminFilters,
    welcomePage,
    filterParams,
    authentication,
    typeAheadSearch,
    contextManager,
    savedContext,
    entities,
    imStructure,
    supportPage,
    savedContext,
    resource,
    loginConfig,
    loader,
  },
});
