import { welcomePageService } from '@/services';

export const welcomePage = {
  namespaced: true,
  state: {
    body: '',
    right_sidebar: '',
    loading: true,
    welcomePageIsUnique: true,
  },
  actions: {
    getWelcomeMessage({ commit }) {
      commit('setLoading', true);
      welcomePageService.getWelcomeMessageData().then((data) => {
        commit('setLoading', false);
        commit('setWelcomeMessageData', data.data);
      });
    },
    toggleUniqness({ commit }, status) {
      commit('uniqueness', status);
    },
  },
  mutations: {
    setWelcomeMessageData(state, data) {
      state.body = data.body;
      state.right_sidebar = data.right_sidebar;
    },
    setLoading(state, status) {
      state.loading = status;
    },
    uniqueness(state, status) {
      state.welcomePageIsUnique = status;
    },
  },
};
