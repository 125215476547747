<template>
  <b-dropdown ref="dropdown" class="user-menu-wrapper" right variant="default" no-caret>
    <template v-slot:button-content>
      <font-awesome-icon class="user-menu-cog-icon" size="lg" icon="cog" />
    </template>
    <b-dropdown-text>
      <span class="fw-bold text-blue"
        >Logged in as {{ user.name }} <span v-if="user.customer">| {{ user.customer.name }}</span></span
      >
    </b-dropdown-text>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item class="ms-1" v-if="isAdmin" to="manage/admin">Admin</b-dropdown-item>
    <div class="dropdown-item ms-2" v-if="displayResourceTable">
      <b-form-checkbox :checked="isContentExcerptShown" @change="handleToggleExcerpt"
        >Show content excerpts</b-form-checkbox
      >
    </div>
    <b-dropdown-item-button class="ms-2" @click="showWelcomePage">Show Welcome Page</b-dropdown-item-button>
    <b-dropdown-item-button class="ms-2" @click="switchTenant" v-if="hasMultipleTenant"
      ><span>Change customer</span></b-dropdown-item-button
    >
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item-button class="ms-2" @click="onClickLogout">Sign out</b-dropdown-item-button>
  </b-dropdown>
</template>

<script>
import SaveContextForm from '@/components/SaveContextForm';
export default {
  name: 'UserMenu',
  components: {
    SaveContextForm,
  },
  data() {
    return {};
  },
  computed: {
    isAdmin() {
      return this.$store.getters['authentication/isAdmin'];
    },
    isContentExcerptShown() {
      return this.$store.state.users.displayContentExcerpts;
    },
    displayResourceTable() {
      return this.$store.state.users.displayResourceTable;
    },
    user() {
      return this.$store.state.authentication.user.user;
    },
    hasMultipleTenant() {
      return this.$store.state.authentication.user.hasMultipleTenant || false;
    },
  },
  methods: {
    onClickLogout() {
      this.$store.dispatch('authentication/logout');
    },
    handleToggleExcerpt() {
      this.$store.dispatch('users/controlContentExcerpts');
    },
    showWelcomePage() {
      const welcomePage = this.$store.state.users.displayWelcomeMessage;
      const resourcePage = this.$store.state.users.displayResourceTable;
      this.$store.dispatch('users/toggleWelcomeMessage', !welcomePage);
      this.$store.dispatch('users/toggleResourceTable', !resourcePage);
    },
    switchTenant() {
      localStorage.removeItem('user');
      location.reload();
    },
  },
};
</script>

<style>
.user-menu-wrapper ul.dropdown-menu {
  width: 270px;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 1px 5px 0px #ddd;
  margin-top: 10px;
}

.user-menu-wrapper .menu-item {
  padding: 4px 24px;
  cursor: pointer;
  position: relative;
}

.user-menu-wrapper .menu-item:hover {
  background-color: #f8f9fa;
}

.user-menu-wrapper button.dropdown-toggle {
  background-color: #24125f;
  color: #fff;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-menu-wrapper button.dropdown-toggle:hover {
  opacity: 0.8;
}

.user-menu-wrapper .user-menu-cog-icon {
  width: 12px;
  color: #fff;
}
</style>

<style scoped>
.user-menu-button {
  border-radius: 0px;
  padding: 0px;
  margin: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
