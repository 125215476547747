<template>
  <b-dropdown ref="dropdown" class="intop-favorites-menu" right variant="default" no-caret>
    <template v-slot:button-content>
      <font-awesome-icon class="favorites-menu-icon" size="lg" icon="star" />
    </template>
    <div class="favorites-form-wrapper">
      <SaveContextForm />
    </div>
  </b-dropdown>
</template>

<script>
import SaveContextForm from '@/components/SaveContextForm';
export default {
  name: 'UserMenu',
  components: {
    SaveContextForm,
  },
};
</script>

<style>
.intop-favorites-menu ul.dropdown-menu {
  width: 250px;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 1px 5px 0px #ddd;
  margin-top: 10px;
  padding: 25px;
}
.intop-favorites-menu .menu-item {
  padding: 4px 24px;
  cursor: pointer;
  position: relative;
}
.intop-favorites-menu .menu-item:hover {
  background-color: #f8f9fa;
}
.intop-favorites-menu button.dropdown-toggle {
  background-color: #24125f;
  color: #fff;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.intop-favorites-menu button.dropdown-toggle:hover {
  opacity: 0.8;
}
.intop-favorites-menu .favorites-menu-icon {
  width: 12px;
  color: #fff;
}
</style>

<style scoped>
.user-menu-button {
  border-radius: 0px;
  padding: 0px;
  margin: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
