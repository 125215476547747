<template>
  <div class="textinput">
    <div class="input-field">
      <input
        :ref="reference"
        type="text"
        :placeholder="this.placeholder"
        :value="this.modelValue"
        @input="handleChange"
      />
    </div>
    <div class="icon">
      <font-awesome-icon class="support-menu-icon" size="lg" icon="pencil-alt" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      default: '',
    },
    placeholder: {
      default: '',
    },
    reference: {
      default: '',
    },
  },
  emits: ['update:modelValue'],
  methods: {
    handleChange(v) {
      this.$emit('update:modelValue', v.target.value);
    },
  },
  mounted() {
    if (this.reference) {
      setTimeout(() => {
        this.$refs[this.reference].focus();
      }, 100);
    }
  },
};
</script>

<style scoped>
.textinput {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.input-field {
  min-width: 200px;
}
input {
  border: none;
  background-color: transparent;
  outline: none;
  display: inline-block;
  width: 100%;
}

input,
input::placeholder {
  font-size: 20px;
  color: #24125f;
  padding-right: 10px;
}
</style>
