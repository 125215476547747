<template>
  <AdminFormBaseUi
    backText="Fileshare Mapping"
    backTo="/manage/filesharemapping"
    :currentPage="`${this.edit ? 'Update' : 'Add new'} fileshare mapping`"
  >
    <h3>{{ this.edit ? 'Update' : 'Create' }}</h3>
    <!-- <div class="form-wrapper">
      <Checkbox label="Enable fileshare mapping" v-model="enabled" />
    </div> -->
    <form class="form-wrapper">
      <div class="field-wrapper">
        <InputBox variant="dim" label="Server Location" :required="true" v-model="serverlocation" />
      </div>
      <div class="field-wrapper">
        <InputBox variant="dim" label="Local Location" :required="true" v-model="locallocation" />
      </div>
      <div class="text-right">
        <Button
          :label="this.edit ? 'Update' : 'Save'"
          background="dark"
          :showLoader="true"
          :disabled="isLoading"
          :isLoading="isLoading && !showFullpageLoader"
          :clickHandler="handleSubmit"
        />
      </div>
    </form>
  </AdminFormBaseUi>
</template>

<script>
import { genericService } from '@/services';
import Button from '@/components/v2/Button';
import Checkbox from '@/components/Checkbox';
import InputBox from '@/components/admin/v2/InputBox';
import AdminFormBaseUi from '@/components/admin/v2/base-ui/AdminFormBaseUi';
import apiUrls from '../../../../config/apiUrls';

export default {
  created() {
    const editFileShare = this.$route.params.id === 'new' ? -1 : this.$route.params.id;
    if (editFileShare !== -1) {
      this.edit = true;
      this.$store.dispatch('loader/setLoadingStatus', true);
      this.$store.dispatch('loader/setFullpageLoaderStatus', true);
      genericService
        .index(apiUrls.FILESHARE_OFFICEMAPPING, 1, {
          get_in: this.$route.params.id,
        })()
        .then((res) => {
          const [item] = res.data;
          this.serverlocation = item.serverlocation;
          this.locallocation = item.locallocation;
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('loader/setFullpageLoaderStatus', false);
        })
        .catch((error) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('loader/setFullpageLoaderStatus', false);
        });
    } else {
      this.edit = false;
    }
  },
  data: function () {
    return {
      edit: false,
      enabled: false,
      serverlocation: '',
      locallocation: '',
    };
  },
  components: {
    Button,
    Checkbox,
    InputBox,
    AdminFormBaseUi,
  },
  computed: {
    isLoading() {
      return this.$store.getters['loader/getLoadingStatus'];
    },
    showFullpageLoader() {
      return this.$store.getters['loader/getFullpageLoaderStatus'];
    },
  },
  methods: {
    create() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      const model = genericService.create(apiUrls.FILESHARE_OFFICEMAPPING);
      model({
        serverlocation: this.serverlocation,
        locallocation: this.locallocation,
      })
        .then((r) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$router.push('/manage/filesharemapping');
        })
        .catch((e) => {
          this.$store.dispatch('alert/error', e || `Unable to create the fileshare mapping, please try again!`);
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
    modify() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      const model = genericService.update(apiUrls.FILESHARE_OFFICEMAPPING);
      model({
        filesharemappingid: this.$route.params.id === 'new' ? -1 : this.$route.params.id,
        serverlocation: this.serverlocation,
        locallocation: this.locallocation,
      })
        .then((r) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          console.log('updated:', r);
          this.$router.push('/manage/filesharemapping');
        })
        .catch((e) => {
          this.$store.dispatch('alert/error', e || `Unable to update the dimension, please try again!`);
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
    handleSubmit(event) {
      event.preventDefault();
      if (!this.edit) {
        this.create();
      } else {
        this.modify();
      }
    },
    handleDelete() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      const model = genericService.remove(apiUrls.FILESHARE_OFFICEMAPPING);
      model({
        filesharemappingid: this.$route.params.id,
      })
        .then((r) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          console.log('deleted:', r);
          this.$router.push('/manage/filesharemapping');
        })
        .catch((e) => {
          this.$store.dispatch('alert/error', e || `Unable to delete the dimension, please try again!`);
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
  },
};
</script>

<style scoped>
h3 {
  font-weight: 300;
  font-size: 22px;
  padding: 30px;
  padding-left: 40px;
  padding-bottom: 10px;
  color: #24125f;
}
.form-wrapper {
  max-width: 600px;
  padding: 0px 30px 40px 40px;
}
.field-wrapper {
  margin-bottom: 25px;
}
</style>
