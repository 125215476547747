import config from '../config/config';
import apiUrls from '../config/apiUrls';
import { authHeader, handleApiResponse } from '../helpers';

function sourcesOverview() {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };

  return fetch(`${config.apiUrl}${apiUrls.DASHBOARD_SOURCES}`, requestOptions).then(handleApiResponse);
}

function resourcesCountByFiletype() {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };

  return fetch(`${config.apiUrl}${apiUrls.DASHBOARD_RESOURCES_COUNT_BY_FILETYPE}`, requestOptions).then(
    handleApiResponse
  );
}

function processingStatus() {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };

  return fetch(`${config.apiUrl}${apiUrls.DASHBOARD_PROCESSING_STATUS}`, requestOptions).then(handleApiResponse);
}

function usersStatus() {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };

  return fetch(`${config.apiUrl}${apiUrls.DASHBOARD_USERS_STATUS}`, requestOptions).then(handleApiResponse);
}

export const dashboardService = {
  sourcesOverview,
  resourcesCountByFiletype,
  processingStatus,
  usersStatus,
};
