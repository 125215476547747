<template>
  <table class="chart-legend-table" v-if="chartData">
    <tr v-for="(label, type, index) in chartData" :key="type">
      <td class="align-top legend-type w-50">
        <div class="row no-gutters">
          <div class="col-3">
            <span
              class="colorBox"
              :style="{
                backgroundColor: colors[index] ? colors[index] : 'transparent',
              }"
            ></span>
          </div>
          <div class="col-9">{{ type }}</div>
        </div>
      </td>
      <td class="align-top text-right ps-2 w-50">{{ numFormat(label) }}</td>
    </tr>
  </table>
</template>

<script>
export default {
  props: ['chartData', 'colors'],
  methods: {
    numFormat(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
  },
};
</script>

<style scoped>
.chart-legend-table {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.25rem;
}
.w-40 {
  width: 40%;
}
.colorBox {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 5px;
}
.legend-type {
  word-break: break-word;
}
</style>
