<template>
  <Pie :data="chartData" :options="options" />
</template>

<script>
import { Pie } from 'vue-chartjs';
import 'chart.js/auto';

export default {
  name: 'PieChart',
  props: ['chartData'],
  components: {
    Pie,
  },
  data() {
    return {
      options: {
        responsive: true,
        title: {
          display: false,
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    };
  },
};
</script>

<style></style>
