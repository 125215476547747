<template>
  <div class="manager-wrapper" v-if="userHasAccess">
    <div class="manager-header">
      <div class="left">
        <Brand />
      </div>
      <div class="right">
        <Header />
      </div>
    </div>
    <div class="manager-container">
      <router-view />
    </div>
  </div>
  <Unknown v-else />
</template>

<script>
import Header from '@/components/admin/v2/Header';
import Brand from '@/components/admin/v2/Brand';
import Unknown from '@/components/admin/v2/Unknown';
import { hasAccessPermission } from '@/helpers/hasAccessPermission';

export default {
  name: 'ManagerWrapper',
  components: {
    Brand,
    Header,
    Unknown,
  },
  mounted() {
    if (this.$router.currentRoute.value.meta.roleRequired) {
      this.userHasAccess = hasAccessPermission(this.$router.currentRoute.value.meta.roleRequired);
    }
  },
  data() {
    return {
      userHasAccess: false,
    };
  },
};
</script>

<style scoped>
.manager-wrapper {
  background-color: #ededed;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.manager-header {
  background-color: #ffffff;
  display: flex;
}
.manager-header .left {
  min-width: 400px;
}
.manager-header .right {
  flex-grow: 1;
}
.manager-container {
  flex-grow: 1;
  height: 0;
}
</style>
