import apiUrls from '../config/apiUrls';
import config from '../config/config';
import { authHeader, handleApiResponse } from '../helpers';

function get() {
  const requestOptions = {
    method: 'GET',
  };

  return fetch(`${config.apiUrl}${apiUrls.LOGIN_CONFIG}`, requestOptions).then(handleApiResponse);
}

function store(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}${apiUrls.LOGIN_CONFIG_STORE}`, requestOptions).then(handleApiResponse);
}

export const loginConfigService = {
  get,
  store,
};
