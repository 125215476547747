<template>
  <div v-if="sources && chartData && chartData.labels.length > 0">
    <h6 class="title mb-3">Sources</h6>
    <div class="row">
      <div class="col-5">
        <PieChart v-if="chartData && isLoaded" :chartData="chartData" />
      </div>
      <div class="col-6 flex-row align-items-center mt-4">
        <PieChartLegend :chartData="legendData" :colors="colors" />
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from './PieChart';
import PieChartLegend from './PieChartLegend';
import { getColors } from '../../../../helpers/chartColors';

export default {
  name: 'SourcesChart',
  props: ['sources'],
  components: {
    PieChart,
    PieChartLegend,
  },
  data() {
    return {
      isLoaded: true,
    };
  },
  watch: {
    sources: {
      handler() {
        this.isLoaded = false;
        this.$nextTick(() => {
          this.isLoaded = true;
        });
      },
      deep: true,
    },
  },
  computed: {
    legendData() {
      const legendData = {};
      for (let label in this.chartData.labels) {
        legendData[this.chartData.labels[label]] = this.chartData.datasets[0].data[label] + ' files';
      }
      return legendData;
    },
    colors() {
      if (this.chartData) {
        return this.chartData.datasets[0].backgroundColor;
      }
      return [];
    },
    chartData() {
      if (this.sources) {
        const sortedData = this.sources
          .filter((source) => source.fromsource > 0)
          .sort((a, b) => (a.fromsource < b.fromsource ? 1 : -1));
        const labels = sortedData.filter((source) => source.fromsource > 0).map((source) => source.name);
        const data = sortedData.filter((source) => source.fromsource > 0).map((source) => source.fromsource);
        const colors = getColors(labels.length);

        return {
          labels: labels,
          datasets: [
            {
              data: data,
              borderWidth: 0,
              backgroundColor: colors,
              hoverBackgroundColor: colors,
            },
          ],
        };
      }
      return null;
    },
  },
};
</script>
