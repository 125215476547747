<template>
  <div
    class="loader"
    :class="[fullPage ? 'position-fixed' : 'position-absolute', size]"
    v-if="isLoading && (showFullpageLoader || propShowFullpageLoader)"
  >
    <i class="fas fa-circle-notch fa-spin spinner"></i>
  </div>
</template>
<script>
export default {
  name: 'loader',
  props: {
    fullPage: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'loader-lg',
    },
    propShowFullpageLoader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters['loader/getLoadingStatus'];
    },
    showFullpageLoader() {
      return this.$store.getters['loader/getFullpageLoaderStatus'];
    },
  },
};
</script>

<style scoped>
.loader {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
}
.loader .spinner {
  color: #262261;
}
.loader-lg {
  font-size: 6rem;
}
.loader-md {
  font-size: 4rem;
}
.loader-sm {
  font-size: 2rem;
}
.loader-xs {
  font-size: 1rem;
}
</style>
