<template>
  <DeleteConfirmation
    displayButtonLabel="Delete"
    entityLabel="tag"
    :handleConfirm="handleTagDelete"
    :customLabel="label"
    classAttr="no-margin-dc-menu"
  />
</template>
<script>
import DeleteConfirmation from './DeleteConfirmation';
import { genericService } from '@/services';
import apiUrls from '@/config/apiUrls';
import { nodeService } from '@/services';
export default {
  props: ['tag'],
  components: {
    DeleteConfirmation,
  },
  computed: {
    label: function () {
      if (this.tag.parenttagid) {
        return 'Are you sure you want to delete the linked tag? This will not delete the original tag.';
      }
      if (this.tag.haslink) {
        return 'Are you sure you want to delete tag? This will also delete all linked tags.';
      }
      return 'Are you sure you want to delete tag?';
    },
  },
  methods: {
    async handleTagDelete() {
      let limit = 1;
      this.$store.dispatch('loader/setLoadingStatus', true);
      if (this.tag.haslink) {
        const { count } = await nodeService.getLinkedTagCountByParentTag(this.tag.tagid);
        limit += count;
      }
      const model = genericService.remove(apiUrls.IMS_TAG);
      model({
        tagid: this.tag.tagid,
      })
        .then((r) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('entities/removeStickyTag', this.tag);
          this.$store.dispatch('imStructure/updater', {
            form: { showModal: false },
          });
          this.$store.dispatch('imStructure/updateTag', {
            offset: 0,
            limit:
              this.$store.state.entities.tags.data.length - limit < 0
                ? 0
                : this.$store.state.entities.tags.data.length - limit,
          });
        })
        .catch((e) => {
          this.$store.dispatch('alert/error', e || `Unable to remove the tag group, please try again!`);
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
  },
};
</script>
