<template>
  <AdminBaseUi title="Sign in Configuration" :showSearchBox="false" key="signInConfiguration">
    <div class="login-configuration-wrapper">
      <form @submit.prevent="handleSubmit">
        <div class="conf-cell">
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio
              v-for="(label, typeCombination) in loginTypeCombinations"
              :key="typeCombination"
              v-model="selectedLoginCombination"
              :aria-describedby="ariaDescribedby"
              name="login-type"
              :value="typeCombination"
              @change="setFormChanged"
              :disabled="onlyReadPermission()"
              required
            >
              {{ label }}
            </b-form-radio>
          </b-form-group>
        </div>
        <div class="conf-cell" v-if="showMicrosoftCofigFields">
          <h6>Enter microsoft configuration</h6>
          <div class="row">
            <div class="col-sm-3">
              <b-form-group id="client-id-label" label="Client Id" label-for="client-id">
                <b-form-input
                  id="client-id"
                  class="rounded-0"
                  v-model="microsoftConfig.clientId"
                  placeholder="Client Id"
                  @input="setFormChanged"
                  :disabled="onlyReadPermission()"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-sm-3">
              <b-form-group id="tenant-id-label" label="Tenant Id" label-for="tenant-id">
                <b-form-input
                  id="tenant-id"
                  class="rounded-0"
                  v-model="microsoftConfig.tenantId"
                  placeholder="Tenant Id"
                  @input="setFormChanged"
                  :disabled="onlyReadPermission()"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-sm-3">
              <b-form-group id="client-secret-label" label="Client Secret" label-for="client-secret">
                <b-form-input
                  id="client-secret"
                  class="rounded-0"
                  v-model="microsoftConfig.clientSecret"
                  :placeholder="clientSecretPlaceholder"
                  @input="setFormChanged"
                  :disabled="onlyReadPermission()"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="conf-cell">
          <Button
            label="Save"
            background="dark"
            :disabled="isLoading || !formChanged || onlyReadPermission()"
            :showLoader="true"
            :isLoading="isLoading"
          />
        </div>
      </form>
    </div>
  </AdminBaseUi>
</template>

<script>
import { mapGetters } from 'vuex';
import { loginConfigService } from '@/services';
import AdminBaseUi from '@/components/admin/v2/base-ui/AdminBaseUi';
import Button from '@/components/v2/Button';
import { hasAccessPermission } from '@/helpers/hasAccessPermission';

export default {
  components: {
    AdminBaseUi,
    Button,
  },
  data() {
    return {
      loginType: ['default', 'microsoft'],
      loginTypeCombinations: {
        default: 'Sign in with username',
        default_microsoft: 'Sign in with username or Microsoft',
        microsoft: 'Sign in with Microsoft',
      },
      selectedLoginCombination: '',
      microsoftConfig: {
        clientId: null,
        tenantId: null,
        clientSecret: null,
      },
      formChanged: false,
      clientSecretPlaceholder: 'Client Secret',
    };
  },
  watch: {
    loginConfig(newVal) {
      this.setConfig();
    },
    microsoftConfig: {
      deep: true,
      handler(val) {
        for (let key in val) {
          this.microsoftConfig[key] = val[key].trim();
        }
      },
    },
  },
  computed: {
    ...mapGetters({ loginConfig: 'loginConfig/getConfig' }),
    showMicrosoftCofigFields() {
      return this.selectedLoginCombination.split('_').includes('microsoft');
    },
    isLoading() {
      return this.$store.getters['loginConfig/getLoadingStatus'];
    },
  },
  methods: {
    handleSubmit() {
      this.$store.commit('loginConfig/setLoadingStatus', true);
      const login_config = {
        loginType: this.selectedLoginCombination.split('_'),
      };
      login_config['microsoftConfig'] = this.microsoftConfig;

      loginConfigService
        .store({ login_config })
        .then((res) => {
          this.$store.dispatch('loginConfig/setLoadingStatus', false);
          this.$store.dispatch('alert/successWithTimer', {
            message: `Settings saved`,
            seconds: 3,
          });
          this.$store.dispatch('loginConfig/getConfig');
        })
        .catch((err) => {
          this.$store.dispatch('loginConfig/setLoadingStatus', false);
          this.$store.dispatch('alert/error', err || `Unable to update the settings`);
        });
    },
    setConfig() {
      if (this.loginConfig) {
        if (this.loginConfig.loginType && this.loginConfig.loginType.length > 0) {
          if (this.loginConfig.loginType.length === 1) {
            this.selectedLoginCombination = this.loginConfig.loginType[0];
          } else {
            this.selectedLoginCombination = 'default_microsoft';
          }
        }

        if (this.loginConfig.microsoftConfig) {
          this.microsoftConfig = { ...this.loginConfig.microsoftConfig };
          this.clientSecretPlaceholder = this.microsoftConfig['clientSecret']
            ? this.microsoftConfig['clientSecret']
            : 'Client Secret';
          this.microsoftConfig['clientSecret'] = '';
        }
      }
    },
    setFormChanged() {
      this.formChanged = true;
    },
    onlyReadPermission() {
      return !hasAccessPermission([1]);
    },
  },
  mounted() {
    this.setConfig();
  },
  created() {
    this.$store.dispatch('dataTable/reset');
  },
};
</script>

<style scoped>
.login-configuration-wrapper {
  margin: 0px 40px;
  margin-top: 20px;
  background-color: #fff;
  padding: 40px;
  height: calc(100% - 20px);
}
.conf-cell {
  padding: 10px 10px;
}
</style>
