<template>
  <AdminBaseUi title="Advanced Settings" :showSearchBox="false">
    <div class="advance-settings-wrapper position-relative">
      <Loader :propShowFullpageLoader="true" />
      <div class="conf-cell">
        <Checkbox
          v-model="FILESHARE_MAPPING_ENABLED"
          label="Enable Office Protocol Handler"
          @change="(val) => this.handleChange('FILESHARE_MAPPING_ENABLED', val)"
          :disabled="this.onlyReadPermission()"
        />
        <div v-if="!this.onlyReadPermission()" :class="`conf-details ${!FILESHARE_MAPPING_ENABLED && 'disabled'}`">
          <router-link to="/manage/filesharemapping">Click here</router-link> to configure and manage fileshare
          mappings.
        </div>
      </div>
      <div class="conf-cell">
        <Checkbox
          v-model="FILESHARE_PDF_PASSTHROUGH_ENABLED"
          label="Enable PDF Passthrough"
          @change="(val) => this.handleChange('FILESHARE_PDF_PASSTHROUGH_ENABLED', val)"
          :disabled="this.onlyReadPermission()"
        />
      </div>
      <div class="conf-cell">
        <Checkbox
          v-model="SORT_RESULT_BY_RELEVANCE"
          label="Sort result by relevance"
          @change="(val) => this.handleChange('SORT_RESULT_BY_RELEVANCE', val)"
          :disabled="this.onlyReadPermission()"
        />
      </div>
      <div class="col-6 conf-cell">
        <div class="d-flex flex-row">
          <div class="me-5">
            <InputBox
              variant="dim"
              label="Results per page"
              :disabled="this.onlyReadPermission()"
              v-model="PER_PAGE_RESULT"
              type="number"
            />
          </div>
          <div class="text-right">
            <Button
              class="buttonStyle"
              label="Save"
              background="dark"
              :disabled="this.onlyReadPermission()"
              :clickHandler="handleInputChange"
            />
          </div>
        </div>
      </div>
    </div>
  </AdminBaseUi>
</template>

<script>
import { genericService } from '@/services';
import Checkbox from '@/components/Checkbox';
import AdminBaseUi from '@/components/admin/v2/base-ui/AdminBaseUi';
import { hasAccessPermission } from '@/helpers/hasAccessPermission';
import apiUrls from '../../../../config/apiUrls';
import Loader from '@/components/v2/Loader';
import InputBox from '@/components/admin/v2/InputBox';
import Button from '@/components/v2/Button';

export default {
  components: {
    Checkbox,
    AdminBaseUi,
    Loader,
    InputBox,
    Button,
  },
  beforeCreate() {
    this.$store.dispatch('loader/setLoadingStatus', true);
    this.$store.dispatch('loader/setFullpageLoaderStatus', true);
    genericService
      .index(apiUrls.CONFIG_LIST, 999)()
      .then((res) => {
        this.$store.dispatch('loader/setLoadingStatus', false);
        this.$store.dispatch('loader/setFullpageLoaderStatus', false);
        const { data } = res;
        this.FILESHARE_MAPPING_ENABLED = data.FILESHARE_MAPPING_ENABLED;
        this.FILESHARE_PDF_PASSTHROUGH_ENABLED = data.FILESHARE_PDF_PASSTHROUGH_ENABLED;
        this.SORT_RESULT_BY_RELEVANCE = data.SORT_RESULT_BY_RELEVANCE;
        this.PER_PAGE_RESULT = data.PER_PAGE_RESULT;
      })
      .catch((err) => {
        this.$store.dispatch('loader/setLoadingStatus', false);
        this.$store.dispatch('loader/setFullpageLoaderStatus', false);
      });
    this.$store.dispatch('dataTable/reset');
  },
  data() {
    return {
      SORT_RESULT_BY_RELEVANCE: false,
      FILESHARE_MAPPING_ENABLED: false,
      FILESHARE_PDF_PASSTHROUGH_ENABLED: false,
      PER_PAGE_RESULT: 20,
    };
  },
  methods: {
    handleInputChange() {
      if (this.PER_PAGE_RESULT <= 100 && this.PER_PAGE_RESULT >= 5) {
        this.handleChange('PER_PAGE_RESULT', parseInt(this.PER_PAGE_RESULT, 10));
        return;
      }

      this.$store.dispatch('alert/error', `Result per page should be between 5 to 100`);
    },
    handleChange(name, value) {
      console.log({ name, value });
      this.$store.dispatch('loader/setLoadingStatus', true);
      const model = genericService.update(apiUrls.CONFIG_UPDATE);
      model({
        name,
        value,
      })
        .then((r) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('alert/success', `Settings saved`);
        })
        .catch((er) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('alert/error', er || `Unable to update the settings`);
        });
    },
    onlyReadPermission() {
      return !hasAccessPermission([1]);
    },
  },
};
</script>

<style>
.advance-settings-wrapper {
  margin: 0px 40px;
  margin-top: 20px;
  background-color: #fff;
  padding: 40px;
  height: calc(100% - 20px);
}
</style>

<style scoped>
.conf-cell {
  padding: 10px 10px;
}
.conf-details {
  padding-left: 22px;
  font-size: 14px;
}

.textinput {
  display: flex;
  align-items: left;
}
.buttonStyle {
  margin-top: 25px;
  margin-bottom: 20px;
  line-height: 28px;
}
</style>
