<template>
  <table class="intop-data-table" width="100%">
    <slot></slot>
  </table>
</template>

<script>
export default {
  props: {},
};
</script>

<style scoped>
table.intop-data-table {
  border-collapse: collapse;
}
</style>
