<template>
  <div :class="getClass">
    <div class="imc-actions">
      <div v-if="this.greenIcon" class="greendot"></div>
      <div v-if="this.infiniteIcon" class="btn-infinite">
        <img src="@/assets/infinite.svg" alt="" />
      </div>
      <div v-if="this.isSuccessIcon">
        <font-awesome-icon style="color: green" icon="fa-solid fa-check" />
      </div>
      <div class="btn-dots" style="display: flex; flex-direction: column" v-if="name === 'tags'">
        <b-dropdown variant="link" class="p-0" right no-caret>
          <template #button-content>
            <img src="@/assets/dots.svg" alt="" />
          </template>
          <b-dropdown-item href="#" @click.prevent="openViewer"> Edit </b-dropdown-item>
          <b-dropdown-item-button>
            <TagDelete :tag="item" />
          </b-dropdown-item-button>
        </b-dropdown>
        <div class="btn-dots" v-if="this.item.haslink" @click="openViewer">
          <img src="@/assets/anchor-solid.svg" alt="" />
        </div>
      </div>
      <div class="btn-dots" @click.prevent="openViewer" v-else>
        <img src="@/assets/dots.svg" alt="" />
      </div>
    </div>
    <div class="pin" v-if="isStickyCard">
      <font-awesome-icon icon="map-pin" class="border-left-color" />
    </div>
    <div class="imc-content" @click.prevent="handleItemSelection">
      <div v-if="isLoading">
        <font-awesome-icon icon="circle-notch" spin />
      </div>
      <span v-else>{{ this.label }}</span>
    </div>
  </div>
</template>

<script>
import TagDelete from './forms/TagDelete';

export default {
  props: {
    label: String,
    variant: { type: String, default: 'red' },
    disabled: { type: Boolean, default: false },
    greenIcon: { type: Boolean, default: false },
    infiniteIcon: { type: Boolean, default: false },
    name: { type: String, default: '' },
    item: { default: null },
    isLoading: { type: Boolean, default: false },
    isSuccessIcon: { type: Boolean, default: false },
    isStickyCard: { type: Boolean, default: false },
    shiftClickActionHandler: { default: false },
  },
  components: {
    TagDelete,
  },
  computed: {
    fill() {
      let _item = null;
      let _id = null;
      if (this.name === 'dimension') {
        _item = this.$store.state.imStructure.dimension;
        _id = this.item.dimensionid;
      } else if (this.name === 'areasOfInterests') {
        _item = this.$store.state.imStructure.areasOfInterests;
        _id = this.item.areaofinterestid;
      } else if (this.name === 'tagGroup') {
        _item = this.$store.state.imStructure.tagGroup;
        _id = this.item.taggroupid;
      }
      return _item ? _item.includes(_id) : false;
    },
    getClass() {
      let _class = 'imc-card-wrapper';
      _class += ` ${this.variant}`;
      if (this.fill) {
        _class += ` bg_is_filled fill_${this.variant}`;
      } else {
        _class += ` bg_not_filled`;
      }
      if (this.disabled) {
        _class += ` imc-disabled`;
      }
      return _class;
    },
  },
  methods: {
    openViewer() {
      this.$store.dispatch('imStructure/updater', {
        form: {
          showModal: true,
          mode: 'edit',
          activeForm: this.name,
          editable: this.item,
        },
      });
    },
    async handleItemSelection() {
      if (event.shiftKey) {
        document.getSelection().removeAllRanges();
        if (this.shiftClickActionHandler) {
          if (this.name === 'tags') {
            this.shiftClickActionHandler(this.item.tagid);
          }
          if (this.name === 'tagGroup') {
            this.shiftClickActionHandler(this.item.taggroupid);
          }
        }
        return;
      }
      if (this.name === 'tags') {
        this.openViewer();
        return;
      }
      let data = null;
      let entity = null;
      let id = null;
      if (this.name === 'dimension') {
        entity = this.$store.state.imStructure.dimension;
        id = this.item.dimensionid;
        // Reset selected tagGroups.
        this.$store.dispatch('imStructure/updater', { tagGroup: [] });
      } else if (this.name === 'areasOfInterests') {
        entity = this.$store.state.imStructure.areasOfInterests;
        id = this.item.areaofinterestid;
        // Reset selected tagGroups.
        this.$store.dispatch('imStructure/updater', { tagGroup: [] });
      } else if (this.name === 'tagGroup') {
        id = this.item.taggroupid;
        entity = this.$store.state.imStructure.tagGroup;
        // Reset selected tags.
        this.$store.dispatch('imStructure/updater', { tags: [] });
      }
      if (!entity) {
        data = [id];
      } else {
        data = entity;
        if (entity.includes(id)) {
          data = data.filter((d) => d !== id); // Remove it.
        } else {
          if (this.name === 'tagGroup') {
            // only one taggroup can be selected at a time
            data = [id];
          } else {
            data.push(id); // Add item.
          }
        }
      }

      await this.$store.dispatch('imStructure/updater', {
        [this.name]: data,
      });

      if (this.name !== 'tagGroup') {
        await this.$store.dispatch('imStructure/updateTagGroup', {
          ignorePageCheck: this.name !== 'tagGroup',
        });
      }

      await this.$store.dispatch('imStructure/updateTag');
    },
  },
};
</script>

<style scoped>
.imc-card-wrapper {
  border-left: 10px solid transparent;
  display: inline-block;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.imc-card-wrapper:hover {
  box-shadow: 0px 6px 12px -6px #bbb;
}

.imc-content {
  font-size: 14px;
  padding: 20px;
  padding-right: 40px;
}

.imc-actions {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
}

.bg_not_filled .imc-content {
  color: #24125f;
}

.bg_is_filled .imc-content {
  color: #fff;
}

.red {
  border-left-color: #e03c31;
}

.yellow {
  border-left-color: #bfb800;
}

.blue {
  border-left-color: #5f8dda;
}

.orange {
  border-left-color: #db8a06;
}

.fill_red {
  background-color: #e03c31;
}

.fill_yellow {
  background-color: #bfb800;
}

.fill_blue {
  background-color: #5f8dda;
}

.fill_orange {
  background-color: #db8a06;
}

.bg_is_filled .imc-content {
  color: #fff;
}

.bg_not_filled {
  background-color: #ededed;
}

.stripe {
  /* background-image: linear-gradient(45deg, #db8a06 25%, transparent 25%, transparent 50%, #db8a06 50%, #db8a06 75%, transparent 75%, #ededed); */
  /* background:
    linear-gradient(-45deg, transparent 45%, #db8a06 45%, #db8a06 55%, transparent 55%) 0 0/10px 10px,
    linear-gradient(-45deg, transparent 45%, #db8a06 45%, #db8a06 55%, transparent 55%) 5px 0px/10px 10px;
  background-position: left top; */
  border-left-style: dotted;
  border-left-color: #db8a06;
}

.fill_stripe {
  color: #db8a06;
}

.greendot {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #6ba539;
  margin-top: 2px;
  margin-right: 5px;
}

.btn-infinite {
  padding-left: 5px;
  padding-right: 5px;
}

.btn-infinite img {
  height: 11px;
  width: 12px;
}

.btn-dots {
  padding-left: 5px;
  padding-right: 5px;
}

.btn-dots img {
  height: 11px;
  width: 9px;
}

.btn-success {
  padding-left: 5px;
  padding-right: 5px;
}

.btn-success img {
  height: 11px;
  width: 9px;
}
</style>
<style>
.imc-actions .btn-dots .dropdown-toggle {
  padding: 0;
}
.pin {
  margin: -3px;
  height: 5px;
  width: 5px;
  margin-left: 5px;
}
</style>
