/**
 * Used by the search suggestion and
 * tag lists on table header.
 *
 */

const groupedFilters = (values) => {
  const grouped = {};
  values.forEach((item) => {
    const formattedItem = {
      name: item.filtervalue,
      tagid: item.tagid,
      taggroupid: item.taggroupid,
    };
    if (grouped.hasOwnProperty(item.taggroupid)) {
      grouped[item.taggroupid]['tags'].push(formattedItem);
    } else {
      grouped[item.taggroupid] = {
        tags: [formattedItem],
        taggroupid: item.taggroupid,
        name: item.name,
      };
    }
  });
  return Object.values(grouped);
};

export default groupedFilters;
