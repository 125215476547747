<template>
  <multiselect
    v-model="selectedLookupField"
    @input="selectLookupfield"
    :options="lookupFields"
    class="w-25 ms-3 align-items-center custom-multiselect"
    selectLabel=""
    deselectLabel=""
  >
    <template slot="caret" slot-scope="{ toggle }">
      <img @mousedown.prevent.stop="toggle()" class="caret-svg caret-dark" src="@/assets/down-arrow.svg" />
    </template>
    <template slot="placeholder">
      <div class="filterbox-label-with-count">
        <div class="fbc-label">{{ placeholder }}</div>
      </div>
    </template>
    <template slot="option" slot-scope="props">
      <div :class="selectedLookupField == props.option ? 'option__selected option__main' : 'option__main'">
        <div class="opt_checkbox">
          <img v-if="selectedLookupField == props.option" src="@/assets/tick-white.svg" />
        </div>
        <span class="option__title">{{ props.option }}</span>
      </div>
    </template>
  </multiselect>
</template>

<script>
import apiUrls from '@/config/apiUrls';
import Multiselect from 'vue-multiselect';

export default {
  name: 'LookupfieldSelect',
  components: {
    Multiselect,
  },
  props: {
    selected: {
      default: '',
    },
    placeholder: {
      default: 'Choose field',
    },
  },
  data() {
    return {
      selectedLookupField: this.selected,
    };
  },
  computed: {
    lookupFields() {
      return this.$store.state.entities.lookupfields;
    },
  },
  methods: {
    selectLookupfield(lookupfield) {
      this.$emit('input', lookupfield);
    },
  },
  async created() {
    this.$store.dispatch('entities/fetchList', [{ url: apiUrls.IMS_LOOKUPFIELDS, entity: 'lookupfields' }]);
  },
};
</script>
