<template>
  <AdminBaseUi title="User" create="/manage/user/new">
    <div v-if="!this.emptySearchResult" class="user-data-table">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell value="Name" sortField="name" />
            <TableHeaderCell value="Username" sortField="username" />
            <TableHeaderCell value="Active" />
            <TableHeaderCell value="Roles" />
            <TableHeaderCell value="Connectors" />
            <TableHeaderCell width="80" />
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow v-for="(row, index) in this.data" :key="index">
            <TableBodyCell :value="row.name" />
            <TableBodyCell :value="row.username" />
            <TableBodyCell>
              <Checkbox :checked="row.active" />
            </TableBodyCell>
            <TableBodyCell>
              <div class="roles-wrapper" v-if="row.roles && row.roles.length">
                <div class="role" v-for="(role, index) in roles(row.roles)" :key="index">
                  {{ role }}
                </div>
              </div>
            </TableBodyCell>
            <TableBodyCell>
              {{ connectors(row.connectors) }}
            </TableBodyCell>
            <TableBodyCell>
              <TableOptions
                :id="row.userid"
                identifier="userid"
                :endpoint="apiUrls.USER"
                page="manage/user"
                deleteModalEntityName="user"
              />
            </TableBodyCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
    <div class="connector-data-table text-center pt-5" v-else>No results found</div>
  </AdminBaseUi>
</template>

<script>
import Checkbox from '@/components/Checkbox';
import AdminBaseUi from '@/components/admin/v2/base-ui/AdminBaseUi';
import Table from '@/components/dataTable/Table';
import TableHeader from '@/components/dataTable/TableHeader';
import TableRow from '@/components/dataTable/TableRow';
import TableHeaderCell from '@/components/dataTable/TableHeaderCell';
import TableBody from '@/components/dataTable/TableBody';
import TableBodyCell from '@/components/dataTable/TableBodyCell';
import TableOptions from '@/components/dataTable/TableOptions';
import apiUrls from '../../../../config/apiUrls';

export default {
  components: {
    Table,
    Checkbox,
    TableRow,
    TableBody,
    TableHeader,
    AdminBaseUi,
    TableOptions,
    TableBodyCell,
    TableHeaderCell,
  },
  created() {
    this.$store.dispatch('dataTable/setTable', apiUrls.USERS);
    const queries = this.$router.currentRoute.value.query;
    const { redirect: page = 1 } = queries;
    this.$store.dispatch('dataTable/setPage', page);
    if (Object.keys(queries).length) {
      this.$nextTick(() => {
        this.$router.push({ query: {} });
      });
    }
  },
  computed: {
    response() {
      return this.$store.state.dataTable.data;
    },
    emptySearchResult() {
      if (!!this.$store.state.dataTable.search.trim() && !this.$store.state.dataTable.data.data.length) {
        return true;
      } else {
        return false;
      }
    },
    data() {
      const { data } = this.response;
      return (data || []).map((item) => ({
        userid: item.userid,
        name: item.name,
        username: item.username,
        active: item.active,
        roles: item.roles,
        connectors: item.connectors,
      }));
    },
  },
  data() {
    return {
      apiUrls: apiUrls,
    };
  },
  methods: {
    roles(roles) {
      return roles.filter((r) => r != 'Connector access') || [];
    },
    connectors(connectors) {
      if (connectors && connectors.length) {
        return connectors.join(', ');
      }
      return '';
    },
  },
};
</script>

<style scoped>
.user-data-table {
  padding: 0px 40px;
}
.roles-wrapper .role {
  display: inline-block;
  background-color: #23125f;
  color: #fff;
  padding: 5px 10px;
  margin: 3px;
}
</style>
