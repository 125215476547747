<template>
  <div class="dimensions">
    <div class="_label">Dimensions</div>
    <div class="card-containers">
      <ImCard
        v-for="(dimension, index) in dimensions"
        :key="index"
        :label="dimension.name"
        :item="dimension"
        variant="red"
        :fill="false"
        name="dimension"
      />
      <ImAddButton name="dimension" />
    </div>
  </div>
</template>

<script>
import ImCard from './ImCard';
import ImAddButton from './ImAddButton';
import apiUrls from '../../../config/apiUrls';
export default {
  components: {
    ImCard,
    ImAddButton,
  },
  created() {
    this.$store.dispatch('entities/fetchList', [{ url: apiUrls.IMS_DIMENSION, entity: 'dimensions' }]);
  },
  computed: {
    dimensions() {
      return this.$store.state.entities.dimensions.data || [];
    },
  },
};
</script>

<style scoped>
.dimensions {
  padding-top: 60px;
  padding-left: 40px;
  padding-right: 40px;
}
._label {
  font-size: 14px;
  color: #688197;
  margin-bottom: 10px;
}
.card-containers {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>
