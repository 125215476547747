<template>
  <div class="team-filter-box">
    <a class="filter-toggler" v-b-toggle="getId" @click.prevent="visible = !visible">
      <span v-if="!visible"><font-awesome-icon icon="caret-right" /></span>
      <span v-if="visible"><font-awesome-icon icon="caret-down" /></span>
      <span class="filter-title">{{ title }}</span>
      <span class="filter-count" v-if="multiselect && selected && selected.length > 0">
        {{ selected.length }}
      </span>
    </a>
    <b-collapse :accordion="accordion" :id="getId" :visible="visible">
      <slot>
        <ul class="list-unstyled filter-content">
          <li v-for="(item, key) in options" :key="title + key">
            <input
              :type="inputType"
              :id="title + key"
              :value="item[inputValueKey]"
              v-model="selected"
              @change="handleSelect"
            />
            <label :for="title + key" v-html="item[inputLabelKey]"></label>
          </li>
        </ul>
      </slot>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: [
    'title',
    'multiselect',
    'items',
    'inputLabelKey',
    'inputValueKey',
    'propSelected',
    'itemlistvisible',
    'accordion',
    'disableSorting',
    'closeOnSelect',
  ],
  emits: ['select'],
  data() {
    return {
      options: this.items,
      visible: this.itemlistvisible,
      selected: this.propSelected ? this.propSelected : this.multiselect ? [] : null,
      inputType: this.multiselect ? 'checkbox' : 'radio',
    };
  },
  computed: {
    getId() {
      return this.title.split(' ').join('-');
    },
  },
  watch: {
    propSelected: {
      handler(newVal) {
        this.selected = newVal;
        this.$nextTick(() => {
          if (!this.disableSorting) {
            this.sortOptionsBySelected();
          }
        });
      },
      deep: true,
    },
    items: {
      handler(newVal) {
        this.options = [...newVal];
        this.$nextTick(() => {
          if (!this.disableSorting) {
            this.sortOptionsBySelected();
          }
        });
      },
      deep: true,
    },
  },
  methods: {
    handleSelect() {
      let selectedItems = this.multiselect ? [] : null;
      if (this.multiselect) {
        selectedItems = this.items.filter((item) => {
          return this.selected.includes(item[this.inputValueKey]);
        });
      } else {
        selectedItems = this.items.find((item) => {
          return this.selected === item[this.inputValueKey];
        });
      }
      if (this.closeOnSelect) {
        this.visible = false;
      }
      this.$emit('select', selectedItems);
      this.$nextTick(() => {
        if (!this.disableSorting) {
          this.sortOptionsBySelected();
        }
      });
    },
    sortOptionsBySelected() {
      if (this.selected) {
        let selectedOptions = this.multiselect ? this.selected : [this.selected];
        this.options = this.options.sort((a, b) => {
          if (selectedOptions.includes(a[this.inputValueKey])) {
            return -1;
          } else if (selectedOptions.includes(b[this.inputValueKey])) {
            return 1;
          }
          return 1;
        });
      }
    },
    resetSelected() {
      this.selected = this.multiselect ? [] : null;
    },
  },
  mounted() {
    if (this.propSelected) {
      this.selected = this.propSelected;
    }
    if (this.options && this.options.length > 0) {
      this.$nextTick(() => {
        if (!this.disableSorting) {
          this.sortOptionsBySelected();
        }
      });
    }
  },
};
</script>

<style scoped>
.team-filter-box {
  font-size: 0.85rem;
}
.filter-toggler {
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  color: #484644;
  text-decoration: none;
  display: block;
}
.filter-toggler:hover {
  background-color: #e0e0e0;
  color: #000;
  font-weight: 600;
}
.filter-title {
  margin-left: 0.5rem;
}
.filter-content {
  margin-bottom: 0.5rem;
}
.filter-content li input {
  display: none;
}
.filter-content li label {
  margin-bottom: 0;
  display: block;
  padding: 0.5rem 1.5rem 0.5rem 2.5rem;
  cursor: pointer;
  border-bottom: 1px solid #f1f1f1;
}
.filter-content li label:hover {
  background-color: #e0e0e0;
  color: #000;
  font-weight: 600;
}
input:checked ~ label {
  background-color: #6264a7;
  color: #fff;
  font-weight: 600;
}
.filter-count {
  background-color: #6264a7;
  color: #fff;
  font-size: 75%;
  width: 16px;
  height: 16px;
  border-radius: 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  line-height: 1.2;
}
</style>
