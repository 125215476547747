import { store } from '../store';

const base = [
  {
    name: 'name',
    title: 'Name',
    sortField: 'name',
    titleClass: 'resource-name',
    dataClass: 'resource-name',
    width: 'minmax(250px, 450px)',
  },
  {
    name: 'modifieddate',
    title: 'Last modified',
    sortField: 'modifieddate',
    width: '180px',
    formatter: (val) => {
      if (!val) {
        return '';
      }

      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date(val);
      return date.toLocaleDateString('en-US', options);
    },
  },
];

export const generateTableFieldDef = () => {
  const { columns } = store.state.contextManager.renderPreview
    ? store.getters['contextManager/demoContext']
    : store.state.contexts.selected;
  const prepareColumns = columns.map((col) => {
    return {
      name: col.label || col.name,
      title: col.title || col.label || col.name,
    };
  });
  const fieldDefs = [...base, ...prepareColumns];
  return fieldDefs;
};
