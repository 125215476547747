<template>
  <AdminBaseUi title="Hidden API List" :showSearchBox="false" key="hiddenApi">
    <div class="hidden-api-call-wrapper">
      <div class="accordion" role="tablist">
        <b-card no-body v-for="(apis, service) in apiEndPoints" :key="service">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <div v-b-toggle="service" class="p-2 text-capitalize">
              {{ service }}
            </div>
          </b-card-header>
          <b-collapse :id="service" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <div class="form-group" v-for="(api, index) in apis" :key="service + index">
                <label for="basic-url">{{ api.name }}</label>
                <div class="row no-gutters">
                  <div class="col-11">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text rounded-0">
                          {{ api.method }}
                        </span>
                      </div>
                      <input type="text" class="form-control rounded-0" v-model="api.url" />
                    </div>
                  </div>
                  <div class="col-1">
                    <Button class="w-100" label="Send" background="dark" @click="sendRequest(api, service)" />
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </AdminBaseUi>
</template>

<script>
import AdminBaseUi from '@/components/admin/v2/base-ui/AdminBaseUi';
import Button from '@/components/v2/Button';
import { backendService } from '@/services';

export default {
  components: {
    AdminBaseUi,
    Button,
  },
  data() {
    return {
      apiEndPoints: {
        crawler: [
          {
            name: 'Crawler health ping',
            url: '/crawler/v1/health/ping',
            method: 'GET',
          },
          {
            name: 'Reload config',
            url: '/crawler/v1/admin/reload-config',
            method: 'POST',
          },
        ],
        ingestor: [
          {
            name: 'Reset Ingestor',
            url: '/ingestor/v1/admin/reset-ingestor',
            method: 'POST',
          },
          {
            name: 'Health Ping',
            url: '/ingestor/v1/health/ping',
            method: 'GET',
          },
          {
            name: 'Reload config',
            url: '/ingestor/v1/admin/reload-config',
            method: 'POST',
          },
        ],
        curator: [
          {
            name: 'Reset Curator',
            url: '/curator/v1/admin/reset-curator',
            method: 'POST',
          },
          {
            name: 'Curate User',
            url: '/curator/v1/admin/curate-user',
            method: 'POST',
          },
          {
            name: 'Health Ping',
            url: '/curator/v1/health/ping',
            method: 'GET',
          },
        ],
        processor: [
          {
            name: 'Reset Processor',
            url: '/processor/v1/admin/reset-processor',
            method: 'POST',
          },
          {
            name: 'Process Pattern',
            url: '/processor/v1/admin/process-patterns',
            method: 'POST',
          },
          {
            name: 'Refresh Access',
            url: '/processor/v1/admin/refresh-access',
            method: 'POST',
          },
          {
            name: 'Autoupdate Lookup',
            url: '/processor/v1/admin/update-lookuppatterns',
            method: 'POST',
          },
          {
            name: 'Health Ping',
            url: '/processor/v1/health/ping',
            method: 'GET',
          },
        ],
      },
    };
  },
  methods: {
    sendRequest(api, service) {
      backendService
        .post({ ...api, service })
        .then((res) => {
          this.$store.dispatch('alert/successWithTimer', {
            message: res.message,
            seconds: 3,
          });
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', err || `Request Failed`);
        });
    },
  },
  created() {
    this.$store.dispatch('dataTable/reset');
  },
};
</script>

<style scoped>
.hidden-api-call-wrapper {
  margin: 0px 40px;
  margin-top: 20px;
  background-color: #fff;
  padding: 40px;
  min-height: calc(100% - 20px);
}
</style>
