<template>
  <tbody>
    <slot></slot>
  </tbody>
</template>

<script>
export default {};
</script>

<style scoped>
tbody {
  background-color: #fff;
}
</style>
