<template>
  <div :class="`inputbox-wrapper ${variant}`">
    <label v-if="this.label" :for="this.label">
      {{ this.label }}
      <span class="requiredlabel" v-if="this.required" title="This field is required.">*</span>
    </label>
    <textarea
      v-if="this.textarea"
      :ref="reference"
      :class="`input-${height}`"
      rows="3"
      :id="this.label"
      @input="handleChange"
      :value="inputField"
      :placeholder="placeholder"
      :required="this.required"
    >
    </textarea>
    <input
      v-else
      :ref="reference"
      :type="this.type"
      @input="handleChange"
      :value="inputField"
      :placeholder="placeholder"
      :required="this.required"
      :disabled="this.disabled"
      :autocomplete="this.autocomplete"
    />
  </div>
</template>

<script>
export default {
  props: {
    getFunc: {
      default: false,
    },
    setFunc: {
      default: false,
    },
    variant: {
      default: 'dark', // white, dim
    },
    placeholder: {
      default: '',
    },
    textarea: {
      default: false,
    },
    label: {
      default: false,
    },
    required: {
      default: false,
    },
    disabled: {
      default: false,
    },
    modelValue: {
      default: undefined,
    },
    height: {
      default: 'small',
    },
    type: {
      default: 'text',
    },
    autocomplete: {
      default: true,
    },
    reference: {
      default: '',
    },
  },
  emits: ['update:modelValue'],
  computed: {
    inputField() {
      return this.getFunc ? this.getFunc() : this.modelValue;
    },
  },
  methods: {
    handleChange(v) {
      if (this.modelValue !== undefined) {
        this.$emit('update:modelValue', v.target.value);
      } else {
        this.setFunc && this.setFunc(v.target.value);
      }
    },
  },
  mounted() {
    if (this.reference) {
      setTimeout(() => {
        this.$refs[this.reference].focus();
      }, 100);
    }
  },
};
</script>

<style scoped>
input,
textarea {
  display: block;
  border: 0px;
  border-radius: 0px;
  padding: 15px 20px;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}
input::placeholder,
textarea::placeholder {
  font-size: 14px;
  font-weight: 400;
}
.input-large {
  height: 300px;
}
label {
  font-size: 14px;
  color: #24125f;
}
.dark input,
.dark textarea {
  background-color: #23125f;
  color: #fff;
}
.dark input::placeholder,
.dark textarea::placeholder {
  color: #fff;
}
.white input,
.white textarea {
  background-color: #fff;
  color: #23125f;
}
.white input::placeholder,
.white textarea::placeholder {
  color: #23125f;
}
.dim input,
.dim textarea {
  background-color: #ededed;
  color: #23125f;
}
.dim input::placeholder,
.dim textarea::placeholder {
  color: #23125f;
}
</style>
