<template>
  <div>
    <div v-if="contexts && contexts.length === 1" class="team-filter-box">
      <div class="filter-label mt-3 pb-2"><b>Context</b></div>
      <div class="team-filter-box">
        <ul class="list-unstyled filter-content">
          <li v-for="context in contexts" :key="'context_' + context.contextid">
            <label for="">{{ context.name }}</label>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="contexts && contexts.length > 1">
      <div class="filter-label mt-3"><b>Select context</b></div>
      <div>
        <Multiselect
          v-if="contexts"
          key="contextFilter"
          title="Context"
          :multiselect="false"
          :items="contexts"
          inputLabelKey="name"
          inputValueKey="contextid"
          :propSelected="selectedContext"
          @select="handleSelect"
          accordion="filter-accordion"
          :disableSorting="true"
          :closeOnSelect="true"
        />
        <div class="selected-context" v-if="selectedContext">
          {{ selectedContextName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from './Multiselect';

export default {
  components: {
    Multiselect,
  },
  computed: {
    contexts() {
      const contexts = this.$store.state.contexts.all.items;
      if (contexts && contexts.length === 1 && !this.selectedContext) {
        this.handleSelect(contexts[0]);
      }
      return contexts;
    },
    hasSelectedCollection() {
      return !!this.$store.state.contexts.selected.contextid;
    },
    selectedContext() {
      if (this.hasSelectedCollection) {
        return this.$store.state.contexts.selected.contextid;
      } else {
        return null;
      }
    },
    selectedContextName() {
      if (this.selectedContext) {
        return this.contexts.find((item) => item.contextid == this.selectedContext).name;
      }
      return null;
    },
  },
  methods: {
    handleSelect(context) {
      if (context) {
        // A context has been selected by user.
        this.$store.dispatch('filterParams/resetFilters');
        this.$store.dispatch('contexts/setSelected', context.contextid);
      } else {
        // This method called for the live context preview from context editor.
        this.$store.dispatch('contexts/setDemoContext');
      }
      if (context.tags) {
        this.$store.dispatch('contexts/setDefaultFilters', context.tags);
      }
    },
  },
};
</script>

<style scoped>
.selected-context {
  padding: 0.5rem 1.5rem 0.5rem 2.5rem;
  cursor: pointer;
  border-bottom: 1px solid #f1f1f1;
  background-color: #6264a7;
  color: #fff;
  font-weight: 600;
  font-size: 0.85rem;
}
.filter-label {
  font-size: 0.85rem;
  margin-left: 0.75rem;
}
.filter-content li label {
  margin-bottom: 0;
  display: block;
  padding: 0.5rem 1.5rem 0.5rem 2.5rem;
  cursor: pointer;
  border-bottom: 1px solid #f1f1f1;
  background-color: #6264a7;
  color: #fff;
  font-weight: 600;
}
.team-filter-box {
  font-size: 0.85rem;
}
</style>
