import config from '../config/config';
import { authHeader, handleApiResponse } from '../helpers';
const QueryString = require('qs');

/**
 *
 * This generic service serves as a simple API for datatypes that does
 * not require any special processing (i.e. connectortypes, filtertypes).
 * It differentiates from normal services by using currying, thus all
 * service endpoints return a function that returns a promise.
 *
 */

export const genericService = {
  index,
  find,
  create,
  update,
  remove,
};

const noCacheHeader = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
};

function index(dataType, per_page = 10, options = {}) {
  return (data = {}) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        ...noCacheHeader,
        ...authHeader(),
      },
    };

    // searching, sorting...
    const { searchField = false, searchValue = false, sort = false, get_in = false, all = false } = options;

    let urlQS = {};

    if (searchField && searchValue) {
      urlQS = {
        search: searchValue,
        search_at: searchField,
      };
    }

    if (all) urlQS = { all, ...urlQS };

    if (get_in) urlQS = { get_in, ...urlQS };

    const formattedQueryString = QueryString.stringify({
      ...data,
      per_page,
      ...urlQS,
    });

    return fetch(`${config.apiUrl}${dataType}?${formattedQueryString}`, requestOptions).then(handleApiResponse);
  };
}

function find(path) {
  return () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        ...noCacheHeader,
        ...authHeader(),
      },
    };
    return fetch(`${config.apiUrl}${path}`, requestOptions).then(handleApiResponse);
  };
}

function remove(dataType) {
  return (data) => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json',
        ...noCacheHeader,
      },
      body: JSON.stringify(data),
    };

    return fetch(`${config.apiUrl}${dataType}`, requestOptions).then(handleApiResponse);
  };
}

function update(dataType) {
  return (data) => {
    const requestOptions = {
      method: 'PATCH',
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json',
        ...noCacheHeader,
      },
      body: JSON.stringify(data),
    };

    return fetch(`${config.apiUrl}${dataType}`, requestOptions).then(handleApiResponse);
  };
}

function create(dataType, options = {}) {
  return (data) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json',
        ...noCacheHeader,
      },
      body: JSON.stringify(data),
    };

    // console.log('requestOptions(filter collections):', requestOptions)

    return fetch(`${config.apiUrl}${dataType}`, requestOptions).then((res) => handleApiResponse(res, options));
  };
}
