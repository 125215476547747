import { genericService } from '@/services';

const index = async ({ dataType, per_page = 20, options = {} }) => {
  const model = genericService.index(dataType, per_page, options);
  const data = await model();
  return data;
};

export const entityService = {
  index,
};
