<template>
  <b-dropdown-item href="#" @click.prevent="copyText"> Copy link </b-dropdown-item>
</template>

<script>
const initialToolTip = 'Click to copy URL to clipboard';
const successTooltip = 'Copied!';
export default {
  props: ['text'],
  data: function () {
    return {
      tooltip: initialToolTip,
    };
  },
  methods: {
    copyText() {
      const _inputField = document.createElement('textarea');
      _inputField.innerText = this.text;
      document.body.appendChild(_inputField);
      _inputField.select();
      _inputField.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.tooltip = successTooltip;
      _inputField.remove();
      setTimeout(() => {
        this.tooltip = initialToolTip;
      }, 2000);
    },
  },
};
</script>

<style scoped>
.clickcopywrapper {
  display: inline-block;
}
.btn {
  background: #262261;
  margin-left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn img {
  width: 21px;
  height: 21px;
}
.intop-resource-table .btn .svg-inline--fa {
  height: 8px;
  color: #fff;
}
.intop-resource-table .btn .svg-inline--fa.fa-w-16 {
  width: 10px;
}
</style>
