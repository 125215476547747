<template>
  <div :class="classWithSelectedChilds">
    <multiselect
      :id="componentId"
      :model-value="selectedOptions"
      label="label"
      track-by="tagid"
      :placeholder="filter.name"
      :options="options"
      :multiple="true"
      :searchable="true"
      :loading="isLoading"
      :internal-search="true"
      :clear-on-select="false"
      :close-on-select="true"
      :options-limit="150"
      :limit="3"
      :limit-text="limitText"
      :max-height="600"
      :show-no-results="false"
      :hide-selected="false"
      :custom-label="customLabel"
      selectLabel=""
      deselectLabel=""
      openDirection="bottom"
      @open="asyncFind"
      @update:model-value="updateValue"
      @remove="removeValue"
    >
      <template v-slot:option="props">
        <div :class="props.option.selected ? 'option__selected option__main' : 'option__main'">
          <div class="opt_checkbox">
            <img v-if="props.option.selected" src="@/assets/tick-white.svg" />
          </div>
          <span class="option__title">{{ props.option.label }}</span>
        </div>
      </template>
      <template v-slot:caret="{ toggle }">
        <img @mousedown.prevent.stop="toggle()" class="caret-svg caret-dark" src="@/assets/down-arrow.svg" />
        <img @mousedown.prevent.stop="toggle()" class="caret-svg caret-white" src="@/assets/down-arrow-white.svg" />
      </template>
      <template v-slot:placeholder>
        <div class="filterbox-label-with-count">
          <div class="fbc-label">{{ filter.name }}</div>
          <div v-if="selectedFiltersCount > 0" class="fbc-count">
            {{ selectedFiltersCount }}
          </div>
        </div>
      </template>
      <template v-slot:noOptions>
        <div class="empty_filter_message">No data available</div>
      </template>
      <template v-slot:noResult>No data found</template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { filterService } from '@/services';

export default {
  name: 'FilterBox',
  props: {
    filter: {},
    showInlineFilters: false,
    oldStyle: false,
    updateBookmarkUrl: true,
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      selectedFilterValues: [],
      options: [],
      isLoading: false,
    };
  },
  methods: {
    customLabel(n) {
      const count = n.count ? `(${n.count})` : '';
      return `${n.label} ${count}`;
    },
    limitText(count) {
      return `and ${count} other options`;
    },
    async asyncFind(search, filterName) {
      this.isLoading = true;

      // fiterName comes in search param when search param is undefined
      search = search && search !== this.componentId ? search : '';

      // replace with getValuesWithoutCounts to turn on resource count per tag
      const data = await filterService.getValuesWithoutCounts(this.filter.taggroupid, {
        search,
        taggroupid: this.filter.taggroupid,
      });

      const activeTags = this.$store.state.filterParams.tags.filter((t) => t.filterid == this.filter.filterid);

      const updatedOptions = data.data.map((el) => {
        const selected = activeTags.find((t) => t.tagid == el.tagid) ? true : false;
        return {
          ...el,
          selected,
        };
      });
      this.options = updatedOptions;
      this.isLoading = false;
    },
    clearAll() {
      this.selectedOptions = [];
    },
    updateValue(val) {
      // Check if item is already selected
      let selectedItem = null;
      if (val.length && val.length > 1) {
        selectedItem = val[val.length - 1];
      } else {
        [selectedItem] = val;
      }
      const isSelected = this.$store.state.filterParams.tags
        .filter((t) => t.taggroupid == this.filter.taggroupid)
        .find((t) => t.tagid == selectedItem.tagid);

      if (!!isSelected) {
        if (!this.oldStyle) {
          // Remove the item.
          this.removeValue(selectedItem);
        }
      } else {
        // Add the item.
        val.forEach((element) => {
          this.$store.dispatch('filterParams/updateTag', {
            ...element,
            count: element.count,
            updateBookmarkUrl: this.updateBookmarkUrl,
          });
        });
        this.$store.dispatch('filterParams/handleMainViewOnFilterChange');
      }
    },
    removeValue(val) {
      this.$store.dispatch('filterParams/removeTag', {
        tagid: val.tagid,
        updateBookmarkUrl: this.updateBookmarkUrl,
      });
    },
  },
  computed: {
    accumulatedCount() {
      return this.options
        ? this.options.reduce((prev, cur) => {
            const count = prev + Number(cur.count);
            return count;
          }, 0)
        : 0;
    },
    componentId() {
      return `filter${this.filter.taggroupid}`;
    },
    selectedOptions() {
      if (this.showInlineFilters) {
        const tags = this.$store.state.filterParams.tags;
        return tags.filter((t) => t.taggroupid === this.filter.taggroupid);
      }
    },
    params() {
      const { tags, searchString } = this.$store.state.filterParams;
      const nodesFlat = tags.map((t) => t.tagid);
      return {
        filters: nodesFlat,
        searchString,
      };
    },
    searchString: {
      set(searchString) {
        this.$store.dispatch('filterParams/searchString', searchString);
      },
      get() {
        return this.$store.state.filterParams.searchString;
      },
    },
    hasChild: {
      get() {
        return this.$store.state.filterParams.tags.find((_filter) => _filter.taggroupid == this.filter.taggroupid);
      },
    },
    classWithSelectedChilds: {
      get() {
        if (!this.oldStyle) {
          if (this.hasChild) {
            return 'intop-sidebar-config-input filter-has-active-child';
          } else {
            return 'intop-sidebar-config-input filter-has-no-active-child';
          }
        } else {
          return 'intop-sidebar-config-input_old';
        }
      },
    },
    selectedFiltersCount: {
      get() {
        const activeTags = this.$store.state.filterParams.tags.filter((t) => t.taggroupid == this.filter.taggroupid);
        return activeTags.length ? activeTags.length : 0;
      },
    },
  },
};
</script>

<style>
.multiselect {
  font-size: 0.9rem !important;
}
.multiselect input::placeholder {
  font-size: 0.85rem !important;
}

.multiselect__content-wrapper::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.multiselect__content-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.multiselect__content-wrapper::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.multiselect__content-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.multiselect__tag {
  background-color: #6c99c5;
}

.multiselect__tag-icon:after {
  color: #d4d4d4;
}
.sidebar-heading {
  font-size: 0.75rem;
}

.filterbox-label-with-count {
  display: flex;
  align-items: center;
}

.filterbox-label-with-count .fbc-count {
  background-color: #fff;
  color: #6c99c5;
  font-size: 11px;
  width: 20px;
  height: 20px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
.multiselect .option__main {
  display: flex;
  align-items: center;
}
.empty_filter_message {
  padding: 0px 20px;
  color: #868e96;
}
</style>
