/**
 * QUERY STRING PATTERNS:
 * > f = Filters, joined by _, divided by {taggroupid}-{tagid}
 * > c = Context
 * > b = Before Date
 * > a = After Date
 * > s = Search items array, joined by _-_
 */

import QS from 'qs';
import router from '../router';

export const savedContexts = () => JSON.parse(JSON.stringify(router.currentRoute.value.query));

/**
 * Should return a property from the URL query
 * string available at this moment.
 *
 * @param {*} field
 */
export const bookmarkedData = (field) => {
  const availableQueries = savedContexts();
  const fieldData = availableQueries[field] ? availableQueries[field] : false;

  if (availableQueries[field]) {
    if (field === 'f') {
      // Parse taggroupid and tagid from filters string.
      return fieldData.split('-').map((f) => {
        const _filter = f.split('_');
        return {
          taggroupid: Number(_filter[0]),
          tagid: Number(_filter[1]),
        };
      });
    } else if (field === 'c') {
      return Number(fieldData);
    } else if (field === 's' && typeof field === 'string') {
      return fieldData.split('_-_');
    }
  }

  return fieldData;
};

const urlFormatter = (data) => {
  const contextId = data.selectedContext || data.c;
  const before = data.modifiedBefore || data.b;
  const after = data.modifiedFrom || data.a;
  const filtersId = data.nodeFilters || data.f;
  const search = data.searchString || data.s;
  const searchByTitle = data.searchByTitle || data.t;
  const params = {
    c: contextId,
  };

  if (before) {
    const [_dateBefore] = before.split(' ');
    params.b = _dateBefore;
  }

  if (after) {
    const [_dateAfter] = after.split(' ');
    params.a = _dateAfter;
  }

  if (search) {
    if (typeof search === 'object' && search.length) {
      params.s = search.join('_-_');
    } else if (typeof search === 'string' && search.trim()) {
      params.s = search;
    }
  }

  if (filtersId) {
    if (typeof filtersId === 'object') {
      if (filtersId.length) {
        params.f = filtersId.map((f) => `${f.taggroupid}_${f.tagid}`).join('-');
      }
    } else {
      params.f = filtersId;
    }
  }

  if (searchByTitle) {
    params.t = searchByTitle;
  }
  return params;
};

export const updateUrl = (queryStrings) => {
  router.push({ query: urlFormatter(queryStrings) });
};

export const generateUrl = (qs) => {
  const { contextdata } = qs;
  return `${window.location.origin}/#/?${QS.stringify(urlFormatter(contextdata))}`;
};

export const updateBookmarks = ({ paramKey, paramValue }) => {
  const _savedContexts = savedContexts();
  if (!_savedContexts.hasOwnProperty(paramKey)) {
    delete _savedContexts[paramKey];
  }
  _savedContexts[paramKey] = paramValue;
  updateUrl(_savedContexts);
};

export const updateBookmarkUrl = (updatedKeys) => {
  const _savedContexts = savedContexts();
  console.log({ context: _savedContexts });
  for (const item of updatedKeys) {
    _savedContexts[item.key] = item.value;
  }
  updateUrl(_savedContexts);
};

export const cleanBookmarksUrl = () => {
  router.push({ query: {} });
};

export const translateQueryStringToDataObject = () => {
  const availableQueries = savedContexts();
  const translatedObject = {
    selectedContext: bookmarkedData('c'),
    nodeFilters: bookmarkedData('f'),
  };
  for (const property in availableQueries) {
    if (property === 's') {
      translatedObject.searchString = bookmarkedData('s');
    } else if (property === 'a') {
      translatedObject.modifiedFrom = bookmarkedData('a');
    } else if (property === 'b') {
      translatedObject.modifiedBefore = bookmarkedData('b');
    } else if (property === 't') {
      translatedObject.searchByTitle = bookmarkedData('t');
    }
  }
  return translatedObject;
};
