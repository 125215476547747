export const hasAccessPermission = (roleRequired) => {
  const getUser = JSON.parse(localStorage.getItem('user'));
  const roles = getUser.user.roles;

  const requiresSuperAdmin = roleRequired.includes(1);
  const requiresImAdmin = roleRequired.includes(2);

  const isSuperAdmin = roles.includes(1);
  const isImAdmin = roles.includes(2);

  // console.log({
  //   requiresImAdmin, requiresSuperAdmin, isSuperAdmin, isImAdmin
  // })

  if (requiresSuperAdmin && isSuperAdmin) {
    return true;
  } else if (requiresImAdmin && isImAdmin) {
    return true;
  } else {
    return false;
  }
};
