import { supportPageService } from '@/services';

export const supportPage = {
  namespaced: true,
  state: {
    title: '',
    description: '',
    intop_support: {},
    internal_support: {},
    initialized: false,
    modalActive: false,
  },
  actions: {
    fetchSupport({ commit, dispatch }) {
      dispatch('loader/setLoadingStatus', true, { root: true });
      dispatch('loader/setFullpageLoaderStatus', true, { root: true });
      supportPageService
        .find()
        .then((data) => {
          dispatch('loader/setLoadingStatus', false, { root: true });
          dispatch('loader/setFullpageLoaderStatus', false, { root: true });
          commit('setData', data.data.valuejson);
          commit('updateState', { initialized: true });
        })
        .catch((error) => {
          dispatch('loader/setLoadingStatus', false, { root: true });
          dispatch('loader/setFullpageLoaderStatus', false, { root: true });
          console.log('Error:', error);
        });
    },
    updateSupport({ state, commit, dispatch }) {
      dispatch('loader/setLoadingStatus', true, { root: true });
      supportPageService
        .update({
          title: state.title,
          description: state.description,
          internal_support: state.internal_support,
        })
        .then((data) => {
          dispatch('loader/setLoadingStatus', false, { root: true });
          dispatch('alert/success', `Support data has been updated.`, {
            root: true,
          });
        })
        .catch((error) => {
          dispatch('loader/setLoadingStatus', false, { root: true });
          dispatch('alert/error', error, { root: true });
          console.log('Error:', error);
        });
    },
    updater({ commit }, nextState) {
      if (typeof nextState === 'object' && !nextState.length && nextState !== null) {
        commit('updateState', nextState);
      } else {
        console.error('nextState needs to be an object: supportPage.module.js');
      }
    },
  },
  mutations: {
    setData(state, data) {
      state.title = data.title;
      state.description = data.description;
      state.intop_support = data.intop_support;
      state.internal_support = data.internal_support;
    },
    updateState(state, nextState) {
      for (const index in nextState) {
        state[index] = nextState[index];
      }
    },
  },
};
