<template>
  <div class="landing-bg-login">
    <b-container>
      <div class="login-form">
        <div class="main-div">
          <div class="panel">
            <div class="text-center">
              <img src="../assets/logo.png" style="width: 200px; margin: 0 auto 30px" />
            </div>
            <div class="error-message">Your account is not yet activated, please contact your administrator.</div>
            <router-link class="btn intop-login-button btn-block mt-2 ml-0" to="/login">Back to sign in</router-link>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Deactive',
  computed: {
    teamContext() {
      return this.$store.getters['app/getTeamContext'];
    },
  },
};
</script>

<style>
body {
  background-color: #efefef;
}
.landing-bg-login {
  background: #444444;
  background-image: url('../assets/bg/4.jpg');
  background-size: cover;
  height: 100vh;
}
.error-message {
  color: #da4b4b;
  border: 1px solid #f1dcdc;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 4px;
}
</style>
