import config from '../config/config';
import apiUrls from '../config/apiUrls';
import { authHeader, handleApiResponse } from '../helpers';

export const connectorService = {
  verify,
  crawl,
};

function verify(connectorId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let url = apiUrls.VERIFY_CONNECTOR.replace(':connectorId', connectorId);

  return fetch(`${config.apiUrl}${url}`, requestOptions).then((res) =>
    handleApiResponse(res, {
      hideErrorAlert: true,
    })
  );
}

function crawl(connectorId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let url = apiUrls.CRAWL_CONNECTOR.replace(':connectorId', connectorId);

  return fetch(`${config.apiUrl}${url}`, requestOptions).then((res) =>
    handleApiResponse(res, {
      hideErrorAlert: true,
    })
  );
}
