<template>
  <AdminBaseUi title="Context" create="/manage/context/new">
    <div v-if="!this.emptySearchResult" class="context-data-table">
      <div v-if="loading && showFullpageLoader">Loading...</div>
      <Table v-if="!loading">
        <TableHeader>
          <TableRow>
            <TableHeaderCell value="Name" sortField="name" width="300" />
            <TableHeaderCell value="Filters" width="300" />
            <TableHeaderCell value="Columns" width="300" />
            <TableHeaderCell value="Visible" width="60" />
            <TableHeaderCell width="50" />
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow v-for="(row, index) in this.data" :key="index">
            <TableBodyCell :value="row.name" />
            <TableBodyCell :value="getFilters(row)" />
            <TableBodyCell :value="getColumns(row)" />
            <TableBodyCell>
              <Checkbox :checked="row.visible" />
            </TableBodyCell>
            <TableBodyCell>
              <TableOptions
                :id="row.contextid"
                identifier="contextid"
                :endpoint="apiUrls.CONTEXT_DELETE"
                page="manage/context"
                deleteModalEntityName="context"
              />
            </TableBodyCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
    <div class="context-data-table text-center pt-5" v-else>No results found</div>
  </AdminBaseUi>
</template>

<script>
import Checkbox from '@/components/Checkbox';
import AdminBaseUi from '@/components/admin/v2/base-ui/AdminBaseUi';
import Table from '@/components/dataTable/Table';
import TableHeader from '@/components/dataTable/TableHeader';
import TableRow from '@/components/dataTable/TableRow';
import TableHeaderCell from '@/components/dataTable/TableHeaderCell';
import TableBody from '@/components/dataTable/TableBody';
import TableBodyCell from '@/components/dataTable/TableBodyCell';
import TableOptions from '@/components/dataTable/TableOptions';
import apiUrls from '../../../../config/apiUrls';

export default {
  components: {
    AdminBaseUi,
    Table,
    TableRow,
    TableHeader,
    TableHeaderCell,
    TableBody,
    TableBodyCell,
    Checkbox,
    TableOptions,
  },
  created() {
    this.$store.dispatch('dataTable/setTable', apiUrls.FILTER_COLLECTIONS);
    const queries = this.$router.currentRoute.value.query;
    const { redirect: page = 1 } = queries;
    this.$store.dispatch('dataTable/setPage', page);
    if (Object.keys(queries).length) {
      this.$nextTick(() => {
        this.$router.push({ query: {} });
      });
    }
  },
  computed: {
    loading() {
      return this.$store.getters['loader/getLoadingStatus'];
    },
    showFullpageLoader() {
      return this.$store.getters['loader/getFullpageLoaderStatus'];
    },
    emptySearchResult() {
      if (!!this.$store.state.dataTable.search.trim() && !this.$store.state.dataTable.data.data.length) {
        return true;
      } else {
        return false;
      }
    },
    response() {
      return this.$store.state.dataTable.data;
    },
    data() {
      const { data } = this.response;
      return (data || []).map((item) => ({
        contextid: item.contextid,
        name: item.name,
        visible: item.visible,
        columns: item.columns,
        filters: item.filters,
      }));
    },
  },
  data() {
    return {
      apiUrls: apiUrls,
    };
  },
  methods: {
    getFilters(row) {
      if (row.filters && row.filters.length) {
        return row.filters.join(', ');
      }
      return '';
    },
    getColumns(row) {
      if (row.columns && row.columns.length) {
        return row.columns.join(', ');
      }
      return '';
    },
  },
};
</script>

<style scoped>
.context-data-table {
  padding: 0px 40px;
}
</style>
