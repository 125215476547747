<template>
  <tr>
    <slot></slot>
  </tr>
</template>

<script>
export default {};
</script>

<style scoped>
tr:nth-child(even) {
  background-color: #fcfcfc;
}
tr:hover {
  background-color: #f5f5f5;
}
</style>
