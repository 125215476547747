<template>
  <th :align="this.align" :width="this.width">
    <div v-if="this.value">
      <div v-if="this.sortField">
        <div @click.prevent="handleSorting" class="sortable-table-head-cell">
          <div class="table-cell-label">
            {{ this.value }}
          </div>
          <div v-if="this.isActive" class="sort-icon">
            <div v-if="this.ascendingOrder === true">
              <font-awesome-icon icon="sort-down" />
            </div>
            <div v-else-if="this.ascendingOrder === false">
              <font-awesome-icon icon="sort-up" />
            </div>
          </div>
        </div>
      </div>
      <div v-else>{{ this.value }}</div>
    </div>
    <slot v-else></slot>
  </th>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    align: {
      type: String,
      default: 'left',
    },
    sortField: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: 'auto',
    },
  },
  data() {
    return {
      ascendingOrder: null,
    };
  },
  computed: {
    isActive() {
      const sortable = this.$store.state.dataTable.sortables;
      return sortable.field ? sortable.field === this.sortField : false;
    },
  },
  methods: {
    handleSorting() {
      if (this.order === null) {
        this.ascendingOrder = true;
      } else {
        this.ascendingOrder = !this.ascendingOrder;
      }
      this.$store.dispatch('dataTable/setSortableField', {
        field: this.sortField,
        order: this.ascendingOrder ? 'asc' : 'desc',
      });
    },
  },
};
</script>

<style scoped>
th {
  padding: 10px 20px 23px 30px;
  font-weight: bold;
  font-size: 15px;
  color: #23125f;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #ededed !important;
}
.sortable-table-head-cell {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.sortable-table-head-cell:hover {
  opacity: 0.7;
}
.sort-icon {
  padding-left: 10px;
  line-height: 0px;
}
.sort-icon .fa-sort-up {
  margin-bottom: -8px;
}
.sort-icon .fa-sort-down {
  margin-bottom: 5px;
}
</style>
