<template>
  <span v-if="displayResourceTablePagination" class="intop-pagination-info"
    >Showing {{ paginationData.to ? `${paginationData.from + 1} - ${paginationData.to}` : paginationData.from }} of
    {{ numFormat(paginationData.total) }}</span
  >
</template>

<script>
export default {
  name: 'PaginationInfo',
  computed: {
    paginationData() {
      return this.$store.state.pagination;
    },
    displayResourceTablePagination() {
      return this.$store.state.users.displayResourceTablePagination;
    },
  },
  methods: {
    numFormat(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
  },
};
</script>

<style scoped>
.intop-pagination-info {
  color: #262261;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 32px;
  padding-left: 15px;
}
</style>
