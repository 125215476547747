<template>
  <div class="imsform-wrapper">
    <b-modal v-model="modal" :hide-header="true" :hide-footer="true" :size="this.size" body-class="ims-modal" centered>
      <div class="dc-modal-close-btn" @click="hideModal">
        <font-awesome-icon class="close-btn" size="lg" icon="times" />
      </div>
      <DimensionForm v-if="form.activeForm === 'dimension'" />
      <AreasOfInterestForm v-if="form.activeForm === 'areasOfInterests'" />
      <TagGroupsForm v-if="form.activeForm === 'tagGroup'" />
      <TagForm v-if="form.activeForm === 'tags'" />
    </b-modal>
  </div>
</template>

<script>
import DimensionForm from './DimensionForm';
import AreasOfInterestForm from './AreasOfInterestForm';
import TagGroupsForm from './TagGroupsForm';
import TagForm from './TagForm';

export default {
  components: {
    DimensionForm,
    AreasOfInterestForm,
    TagGroupsForm,
    TagForm,
  },
  computed: {
    form() {
      return this.$store.state.imStructure.form;
    },
    modal: {
      set(showModal) {
        this.$store.dispatch('imStructure/updater', {
          form: { showModal },
        });
      },
      get() {
        return this.form.showModal;
      },
    },
    size() {
      if (this.form.activeForm === 'tagGroup') {
        return 'xl';
      } else if (this.form.activeForm === 'tags') {
        return 'lg';
      } else {
        return 'md';
      }
    },
  },
  methods: {
    hideModal() {
      this.$store.dispatch('imStructure/updater', {
        form: { showModal: false },
      });
    },
  },
};
</script>

<style>
.ims-modal {
  background-color: #ededed;
}
.dc-modal-close-btn {
  position: absolute;
  display: inline-block;
  line-height: 0px;
  padding: 20px;
  top: 0;
  right: 0;
  cursor: pointer;
}
</style>
