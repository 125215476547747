import { loginConfigService } from '../services';

export const loginConfig = {
  namespaced: true,
  state: {
    config: null,
    isLoading: true,
  },
  getters: {
    getConfig(state) {
      return state.config;
    },
    getLoadingStatus(state) {
      return state.isLoading;
    },
  },
  actions: {
    getConfig({ commit }) {
      commit('setLoadingStatus', true);
      loginConfigService.get().then((res) => {
        commit('setConfig', res.data);
        commit('setLoadingStatus', false);
      });
    },
  },
  mutations: {
    setConfig(state, config) {
      state.config = config;
    },
    setLoadingStatus(state, status) {
      state.isLoading = status;
    },
  },
};
