<template>
  <div class="favorites-context-wrapper">
    <b-modal
      :id="modalid"
      v-model="modalActive"
      size="lg"
      scrollable
      :hide-footer="true"
      class="favorite-modal"
      header-class="override-modal-header"
      centered
    >
      <template v-slot:header>
        <div class="favorite-modal-header">
          <div class="modal-close-btn" @click="modalActive = false">
            <font-awesome-icon class="close-btn" size="lg" icon="times" />
          </div>
          <div class="favorit-modal-title">My favorites</div>
        </div>
      </template>

      <div class="favorite-modal-body">
        <div v-if="this.isLoading">
          <Loader size="loader-sm"></Loader>
        </div>
        <div v-else>
          <div v-if="!this.isLoading && this.contexts.length <= 0" class="fdr_label">No saved item yet!</div>
          <div v-for="(ctx, index) in this.contexts" :key="index" class="context-label">
            <div class="ctx_label dark-color fade-on-hover dark-color-hover" @click.prevent="switchContext(ctx)">
              {{ ctx.name }}
            </div>
            <div class="d-flex">
              <div class="mx-2">
                <button
                  v-b-tooltip.hover
                  class="bttn-wrapper dark bttn-circle bttn-icon"
                  @click="copyLink(_generateUrl(ctx))"
                  type="button"
                  :title="copyTooltip"
                >
                  <div>
                    <font-awesome-icon class="bttn-icon" icon="link" />
                  </div>
                </button>
              </div>
              <div class="me-3">
                <Button
                  :clickHandler="() => deleteFilterSelection(ctx)"
                  :circle="true"
                  icon="trash"
                  background="red"
                  title="Click to delete"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        id="deleteConfirmationFavoriteModal"
        v-model="deleteConfirmation"
        size="sm"
        scrollable
        :hide-footer="true"
        class="favorite-modal"
        centered
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
      >
        <template v-slot:header>
          <div class="favorite-modal-header">
            <!-- <div class="modal-close-btn" @click="deleteConfirmation = false">
              <font-awesome-icon class="close-btn" size="lg" icon="times" />
            </div> -->
            <div class="favorit-modal-title">Delete favorite?</div>
          </div>
        </template>

        <div class="favorite-modal-body">
          <div>
            <div class="mb-5">Are you sure you want to delete this favorite?</div>
            <div class="d-flex justify-content-center">
              <div class="me-2">
                <Button :clickHandler="cancelDeleteFavorite" label="Cancel" background="light" />
              </div>
              <div>
                <Button
                  :clickHandler="this.confirmDeleteFavorite"
                  label="Delete"
                  background="red"
                  :disabled="favoriteItemDeleteing ? true : false"
                  :showLoader="true"
                  :isLoading="favoriteItemDeleteing"
                />
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </b-modal>
  </div>
</template>

<script>
import { updateUrl, generateUrl } from '@/helpers/bookmark';
import Button from '@/components/v2/Button';
import { genericService } from '@/services';
import apiUrls from '../config/apiUrls';
import Loader from '@/components/v2/Loader';

const initialToolTip = 'Click to copy URL to clipboard';
const successTooltip = 'Copied!';

export default {
  components: {
    Button,
    Loader,
  },
  props: ['modalid'],
  data() {
    return {
      modalActive: false,
      deleteConfirmation: false,
      deleteableFavoriteItemId: false,
      copyTooltip: initialToolTip,
      favoriteItemDeleteing: false,
    };
  },
  computed: {
    contexts() {
      return this.$store.state.savedContext.contexts;
    },
    isLoading() {
      return this.$store.getters['loader/getLoadingStatus'];
    },
    showFullpageLoader() {
      return this.$store.getters['loader/getFullpageLoaderStatus'];
    },
  },
  methods: {
    openFavorites() {
      if (!this.modalActive) {
        this.$store.dispatch('loader/setLoadingStatus', true);
        this.$store.dispatch('loader/setFullpageLoaderStatus', true);
        this.$store
          .dispatch('savedContext/fetchSavedContexts')
          .then((res) => {
            this.$store.dispatch('loader/setLoadingStatus', false);
            this.$store.dispatch('loader/setFullpageLoaderStatus', false);
          })
          .catch((err) => {
            this.$store.dispatch('loader/setLoadingStatus', false);
            this.$store.dispatch('loader/setFullpageLoaderStatus', false);
          });
        this.modalActive = true;
      }
    },
    switchContext(singleContext) {
      updateUrl(singleContext.contextdata);
      setTimeout(() => {
        location.reload();
      }, 300);
    },
    deleteFilterSelection({ usersavedcontextid }) {
      this.deleteableFavoriteItemId = usersavedcontextid;
      this.deleteConfirmation = true;
    },
    confirmDeleteFavorite() {
      this.showContextsList = false;
      this.favoriteItemDeleteing = true;
      const model = genericService.remove(apiUrls.USER_SAVED_CONTEXT);
      model({ usersavedcontextid: this.deleteableFavoriteItemId }).then((res) => {
        this.$store
          .dispatch('savedContext/fetchSavedContexts')
          .then((res) => {
            this.showContextsList = true;
            this.deleteConfirmation = false;
            this.favoriteItemDeleteing = false;
            this.deleteableFavoriteItemId = null;
          })
          .catch((err) => {
            this.showContextsList = true;
            this.deleteConfirmation = false;
            this.favoriteItemDeleteing = false;
            this.deleteableFavoriteItemId = null;
          });
      });
    },
    _generateUrl(qs) {
      return generateUrl(qs);
    },
    copyLink(link) {
      const _inputField = document.createElement('textarea');
      _inputField.innerText = link;
      document.body.appendChild(_inputField);
      const selection = document.getSelection();
      const range = document.createRange();
      //  range.selectNodeContents(textarea);
      range.selectNode(_inputField);
      selection.removeAllRanges();
      selection.addRange(range);

      document.execCommand('copy');
      selection.removeAllRanges();
      _inputField.remove();

      this.copyTooltip = successTooltip;
      setTimeout(() => {
        this.copyTooltip = initialToolTip;
      }, 2000);
    },
    cancelDeleteFavorite() {
      this.deleteConfirmation = false;
      this.favoriteItemDeleteing = false;
      this.deleteableFavoriteItemId = null;
    },
  },
};
</script>
<style scoped>
.modal-close-btn {
  position: absolute;
  right: -5px;
  top: -5px;
  margin-top: -5px;
  color: #222;
  cursor: pointer;
  opacity: 0.6;
  padding: 20px;
}
.modal-close-btn:hover {
  opacity: 1;
}
.favorit-modal-title {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #24125f;
}
.favorites-context-wrapper .fdr_label {
  color: #666;
  padding: 0px;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 5px;
}
.context-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.context-label:nth-child(odd) {
  background-color: #f7f7f7;
}
.context-label:nth-child(even) {
  background-color: #fcfcfc;
}
.ctx_label {
  flex-grow: 1;
  padding: 15px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
}
.modal-dialog .modal-content {
  border: 2px solid #fff;
}

.favorite-modal-header {
  background-color: #f7f7f7;
  padding: 20px;
}

.favorite-modal-body {
  padding: 30px;
}
</style>
<style>
#deleteConfirmationFavoriteModal .modal-header {
  background-color: #f7f7f7;
  padding: 0;
}
.override-modal-header {
  background-color: #f7f7f7;
  padding: 0;
}
</style>
