<template>
  <div class="imc-card-wrapper blue">
    <div class="imc-content">
      <span>{{ this.tagGroup.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['tagGroup'],
};
</script>

<style scoped>
.imc-card-wrapper {
  border-left: 10px solid transparent;
  display: inline-block;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s;
  background-color: #ededed;
}
.imc-card-wrapper:hover {
  box-shadow: 0px 6px 12px -6px #bbb;
}
.imc-content {
  font-size: 14px;
  padding: 15px;
  padding-right: 15px;
}
.blue {
  border-left-color: #5f8dda;
}
</style>
