const handleFiltersWithTableName = (options) => {
  const { state, payloads, field = payloads.tableName + 'id' } = options;
  const filters = [];
  payloads.items.map((filter) => {
    filters.push(filter[field]);
  });
  state.filters = {
    ...state.filters,
    [payloads.tableName]: {
      [field]: [...new Set(filters)],
    },
  };
};

export const adminFilters = {
  namespaced: true,
  state: {
    filters: {},
  },
  actions: {
    add({ commit }, payloads) {
      commit('add', payloads);
    },
    remove({ commit }, payloads) {
      commit('remove', payloads);
    },
    reset({ commit }) {
      commit('clear');
    },
  },
  mutations: {
    add(state, payloads) {
      if (payloads.tableName) {
        handleFiltersWithTableName({ state, payloads });
      }
    },
    remove(state, payloads) {
      if (payloads.tableName) {
        handleFiltersWithTableName({ state, payloads });
      }
    },
    clear(state) {
      state.filters = {};
    },
  },
};
