<template>
  <div>
    <ImFormModal />
    <div class="imstructure-container">
      <Dimensions />
      <AreasOfInterest />
      <TagGroups />
      <Tags />
    </div>
  </div>
</template>

<script>
import Dimensions from './Dimensions';
import AreasOfInterest from './AreasOfInterest';
import TagGroups from './TagGroups';
import Tags from './Tags';
import ImFormModal from './forms/ImFormModal';

export default {
  components: {
    Dimensions,
    AreasOfInterest,
    TagGroups,
    Tags,
    ImFormModal,
  },
};
</script>

<style scoped>
.imstructure-container {
  background-color: #fff;
  margin: 50px 30px 0px 30px;
  height: calc(100vh - 170px);
  overflow-x: scroll;
  padding-bottom: 50px;
}
.aoi {
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
}
.drag,
.drop {
  display: inline-block;
}
.drop.allowed {
  background-color: #dfd;
}
</style>
