<template>
  <div class="resource-search-wrapper" v-click-outside="clickedOutside">
    <b-form @submit.prevent="handleResourceSearch" autocomplete="off">
      <b-form-input
        v-model="searchKeyword"
        placeholder="Search"
        @click="toggleOptions"
        @input="debounceHandleSearch"
        class="input-resource-search light-input dark-color"
        ref="fetchSearchField"
      />
      <font-awesome-icon size="lg" class="search-icon" icon="search" />
      <font-awesome-icon
        v-if="showResetIcon"
        class="dark-color fade-on-hover pointer resource-search-reset-icon"
        size="lg"
        icon="times-circle"
        @click.prevent="resetKeyword"
      />
    </b-form>
    <ResourceSearchOptions v-if="showOptions" :handleResourceSearch="handleResourceSearch" />
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { filterService } from '@/services';
import ResourceSearchOptions from '@/components/resourceSearch/ResourceSearchOptions';

export default {
  created() {
    this.debounceHandleSearch = debounce(this.handleSearch, 1200);
  },
  components: {
    ResourceSearchOptions,
  },
  data() {
    return {
      options: [],
    };
  },
  watch: {
    searchKeyword(val) {
      if (val.trim()) {
        this.$refs.fetchSearchField.focus();
      }
    },
  },
  computed: {
    searchKeyword: {
      set(searchString) {
        this.$store.dispatch('resource/updater', {
          searchString,
        });
        if (searchString.trim() === '') {
          this.$store.dispatch('resource/updater', {
            searchOptions: [],
            searchSpellingError: '',
          });
        }
      },
      get() {
        return this.$store.state.resource.searchString;
      },
    },
    loading() {
      return this.$store.state.resource.searchLoading;
    },
    showOptions() {
      return this.$store.state.resource.searchToggleOptions;
    },
    showResetIcon() {
      return !!this.searchKeyword;
    },
  },
  methods: {
    handleSearch() {
      this.$store.dispatch('resource/getSearchSuggestions');
      if (!this.showOptions && this.searchKeyword) {
        this.$store.dispatch('resource/updater', {
          searchToggleOptions: true,
        });
      }
    },
    handleResourceSearch() {
      this.$store.dispatch('filterParams/searchString', this.searchKeyword);
      this.$store.dispatch('resource/updater', {
        searchToggleOptions: false,
        searchString: '',
        searchOptions: [],
        searchSpellingError: '',
      });
      this.$refs.fetchSearchField.blur();
    },
    toggleOptions() {
      const isOpen = this.$store.state.resource.searchToggleOptions;
      if (!isOpen) {
        this.$store.dispatch('resource/updater', {
          searchToggleOptions: true,
        });
      }
    },
    clickedOutside() {
      this.$store.dispatch('resource/smartToggleOptions');
    },
    resetKeyword() {
      this.searchKeyword = '';
      this.$store.dispatch('resource/updater', {
        searchToggleOptions: false,
      });
    },
  },
};
</script>

<style>
.resource-search-wrapper {
  width: 750px;
  min-width: 400px;
  position: relative;
  z-index: 101;
}
.resource-search-wrapper .input-resource-search {
  width: 100% !important;
  min-width: 400px;
  padding-left: 50px;
  padding-right: 50px;
}
.resource-search-wrapper .resource-search-reset-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 14px;
}
.resource-search-wrapper .search-icon {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 10px;
  margin-left: 16px;
}
</style>
