const qs = require('qs');
import config from '../config/config';
import { authHeader, handleApiResponse } from '../helpers';
import { store } from '../store';

function filterItems(url, data = {}) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const formattedQueryString = qs.stringify(data);

  return fetch(`${config.apiUrl}${url}?${formattedQueryString}`, requestOptions)
    .then((res) =>
      handleApiResponse(res, {
        hideErrorAlert: true,
        returnResponseOnError: true,
      })
    )
    .catch((error) => {
      store.dispatch('alert/error', `API request failed: ${error.error}`);
    });
}

export const imStructureService = {
  filterItems,
};
