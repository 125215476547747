<template>
  <div class="resource-search-options-wrapper">
    <button
      v-if="searchString.length"
      class="d-flex align-content-center search-dup"
      @click.prevent="handleSearchClick"
    >
      <div class="tip dark-color">Press enter to search</div>
      <div class="dup-icon dark-color">
        <font-awesome-icon size="lg" icon="search" />
      </div>
      <div class="pl-2 search-dup-label dark-color">{{ searchString }}</div>
    </button>
    <div v-else class="tip dark-color">Press enter to search</div>
    <div v-if="spellingError" class="spell-error">
      <span class="spell-error-label dark-color">did you mean:</span>
      <span class="spell-error-value fade-on-hover dark-color" @click.prevent="handleSpellingError">{{
        spellingError
      }}</span>
    </div>

    <div v-if="this.options.length" class="dark-color top-label pl-50">Relevant filters:</div>

    <div class="mb-3 pl-50" v-for="(item, index) in this.options" :key="index">
      <div class="dark-color taggroup-label">{{ item.name }}</div>
      <div class="tag-cards-wrapper">
        <div
          class="tag-card light-blue-bg fade-on-hover pointer"
          v-for="(tag, index) in item.tags"
          :key="index"
          @click.prevent="() => handleTagSelect(tag)"
        >
          {{ tag.name }}
        </div>
      </div>
    </div>
    <div class="pt-4" v-if="!this.options.length"></div>
  </div>
</template>

<script>
import groupedFilters from '../../helpers/groupedFilters';

export default {
  props: ['handleResourceSearch'],
  computed: {
    options() {
      return groupedFilters(this.$store.state.resource.searchOptions);
    },
    searchString() {
      return this.$store.state.resource.searchString;
    },
    spellingError() {
      return this.$store.state.resource.searchSpellingError;
    },
  },
  methods: {
    handleTagSelect(tag) {
      this.$store.dispatch('filterParams/updateTag', {
        tagid: tag.tagid,
        label: tag.name,
        taggroupid: tag.taggroupid,
      });
      this.$store.dispatch('resource/updater', {
        searchToggleOptions: false,
        searchString: '',
        searchOptions: [],
        searchSpellingError: '',
      });
    },
    handleSpellingError() {
      this.$store.dispatch('resource/updater', {
        searchString: this.spellingError,
        searchOptions: [],
      });
      this.$nextTick(() => {
        this.handleResourceSearch();
      });
    },
    handleSearchClick() {
      this.handleResourceSearch();
    },
  },
};
</script>

<style scoped>
.resource-search-options-wrapper {
  box-shadow: 0px 4px 6px 2px rgba(25, 25, 25, 0.12);
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 45px;
  padding-bottom: 35px;
}
.tip {
  position: absolute;
  right: 0;
  top: 0;
  margin: 20px 25px;
  font-weight: 500;
  cursor: pointer;
}
.tag-card {
  margin-right: 10px;
  padding: 6px 20px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 10px;
  display: inline-block;
}
.taggroup-label {
  font-size: 13px;
  margin-bottom: 1px;
  padding-top: 5px;
  padding-bottom: 2px;
}
.top-label {
  font-size: 14px;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 3px;
}
.search-dup {
  padding: 20px;
  padding-left: 22px;
  padding-bottom: 10px;
  cursor: pointer;
  margin-bottom: 5px;
  display: block;
  width: 100%;
  outline: none;
  border: none;
  background-color: #fff;
  z-index: 9999;
}
.dup-icon {
  padding-right: 4px;
}
.tag-cards-wrapper {
  padding-right: 40px;
}
.spell-error {
  margin-left: 50px;
}
.spell-error-label {
  font-style: italic;
  margin-right: 6px;
}
.spell-error-value {
  font-weight: bold;
  cursor: pointer;
}
</style>
