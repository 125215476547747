import { createRouter, createWebHashHistory } from 'vue-router';

import { store } from '../store';

// pages
import Login from '@/pages/Login';
import Main from '@/pages/Main';
import Teams from '@/pages/Teams';
import Deactive from '@/pages/Deactive';
import SelectTenant from '@/pages/SelectTenant';

// child components
import ImStructure from '@/components/admin/imStructure/ImStructure';

import Manager from '@/components/admin/v2/ManagerWrapper';
import AdminDashboard from '@/components/admin/v2/AdminDashboard';
import ContextManager from '@/components/admin/v2/ContextManager';

import Connector from '@/components/admin/v2/connectors/Connector';
import ConnectorForm from '@/components/admin/v2/connectors/ConnectorForm';
import User from '@/components/admin/v2/users/User';
import UserForm from '@/components/admin/v2/users/UserForm';

import Support from '@/components/admin/v2/pages/Support';
import Welcome from '@/components/admin/v2/pages/Welcome';
import RelationshipEditor from '@/components/admin/v2/relationshipEditor/RelationshipEditor';
import AdvanceSettings from '@/components/admin/v2/advance-settings/AdvanceSettings';
import FileShareOfficeFileMapping from '@/components/admin/v2/office-protocol/FileShareOfficeFileMapping';
import FileShareOfficeFileMappingForm from '@/components/admin/v2/office-protocol/FileShareOfficeFileMappingForm';
import LoginConfiguration from '@/components/admin/v2/login-configuration/LoginConfiguration';
import HiddenApiCall from '@/components/admin/v2/hidden-api-call/HiddenApiCall';

import Context from '@/components/admin/v2/context/Context';

import { hasAccessPermission } from '@/helpers/hasAccessPermission';
import appInsights from '../appInsights';
import { generateW3CId } from '@microsoft/applicationinsights-core-js';

const routes = [
  {
    path: '/manage',
    name: 'Manager',
    component: Manager,
    children: [
      {
        name: 'Admin Dashboard',
        path: 'admin',
        component: AdminDashboard,
        meta: { roleRequired: [1, 2] },
      },
      {
        name: 'Connector',
        path: 'connector',
        component: Connector,
        meta: { roleRequired: [1, 2] },
      },
      {
        name: 'Connectors',
        path: 'connector/:id',
        component: ConnectorForm,
        meta: { roleRequired: [1, 2] },
      },
      {
        name: 'Users',
        path: 'user',
        component: User,
        meta: { roleRequired: [1, 2] },
      },
      {
        name: 'User',
        path: 'user/:id',
        component: UserForm,
        meta: { roleRequired: [1, 2] },
      },
      {
        name: 'Welcome',
        path: 'welcomepage',
        component: Welcome,
        meta: { roleRequired: [1, 2] },
      },
      {
        name: 'Support',
        path: 'supportpage',
        component: Support,
        meta: { roleRequired: [1, 2] },
      },
      {
        name: 'Context Manager',
        path: 'context',
        component: Context,
        meta: { roleRequired: [1, 2] },
      },
      {
        name: 'ContextManager',
        path: 'context/:id',
        component: ContextManager,
        meta: { roleRequired: [1, 2] },
      },
      {
        name: 'IM Structure',
        path: 'im-structure',
        component: ImStructure,
        meta: { roleRequired: [1, 2] },
      },
      {
        name: 'Relationship Editor',
        path: 'relationshipeditor',
        component: RelationshipEditor,
        meta: { roleRequired: [1, 2] },
      },
      {
        name: 'FileshareMapping',
        path: 'filesharemapping',
        component: FileShareOfficeFileMapping,
        meta: { roleRequired: [1, 2] },
      },
      {
        name: 'FileshareMappingForm',
        path: 'filesharemapping/:id',
        component: FileShareOfficeFileMappingForm,
        meta: { roleRequired: [1, 2] },
      },
      {
        name: 'Advanced Settings',
        path: 'advancesettings',
        component: AdvanceSettings,
        meta: { roleRequired: [1, 2] },
      },
      {
        name: 'Login Configuration',
        path: 'loginConfiguration',
        component: LoginConfiguration,
        meta: { roleRequired: [1, 2] },
      },
      {
        name: 'Hidden Api Call',
        path: 'hiddenapicall',
        component: HiddenApiCall,
        meta: { roleRequired: [1] },
      },
    ],
  },
  {
    path: '/',
    name: 'Main',
    component: Main,
  },
  {
    path: '/teams',
    name: 'teams',
    component: Teams,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/activate-account',
    name: 'Deactive',
    component: Deactive,
  },
  {
    path: '/select-tenant',
    name: 'SelectTenant',
    component: SelectTenant,
    props: true,
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHashHistory('/'),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/activate-account', '/select-tenant'];
  const authRequired = !publicPages.includes(to.path);
  let loggedIn = localStorage.getItem('user');
  if (loggedIn) {
    loggedIn = JSON.parse(loggedIn);
  }
  let tokenExpired = true;
  if (loggedIn && loggedIn.expiresIn) {
    const now = new Date();
    tokenExpired = now.getTime() > loggedIn.expiresIn;
  }
  // Keep the full path of the active route.
  store.dispatch('app/setCurrentPath', to.fullPath);

  if (authRequired && (!loggedIn || tokenExpired)) {
    return next('/login');
  }

  const roleRequired = to.meta.roleRequired || false;
  if (roleRequired) {
    if (!hasAccessPermission(roleRequired)) {
      return next('/');
    }
  }

  if (appInsights) {
    const name = 'WEB ' + to.path;
    const traceId = generateW3CId();
    appInsights.context.telemetryTrace.name = name;
    appInsights.context.telemetryTrace.traceID = traceId;
    appInsights.startTrackPage(name);
  }

  next();
});

router.afterEach((route) => {
  if (appInsights) {
    const name = 'WEB ' + route.path;
    const url = location.protocol + '//' + location.host + route.fullPath;
    appInsights.stopTrackPage(name, url);
    appInsights.flush();
  }
});

export default router;
