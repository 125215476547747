<template>
  <div>
    <div>
      <PaginationInfo />
    </div>
    <div class="pagination-buttons-wrapper">
      <b-button-group
        v-if="displayResourceTablePagination && totalPage > 1"
        :class="this.loading ? 'intop-pagination disabled-pagination' : 'intop-pagination'"
      >
        <b-button
          :class="`pagbtn px-0 nav-btn ${isFirstPage && 'disabled-pagenator-indicator'}`"
          variant="default"
          @click="paginate(currentPage - 1, false)"
          title="Go to previous page"
        >
          <font-awesome-icon icon="chevron-left" />
        </b-button>
        <b-button
          :variant="getVariant(n)"
          v-for="n in maxWindowSize"
          :key="n"
          @click="paginate(n)"
          class="paginator-counts pagbtn"
          >{{ getPageNum(n) }}</b-button
        >
        <b-button
          :class="`pagbtn px-0 nav-btn ${isLastPage && 'disabled-pagenator-indicator'}`"
          variant="default"
          @click="paginate(currentPage + 1, false)"
          title="Go to next page"
        >
          <font-awesome-icon icon="chevron-right" />
        </b-button>
      </b-button-group>
    </div>
  </div>
</template>

<script>
import PaginationInfo from '@/components/PaginationInfo';

export default {
  name: 'Pagination',
  data() {
    return {
      onEachSide: 1,
    };
  },
  components: { PaginationInfo },
  computed: {
    currentPage() {
      return this.$store.state.pagination.currentPage;
    },
    totalPage() {
      return this.$store.state.pagination.lastPage;
    },
    hasOffset() {
      return this.totalPage <= this.onEachSide * 2;
    },
    maxWindowSize() {
      return this.totalPage === 1 ? 1 : Math.min(this.totalPage, this.windowSize);
    },
    windowSize() {
      if (this.hasOffset) {
        return this.totalPage;
      } else {
        return this.onEachSide * 2 + 1;
      }
    },
    windowStart() {
      if (this.hasOffset) {
        return 1;
      }
      if (this.$store.state.pagination.currentPage <= this.onEachSide) {
        return 1;
      } else if (this.$store.state.pagination.currentPage >= this.totalPage - this.onEachSide) {
        return this.totalPage - this.onEachSide * 2;
      }
      return this.$store.state.pagination.currentPage - this.onEachSide;
    },
    displayResourceTablePagination() {
      return this.$store.state.users.displayResourceTablePagination;
    },
    loading() {
      return this.$store.state.pagination.loading;
    },
    isFirstPage() {
      return this.currentPage === 1;
    },
    isLastPage() {
      return this.currentPage === this.totalPage;
    },
  },
  methods: {
    getVariant(num) {
      return this.getPageNum(num) === this.currentPage ? 'active-paginated' : 'default';
    },
    getPageNum(num) {
      return this.windowStart - 1 + num;
    },
    paginate(page, relative = true) {
      if (relative) {
        page = this.getPageNum(page);
      }
      page = Math.max(1, Math.min(this.totalPage, page));
      this.$store.dispatch('pagination/setCurrentPage', page);
    },
  },
};
</script>

<style scoped>
.pagination-buttons-wrapper {
  margin-left: 14px;
}

.intop-pagination .btn-default {
  background-color: transparent;
}

.intop-pagination {
  box-shadow: none;
  border-radius: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.intop-pagination .btn {
  box-shadow: none;
}

.disabled-pagination {
  opacity: 0.7;
  pointer-events: none;
}
.pagbtn {
  padding: 0px 2px;
}
.pagbtn:first-child {
  padding-left: 0px;
  margin-left: 0px;
}
.pagbtn:last-child {
  padding-right: 0px;
  margin-right: 0px;
}
.intop-pagination .btn-default {
  color: #999;
}
.btn-active-paginated {
  color: #fff;
  background-color: #262261;
  padding: 3px;
  line-height: 14px;
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50% !important;
}
.btn-active-paginated:hover {
  color: #fff;
}
.intop-pagination .nav-btn {
  color: #262261;
  border-radius: 0px;
  padding: 0px 3px;
}
.disabled-pagenator-indicator {
  opacity: 0.3;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  flex: 0 0 auto;
}
</style>
