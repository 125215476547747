import config from "../config/config";
import apiUrls from "../config/apiUrls";
import { authHeader, handleApiResponse } from "../helpers";

function find() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}${apiUrls.SUPPORT_DETAILS}`,
    requestOptions
  ).then(handleApiResponse);
}

function update(data) {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(
    `${config.apiUrl}${apiUrls.SUPPORT_UPDATE}`,
    requestOptions
  ).then(handleApiResponse);
}

function remove(dataType) {
  return (data) => {
    const requestOptions = {
      method: "DELETE",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    return fetch(`${config.apiUrl}/private/${dataType}`, requestOptions).then(
      handleApiResponse
    );
  };
}

export const supportPageService = {
  find,
  update,
  remove,
};
