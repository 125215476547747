import { contextService } from '@/services';

import { bookmarkedData, updateBookmarks, cleanBookmarksUrl } from '@/helpers/bookmark';

export const contexts = {
  namespaced: true,
  state: {
    all: {
      items: [],
      loading: false,
    },
    selected: {
      // While adding a new state in selected,
      // also update the "resetContexts(state)" mutator below.
      contextid: null,
      filters: [],
      columns: [],
      name: null,
      ordering: null,
    },
  },
  getters: {
    getSelected(state) {
      return state.selected;
    },
  },
  actions: {
    getAll() {
      console.trace();
    },
    getHydratedContexts({ commit, dispatch }, payload = { visibilityFilter: true }) {
      return new Promise((resp, rejp) => {
        commit('getAllRequest');

        contextService.getHydratedContexts(payload).then(
          async ({ data: contexts }) => {
            contexts = contexts.map((c) => ({
              ...c,
              filters: c.filters || [],
              columns: c.columns || [],
            }));
            commit('getAllSuccess', contexts);
            const bookmarkedContextId = bookmarkedData('c');
            if (bookmarkedContextId) {
              const selectedContext = this.state.contexts.all.items.find((el) => el.contextid === bookmarkedContextId);
              commit('select', selectedContext);
            }
            await dispatch(
              'filterParams/updateTagFromBookmark',
              {
                nodeFilters: bookmarkedData('f') || [],
                searchString: bookmarkedData('s') || [],
                modifiedFrom: bookmarkedData('a') || '',
                modifiedBefore: bookmarkedData('b') || '',
              },
              { root: true }
            );
            await dispatch('app/setSearchByTitle', bookmarkedData('t') || false, { root: true });
            await dispatch('filterParams/handleMainViewOnFilterChange', {}, { root: true });
            resp(contexts);
          },
          (error) => {
            commit('getAllFailure', error);
            rejp(error);
          }
        );
      });
    },
    setSelected({ commit }, contextId) {
      commit('resetSelectedContext');
      cleanBookmarksUrl();
      const selectedContext = this.state.contexts.all.items.find((el) => el.contextid === contextId);
      commit('select', selectedContext);
      updateBookmarks({
        paramKey: 'selectedContext',
        paramValue: selectedContext.contextid,
      });
    },
    setDemoContext({ commit, rootGetters }) {
      commit('select', rootGetters['contextManager/demoContext']);
    },
    setDefaultFilters({ dispatch }, tags) {
      if (tags) {
        tags.map((n) => dispatch('filterParams/updateTag', n, { root: true }));
      }
    },
  },
  mutations: {
    getAllRequest(state) {
      state.all = { loading: true };
    },
    getAllSuccess(state, contexts) {
      state.all = { items: contexts };
    },
    getAllFailure(state, error) {
      state.all = { error };
    },
    select(state, context) {
      state.selected = context;
    },
    resetSelectedContext(state) {
      state.selected = {
        contextid: null,
        filters: [],
        columns: [],
        name: null,
        ordering: null,
      };
    },
  },
};
