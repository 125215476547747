<template>
  <td>
    <div v-if="this.value">{{ this.value }}</div>
    <slot v-else></slot>
  </td>
</template>

<script>
export default {
  props: ["value"],
};
</script>

<style scoped>
td {
  padding: 10px 31px;
  height: 73px;
  font-size: 14px;
  color: #24125f;
}
</style>
