import apiUrls from '../config/apiUrls';
import config from '../config/config';
import { authHeader, handleApiResponse } from '../helpers';

export const resourceService = {
  getOne,
  get,
};

function getOne(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  let url = apiUrls.RESOURCE_DETAILS.replace(':id', id);
  return fetch(`${config.apiUrl}${url}`, requestOptions).then(handleApiResponse);
}

function get(postData) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(postData),
  };

  return fetch(`${config.apiUrl}${apiUrls.RESOURCE}`, requestOptions).then(handleApiResponse);
}
