<template>
  <div class="team-favorite-box">
    <Multiselect
      ref="favoriteMenu"
      key="favorite"
      title="Favorites"
      :multiselect="false"
      :itemlistvisible="false"
      :disableSorting="true"
      accordion="filter-accordion"
    >
      <div class="filter-content pt-2">
        <Button :clickHandler="addFavorite" label="Add favorite" background="dark" class="ms-3 mb-2" />
        <br />
        <Button :clickHandler="openFavorites" label="View all favorites" background="dark" class="ms-3 mb-2" />
      </div>
    </Multiselect>
    <b-modal
      ref="add-favorite-modal"
      v-model="addFavoriteModal"
      id="add-favorite-modal"
      modal-class="add-favorite-modal"
    >
      <template v-slot:header>
        <div></div>
      </template>
      <SaveContextForm ref="favoriteComponent" />
    </b-modal>
    <FavoriteContexts modalid="favoriteContexts" ref="favoriteContexts" />
  </div>
</template>

<script>
import Multiselect from './Multiselect';
import SaveContextForm from '@/components/SaveContextForm';
import FavoriteContexts from '@/components/FavoriteContexts.vue';
import Button from '@/components/v2/Button';

export default {
  components: {
    Multiselect,
    SaveContextForm,
    FavoriteContexts,
    Button,
  },
  data() {
    return {
      modaltop: '0',
      modalleft: '0',
      addFavoriteModal: false,
    };
  },
  methods: {
    addFavorite() {
      const favoriteBoxWrapperRect = document.getElementById('favoriteBoxWrapper').getBoundingClientRect();
      const sidebarRect = document.getElementById('team-sidebar-container').getBoundingClientRect();
      this.modaltop = favoriteBoxWrapperRect.top + 'px';
      this.modalleft = sidebarRect.right + 'px';
      this.addFavoriteModal = true;
      const dialog = { ...this.$refs['add-favorite-modal'].$refs };

      this.$nextTick(() => {
        dialog.element.style.top = this.modaltop;
        dialog.element.style.left = this.modalleft;
      });
    },
    openFavorites() {
      this.$refs.favoriteContexts.openFavorites();
    },
  },
};
</script>

<style scoped>
.filter-content {
  margin-bottom: 0.5rem;
}
:global(.filter-content .bttn-wrapper) {
  padding: 8px 20px;
  font-size: 0.85rem;
  width: 10rem;
}
:global(#add-favorite-modal .modal-dialog) {
  position: absolute;
  margin-top: 0;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1), 2px -2px 4px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1), 2px -2px 4px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1), 2px -2px 4px 0px rgba(0, 0, 0, 0.1);
}
:global(#add-favorite-modal .modal-dialog .modal-header) {
  display: none;
}
:global(#add-favorite-modal .modal-dialog .modal-footer) {
  display: none;
}
:global(#add-favorite-modal .modal-dialog .modal-body) {
  background: #f5f5f5;
  padding: 1.25rem;
}
:global(#add-favorite-modal .modal-dialog .modal-body textarea) {
  background: #fff;
}
:global(#add-favorite-modal .modal-dialog .modal-body .view-all) {
  display: none;
}
:global(#add-favorite-modal .modal-dialog .modal-body .input-label) {
  font-size: 0.85rem;
  font-weight: bold;
  margin-bottom: 14px !important;
}
:global(#add-favorite-modal .modal-dialog .modal-body button) {
  font-size: 0.85rem;
  font-weight: bold;
}
</style>
<style>
#favoriteContexts .modal-header {
  background-color: #f5f5f5;
}
#favoriteContexts .favorite-modal-header {
  padding: 25px 35px;
}
#favoriteContexts .favorit-modal-title {
  font-size: 1rem;
  font-weight: bold;
}
#favoriteContexts .favorite-modal-body .context-label {
  background-color: #f5f5f5 !important;
  margin-bottom: 10px;
}
#favoriteContexts .favorite-modal-body .ctx_label {
  font-size: 0.85rem;
  font-weight: bold;
}
</style>
