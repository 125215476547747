<template>
  <div class="header-wrapper">
    <div class="menu-link-wrapper" v-for="(menu, index) in menus" :key="index">
      <b-dropdown
        size="lg"
        variant="link"
        class="system-config-menu"
        toggle-class="text-decoration-none"
        no-caret
        v-if="menu.child"
      >
        <template v-slot:button-content>
          <a :class="`menu-link ${isActive(menu) && 'menu-active'}`" href="/">
            <span>{{ menu.name }}</span>
            <div class="active-border"></div>
          </a>
        </template>
        <b-dropdown-item v-for="(option, index) in menu.child" :key="index" v-show="menuHasPermission(option)">
          <template v-slot:default>
            <router-link :to="option.path" class="dropdown-item">
              <span>{{ option.name }}</span>
            </router-link>
          </template>
        </b-dropdown-item>
      </b-dropdown>
      <router-link
        :class="`menu-link ${isActive(menu) && 'menu-active'}`"
        v-else-if="!menu.child && !menu.hidden && !menu.fullPath"
        :to="menu.path"
      >
        <span>{{ menu.name }}</span>
        <div class="active-border"></div>
      </router-link>
      <router-link
        :class="`menu-link ${isActive(menu) && 'menu-active'}`"
        v-else-if="menu.fullPath"
        :to="menu.path + '?v=' + parseInt(Math.random() * 100)"
      >
        <span>{{ menu.name }}</span>
        <div class="active-border"></div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { hasAccessPermission } from '@/helpers/hasAccessPermission';

export default {
  data: function () {
    return {
      menus: [
        {
          name: 'Dashboard',
          path: '/manage/admin',
          fullPath: true,
        },
        {
          name: 'System Configurations',
          child: [
            { name: 'Connectors', path: '/manage/connector' },
            { name: 'Users', path: '/manage/user' },
            { name: 'Welcome Page', path: '/manage/welcomepage' },
            { name: 'Support Page', path: '/manage/supportpage' },
            {
              name: 'Advanced Settings',
              path: '/manage/advancesettings',
              roles: [1, 2],
            },
            {
              name: 'Sign in Configuration',
              path: '/manage/loginConfiguration',
              roles: [1, 2],
            },
          ],
        },
        {
          name: 'Context Manager',
          path: '/manage/context',
        },
        {
          name: 'IM Structure',
          path: '/manage/im-structure',
        },
        {
          name: 'Relationship Editor',
          path: '/manage/relationshipeditor',
          hidden: true,
        },
      ],
    };
  },
  computed: {
    currentPath() {
      return this.$store.state.app.currentPath;
    },
  },
  methods: {
    isActive(option) {
      if (option.child && option.child.length) {
        return option.child.some((c) => this.currentPath.includes(c.path));
      }
      return this.currentPath.includes(option.path);
    },
    menuHasPermission(menu) {
      if (menu.roles) {
        return hasAccessPermission(menu.roles);
      }
      return true;
    },
  },
};
</script>

<style scoped>
.header-wrapper {
  height: 100%;
  display: flex;
}

.menu-link-wrapper {
  position: relative;
}

.menu-link {
  padding: 60px 20px;
  font-size: 14px;
  height: 50px;
  color: #23125f;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.menu-link:hover {
  text-shadow: 0.2px 0.2px 0.5px #23125f;
}
.menu-active {
  text-shadow: 0.2px 0.2px 0.5px #23125f;
}
.menu-active .active-border {
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(100% - 40px);
  margin-left: 20px;
  background-color: #23125f;
  margin-bottom: -2px;
}
</style>

<style>
.header-wrapper .system-config-menu .btn:focus {
  box-shadow: none;
}
.header-wrapper .system-config-menu .btn {
  margin: 0px;
  padding: 0px;
  border: 0px;
}
.header-wrapper .system-config-menu ul.dropdown-menu {
  border-radius: 0px;
  border: 0px;
  background-color: #262261;
  color: #fff;
  min-width: 190px;
  margin-top: -30px;
}
.header-wrapper .system-config-menu li .dropdown-item {
  color: #fff;
}
.header-wrapper .system-config-menu li .dropdown-item:hover {
  background-color: #3c3697;
  color: #fff;
}
</style>
