<template>
  <div class="tag-form">
    <div v-if="renderForm" class="form-wrapper">
      <div class="mb-4">
        <TextInput v-if="enableEditingTitle" reference="tagname" v-model="label" placeholder="Tag" />
        <div v-else class="form-header">
          <div class="toplabel">{{ label }}</div>
          <div class="actions">
            <b-dropdown id="dropdown-dropright" dropright variant="default" no-caret>
              <template v-slot:button-content>
                <img src="@/assets/dots.svg" alt="" />
              </template>
              <b-dropdown-item @click.prevent="showNameField" href="#">Edit tag name</b-dropdown-item>
              <b-dropdown-item-button>
                <DeleteConfirmation
                  displayButtonLabel="Delete"
                  entityLabel="tag"
                  :handleConfirm="handleDelete"
                  :customLabel="deleteLabel"
                  classAttr="no-margin-dc-menu"
                />
              </b-dropdown-item-button>
            </b-dropdown>
          </div>
        </div>
      </div>
      <b-container>
        <b-row class="mb-5">
          <b-col class="no-padding" :sm="5">
            <div class="df-label">Tag Group:</div>
            <DynamicSelect
              id="taggroupid"
              class="text-break-all"
              title="Tag Group"
              label="name"
              :singleSelect="true"
              :handleOpen="this.onTagGroupOpen"
              v-model="taggroupid"
            />
          </b-col>
          <b-col :sm="7">
            <div class="map">
              <div class="w-50">
                <div class="depmapwrapper dimmap">
                  <div class="depmaplabel">Dimension:</div>
                  <div
                    v-if="this.explorer && this.explorer.dimension && this.explorer.dimension.name"
                    class="depmapitems"
                  >
                    <div class="comma-separated-wrapper text-break-all">
                      <span class="has-border">{{ this.explorer.dimension.name }}</span>
                    </div>
                  </div>
                  <div class="depmapitems ms-1" v-else>- - -</div>
                </div>
              </div>
              <div class="w-50">
                <div class="depmapwrapper">
                  <div class="depmaplabel">Areas of interest:</div>
                  <div
                    v-if="this.explorer && this.explorer.areaofinterests && this.explorer.areaofinterests.length"
                    class="depmapitems"
                  >
                    <div
                      class="comma-separated-wrapper text-break-all"
                      v-for="(aot, index) in this.explorer.areaofinterests"
                      :key="index"
                    >
                      <span class="has-border">{{ aot.name }}</span>
                      <span class="the-comma" v-if="index + 1 < explorer.areaofinterests.length">, </span>
                    </div>
                  </div>
                  <div class="depmapitems ms-1" v-else>- - -</div>
                </div>
              </div>
            </div>
            <div class="depmapwrapper tgmap map">
              <div class="w-50">
                <div class="depmaplabel">Contexts:</div>
                <div
                  class="depmapitems"
                  v-if="this.explorer && this.explorer.contexts && this.explorer.contexts.length"
                >
                  <div class="comma-separated-wrapper" v-for="(aot, index) in this.explorer.contexts" :key="index">
                    <span class="has-border">{{ aot.name }}</span>
                    <span class="the-comma" v-if="index + 1 < explorer.contexts.length">, </span>
                  </div>
                </div>
                <div class="depmapitems ms-1" v-else>- - -</div>
              </div>

              <div class="w-50">
                <div class="depmaplabel" v-if="this.editableData && this.editableData.parenttagid">From Tag Group:</div>
                <div class="depmaplabel" v-else>Other Tag Groups:</div>
                <div class="depmapitems" v-if="this.editableData && this.editableData.haslink && this.linkedTags">
                  <div class="comma-separated-wrapper" v-for="(tag, index) in this.linkedTags" :key="index">
                    <span class="has-border">{{ tag.name }} </span>
                    <span class="the-comma" v-if="index + 1 < linkedTags.length">, </span>
                  </div>
                </div>
                <div
                  class="depmapitems"
                  v-else-if="this.editableData && this.editableData.parenttagid && this.parentTagGroup"
                >
                  <div class="comma-separated-wrapper" v-for="(tagGroup, index) in this.parentTagGroup" :key="index">
                    <span class="has-border">{{ tagGroup.name }} </span>
                    <span class="the-comma" v-if="index + 1 < parentTagGroup.length">, </span>
                  </div>
                </div>
                <div class="depmapitems ms-1" v-else>- - -</div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div class="pattern-table-wrapper">
        <div class="drawcols pattern-table-header">
          <div class="cell action"></div>
          <div class="cell select">Type</div>
          <div class="cell label">Pattern</div>
        </div>
        <div v-for="(pattern, index) in patterns" :key="index" class="drawcols pattern-table-body">
          <div class="cell action">
            <div class="col-act-btn" @click.prevent="() => deletePattern(index)">
              <font-awesome-icon class="act-icon" size="lg" icon="trash" />
            </div>
          </div>
          <div class="cell select">
            <DynamicSelect
              id="patterntypeid"
              title="Type"
              label="name"
              :singleSelect="true"
              v-model="pattern.patterntypeid"
              :handleOpen="() => onPatternTypeOpen(index)"
              :inputPayload="index"
              :handleInput="onPatternTypeSelect"
            />
          </div>
          <div class="cell label d-flex" v-if="pattern.patterntypeid === 4">
            <InputBox
              class="w-50 border-right"
              variant="white"
              :getFunc="() => pattern.pattern"
              :setFunc="(_pattern) => handlePatternNameChange(index, _pattern)"
            />
            <LookupfieldSelect
              class="w-50"
              v-model="pattern.lookupfield"
              :selected="pattern.lookupfield"
              placeholder="Choose field"
            />
          </div>
          <div class="cell label" v-else-if="pattern.patterntypeid === 6">
            <DynamicSelect
              id="tagid"
              label="label"
              :optGroup="true"
              :singleSelect="true"
              :handleOpen="() => tagAsPatternPayload.filter((item) => item.tagid != pattern.tag)"
              :handleInput="({ input }) => (pattern.pattern = input.label)"
              :handleSearchChange="(keyword) => onSearchTag(keyword, pattern.tag)"
              groupBykey="name"
              v-model="pattern.tag"
            />
          </div>

          <div class="cell label" v-else>
            <InputBox
              variant="white"
              :getFunc="() => pattern.pattern"
              :setFunc="(_pattern) => handlePatternNameChange(index, _pattern)"
            />
          </div>
        </div>
        <div class="add-row-wrapper">
          <div class="col-act-btn add-row-btn" title="Add a new row" @click.prevent="addNewRow">
            <font-awesome-icon class="act-icon" size="lg" icon="plus" />
          </div>
        </div>
      </div>
      <div>
        <div class="mt-5 text-right">
          <span class="me-3">
            <Button label="Cancel" background="light" :disabled="isLoading" :clickHandler="handleCancel" />
          </span>
          <Button
            :label="this.edit ? 'Update' : 'Save'"
            background="dark"
            :showLoader="true"
            :isLoading="isLoading"
            :disabled="isLoading || !isFormChanged"
            :clickHandler="handleSubmit"
          />
        </div>
      </div>
      <b-modal
        v-model="mutualPatternConfirmation"
        :hide-header="true"
        :hide-footer="true"
        size="lg"
        body-class="ims-modal"
        centered
      >
        <div class="dc-modal-close-btn" @click="mutualPatternConfirmation = false">
          <font-awesome-icon class="close-btn" size="lg" icon="times" />
        </div>
        <div class="mutual_pattern_confirmation">
          <div class="df-label-lg">Duplicate Pattern Found</div>
          <div class="duplicate-pattern-table-wrapper">
            <table class="table duplicate-pattern-table mb-0">
              <thead>
                <tr>
                  <th scope="col">Pattern</th>
                  <th scope="col">Used in tag</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="patternObj in mutualPatterns">
                  <tr v-for="pattern in patternObj.mutualpatterns" :key="'pattern_obj_' + pattern">
                    <td>{{ pattern }}</td>
                    <td>{{ patternObj.tag }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="mutual_pattern_confirmation text-right pt-0">
          <span class="me-3">
            <Button
              label="Cancel"
              background="light"
              :disabled="isLoading"
              :clickHandler="() => (mutualPatternConfirmation = false)"
            />
          </span>
          <Button
            label="Continue"
            background="dark"
            :showLoader="true"
            :isLoading="isLoading"
            :disabled="isLoading"
            :clickHandler="confirmSubmit"
          />
        </div>
      </b-modal>
    </div>
    <div v-else>Fetching data, please wait...</div>
  </div>
</template>

<script>
import { genericService, nodeService } from '@/services';
import apiUrls from '@/config/apiUrls';
import InputBox from '@/components/admin/v2/InputBox';
import Button from '@/components/v2/Button';
import { entityService } from '@/services';
import DynamicSelect from '../../../DynamicSelect';
import TextInput from './TextInput';
import DeleteConfirmation from './DeleteConfirmation';
import LookupfieldSelect from './LookupfieldSelect';
import { isEqual, cloneDeep } from 'lodash';

export default {
  data() {
    return {
      label: '',
      dbLabel: '',
      dbTaggroupid: null,
      taggroupid: null,
      tagGroups: [],
      tag: null,
      patterns: [{ patterntypeid: null, pattern: '' }],
      dbPatterns: [],
      patternTypes: [],
      renderForm: false,
      explorer: null,
      enableEditingTitle: true,
      tagByTagGroups: [],
      mutualPatterns: [],
      mutualPatternConfirmation: false,
      tagAsPatternPayload: [],
      linkedTags: null,
      parentTagGroup: null,
      isLabelChanged: false,
      isTaggroupidChanged: false,
      isPatternChanged: false,
    };
  },
  components: {
    Button,
    InputBox,
    TextInput,
    DynamicSelect,
    DeleteConfirmation,
    LookupfieldSelect,
  },
  watch: {
    async taggroupid(taggroupid) {
      this.isTaggroupidChanged = taggroupid !== this.dbTaggroupid;
      const tagGroup = await genericService.index(`${apiUrls.IMS_TAGGROUP}${taggroupid}`)();
      this.explorer = tagGroup.data;
    },
    label(newVal) {
      this.isLabelChanged = newVal !== this.dbLabel;
    },
    patterns: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.isPatternChanged = !isEqual(newVal, this.dbPatterns);
      },
    },
  },
  async created() {
    this.renderForm = false;
    const tagGroups = await genericService.index(apiUrls.IMS_TAGGROUP, 999, { all: true })();
    this.tagGroups = tagGroups.data;
    const patternTypes = await genericService.index(apiUrls.PATTERNTYPE, 999)();
    this.patternTypes = patternTypes.data;
    let tagAsPattern = await this.fetchTagByGroup();
    this.tagAsPatternPayload = this.getFilteredTag(tagAsPattern);
    if (this.edit) {
      this.enableEditingTitle = false;
      const tag = await genericService.index(`${apiUrls.IMS_TAG}${this.editableData.tagid}`, 999)();
      const { label, patterns, taggroups, childtags, linkedtags, parenttaggroup } = tag.data;
      if (patterns && patterns.length) {
        let count = -1;
        this.patterns = patterns.map((pt) => {
          if (pt.patterntypeid === 6 && childtags) {
            count++;
            return {
              pattern: pt.pattern,
              patterntypeid: pt.patterntypeid,
              lookupfield: pt.lookupfield,
              tag: childtags[count] ? childtags[count].patternfromtagid : null,
            };
          }
          return {
            pattern: pt.pattern,
            patterntypeid: pt.patterntypeid,
            lookupfield: pt.lookupfield,
          };
        });
      } else {
        this.patterns = [];
      }
      this.label = label;
      this.dbLabel = label;
      this.taggroupid = taggroups.taggroupid;
      this.dbTaggroupid = taggroups.taggroupid;
      this.dbPatterns = cloneDeep(this.patterns);
      this.linkedTags = linkedtags;
      this.parentTagGroup = parenttaggroup;
    } else {
      this.enableEditingTitle = true;
      const [tagGroup] = this.$store.state.imStructure.tagGroup;
      if (typeof tagGroup === 'number') {
        this.taggroupid = tagGroup;
      }
    }
    this.renderForm = true;
  },
  computed: {
    edit() {
      return this.$store.state.imStructure.form.mode === 'edit';
    },
    editableData() {
      return this.$store.state.imStructure.form.editable;
    },
    getSanitizedPatterns() {
      return this.patterns;
    },
    isLoading() {
      return this.$store.getters['loader/getLoadingStatus'];
    },
    tags() {
      return this.$store.state.entities.tags || [];
    },
    deleteLabel() {
      if (!this.editableData) return '';
      if (this.editableData.parenttagid) {
        return 'Are you sure you want to delete the linked tag? This will not delete the original tag.';
      }
      if (this.editableData.haslink) {
        return `Are you sure you want to delete tag? This will also delete all linked tags(${this.linkedTags.length} linked tag)`;
      }
      return 'Are you sure you want to delete tag?';
    },
    editabletagId() {
      if (!this.editableData) return null;
      return this.editableData.parenttagid ? this.editableData.parenttagid : this.editableData.tagid;
    },
    editableParentTagGroupId() {
      if (!this.editableData) return this.taggroupid;
      return this.editableData.parenttagid ? this.parentTagGroup[0].taggroupid : this.taggroupid;
    },
    isFormChanged() {
      return this.edit ? this.isLabelChanged || this.isPatternChanged || this.isTaggroupidChanged : true;
    },
  },
  methods: {
    addNewRow() {
      this.patterns = [...this.patterns, { patterntypeid: null, pattern: '' }];
    },
    deletePattern(index) {
      const patterns = Object.assign([], this.patterns);
      patterns.splice(index, 1);
      this.patterns = [];
      this.$nextTick(() => {
        this.patterns = patterns;
      });
    },
    handlePatternNameChange(index, pattern) {
      this.patterns[index]['pattern'] = pattern.trim();
    },
    async onPatternTypeOpen() {
      return this.patternTypes;
    },
    onPatternTypeSelect(data) {
      const { input, payload } = data;
      const { patterntypeid } = input;
      this.patterns[payload]['patterntypeid'] = patterntypeid;
    },
    async onTagGroupOpen() {
      return this.tagGroups;
    },
    ontagSelect({ input }) {
      return input.label;
    },
    getFilteredTag(tags) {
      tags = tags.filter((tag) => (this.edit ? tag.tagid !== this.editableData.tagid : true));
      tags = tags.filter((tag) => !tag.parenttagid);
      return tags.filter((tag) => (this.edit ? tag.tagid !== this.editableData.parenttagid : true));
    },
    async fetchTagByGroup() {
      const tags = await genericService.index(apiUrls.TAG_BY_GROUP, 20)();
      return tags.data;
    },
    async onSearchTag(keyword, selectedTag = null) {
      if (!keyword) return [];
      const tags = await entityService.index({
        dataType: apiUrls.TAG_BY_GROUP,
        options: {
          searchField: 'label',
          searchValue: keyword,
        },
      });
      if (!tags.data) return [];
      return this.getFilteredTag(tags.data).filter((item) => item.tagid != selectedTag);
    },
    async onTagGroupSearch(keyword) {
      const tagGroups = await entityService.index({
        dataType: apiUrls.IMS_TAGGROUP,
        options: {
          searchField: 'name',
          searchValue: keyword,
        },
      });
      return tagGroups.data;
    },
    handleCancel() {
      this.$store.dispatch('imStructure/updater', {
        form: { showModal: false },
      });
    },
    modify() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      const model = genericService.update(apiUrls.IMS_TAG);
      model({
        tagid: this.editabletagId,
        label: this.label.trim(),
        taggroupid: this.editableParentTagGroupId,
        patterns: this.getSanitizedPatterns,
        islinkedTag: this.editableData.parenttagid ? true : false,
        linkedtaggroupid: this.editableData.parenttagid ? this.taggroupid : null,
        linkedtagid: this.editableData.parenttagid ? this.editableData.tagid : null,
      })
        .then((r) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('imStructure/updateTag', {
            offset: 0,
            limit: this.tags.data.length,
          });
          this.$store.dispatch('imStructure/updater', {
            form: { showModal: false },
          });
          this.$store.dispatch('entities/fetchStickyTagAndTagGroups');
        })
        .catch((e) => {
          this.$store.dispatch('alert/error', e || `Unable to update the tag group, please try again!`);
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
    create() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      const model = genericService.create(apiUrls.IMS_TAG);

      model({
        label: this.label.trim(),
        taggroupid: this.taggroupid,
        patterns: this.getSanitizedPatterns,
      })
        .then((r) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('imStructure/updateTag', {
            offset: 0,
            limit: this.tags.data.length + 1,
          });
          this.$store.dispatch('imStructure/updater', {
            form: { showModal: false },
          });
          this.$store.dispatch('entities/fetchStickyTagAndTagGroups');
        })
        .catch((e) => {
          this.$store.dispatch('alert/error', e || `Unable to create the tag, please try again!`);
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
    async handleSubmit(event) {
      event.preventDefault();
      // Check for  tag and taggroupid combination is unique
      if (this.edit) {
        try {
          this.$store.dispatch('loader/setLoadingStatus', true);

          await nodeService.checkTagAndTaggroupidCombinationIsUnique({
            tagid: this.editabletagId,
            label: this.label.trim(),
            taggroupid: this.editableParentTagGroupId,
          });
          this.$store.dispatch('loader/setLoadingStatus', false);
        } catch (err) {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('alert/error', err);
          return 0;
        }
      }

      if (!this.label.trim()) {
        this.$store.dispatch('alert/error', 'Name is required');
        return 0;
      }

      if (!this.taggroupid) {
        this.$store.dispatch('alert/error', 'Tag group is required');
        return 0;
      }

      this.mutualPatterns = [];
      // Check for patterns with empty type.
      const emptyTypePatterns = this.patterns.filter((p) => !p.patterntypeid && p.pattern);
      if (emptyTypePatterns.length) {
        this.$store.dispatch('alert/error', `Pattern type can not be empty, please update and try again`);
      } else {
        const patterns = (this.getSanitizedPatterns || []).map((p) => p.pattern);
        const tags = (this.getSanitizedPatterns || []).map((p) => (p.tag ? p.tag : null));

        try {
          this.$store.dispatch('loader/setLoadingStatus', true);
          const mutualPatterns = await genericService.create(apiUrls.IMS_TAG_PATTERN_MUTUAL)({
            patterns,
            skiptag: this.edit ? this.editabletagId : null,
          });

          if (!mutualPatterns.patterns.length) {
            this.$store.dispatch('loader/setLoadingStatus', false);
            this.confirmSubmit();
          } else {
            this.$store.dispatch('loader/setLoadingStatus', false);
            this.mutualPatterns = mutualPatterns.patterns;
            this.mutualPatternConfirmation = true;
          }
        } catch (err) {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('alert/error', err);
        }
      }
    },
    confirmSubmit() {
      if (!this.edit) {
        this.create();
      } else {
        this.modify();
      }
    },
    async handleDelete() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      let limit = 1;
      if (this.editableData.haslink) {
        const { count } = await nodeService.getLinkedTagCountByParentTag(this.editableData.tagid);
        limit += count;
      }
      const model = genericService.remove(apiUrls.IMS_TAG);
      model({
        tagid: this.editableData.tagid,
      })
        .then((r) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('entities/removeStickyTag', this.editableData);
          this.$store.dispatch('imStructure/updater', {
            form: { showModal: false },
          });
          this.$store.dispatch('imStructure/updateTag', {
            offset: 0,
            limit: this.tags.data.length - limit < 0 ? 0 : this.tags.data.length - limit,
          });
        })
        .catch((e) => {
          this.$store.dispatch('alert/error', e || `Unable to remove the tag group, please try again!`);
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
    showNameField() {
      this.enableEditingTitle = true;
      setTimeout(() => {
        document.querySelector('div.textinput input').focus();
      }, 1);
    },
  },
};
</script>

<style scoped>
.pattern-table-wrapper {
  margin-left: -45px;
  margin-bottom: 75px;
  position: relative;
}
.drawcols {
  display: flex;
}
.drawcols .action {
  font-size: 16px;
  flex-basis: 50px;
  height: 50px;
  display: flex;
  flex: 0 0 42px;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}
.drawcols .cell {
  margin: 1px;
}
.drawcols .select {
  flex: 0 0 200px;
  background-color: #fff;
}
.drawcols .label {
  flex: 1;
  background-color: #fff;
}
.pattern-table-header .cell {
  background-color: transparent;
  font-size: 14px;
  height: 28px;
  padding-left: 15px;
  color: #24125f;
  font-weight: bold;
}
.col-act-btn {
  cursor: pointer;
}
.pattern-body-wrapper {
  max-height: 200px;
  overflow-y: auto;
}
.tag-form {
  padding: 30px;
}
.df-label {
  font-size: 14px;
  padding-bottom: 15px;
  color: #24125f;
  font-weight: bold;
}
.df-label-lg {
  font-size: 18px;
  color: #24125f;
  font-weight: bold;
  margin-top: -20px;
  padding-bottom: 25px;
}
.mutual_pattern_confirmation li {
  font-size: 14px;
  padding-bottom: 8px;
  color: #24125f;
}
.depmapwrapper {
  font-size: 14px;
  line-height: 18px;
  color: #24125f;
}
.map {
  display: flex;
  margin-bottom: 15px;
  margin-left: 30px;
}
.dimmap {
  margin-right: 30px;
}
.tgmap {
  margin-left: 30px;
}
.depmaplabel {
  font-weight: bold;
}
.comma-separated-wrapper {
  display: inline-block;
}
.comma-separated-wrapper .has-border {
  border-bottom: 1px solid #24125f;
}
.comma-separated-wrapper .the-comma {
  margin-right: 5px;
}
.form-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.toplabel {
  font-size: 22px;
  color: #24125f;
}
.actions {
  margin-left: 5px;
  cursor: pointer;
}
.actions img {
  height: 21px;
}
.add-row-wrapper {
  margin-left: 45px;
}
.add-row-btn {
  display: inline-block;
  padding: 10px;
}
.mutual_pattern_confirmation {
  padding: 30px;
}
.duplicate-pattern-table.table th {
  padding: 0.75rem;
  background-color: #fff;
  width: auto;
}
.duplicate-pattern-table.table tr td {
  padding: 0.75rem;
  border-bottom: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.duplicate-pattern-table-wrapper {
  max-height: 300px;
  overflow: auto;
}
</style>
