<template>
  <div :class="`delete-confirmation-wrapper ${getClass}`">
    <b-button variant="default" @click.prevent="showConfirmationModal"
      ><span class="text-danger">{{ displayButtonLabel }}</span></b-button
    >
    <b-modal v-model="modal" :hide-header="true" :hide-footer="true" size="sm" body-class="dc-modal-container" centered>
      <div class="dc-modal-header">
        Confirm
        <div class="dc-modal-close-btn" @click="hideConfirmationModal">
          <font-awesome-icon class="close-btn" icon="times" />
        </div>
      </div>
      <div v-if="!hideForm">
        <div class="dc-modal-body" v-if="customLabel">
          {{ customLabel }}
        </div>
        <div class="dc-modal-body" v-else>Are you sure you want to delete {{ this.entityLabel }}?</div>
        <div class="dc-modal-footer">
          <div class="cancel">
            <Button label="CANCEL" background="light" :clickHandler="hideConfirmationModal" />
          </div>
          <div class="delete">
            <Button
              label="DELETE"
              background="red"
              :showLoader="true"
              :isLoading="isLoading"
              :disabled="isLoading"
              :clickHandler="_handleConfirm"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="dc-modal-body">
          {{ formHideMessage }}
        </div>
        <div class="dc-modal-footer">
          <div class="cancel">
            <Button label="CLOSE" background="light" :clickHandler="hideConfirmationModal" />
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Button from '@/components/v2/Button';

export default {
  components: {
    Button,
  },
  props: [
    'displayButtonLabel',
    'entityLabel',
    'handleConfirm',
    'classAttr',
    'hideForm',
    'formHideMessage',
    'customLabel',
  ],
  data() {
    return {
      modal: false,
    };
  },
  computed: {
    getClass() {
      return this.classAttr ? this.classAttr : '';
    },
    isLoading() {
      return this.$store.getters['loader/getLoadingStatus'];
    },
  },
  methods: {
    showConfirmationModal() {
      this.modal = true;
    },
    hideConfirmationModal() {
      this.modal = false;
    },
    _handleConfirm() {
      if (this.handleConfirm) {
        this.handleConfirm();
        this.hideConfirmationModal();
      }
    },
  },
};
</script>

<style>
.dc-modal-container {
  padding: 0px !important;
}

.delete-confirmation-wrapper button {
  width: 100%;
  text-align: left;
}
</style>

<style scoped>
.dc-modal-header {
  background-color: #ededed;
  padding: 15px 20px;
  font-size: 1.5rem;
  color: #24125f;
  font-weight: bold;
  position: relative;
}

.dc-modal-close-btn {
  position: absolute;
  display: inline-block;
  line-height: 0px;
  padding: 20px;
  top: 0;
  right: 0;
}

.dc-modal-body {
  padding: 20px;
}

.dc-modal-footer {
  padding: 20px;
  display: flex;
  padding-top: 5px;
  justify-content: center;
}

div.cancel {
  margin-right: 20px;
}
</style>
