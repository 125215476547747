<template>
  <main role="main">
    <div class="container-fluid resources-table-wrapper responsive-resource-wrapper">
      <b-row>
        <b-col>
          <div class="intop-resource-outer-header" ref="resourceheader">
            <div class="left">
              <SelectedFilters />
            </div>
            <div v-if="hasFilters" class="d-flex align-items-start right">
              <SearchSettings />
              <Pagination />
            </div>
          </div>
          <div class="resource-table-body-wrapper position-relative">
            <Datagrid
              :height="`${this.bodyHeight - 100}px`"
              :tablefields="fieldDefinitions"
              :tabledata="tableData"
              @sortBy="setSortParam"
              :contextColumns="contextColumns"
            >
              <template v-slot:body-name="props">
                <div class="d-flex align-items-center">
                  <div class="d-flex">
                    <img width="20" class="ms-1 me-3" :src="getFileIconFromMapper(props.data.FileType)" alt="" />
                  </div>
                  <div>
                    <a
                      :href="props.data.openurl ? props.data.openurl : props.data.url"
                      target="_blank"
                      class="intop-table-url me-2"
                      >{{ props.data.name }}</a
                    >
                    <div v-if="isContentExcerptShown" class="intop-table-snippet">
                      {{ props.data.contentexcerpt }}...
                    </div>
                  </div>
                </div>
              </template>

              <template v-slot:body-dropdown-menu="props">
                <div class="dropdown-container d-flex justify-content-end w-100">
                  <b-dropdown
                    variant="link"
                    class="resource-item-menu"
                    toggle-class="text-decoration-none"
                    right
                    no-caret
                    boundary="body"
                    :dropup="isDropdownMenuInTheUpperSide(props.index)"
                    no-flip
                  >
                    <template #button-content>
                      <i class="fas fa-ellipsis-h"></i>
                    </template>
                    <b-dropdown-item @click="onPreviewClicked(props.data)"> Text preview </b-dropdown-item>
                    <ClickToCopy v-if="props.data.url" :text="props.data.url" />
                  </b-dropdown>
                </div>
              </template>
            </Datagrid>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-modal v-model="documentPreview" id="documentPreview" size="lg" ok-only ok-title="Close">
      <template class="intop-resource-header-container" v-slot:header>
        <div class="modal-content-wrapper">
          <div class="__text_prev">Text Preview</div>
          <div class="modal-close-btn" @click="hideResourceModal">
            <font-awesome-icon class="close-btn" size="lg" icon="times" />
          </div>
          <div class="document-preview-tags">
            <span v-for="(tag, index) in tagsOfCurrentContext" :key="index">
              <b-badge pill v-if="index < 3 || showAllContexts" variant="primary" class="me-1 intop-resource-badge">{{
                tag.label
              }}</b-badge>
            </span>
            <b-button
              v-if="tagsOfCurrentContext.length > 3"
              class="btn-resource-badge-toggler"
              @click.prevent="toggleAllContexts"
              variant="outline-primary btn-sm"
              >{{ showAllContexts ? 'Show few Tags' : 'Show all Tags' }}</b-button
            >
          </div>
          <h3 class="resource-name">{{ activeResource.name }}</h3>
        </div>
      </template>
      <div class="document-preview-body">
        <p class="card-text">{{ activeResource.fulltext }}</p>
      </div>
    </b-modal>
  </main>
</template>

<script>
import { resourceService } from '@/services';
import { generateTableFieldDef, getFileIcon } from '@/helpers';
import SelectedFilters from '@/components/SelectedFilters';
import Pagination from '@/components/Pagination';
import SearchSettings from '@/components/SearchSettings';
import Datagrid from '@/components/datagrid/Datagrid';
import ClickToCopy from '@/components/ClickToCopy';

export default {
  name: 'ResourcesTable',
  components: {
    SelectedFilters,
    Pagination,
    ClickToCopy,
    SearchSettings,
    Datagrid,
  },
  data() {
    return {
      sortOrder: '',
      activeResource: {
        name: '',
        fulltext: '',
        id: 0,
        tags: [],
      },
      showAllContexts: false,
      numberOfResourcesInThisPage: 0,
      tableData: [],
      documentPreview: false,
    };
  },
  created() {
    this.getResources();
  },
  watch: {
    params: {
      handler(val) {
        if (this.hasFilters) {
          this.getResources();
        } else {
          this.sortOrder = '';
          this.tableData = [];
        }
      },
      deep: true,
    },
    currentPage(val) {
      this.getResources(val);
    },
  },
  computed: {
    contextColumns() {
      return this.selectedContextColumns.map((item) => item.label);
    },
    isContentExcerptShown() {
      return this.$store.state.users.displayContentExcerpts;
    },
    fieldDefinitions() {
      const fields = generateTableFieldDef();
      fields.push({
        name: 'dropdown-menu',
        title: ` `,
      });
      return fields;
    },
    params() {
      const { columns, contextid } = this.renderPreview
        ? this.$store.getters['contextManager/demoContext']
        : this.$store.state.contexts.selected;
      const { tags, searchString, modifiedFrom, modifiedBefore } = this.$store.state.filterParams;

      // Collection of filters with its selected tag id's
      const filters = tags.reduce(function (filter, tag) {
        filter[tag.taggroupid] = filter[tag.taggroupid] || [];
        filter[tag.taggroupid].push(tag.tagid);
        return filter;
      }, Object.create(null));

      const { settings } = this.$store.state.app;
      let titleSearch = false;
      if (searchString.length > 0) {
        titleSearch = this.$store.state.app.searchTitleOnly;
      }

      return {
        filters,
        contextid,
        titleSearch,
        modifiedFrom,
        modifiedBefore,
        searchString: searchString || [],
        columns: columns.map((col) => col.taggroupid),
        sortByRelevance: settings.hasOwnProperty('SORT_RESULT_BY_RELEVANCE')
          ? settings.SORT_RESULT_BY_RELEVANCE
          : false,
        per_page: settings.hasOwnProperty('PER_PAGE_RESULT') ? settings.PER_PAGE_RESULT : 20,
      };
    },
    currentPage() {
      return this.$store.state.pagination.currentPage;
    },
    loading() {
      return this.$store.state.pagination.loading;
    },
    hasFilters() {
      return this.$store.getters['filterParams/hasFilters'];
    },
    isContentExcerptShown() {
      return this.$store.state.users.displayContentExcerpts;
    },
    tagsOfCurrentContext() {
      const activeTagGroups = this.$store.state.contexts.selected.filters.map((f) => f.taggroupid);
      return this.activeResource.tags.filter((vt) => activeTagGroups.includes(vt.taggroupid));
    },
    renderPreview() {
      return this.$store.state.contextManager.renderPreview;
    },
    bodyHeight() {
      return this.$store.state.app.bodyHeight;
    },
    selectedContext() {
      return this.$store.getters['contexts/getSelected'];
    },
    selectedContextColumns() {
      return this.selectedContext.columns;
    },
  },
  methods: {
    keepColumnItemsBasedOnSelectedFilterItems(cell) {
      const { taggroupid, label, value } = cell;
      const filteringColumn = this.$store.state.filterParams.tags.find((t) => t.taggroupid === taggroupid);
      if (filteringColumn) {
        const isAccepted = this.$store.state.filterParams.tags.filter(
          (t) => t.taggroupid === taggroupid && t.label.trim() === value.trim()
        );
        return isAccepted.length > 0;
      }
      return true;
    },
    getResources(page = 1) {
      const params = { ...this.params, page };
      if (this.sortOrder) {
        params['sort'] = this.sortOrder;
      }
      this.onLoading();
      resourceService
        .get(params)
        .then((res) => {
          this.onPaginationData(res);
          this.numberOfResourcesInThisPage = res.data.length;
          // Flatten the columns property /into keys on the row itself
          this.tableData = res.data.map((d) => {
            let multiCols = d.filtercolumns;
            if (multiCols) {
              const multiColsGrouped = multiCols
              /*
               * Disable #774 for now -  Filter out the columns that are not selected in the filter
               *
                .filter((cell) => {
                  const keepCell = this.keepColumnItemsBasedOnSelectedFilterItems(cell);
                  return keepCell ? cell : false;
                })
              */
                .reduce((item, col) => {
                  item[col.label] = item[col.label] || [];
                  item[col.label].push({
                    contextcolumn: col.value,
                    label: col.label,
                  });
                  return item;
                }, Object.create(null));
              Object.keys(multiColsGrouped).map((i) => {
                const labels = multiColsGrouped[i].map((mc) => mc.contextcolumn);
                d[i] = i === 'FileType' ? labels[0] : labels;
              });
            }
            delete d.columns;
            return d;
          });

          document.querySelector('.datagrid-container').scrollTo(0, 0);

          this.onLoaded();
        })
        .catch((error) => {
          this.onLoaded();
        });
    },
    onPaginationData(paginationData) {
      this.$store.dispatch('pagination/setPaginationData', paginationData);
    },
    onLoading() {
      this.$store.dispatch('pagination/setLoading', true);
    },
    onLoaded() {
      this.$store.dispatch('pagination/setLoading', false);
    },
    onPreviewClicked(rowData) {
      this.activeResource = {
        name: '',
        fulltext: '',
        id: 0,
        tags: [],
      };

      resourceService.getOne(rowData.resourceid).then((result) => {
        this.activeResource = {
          ...result.data,
          fulltext: result.data.fulltext ? result.data.fulltext : '',
        };
      });
      this.showAllContexts = false;
      this.documentPreview = true;
    },
    toggleAllContexts() {
      this.showAllContexts = !this.showAllContexts;
    },
    handleToggleExcerpt() {
      this.$store.dispatch('users/controlContentExcerpts');
    },
    hideResourceModal() {
      this.documentPreview = false;
    },
    setSortParam(sortOrder) {
      this.sortOrder = sortOrder.field + '|' + sortOrder.order;
      this.getResources();
    },
    getFileIconFromMapper(filetype) {
      try {
        return require('@/assets/' + getFileIcon(filetype));
      } catch (e) {
        return '';
      }
    },
    isDropdownMenuInTheUpperSide(rowIndex) {
      return this.numberOfResourcesInThisPage > 1 && rowIndex + 1 === this.numberOfResourcesInThisPage;
    },
  },
};
</script>

<style>
.resources-table-wrapper {
  position: relative;
  padding-top: 16px;
}

div.document-preview-tags {
  padding-bottom: 10px;
  line-height: 35px;
}
div.document-preview-tags span.badge {
  padding: 8px 12px;
}

div#documentPreview .modal-header {
  background-color: #ededed;
  border-bottom: none;
  padding: 20px 30px;
}

div#documentPreview .modal-body {
  padding: 0px;
}

div#documentPreview .btn-primary {
  border-radius: 0px;
  background-color: #29235c;
  border: 0px;
}

div#documentPreview .intop-resource-badge {
  border-radius: 0px;
  background-color: #5490cc;
  padding: 10px 20px;
}

div#documentPreview .btn-resource-badge-toggler {
  border-radius: 0px;
  padding: 6.5px 20px;
  font-weight: 500;
}

div#documentPreview * {
  font-family: 'Roboto', 'Courier New', Courier, monospace;
}
div#documentPreview h3.resource-name {
  color: #29235c;
  font-family: 'Roboto', 'Courier New', Courier, monospace;
  margin-top: 15px;
  font-weight: 500;
  font-size: 26px;
  word-break: break-word;
}

div#documentPreview .modal-content-wrapper {
  width: 100%;
  position: relative;
}
div#documentPreview .modal-close-btn {
  color: #888;
  font-size: 13px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px;
  margin-right: -15px;
  margin-top: -10px;
}
div#documentPreview .modal-close-btn:hover {
  color: #29235c;
  cursor: pointer;
}

.intop-table-url {
  color: #5490cc;
}

.text-preview-button {
  color: green;
}

.document-preview-body {
  height: calc(100vh - 400px);
  overflow-y: scroll;
  font-size: 14px;
  color: #29235c;
  white-space: pre-line;
  padding: 20px 30px;
}

.__text_prev {
  font-size: 14px;
  color: #6e7072;
  margin-bottom: 5px;
}

.--table-sort-icon-active {
  color: #3c7ff3;
}

.intop-table-snippet {
  padding-top: 15px;
  color: #29235c;
}

.intop-resource-outer-header {
  display: flex;
  margin-bottom: 15px;
  margin-top: 3px;
  padding-right: 10px;
}
.intop-resource-outer-header .left {
  flex-grow: 1;
}

.intop-resource-outer-header .right {
  min-width: 300px;
  text-align: right;
  justify-content: flex-end;
}

.resource-item-menu button,
.resource-item-menu button:hover {
  color: #343a40;
}
.resource-item-menu button:focus {
  box-shadow: none;
}
.resource-item-menu .dropdown-item {
  font-size: 0.9rem;
}
</style>

<style lang="scss">
.resources-table-wrapper {
  .datagrid .datagrid-item {
    border-bottom: 7px solid #ededed;
    font-size: 0.9rem;
  }
  .datagrid .datagrid-head-item {
    color: #29235c;
    font-weight: 500;
    background-color: #ededed;
    font-size: 0.9rem;
    border-bottom: 0;
    padding-top: 0;
  }
  .datagrid .datagrid-head-item.resource-name {
    padding-left: calc(20px + 2.25rem);
  }

  @media screen and (max-width: 1600px) {
    .datagrid .datagrid-item {
      padding: 0.45rem 0.65rem;
      font-size: 0.77rem;
    }
    .datagrid .datagrid-head-item {
      font-size: 0.9rem;
    }
    .datagrid .datagrid-head-item.resource-name {
      padding-left: calc(20px + 1.8rem);
    }
  }
}
</style>
