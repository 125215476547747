<template>
  <div class="resource-search-options-wrapper" v-if="spellingError || searchOptions.length > 0">
    <div class="row search-result-container">
      <div class="col-md-12 mb-2" v-if="spellingError">
        <div class="spell-error">
          <span class="spell-error-label dark-color"><i>did you mean:</i></span>
          <a class="spell-error-value fade-on-hover dark-color ms-2" @click.prevent="handleSpellingError">
            <b>{{ spellingError }}</b>
          </a>
        </div>
      </div>
      <div class="col-md-12" v-if="!showingMore">
        <a
          href="#"
          class="badge search-badge"
          v-for="tag of showlessOptions"
          :key="'filter_' + tag.tagid"
          @click.prevent="() => handleTagSelect(tag)"
        >
          {{ tag.filtervalue }}
        </a>
        <a href="#" class="badge" @click.prevent="showingMore = true" v-if="searchOptions.length > 5"> Show more </a>
      </div>
      <div class="col-md-12" v-if="showingMore && this.options.length">
        <div class="mb-2" v-for="(item, index) in this.options" :key="'taggroup_' + index">
          <div class="dark-color taggroup-label mb-1">
            <small>{{ item.name }}</small>
          </div>
          <div>
            <a
              href="#"
              class="badge search-badge"
              v-for="tag of item.tags"
              :key="'filter_' + tag.tagid"
              @click.prevent="() => handleTagSelect(tag)"
            >
              {{ tag.name }}
            </a>
          </div>
        </div>
        <a href="#" class="badge pl-0" @click.prevent="showingMore = false" v-if="searchOptions.length > 5">
          Show less
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import groupedFilters from '../../helpers/groupedFilters';

export default {
  props: ['handleResourceSearch'],
  data() {
    return {
      showingMore: false,
    };
  },
  computed: {
    searchOptions() {
      return this.$store.state.resource.searchOptions;
    },
    options() {
      return groupedFilters(this.$store.state.resource.searchOptions);
    },
    showlessOptions() {
      return this.$store.state.resource.searchOptions.slice(0, 5);
    },
    searchString() {
      return this.$store.state.resource.searchString;
    },
    spellingError() {
      return this.$store.state.resource.searchSpellingError;
    },
  },
  methods: {
    handleTagSelect(tag) {
      this.$store.dispatch('filterParams/updateTag', {
        tagid: tag.tagid,
        label: tag.filtervalue ? tag.filtervalue : tag.name,
        taggroupid: tag.taggroupid,
      });
      this.$store.dispatch('resource/updater', {
        searchToggleOptions: false,
        searchString: '',
        searchOptions: [],
        searchSpellingError: '',
      });
    },
    handleSpellingError() {
      this.$store.dispatch('resource/updater', {
        searchString: this.spellingError,
        searchOptions: [],
      });
      this.$nextTick(() => {
        this.handleResourceSearch();
      });
    },
    handleSearchClick() {
      this.handleResourceSearch();
    },
  },
};
</script>

<style scoped>
.resource-search-options-wrapper {
  box-shadow: 0px 4px 6px 2px rgba(25, 25, 25, 0.12);
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 45px;
  padding: 1rem;
}
.spell-error-value {
  cursor: pointer;
}
.spell-error-value:hover {
  text-decoration: none;
}
</style>
