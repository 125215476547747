<template>
  <div class="ims-search">
    <form>
      <div class="searchfield-icon">
        <Loading v-if="loading" />
        <font-awesome-icon v-else size="lg" class="search-icon" icon="search" />
      </div>
      <input :placeholder="`Search ${this.name}`" v-model="searchAble" @input="debounceHandleSearch" />
    </form>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import Loading from '@/components/Loading';

export default {
  props: ['name'],
  components: {
    Loading,
  },
  created() {
    this.debounceHandleSearch = debounce(this.handleSearch, 1200);
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    searches() {
      return this.$store.state.imStructure.searching;
    },
    searchAble: {
      set(keyword) {
        this.loading = true;
        if (this.name === 'Tag Group') {
          this.$store.dispatch('imStructure/updater', {
            searching: {
              ...this.searches,
              tagGroup: keyword,
            },
          });
        } else if (this.name === 'Tags') {
          this.$store.dispatch('imStructure/updater', {
            searching: {
              ...this.searches,
              tags: keyword,
            },
          });
        }
      },
      get() {
        if (this.name === 'Tag Group') {
          return this.searches.tagGroup;
        } else if (this.name === 'Tags') {
          return this.searches.tags;
        }
      },
    },
  },
  methods: {
    async handleSearch() {
      try {
        if (this.name === 'Tag Group') {
          await this.$store.dispatch('imStructure/updateTagGroup');
          await this.$store.dispatch('imStructure/updateTag');
        } else if (this.name === 'Tags') {
          await this.$store.dispatch('imStructure/updateTag');
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (this.name === 'Tag Group') {
          this.$store.dispatch('entities/updater', { tagGroups: [] });
          this.$store.dispatch('entities/updater', { tags: [] });
        } else if (this.name === 'Tags') {
          this.$store.dispatch('entities/updater', { tags: [] });
        }
      }
    },
  },
};
</script>

<style scoped>
.ims-search {
  width: 300px;
  position: relative;
}
.ims-search input {
  border: 0px;
  border-radius: 0px;
  border-bottom: 2px solid #ededed;
  display: block;
  width: 100%;
  padding-left: 30px;
  font-size: 14px;
  line-height: 26px;
  outline: none;
}
.search-icon {
  font-size: 14px;
  color: #698298;
  margin-left: 6px;
}
.searchfield-icon {
  display: inline-block;
  position: absolute;
}
</style>
