import apiUrls from '../config/apiUrls';
import { imStructureService } from '../services';

export const imStructure = {
  namespaced: true,
  state: {
    dimension: [],
    areasOfInterests: [],
    tagGroup: [],
    tags: [],
    form: {
      activeForm: null,
      showModal: false,
      mode: 'create',
      editable: null,
    },
    searching: {
      tagGroup: '',
      tags: '',
    },
    loading: false,
  },
  actions: {
    updater({ commit }, nextState) {
      if (typeof nextState === 'object' && !nextState.length && nextState !== null) {
        commit('updateState', nextState);
      } else {
        console.error('nextState needs to be an object: imStructure.module.js');
      }
    },
    async updateTagGroup({ commit, state, dispatch, rootState }, options = {}) {
      const { dimension, areasOfInterests } = state;

      if (!options.limit) options.limit = 20;
      if (!options.offset) options.offset = 0;

      const updater = async () => {
        commit('updateState', { loading: true });
        const tagGroups = await imStructureService.filterItems(apiUrls.IMS_TAGGROUP, {
          dimension,
          areasOfInterests,
          search: state.searching.tagGroup,
          ...options,
        });
        if (options.mergeState) {
          dispatch('entities/merger', { tagGroups }, { root: true });
        } else {
          dispatch('entities/updater', { tagGroups }, { root: true });
        }
        commit('updateState', { loading: false });
      };

      if (state.searching.tagGroup) {
        dispatch('updater', { tagGroup: [], tags: [] });
      }
      await updater();
    },
    async updateTag({ commit, state, dispatch, rootState }, options = {}) {
      const { areasOfInterests, dimension } = state;
      let { tagGroup } = state;
      let fetchTags = true;
      commit('updateState', { loading: true });

      if (!options.limit) options.limit = 20;
      if (!options.offset) options.offset = 0;

      if ((areasOfInterests.length || dimension.length) && !tagGroup.length) {
        const { data: tagGroups } = rootState.entities.tagGroups;
        if (!tagGroups.length) fetchTags = false;
      }

      // Handle tag selection when searching on tag group.
      if (state.searching.tagGroup) {
        const { data: tagGroups } = rootState.entities.tagGroups;
        if (!tagGroups.length) fetchTags = false;
      }

      // If there are already selected taggroup than include their ids
      // istead of all of the taggroup available under that search query for tag fetching
      if (state.tagGroup.length > 0) {
        tagGroup = state.tagGroup;
      }

      if (fetchTags) {
        const tags = await imStructureService.filterItems(apiUrls.IMS_TAG, {
          dimension,
          tagGroup,
          areasOfInterests,
          searchTagGroup: state.searching.tagGroup,
          search: state.searching.tags,
          ...options,
        });
        if (options.mergeState) {
          dispatch('entities/merger', { tags }, { root: true });
        } else {
          dispatch('entities/updater', { tags }, { root: true });
        }
      } else {
        dispatch('entities/updater', { tags: [] }, { root: true });
      }
      commit('updateState', { loading: false });
    },
  },
  mutations: {
    updateState(state, nextState) {
      for (const index in nextState) {
        state[index] = nextState[index];
      }
    },
  },
};
