<template>
  <div class="date-filter-container">
    <label for=""
      ><b>{{ title }}</b></label
    >
    <div class="date-picker-container" id="datePickerContainerID">
      <Datepicker
        :id="filter"
        :key="filter"
        :ref="filter"
        :class="`intop-datepicker ${filter} team-popup-datepicker`"
        :placeholder="placeholder"
        :model-value="value"
        model-type="yyyy-MM-dd"
        @update:modelValue="setValue"
        :clearable="true"
        autoApply
        locale="en"
        :enableTimePicker="false"
        :disabled-dates="notAfterToday"
      >
        <template #dp-input="{ value }">
          <div class="intop-datepicker-input">{{ formattedTitle }}</div>
        </template>
      </Datepicker>
    </div>
  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
export default {
  props: ['title', 'filter'],
  components: {
    Datepicker,
  },
  data() {
    return {
      placeholder: 'Select date',
    };
  },
  methods: {
    setValue(val) {
      const sqlDate = val ?? '';
      const action = this.filter === 'modifiedBefore' ? 'setModifiedBefore' : 'setModifiedFrom';

      this.$store.dispatch(`filterParams/${action}`, sqlDate);
    },
    notAfterToday(date) {
      return date > new Date(new Date());
    },
  },
  computed: {
    value() {
      return this.$store.state.filterParams[this.filter];
    },
    formattedTitle() {
      if (!this.value) return this.placeholder;
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date(this.value);
      return date.toLocaleDateString('en-US', options);
    },
  },
};
</script>

<style scoped>
.date-filter-container {
  font-size: 0.85rem;
  padding: 0.5rem 1.5rem 0;
}
</style>
<style>
.date-filter-container .intop-datepicker-input {
  background: #fff !important;
  border-color: #fff !important;
  border-radius: 0;
  font-size: 14px;
  cursor: pointer;
}
.date-filter-container .intop-datepicker-input::placeholder {
  color: #484644;
}
.team-popup-datepicker {
  width: 272px !important;
}
</style>
