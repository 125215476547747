<template>
  <div v-if="visible" class="progressbar-wrapper">
    <b-progress :value="progress" height="2px" class="mt-2 intop-progressbar"></b-progress>
  </div>
  <div v-else class="intop-progress-placeholder"></div>
</template>

<script>
export default {
  mounted() {
    this.progress = 10;
    this.intervalHandler = setInterval(() => {
      if (this.progress >= 100) {
        this.progress = 0;
      }
      if (this.loading) {
        this.progress = this.progress + 10;
      }
    }, 1000);
  },
  data() {
    return {
      progress: 0,
      visible: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.pagination.loading;
    },
  },
  watch: {
    loading: function (val) {
      if (val) {
        this.progress = 10;
        this.visible = true;
      } else {
        this.progress = 99;
        setTimeout(() => {
          this.visible = false;
        }, 300);
      }
    },
  },
  beforeUnmount() {
    clearInterval(this.intervalHandler);
  },
};
</script>

<style scoped>
div.progressbar-wrapper {
}
.intop-progressbar {
  margin-top: 0px !important;
  border-radius: 0px !important;
}
.intop-progress-placeholder {
  height: 2px;
  background-color: rgb(90, 154, 219);
}
</style>
