<template>
  <div class="custom-multiselect-wrapper">
    <div :class="`variant-${this.variant}`">
      <Multiselect
        :options="optGroup ? optData : getItems"
        :multiple="true"
        :searchable="true"
        :track-by="this.id"
        :label="this.label"
        :group-values="optGroup ? getValues : undefined"
        :group-label="optGroup ? getlabel : undefined"
        :group-select="optGroup ? false : undefined"
        selectLabel=""
        class="custom-multiselect"
        :closeOnSelect="!!singleSelect"
        :loading="isLoading"
        @open="_handleOpen"
        @close="_handleClose"
        @update:model-value="_handleInput"
        @search-change="_handleSearchChange"
        :option-height="optionHeight"
      >
        <template v-slot:caret="{ toggle }">
          <img @mousedown.prevent.stop="toggle()" class="caret-svg caret-dark" src="@/assets/down-arrow.svg" />
        </template>

        <template v-slot:option="props">
          <div class="option_group" v-if="props.option.$isLabel">
            <span
              ><strong>{{ props.option.$groupLabel }}</strong></span
            >
          </div>
          <div
            v-if="!props.option.$isLabel"
            :class="[
              selectedItems.includes(props.option[id]) ? 'option__selected option__main' : 'option__main',
              optGroup ? 'paddingLeft' : '',
            ]"
          >
            <div class="opt_checkbox" v-if="!props.option.$isLabel">
              <img v-if="selectedItems.includes(props.option[id])" src="@/assets/tick-white.svg" />
            </div>
            <span class="option__title"
              >{{ props.option[label].substring(0, 20) }} {{ isLargeText(props.option[label]) ? '...' : '' }}</span
            >
          </div>
        </template>

        <template v-slot:placeholder>
          <div class="filterbox-label-with-count">
            <div class="fbc-label">{{ getTitle }}</div>
            <div v-if="selectedItems.length && !singleSelect" class="fbc-count">({{ selectedItems.length }})</div>
          </div>
        </template>
      </Multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { genericService } from '@/services';
import _ from 'lodash';

export default {
  components: {
    Multiselect,
  },
  props: {
    id: {
      default: -1,
    },
    modelValue: {
      default: '',
    },
    label: {
      default: '',
    },
    title: {
      default: '',
    },
    handleOpen: {
      default: false,
    },
    handleInput: {
      default: false,
    },
    inputPayload: {
      default: false,
    },
    singleSelect: {
      default: false,
    },
    handleSearchChange: {
      default: false,
    },
    variant: {
      default: 'white',
    },
    optGroup: {
      default: false,
    },
    groupBykey: {
      default: false,
    },
  },
  data() {
    return {
      options: [],
      invisibleItems: [],
      isLoading: false,
      selectedItems: [],
      optionHeight: 44,
    };
  },
  async created() {
    if (this.selectedItems !== null || this.selectedItems.length > 0) {
      const initialData = await this.handleOpen();
      this.options = initialData;
      if (this.singleSelect) {
        this.selectedItems = [this.modelValue];
      } else {
        this.selectedItems = this.modelValue;
      }
      await this.handleInvisibleItems();
    }
  },
  computed: {
    getTitle() {
      if (this.singleSelect && this.selectedItems.length) {
        const _item = this.getItems.find((o) => o[this.id] === this.selectedItems[0]);
        return _item ? _item[this.label] : '';
      }
      return this.title;
    },
    getItems() {
      return [...this.invisibleItems, ...this.options];
    },
    getlabel() {
      return 'label';
    },
    getValues() {
      return 'child';
    },
    optData() {
      if (!this.optGroup) {
        return [];
      }
      let data = [];
      let optGroups = this.mapByTagGroup();
      for (let key in optGroups) {
        data.push({ label: key, child: optGroups[key] });
      }
      return data;
    },
  },
  methods: {
    mapByTagGroup() {
      return _.groupBy(this.getItems, (item) => item[this.groupBykey]);
    },
    isLargeText(label) {
      return label.length > 20;
    },
    async handleInvisibleItems() {
      const tableName = this.id.replace(/id$/, '');
      const availableId = this.getItems.map((op) => op[this.id]);
      const neededToFetch = this.selectedItems
        .filter((singleId) => !availableId.includes(singleId))
        .filter((item) => !!item);

      if (neededToFetch.length) {
        this.isLoading = true;
        const groupName = this.optGroup ? 'group' : '';
        const url = this.optGroup ? `/private/${tableName}/${groupName}` : `/private/${tableName}`;
        const response = await genericService.index(`${url}`, 999, {
          get_in: neededToFetch.join(', '),
        })();
        this.invisibleItems = response.data;
        this.isLoading = false;
      }
    },
    async _handleClose() {
      await this.handleInvisibleItems();
    },
    async _handleOpen() {
      if (this.handleOpen) {
        this.isLoading = true;
        this.options = await this.handleOpen();
        this.isLoading = false;
      }
      await this.handleInvisibleItems();
    },
    async _handleInput(item) {
      [item] = item;
      if (item[this.id]) {
        if (this.singleSelect) {
          this.selectedItems = [item[this.id]];
        } else {
          // Check if already selected.
          if (this.selectedItems.includes(item[this.id])) {
            this.selectedItems = this.selectedItems.filter((i) => i !== item[this.id]);
          } else {
            this.selectedItems = [item[this.id], ...this.selectedItems];
          }
        }
      }
      if (this.handleInput) {
        this.isLoading = true;
        await this.handleInput({
          input: item,
          payload: this.inputPayload,
        });
        this.isLoading = false;
      }
      if (this.singleSelect) {
        const [singleSelectionValue] = this.selectedItems;
        this.$emit('update:modelValue', singleSelectionValue);
      } else {
        this.$emit('update:modelValue', this.selectedItems);
      }
    },
    async _handleSearchChange(search) {
      if (this.handleSearchChange) {
        this.isLoading = true;
        this.options = await this.handleSearchChange(search);
        await this.handleInvisibleItems();
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
.custom-multiselect-wrapper {
  background-color: #fff;
}
/*.multiselect {
  height: 51px;
}*/
.multiselect__placeholer {
  margin-bottom: 9px;
}
.custom-multiselect .multiselect__tags {
  border: none;
  border-radius: 0px;
}
.custom-multiselect .multiselect__select {
  height: 50px;
}
.custom-multiselect .multiselect__tags {
  padding-top: 13px;
  padding-left: 20px;
}
.custom-multiselect .fbc-label {
  color: #24125f;
  font-size: 14px;
}
.custom-multiselect .fbc-count {
  color: #24125f;
  font-size: 14px;
  letter-spacing: 1px;
  margin-left: 5px;
}
.custom-multiselect .caret-svg {
  width: 60px;
  height: 50px;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 10px;
}
.custom-multiselect-wrapper .variant-dim .custom-multiselect,
.custom-multiselect-wrapper .variant-dim input.multiselect__input,
.custom-multiselect-wrapper .variant-dim .multiselect__tags {
  background-color: #ededed;
}

.custom-multiselect-wrapper .multiselect__option--group {
  background: #ededed;
  color: #35495e;
  font-size: large;
}

.custom-multiselect-wrapper .multiselect__option--disabled {
  background-color: white !important;
  color: black !important;
  cursor: text;
  pointer-events: none;
}
.paddingLeft {
  padding-left: 10px;
}
</style>
