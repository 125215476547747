<template>
  <div class="intop-selected-filters-wrapper">
    <div
      v-for="(filter, index) in this.filters"
      :class="filter.search ? 'selected-filter-item selected-filter-item-search' : 'selected-filter-item'"
      @click.self="handleEdit(filter)"
      :key="index"
    >
      {{ filter.filterName ? filter.filterName + ': ' : '' }} {{ filter.label }}
      <div @click.prevent="handleClear(filter)" class="clear-filter-btn">
        <font-awesome-icon class="cfbtn" size="lg" icon="times" />
      </div>
    </div>
    <div class="selected-filter-item view-more" v-if="totalFilters > 5" @click="showAllFilters = !showAllFilters">
      VIEW {{ showAllFilters ? 'LESS' : 'MORE' }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAllFilters: false,
      totalFilters: 0,
    };
  },
  computed: {
    filters() {
      const selectedFilters = this.$store.state.contexts.selected.filters || [];
      const tags = this.$store.state.filterParams.tags || [];
      const searchString = this.$store.state.filterParams.searchString || [];
      const modifiedBefore = this.$store.state.filterParams.modifiedBefore || '';
      const modifiedFrom = this.$store.state.filterParams.modifiedFrom || '';
      const activeSelectedFilters = selectedFilters.filter((sf) => {
        if (tags.find((t) => t.taggroupid === sf.taggroupid)) {
          return sf;
        }
      });

      const selectedOptionsArr = tags.map((n) => {
        const expectedFilter = selectedFilters.find((f) => f.filterid === n.filterid);
        if (expectedFilter && expectedFilter.name) {
          return {
            ...n,
            filterName: false,
          };
        }
      });

      let reOrderedTags = [];
      activeSelectedFilters.forEach((f) => {
        const groupTags = selectedOptionsArr.filter((t) => t.taggroupid === f.taggroupid);
        reOrderedTags = [...reOrderedTags, ...groupTags];
      });

      if (modifiedFrom !== '') {
        const [label] = modifiedFrom.split(' ');
        reOrderedTags.push({
          filterName: 'Modified From',
          label: this.formattedDateLabel(label),
          tagid: 'modifiedFrom',
        });
      }

      if (modifiedBefore !== '') {
        const [label] = modifiedBefore.split(' ');
        reOrderedTags.push({
          filterName: 'Modified To',
          label: this.formattedDateLabel(label),
          tagid: 'modifiedBefore',
        });
      }

      if (searchString.length > 0) {
        searchString.forEach((search) => {
          reOrderedTags.push({
            filterName: false,
            label: search,
            tagid: 'fulltext',
            search: true,
          });
        });
      }

      const chunk = (arr, len) => {
        const chunks = [];
        let i = 0;
        const n = arr.length;
        while (i < n) {
          chunks.push(arr.slice(i, (i += len)));
        }
        return chunks;
      };
      const filteredFilters = reOrderedTags.filter((f) => {
        if (!!f) return f;
      });
      this.totalFilters = filteredFilters.length;
      if (filteredFilters.length > 5 && !this.showAllFilters) {
        return chunk(filteredFilters, 5)[0];
      } else {
        return filteredFilters;
      }
    },
  },
  methods: {
    handleClear(filter) {
      if (filter.tagid === 'fulltext') {
        this.$store.dispatch('filterParams/removeSearchKeyword', filter.label);
      } else if (filter.tagid === 'modifiedBefore') {
        this.$store.dispatch(`filterParams/setModifiedBefore`, '');
      } else if (filter.tagid === 'modifiedFrom') {
        this.$store.dispatch(`filterParams/setModifiedFrom`, '');
      } else {
        this.$store.dispatch('filterParams/removeTag', {
          tagid: filter.tagid,
          filterid: filter.filterid,
        });
      }
    },
    handleEdit(filter) {
      if (filter.search) {
        this.$store.dispatch('resource/updater', {
          searchOptions: [],
          searchString: filter.label,
          searchToggleOptions: true,
        });
        this.$store.dispatch('filterParams/removeSearchKeyword', filter.label);
        this.$store.dispatch('resource/getSearchSuggestions');
      }
    },
    formattedDateLabel(label) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date(label);
      return date.toLocaleDateString('en-US', options);
    },
  },
};
</script>

<style scoped>
.intop-selected-filters-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.selected-filter-item {
  background-color: #5290cc;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  margin-right: 5px;
  padding: 6px 18px;
  padding-right: 30px;
  position: relative;
  cursor: default;
  margin-bottom: 5px;
  border: 2px solid transparent;
}
.selected-filter-item:hover {
  background-color: rgb(71, 123, 175);
}
.selected-filter-item-search {
  background-color: transparent;
  border: 2px solid #5290cc;
  color: #5290cc;
  cursor: text;
}
.selected-filter-item-search .clear-filter-btn {
  color: #5290cc;
}
.selected-filter-item-search:hover {
  background-color: #ededed;
  color: #5290cc;
}
.selected-filter-item-search .clear-filter-btn:hover {
  color: #eee;
}
.clear-filter-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 4px;
  margin-top: 6px;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eee;
  bottom: 0;
  cursor: pointer;
}
.clear-filter-btn:hover {
  background-color: rgb(58, 103, 148);
}
.clear-filter-btn .cfbtn {
  width: 8px;
}

.view-more {
  padding-right: 15px;
  background-color: transparent;
  color: #5290cc;
  cursor: pointer;
  border: 1px solid transparent;
}
.view-more:hover {
  background-color: transparent;
  border-color: transparent;
  color: #262262;
}
</style>
