import { genericService } from '@/services';
import apiUrls from '../config/apiUrls';

export const savedContext = {
  namespaced: true,
  state: {
    contexts: [],
  },
  actions: {
    fetchSavedContexts({ commit }) {
      return genericService
        .index(`${apiUrls.USER_SAVED_CONTEXT}?per_page=999`)()
        .then((res) => {
          commit('fetchSavedContexts', res.data);
        });
    },
  },
  mutations: {
    fetchSavedContexts(state, data) {
      state.contexts = data;
    },
  },
};
