<template>
  <div class="swtich-wrapper">
    <div class="switch-label">Live Preview</div>
    <div class="toggle-wrapper">
      <div
        @click.prevent="preview = !preview"
        :class="preview ? 'toggle-box toggle-box-active' : 'toggle-box toggle-box-inactive'"
      ></div>
      <div :class="preview ? 'toggle-bar toggle-bar-active' : 'toggle-bar toggle-bar-inactive'"></div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    preview: {
      set() {
        const status = this.$store.state.contextManager.renderPreview;
        this.$store.dispatch('contextManager/updater', {
          renderPreview: !status,
        });
        this.$store.dispatch('app/calculateBody', 'intop-middle-panel');
      },
      get() {
        return this.$store.state.contextManager.renderPreview;
      },
    },
  },
};
</script>

<style scoped>
.swtich-wrapper {
  display: flex;
}
.switch-label {
  color: #525780;
  font-size: 14px;
  margin-right: 16px;
}
.toggle-wrapper {
  width: 45px;
  height: 25px;
  position: relative;
}
.toggle-box {
  width: 20px;
  height: 20px;
  border-radius: 30px;
  background-color: #688197;
  position: absolute;
  top: 0;
  z-index: 1;
  margin-top: 1px;
  box-shadow: 0px 7px 15px 0px #a0b6ca;
  cursor: pointer;
}
.toggle-box-active {
  right: 0;
}
.toggle-box-inactive {
  left: 0;
}
.toggle-bar {
  height: 8px;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 7px;
  border-radius: 10px;
}
.toggle-bar-active {
  background-color: rgb(20, 155, 88);
}
.toggle-bar-inactive {
  background-color: #fff;
}
</style>
