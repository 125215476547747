<template>
  <div
    class="drag-drop-wrapper"
    @dragover.prevent="() => handledragOver('filter')"
    @dragleave="handleDragLeave"
    @drop="handleDrop"
  >
    <div v-if="this.filters.length > 0" class="items-scroll">
      <div class="top-index" @dragover="() => handleIndexDragOver(-1, 'filter')">
        <div :class="filterDragAcitve && hoveredIndex === -1 ? 'append-item-bottom-active' : 'append-item-bottom'" />
      </div>
      <div
        v-for="(filter, index) in this.filters"
        class="single-filter"
        @dragover="() => handleIndexDragOver(index, 'filter')"
        :key="index"
      >
        <div
          class="filter-item-draggable"
          :draggable="true"
          @dragstart="() => handleDragStart({ tagGroup: filter, groupName: 'filter' })"
        >
          <TagGroupBox :filter="filter" />
        </div>
        <div :class="filterDragAcitve && hoveredIndex === index ? 'append-item-bottom-active' : 'append-item-bottom'" />
      </div>
    </div>
    <div class="drag-handle-wrapper">
      <DragDropHandler :active="filterDragAcitve" entity="filters" />
    </div>
  </div>
</template>

<script>
import TagGroupBox from '@/components/admin/v2/TagGroupBox';
import DragDropHandler from '@/components/v2/DragDropHandler';

export default {
  components: {
    TagGroupBox,
    DragDropHandler,
  },
  computed: {
    hoveredIndex() {
      return this.$store.state.contextManager.hoveredIndex;
    },
    filters() {
      return this.$store.state.contextManager.filters;
    },
    activeDragGroup() {
      return this.$store.state.contextManager.activeDragGroup;
    },
    filterDragAcitve() {
      return this.activeDragGroup === 'filter' ? true : false;
    },
  },
  methods: {
    handledragOver(activeDragGroup) {
      if (!this.activeDragGroup) {
        this.$store.dispatch('contextManager/updater', {
          activeDragGroup,
        });
        if (this.hoveredIndex === null) {
          this.$store.dispatch('contextManager/updater', {
            hoveredIndex: this.filters.length - 1,
          });
        }
      }
    },
    handleDragLeave() {
      this.$store.dispatch('contextManager/updater', { activeDragGroup: '' });
    },
    cloneTagGroup(item) {
      return item;
    },
    handleDragStart({ tagGroup, groupName }) {
      this.$store.dispatch('contextManager/updater', {
        itemBeingDragged: { tagGroup, groupName },
      });
    },
    handleDrop() {
      this.$store.dispatch('contextManager/addDraggedItem', {
        itemDropedOn: 'filter',
      });
    },
    handleIndexDragOver(index, groupName) {
      if (this.hoveredIndex !== index) {
        this.$store.dispatch('contextManager/updater', {
          hoveredIndex: index,
          activeDragGroup: groupName,
        });
      }
    },
    handleDragToSortStart({ tagGroup, groupName }) {
      this.$store.dispatch('contextManager/updater', {
        itemBeingDragged: { tagGroup, groupName },
      });
    },
  },
};
</script>

<style scoped>
.drag-drop-wrapper {
  height: calc(100vh - 300px);
  position: relative;
}
.drag-drop-wrapper .items-scroll {
  overflow-x: auto;
  max-height: calc(100% - 60px);
  margin-bottom: 10px;
}
.top-index {
  background-color: transparent;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-top: -20px;
}
.append-item-bottom {
  height: 6px;
  margin: 5px 30px;
  background-color: transparent;
}
.append-item-bottom-active {
  height: 6px;
  background-color: #23125f;
  border-radius: 50px;
  margin: 5px 30px;
  transition: all 0.3s ease-in-out;
}
.drag-on-me {
  bottom: 0;
  left: 0;
  right: 0;
}
.filter-item-draggable {
  cursor: move;
}
</style>
