<template>
  <!-- progress users -->
  <div class="mx-4 mb-4 pt-3" v-if="usersProgress">
    <div class="row">
      <div class="col-11 offset-1">
        <div class="row">
          <div class="col-12">
            <h6 class="title">Processing Users</h6>
          </div>
        </div>
        <div class="row mb-4 mt-2">
          <div class="col-10">
            <div class="progress rounded-0 bg-white">
              <div
                class="progress-bar bg-success"
                role="progressbar"
                :style="{ width: usersProgress.usersProgress + '%' }"
                :aria-valuenow="usersProgress.usersProgress"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div class="col-2 ps-4 d-flex justify-content-end align-items-center">{{ usersProgress.usersProgress }}%</div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <Button class="mb-4 me-2" label="Reprocess now" background="inverse-dark" :clickHandler="curateUser" />

                <Button class="mb-4" label="Reset cache" background="inverse-dark" :clickHandler="resetProcessor" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@/components/v2/Button';
import { backendService } from '@/services';

export default {
  name: 'UsersProgress',
  props: ['usersProgress'],
  components: {
    Button,
  },
  methods: {
    async curateUser() {
      try {
        // curate user
        const response = await backendService.post({
          name: 'Curate User',
          url: '/curator/v1/admin/curate-user',
          method: 'POST',
          service: 'curator',
        });

        this.$store.dispatch('alert/successWithTimer', {
          message: response.message,
          seconds: 3,
        });
      } catch (error) {
        this.$store.dispatch('alert/error', error || `Request Failed`);
      }
    },
    async resetProcessor() {
      try {
        // reset processor
        const response = await backendService.post({
          name: 'Refresh Access',
          url: '/processor/v1/admin/refresh-access',
          method: 'POST',
          service: 'processor',
        });

        this.$store.dispatch('alert/successWithTimer', {
          message: response.message,
          seconds: 3,
        });
      } catch (error) {
        this.$store.dispatch('alert/error', error || `Request Failed`);
      }
    },
  },
};
</script>
