<template>
  <div>
    <b-dropdown-item @click.prevent="crawlConnector"> Crawl now </b-dropdown-item>
    <b-modal
      v-model="showModal"
      :hide-header="true"
      :hide-footer="true"
      :no-close-on-backdrop="true"
      size="lg"
      body-class="intop-modal-container"
      centered
    >
      <div class="intop-modal-header">
        Crawl {{ connector.name }} Connector
        <div class="intop-modal-close-btn" @click="hideCrawlNowModal">
          <font-awesome-icon class="close-btn" size="lg" icon="times" />
        </div>
      </div>
      <div class="intop-modal-body">
        <Loading class="text-center mx-auto d-block" v-if="isLoading" />
        <div v-if="response && response.message">
          <font-awesome-icon v-if="response.success === false" class="text-danger mr-1" size="lg" icon="times" />
          <font-awesome-icon v-else class="text-success mr-1" size="lg" icon="check" />
          {{ response.message }}
        </div>
      </div>
      <div class="intop-modal-footer">
        <div class="cancel ml-auto">
          <Button label="Dismiss" background="light" :clickHandler="hideCrawlNowModal" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Loading from '@/components/Loading';
import Button from '@/components/v2/Button';
import { connectorService } from '@/services';

export default {
  name: 'CrawlConnector',
  props: ['connector'],
  components: {
    Loading,
    Button,
  },
  data() {
    return {
      showModal: false,
      isLoading: false,
      response: {},
    };
  },
  methods: {
    async crawlConnector() {
      this.showModal = true;
      this.isLoading = true;
      this.response = {};

      try {
        const response = await connectorService.crawl(this.connector.connectorid);
        this.response = response;
        this.isLoading = false;
      } catch (error) {
        this.response = {
          success: false,
          message: error ? error : 'Crawling failed!',
        };
        this.isLoading = false;
      }
    },
    hideCrawlNowModal() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
@import '../../../../assets/css/intop-modal.css';
</style>
