<template>
  <div class="aois">
    <div class="_label">Areas of Interest</div>
    <div class="card-containers">
      <ImCard
        v-for="(aot, index) in areasOfInterests"
        :key="index"
        :label="aot.name"
        :item="aot"
        name="areasOfInterests"
        variant="yellow"
        :fill="false"
      />
      <ImAddButton name="areasOfInterests" />
    </div>
  </div>
</template>

<script>
import apiUrls from '../../../config/apiUrls';
import ImAddButton from './ImAddButton';
import ImCard from './ImCard';
export default {
  components: {
    ImCard,
    ImAddButton,
  },
  created() {
    this.$store.dispatch('entities/fetchList', [{ url: apiUrls.IMS_AREAOFINTEREST, entity: 'areasOfInterests' }]);
  },
  computed: {
    areasOfInterests() {
      return this.$store.state.entities.areasOfInterests.data || [];
    },
  },
};
</script>

<style scoped>
.aois {
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
}
._label {
  font-size: 14px;
  color: #688197;
  margin-bottom: 10px;
}
.card-containers {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>
