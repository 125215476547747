<template>
  <div class="brand-wrapper">
    <a class="logo" href="/">
      <img src="@/assets/logo.png" />
    </a>
    <div class="user-handle">Logged in as: {{ this.user.name }}</div>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      const { user } = this.$store.state.authentication.user;
      return user;
    },
  },
};
</script>

<style scoped>
.brand-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-left: 40px;
}
.logo img {
  width: 185px;
}
.user-handle {
  color: #23125f;
  font-size: 12px;
  font-weight: 400;
}
</style>
