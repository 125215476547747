<template>
  <AdminFormBaseUi
    backText="Connector"
    backTo="/manage/connector"
    :currentPage="`${this.edit ? 'Update' : 'Add new'} connector`"
  >
    <h3>{{ this.edit ? 'Update' : 'Create' }}</h3>
    <form class="form-wrapper">
      <div class="field-wrapper">
        <InputBox variant="dim" label="Name" :required="true" v-model="name" />
      </div>
      <div class="field-wrapper" v-if="allConnectorTypes.length">
        <div class="label-field">Connector Type</div>
        <DynamicSelect
          id="connectortypeid"
          title="Connector Type"
          label="name"
          :singleSelect="true"
          :handleOpen="this.onConnectorTypeOpen"
          v-model="connectortypeid"
          variant="dim"
        />
      </div>
      <div v-else>---</div>
      <div class="field-wrapper">
        <InputBox
          variant="dim"
          :textarea="true"
          height="large"
          label="Config"
          :getFunc="() => this.config"
          :setFunc="(config) => (this.config = config)"
        />
        <div class="invalid-json" v-if="this.invalidConfig">
          {{ inValidJsonMessage }}
        </div>
      </div>
      <div class="field-wrapper" v-if="ingestionmethodid && ingestionMethods.length > 1">
        <div class="label-field">Ingestion Method</div>
        <DynamicSelect
          id="ingestionmethodid"
          title="Ingestion Method"
          label="name"
          :singleSelect="true"
          :handleOpen="this.onIngestionMethodOpen"
          v-model="ingestionmethodid"
          variant="dim"
        />
      </div>
      <div class="mb-3">
        <b-form-checkbox v-model="enabled" name="check-button" switch> Enabled </b-form-checkbox>
      </div>
      <div class="text-right">
        <Button
          :label="this.edit ? 'Update' : 'Save'"
          background="dark"
          :disabled="isLoading"
          :showLoader="true"
          :isLoading="isLoading && !showFullpageLoader"
          :clickHandler="handleSubmit"
        />
      </div>
    </form>
  </AdminFormBaseUi>
</template>

<script>
import { genericService } from '@/services';
import Button from '@/components/v2/Button';
import Checkbox from '@/components/Checkbox';
import DynamicSelect from '@/components/DynamicSelect';
import InputBox from '@/components/admin/v2/InputBox';
import AdminFormBaseUi from '@/components/admin/v2/base-ui/AdminFormBaseUi';
import apiUrls from '../../../../config/apiUrls';
import { validCronExpresstionChecker } from '@/services';

export default {
  async created() {
    this.$store.dispatch('loader/setLoadingStatus', true);
    this.$store.dispatch('loader/setFullpageLoaderStatus', true);
    await this.getIngestionMethods();
    const editConnector = this.$route.params.id === 'new' ? -1 : this.$route.params.id;
    if (editConnector !== -1) {
      this.edit = true;
      const res = await genericService.index(apiUrls.CONNECTOR, 1, {
        get_in: this.$route.params.id === 'new' ? -1 : this.$route.params.id,
      })();
      const [item] = res.data ?? {};
      const { config = {}, connectorid, connectortypeid, enabled, name, ingestionmethodid } = item ?? {};
      this.config = JSON.stringify(config, null, 4);
      this.connectortypeid = connectortypeid;
      this.enabled = enabled;
      this.name = name;
      if (this.ingestionMethods.length > 1) {
        this.ingestionmethodid = ingestionmethodid;
      } else {
        this.ingestionmethodid = 1;
      }
    } else {
      this.edit = false;
      this.ingestionmethodid = 1;
    }
    const typesRes = await genericService.index(apiUrls.CONNECTORTYPE, 999)();
    this.allConnectorTypes = typesRes.data;
    this.$store.dispatch('loader/setLoadingStatus', false);
    this.$store.dispatch('loader/setFullpageLoaderStatus', false);
  },
  data() {
    return {
      edit: false,
      enabled: true,
      switchChecked: true,
      name: '',
      connectortypeid: -1,
      config: '{}',
      ingestionmethodid: '',
      allConnectorTypes: [],
      invalidConfig: false,
      ingestionMethods: [],
      inValidJsonMessage: 'Invalid JSON found.',
    };
  },
  watch: {
    config(val) {
      try {
        JSON.stringify(JSON.parse(this.config));
        if (this.invalidConfig) {
          this.invalidConfig = false;
        }
        const isCronValid = validCronExpresstionChecker(JSON.parse(this.config));
        if (!isCronValid) {
          this.invalidConfig = true;
          this.setJsonErrorMessage('Schedule is not valid');
        }
      } catch (error) {
        this.invalidConfig = true;
        this.setJsonErrorMessage('Invalid JSON found.');
      }
    },
  },
  computed: {
    connectorList() {
      return this.$store.state.dataTable.data;
    },
    redirectToPage() {
      if (this.connectorList && this.connectorList.total) {
        return Math.ceil((this.connectorList.total + 1) / this.connectorList.per_page);
      } else {
        this.$store.dispatch('dataTable/setTable', apiUrls.CONNECTORS_LIST);
        this.$store.dispatch('dataTable/setPage', 1);
        return 1;
      }
    },
    isLoading() {
      return this.$store.getters['loader/getLoadingStatus'];
    },
    showFullpageLoader() {
      return this.$store.getters['loader/getFullpageLoaderStatus'];
    },
  },
  components: {
    Button,
    Checkbox,
    InputBox,
    DynamicSelect,
    AdminFormBaseUi,
  },
  methods: {
    create() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      const connector = {
        name: this.name,
        connectortypeid: this.connectortypeid,
        config: this.config,
        enabled: this.enabled,
        ingestionmethodid: this.ingestionmethodid,
      };
      const model = genericService.create(apiUrls.CONNECTOR);
      model(connector)
        .then((r) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$router.push({
            path: '/manage/connector',
            query: { page: this.redirectToPage },
          });
        })
        .catch((e) => {
          this.$store.dispatch('alert/error', e || `Unable to create the connector, please try again!`);
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
    modify() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      const connector = {
        connectorid: this.$route.params.id === 'new' ? -1 : this.$route.params.id,
        name: this.name,
        connectortypeid: this.connectortypeid,
        config: this.config,
        enabled: this.enabled,
        ingestionmethodid: this.ingestionmethodid,
      };
      const model = genericService.update(apiUrls.CONNECTOR);
      model(connector)
        .then((r) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$router.back();
        })
        .catch((e) => {
          this.$store.dispatch('alert/error', e || `Unable to update the connector, please try again!`);
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
    handleSubmit(event) {
      event.preventDefault();
      if (!this.edit) {
        this.create();
      } else {
        this.modify();
      }
    },
    onConnectorTypeOpen() {
      return this.allConnectorTypes;
    },
    onIngestionMethodOpen() {
      return this.ingestionMethods;
    },
    async getIngestionMethods() {
      const ingestionMethods = await genericService.index(`${apiUrls.INGESTIONMETHODS}`)();

      this.ingestionMethods = ingestionMethods;
    },
    setJsonErrorMessage(message) {
      this.inValidJsonMessage = message;
    },
  },
};
</script>

<style scoped>
h3 {
  font-weight: 300;
  font-size: 22px;
  padding: 30px;
  padding-left: 40px;
  padding-bottom: 10px;
  color: #24125f;
}
.label-field {
  font-size: 14px;
  color: #24125f;
  padding-bottom: 6px;
}
.form-wrapper {
  max-width: 600px;
  padding: 0px 30px 40px 40px;
}
.field-wrapper {
  margin-bottom: 25px;
}
.invalid-json {
  font-size: 12px;
  font-weight: 500;
  color: rgb(245, 95, 69);
  padding-top: 2px;
}
</style>
