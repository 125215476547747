<template>
  <div class="admin-base-search-wrapper">
    <div class="input-wrapper">
      <div v-if="this.loading" class="loading-wrapper">
        <Loading />
      </div>
      <font-awesome-icon
        size="lg"
        :class="this.isEmptyKeyword ? 'close-btn close-btn-out' : 'close-btn close-btn-in'"
        @click.prevent="resetKeyword"
        icon="times-circle"
      />
      <font-awesome-icon
        size="lg"
        :class="this.isEmptyKeyword ? 'close-btn close-btn-in' : 'close-btn close-btn-out'"
        @click.prevent="resetKeyword"
        icon="search"
      />
      <input type="text" class="base-ui-searh-field" v-model="keyword" @input="debounceHandleSearch" maxlength="120" />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import Loading from '@/components/Loading';

export default {
  components: {
    Loading,
  },
  created() {
    this.debounceHandleSearch = debounce(this.handleSearch, 1200);
  },
  data() {
    return {
      loading: false,
      keyword: '',
    };
  },
  computed: {
    isEmptyKeyword() {
      return !!!this.keyword.trim();
    },
  },
  methods: {
    async handleSearch() {
      this.$store.dispatch('dataTable/resetPage');
      this.loading = true;
      await this.$store.dispatch('dataTable/setSearchField', this.keyword);
      this.loading = false;
    },
    resetKeyword() {
      this.keyword = '';
      this.handleSearch();
    },
  },
};
</script>

<style scoped>
.admin-base-search-wrapper {
  padding: 20px;
}
.admin-base-search-wrapper input {
  display: block;
  transition: 0.2s ease-in-out;
  border: 0px;
  border-radius: 0px;
  padding: 13px 20px;
  background-color: #fff;
  outline: none;
  color: #688197;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}
.admin-base-search-wrapper input::placeholder {
  font-size: 14px;
  color: #688197;
  font-weight: 400;
}
.input-wrapper {
  position: relative;
  overflow: hidden;
}
.loading-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 10px;
  margin-right: 10px;
}
.close-btn {
  color: #aaa;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  margin-top: 16px;
  margin-left: 12px;
  left: 0;
  top: 0;
}
.close-btn:hover {
  color: #666;
}
.close-btn-out {
  margin-left: -20px;
}
input.base-ui-searh-field {
  padding-left: 40px;
  padding-right: 40px;
}
</style>
