<template>
  <div class="intop-universe universe-height">
    <div v-if="!preview" class="intop-nav-wrapper">
      <Navbar />
    </div>
    <div class="intop-app-container" fluid>
      <div class="intop-left-panel">
        <Sidebar />
      </div>
      <div class="intop-middle-panel">
        <WelcomeMessage v-if="displayWelcomeMessage" />
        <ResourcesTable class="main-resource-table" v-if="displayResourceTable" />
      </div>
      <div class="intop-right-panel"></div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar';
import Navbar from '@/components/Navbar';
import ResourcesTable from '@/components/ResourcesTable';
import WelcomeMessage from '@/components/WelcomeMessage';

export default {
  name: 'Main',
  props: {
    preview: { type: Boolean, default: false },
  },
  components: {
    Sidebar,
    Navbar,
    ResourcesTable,
    WelcomeMessage,
  },
  data() {
    return {};
  },
  created() {
    window.addEventListener('resize', this.resizeIntOpUniverseHeight);
    this.$store.dispatch('app/syncSettings');
    this.$store.dispatch('app/resetTenantData');
    setTimeout(() => {
      this.$store.dispatch('authentication/logout');
      setTimeout(() => {
        this.$store.dispatch('alert/error', `Your login session has expired, please login again.`);
      }, 2000);
    }, 10800 * 1000);
  },
  mounted() {
    this.resizeIntOpUniverseHeight();
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeIntOpUniverseHeight);
  },
  computed: {
    displayResourceTable() {
      return this.$store.state.users.displayResourceTable;
    },
    displayWelcomeMessage() {
      return this.$store.state.users.displayWelcomeMessage;
    },
  },
  methods: {
    resizeIntOpUniverseHeight() {
      this.$store.dispatch('app/calculateBody', 'intop-middle-panel');
    },
  },
};
</script>

<style scoped>
.intop-universe {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.intop-app-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}
.intop-left-panel {
  background-color: #ededed;
}
.intop-middle-panel {
  flex-grow: 1;
  overflow: hidden;
  background-color: #ededed;
}
</style>
