import { userService } from '../services';
import router from '../router';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { status: { loggedIn: true }, user } : { status: {}, user: null };

export const authentication = {
  namespaced: true,
  state: {
    loginFailed: false,
    ...initialState,
  },
  getters: {
    isAdmin: (state) => {
      if (state.user && state.user.user && state.user.user.roles) {
        const roles = state.user.user.roles;
        if (roles.length && (roles.includes(1) || roles.includes(2))) {
          return true;
        }
      }
      return false;
    },
  },
  actions: {
    login({ dispatch, commit, rootGetters }, { username, password }) {
      dispatch('loader/setLoadingStatus', true, { root: true });
      commit('loginRequest', { username });
      userService
        .login(username, password)
        .then((user) => {
          dispatch('loader/setLoadingStatus', false, { root: true });
          commit('toggleLoginAttempt', false);
          if (!user.user.active) {
            router.push('/activate-account');
          } else {
            commit('loginSuccess', user);
            if (rootGetters['app/getTeamContext']) {
              return router.push('/teams');
            }
            router.push('/');
          }
        })
        .catch((error) => {
          dispatch('alert/error', `The username or password is incorrect`, {
            root: true,
          });
          commit('toggleLoginAttempt', true);
          commit('loginFailure', error);
          dispatch('loader/setLoadingStatus', false, { root: true });
        });
    },
    loginViaAzure({ dispatch, commit, rootGetters }, azureToken) {
      dispatch('loader/setLoadingStatus', true, { root: true });
      dispatch('loader/setFullpageLoaderStatus', true, { root: true });
      userService
        .loginViaAzure(azureToken)
        .then((user) => {
          dispatch('loader/setLoadingStatus', false, { root: true });
          dispatch('loader/setFullpageLoaderStatus', false, { root: true });
          commit('toggleLoginAttempt', false);
          if (user.tenants) {
            dispatch('app/setTenantData', { tenants: user.tenants, accessToken: user.accessToken }, { root: true });
            return router.push({
              name: 'SelectTenant',
            });
          }
          if (!user.user.active) {
            router.push('/activate-account');
          } else {
            commit('loginSuccess', user);
            if (rootGetters['app/getTeamContext']) {
              return router.push('/teams');
            }
            router.push('/');
          }
        })
        .catch((error) => {
          dispatch('loader/setLoadingStatus', false, { root: true });
          dispatch('loader/setFullpageLoaderStatus', false, { root: true });
          dispatch('alert/error', error.message || `Login failed!`, {
            root: true,
          });
          commit('toggleLoginAttempt', true);
          commit('loginFailure', error);
        });
    },
    loginViaTeams({ dispatch, commit, rootGetters }, token) {
      dispatch('loader/setLoadingStatus', true, { root: true });
      dispatch('loader/setFullpageLoaderStatus', true, { root: true });
      userService
        .loginViaTeams(token)
        .then((user) => {
          dispatch('loader/setLoadingStatus', false, { root: true });
          dispatch('loader/setFullpageLoaderStatus', false, { root: true });
          commit('toggleLoginAttempt', false);
          if (user.tenants) {
            dispatch('app/setTenantData', { tenants: user.tenants, accessToken: user.accessToken }, { root: true });
            return router.push({
              name: 'SelectTenant',
            });
          }
          if (!user.user.active) {
            router.push('/activate-account');
          } else {
            commit('loginSuccess', user);
            if (rootGetters['app/getTeamContext']) {
              return router.push('/teams');
            }
            router.push('/');
          }
        })
        .catch((error) => {
          dispatch('loader/setLoadingStatus', false, { root: true });
          dispatch('loader/setFullpageLoaderStatus', false, { root: true });
          dispatch('alert/error', error || `Login failed!`, {
            root: true,
          });
          commit('toggleLoginAttempt', true);
          commit('loginFailure', error);
        });
    },
    loginIntoTenant({ dispatch, commit, rootGetters }, { azureToken, tenant }) {
      dispatch('loader/setLoadingStatus', true, { root: true });
      dispatch('loader/setFullpageLoaderStatus', true, { root: true });
      userService
        .loginIntoTenant(azureToken, tenant)
        .then((user) => {
          dispatch('loader/setLoadingStatus', false, { root: true });
          dispatch('loader/setFullpageLoaderStatus', false, { root: true });
          commit('toggleLoginAttempt', false);
          if (!user.user.active) {
            router.push('/activate-account');
          } else {
            commit('loginSuccess', user);
            if (rootGetters['app/getTeamContext']) {
              return router.push('/teams');
            }
            router.push('/');
          }
        })
        .catch((error) => {
          dispatch('loader/setLoadingStatus', false, { root: true });
          dispatch('loader/setFullpageLoaderStatus', false, { root: true });
          dispatch('alert/error', error.message || `Login failed!`, {
            root: true,
          });
          commit('toggleLoginAttempt', true);
          commit('loginFailure', error);
        });
    },
    async logout({ commit }) {
      commit('logout');
      if (router.currentRoute.fullPath != '/login') {
        await userService.logout();
        router.go('/login');
        // IE11 fix.
        setTimeout(() => {
          window.location = '/';
        }, 1);
      }
    },
  },
  mutations: {
    loginRequest(state, user) {
      state.status = { loggingIn: true };
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status = { loggedIn: true };
      state.user = user;
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
    },
    toggleLoginAttempt(state, value) {
      state.loginFailed = value;
    },
  },
};
