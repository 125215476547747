<template>
  <AdminBaseUi
    title="Welcome Page Manager"
    :showSearchBox="false"
    :showPagination="false"
    :handleSubmit="this.handleSubmit"
  >
    <div class="welcome-pages-container">
      <div class="left">
        <div class="wpc-label text-blue">Page Content</div>
        <div class="editor-wrapper">
          <vue-editor class="editor" v-model="body"></vue-editor>
        </div>
      </div>
      <div class="right">
        <div class="wpc-label text-blue">Sidebar Content</div>
        <div class="editor-wrapper">
          <vue-editor class="editor" v-model="right_sidebar"></vue-editor>
        </div>
      </div>
    </div>
  </AdminBaseUi>
</template>

<script>
import { welcomePageService } from '@/services';
import AdminBaseUi from '@/components/admin/v2/base-ui/AdminBaseUi';
import Editor from '@/components/admin/v2/Editor';

export default {
  components: {
    'vue-editor': Editor,
    AdminBaseUi,
  },
  created() {
    this.$store.dispatch('loader/setLoadingStatus', true);
    this.$store.dispatch('loader/setFullpageLoaderStatus', true);
    this.$store.dispatch('dataTable/reset');
    welcomePageService
      .getWelcomeMessageData()
      .then((data) => {
        const { body, right_sidebar } = data.data;
        this.body = body;
        this.right_sidebar = right_sidebar;
        this.$store.dispatch('loader/setLoadingStatus', false);
        this.$store.dispatch('loader/setFullpageLoaderStatus', false);
      })
      .catch((err) => {
        this.$store.dispatch('loader/setLoadingStatus', false);
        this.$store.dispatch('loader/setFullpageLoaderStatus', false);
      });
  },
  data() {
    return {
      body: '',
      right_sidebar: '',
      publishing: false,
      published: false,
    };
  },
  methods: {
    handleSubmit() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      this.published = false;
      this.publishing = true;
      welcomePageService
        .updateWelcomeMessageData({
          body: this.body,
          right_sidebar: this.right_sidebar,
        })
        .then((res) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.publishing = false;
          this.published = true;
          this.$store.dispatch('alert/success', `Welcome page content has been updated`);
        })
        .catch((err) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
  },
};
</script>

<style>
.welcome-pages-container {
  display: flex;
  background-color: #fff;
  height: 100%;
  margin: 0px 40px;
}
.welcome-pages-container .left {
  flex-basis: 60%;
}
.welcome-pages-container .right {
  flex-basis: 40%;
  border-left: 1px solid rgb(240, 240, 240);
}
.welcome-pages-container .wpc-label {
  font-weight: bold;
  padding: 15px 16px;
  font-weight: 500;
  font-size: 14px;
}
.welcome-pages-container .editor-wrapper {
  height: calc(100% - 90px);
}
.welcome-pages-container .ql-toolbar.ql-snow {
  border: none;
  padding-top: 0px !important;
}
.welcome-pages-container .ql-container.ql-snow {
  border: none;
}
.welcome-pages-container .quillWrapper.editor {
  height: 100%;
}
</style>
