import { genericService } from '@/services';
import apiUrls from '../config/apiUrls';
import { updateBookmarks } from '@/helpers/bookmark';

export const app = {
  namespaced: true,
  state: {
    headerHeight: 0,
    bodyHeight: 0,
    currentPath: '/',
    settings: {},
    searchTitleOnly: false,
    teamContext: null,
    tenantData: {
      tenants: [],
      accessToken: false,
    },
  },
  getters: {
    getTeamContext(state) {
      return state.teamContext;
    },
    getTenantList(state) {
      return state.tenantData.tenants;
    },
    getAccessToken(state) {
      return state.tenantData.accessToken;
    },
  },
  actions: {
    async syncSettings({ commit }) {
      const configs = await genericService.index(apiUrls.CONFIG_LIST)();
      commit('updateSettings', configs.data);
    },
    calculateBody({ commit }, className) {
      const bodyWrapper = document.querySelector(`.${className}`);
      if (bodyWrapper) {
        const topOuterHeight = window.pageYOffset + bodyWrapper.getBoundingClientRect().top;
        const bodyHeight = window.innerHeight - topOuterHeight;
        commit('updateBodyHeight', bodyHeight);
      }
    },
    setCurrentPath({ commit }, pathName) {
      commit('updateCurrentPath', pathName);
    },
    setSearchByTitle({ commit }, searchTitle) {
      commit('updateSearchTitleOnly', searchTitle);
      updateBookmarks({
        paramKey: 't',
        paramValue: searchTitle,
      });
    },
    setTeamContext({ commit }) {
      microsoftTeams.getContext((context) => {
        commit('setTeamContext', context);
      });
    },
    setTenantData({ commit }, nextState) {
      commit('setTenantData', nextState);
    },
    resetTenantData({ commit }) {
      commit('setTenantData', { tenants: [], accessToken: false });
    },
  },
  mutations: {
    updateBodyHeight(state, height) {
      state.type = 'success';
      state.bodyHeight = height;
    },
    updateCurrentPath(state, pathName) {
      state.currentPath = pathName;
    },
    updateSettings(state, settings) {
      state.settings = settings;
    },
    updateSearchTitleOnly(state, searchTitle) {
      state.searchTitleOnly = searchTitle;
    },
    setTeamContext(state, context) {
      state.teamContext = context;
    },
    setTenantData(state, { tenants, accessToken }) {
      state.tenantData = { tenants, accessToken };
    },
  },
};
