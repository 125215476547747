import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';

const instrumentationKey = process.env.VUE_APP_INSTRUMENTATION_KEY;
let appInsights = null;
if (instrumentationKey) {
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: instrumentationKey,
      disableFetchTracking: false,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      disableCorrelationHeaders: false,
      distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
      // enableAutoRouteTracking: true,
    },
  });
  appInsights.loadAppInsights();

  var telemetryInitializer = (envelope) => {
    envelope.tags['ai.cloud.role'] = 'frontend';
  };
  appInsights.addTelemetryInitializer(telemetryInitializer);
}

export default appInsights;
