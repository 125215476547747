<template>
  <div class="pagination-wrapper" v-if="paginate.remaining">
    <div class="pagination-btn" @click.prevent="handlePagination">+ {{ paginate.remaining }}</div>
  </div>
</template>

<script>
export default {
  computed: {
    paginate() {
      let { total = 0, start = 0, end = 0, data = [] } = this.$store.state.typeAheadSearch.items;

      const rows_count = data.length;
      const remaining = Math.max(total - end, 0);

      return {
        total,
        start,
        end,
        rows_count,
        remaining,
      };
    },
  },
  methods: {
    handlePagination() {
      this.$store.dispatch('typeAheadSearch/updateItems', {
        offset: this.paginate.end,
        limit: 20,
      });
    },
  },
};
</script>

<style scoped>
.pagination-wrapper {
  display: inline-block;
  height: 60px;
  margin-bottom: 10px;
  margin-right: 10px;
}
.pagination-btn {
  font-size: 14px;
  color: #24125f;
  background-color: #ededed;
  height: 51px;
  width: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.pagination-btn:hover {
  opacity: 0.8;
}
</style>
