<template>
  <a :class="classes" @click="_clickHandler" :href="this.href" v-if="this.href" :title="this.title">
    <div v-if="this.icon">
      <font-awesome-icon class="bttn-icon" :icon="this.icon" />
    </div>
    <div v-else>{{ this.label }}</div>
  </a>
  <button
    class="position-relative"
    :class="classes"
    @click="_clickHandler"
    type="submit"
    :title="this.title"
    :disabled="disabled"
    v-else
  >
    <div>
      <div v-if="this.icon">
        <font-awesome-icon class="bttn-icon" :icon="this.icon" />
      </div>
      <div v-else>{{ this.label }}</div>
    </div>
    <div v-if="isLoading && showLoader">
      <div class="btn-loader">
        <font-awesome-icon icon="circle-notch" spin />
      </div>
    </div>
  </button>
</template>

<script>
export default {
  emits: ['click'],
  props: {
    label: {
      default: '',
    },
    background: {
      default: 'dark',
    },
    clickHandler: {
      default: undefined,
    },
    to: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    circle: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      let _cls = `bttn-wrapper ${this.background}`;
      if (this.circle) {
        _cls += ` bttn-circle`;
      }
      if (this.icon) {
        _cls += ` bttn-icon`;
      }
      return _cls;
    },
  },
  methods: {
    _clickHandler() {
      if (this.to !== '') {
        this.$router.push(this.to);
      } else if (this.clickHandler !== undefined) {
        this.clickHandler(event);
      } else if (this.href) {
        window.location.href = this.href;
      }
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
@import '../../assets/css/intop-button.css';
</style>
