import { userService } from '../services';

export const users = {
  namespaced: true,
  state: {
    all: {},
    displayContentExcerpts: false,
    displayWelcomeMessage: true,
    displayResourceTable: false,
    displayResourceTablePagination: false,
  },
  actions: {
    getAll({ commit }) {
      commit('getAllRequest');

      userService.getAll().then(
        (users) => commit('getAllSuccess', users),
        (error) => commit('getAllFailure', error)
      );
    },
    controlContentExcerpts({ commit }) {
      commit('setContentExcerpts');
    },
    toggleWelcomeMessage({ commit }, status) {
      commit('toggleWelcomeMessage', status);
    },
    toggleResourceTable({ commit }, status) {
      commit('toggleResourceTable', status);
    },
    toggleResourceTablePagination({ commit }, status) {
      commit('toggleResourceTablePagination', status);
    },
  },
  mutations: {
    setContentExcerpts(state) {
      state.displayContentExcerpts = !state.displayContentExcerpts;
    },
    getAllRequest(state) {
      state.all = { loading: true };
    },
    getAllSuccess(state, users) {
      state.all = { items: users };
    },
    getAllFailure(state, error) {
      state.all = { error };
    },
    toggleWelcomeMessage(state, status) {
      state.displayWelcomeMessage = status;
    },
    toggleResourceTable(state, status) {
      state.displayResourceTable = status;
    },
    toggleResourceTablePagination(state, status) {
      state.displayResourceTablePagination = status;
    },
  },
};
