<template>
  <div class="imaddbutton">
    <div @click.prevent="handleForm" class="addbtncontainer">+</div>
  </div>
</template>

<script>
export default {
  props: {
    name: '',
  },
  methods: {
    async handleForm() {
      this.$store.dispatch('imStructure/updater', {
        form: {
          showModal: true,
          activeForm: this.name,
        },
      });
    },
  },
};
</script>

<style scoped>
.imaddbutton {
  display: inline-block;
  margin-bottom: 10px;
}
.addbtncontainer {
  font-size: 20px;
  line-height: 20px;
  color: #567080;
  border: 2px dashed #567080;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.addbtncontainer:hover {
  opacity: 0.8;
}
</style>
