const colors = [
  '#24125F',
  '#5F8DDA',
  '#833177',
  '#C5299B',
  '#333D79',
  '#C6C4D2',
  '#A2AAAD',
  '#688197',
  '#E03C31',
  '#EAA794',
  '#DB8A06',
  '#E1E000',
  '#BFB800',
  '#6BA539',
];

export const getColors = (numberOfColors) => {
  let colorsArr = Array(Math.ceil(numberOfColors / colors.length))
    .fill(colors)
    .flat();
  return colorsArr.slice(0, numberOfColors);
};
