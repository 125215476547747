import { store } from '../store';
import config from '../config/config';
import { authHeader, handleApiResponse } from '../helpers';
import apiUrls from '../config/apiUrls';

export const filterService = {
  getAll,
  getValues,
  getValuesWithoutCounts,
  getValuesWithCounts,
  getResourceCountPerNode,
  getResourceCounts,
  createFilter,
  updateFilter,
  deleteFilter,
};

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}${apiUrls.FILTER_LIST}`, requestOptions).then(handleApiResponse);
}

function getValues(taggroups, filterSearch) {
  const { tags, searchString, modifiedFrom, modifiedBefore } = store.state.filterParams;
  const nodesFlat = tags.map((n) => n.tagid);

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      taggroups,
      filterSearch,
      filters: nodesFlat,
      searchString,
      modifiedFrom,
      modifiedBefore,
    }),
  };

  return fetch(`${config.apiUrl}${apiUrls.FILTER_VALUES}`, requestOptions).then(handleApiResponse);
}

function getValuesWithoutCounts(id, { search, taggroupid }) {
  const { tags, searchString, modifiedFrom, modifiedBefore } = store.state.filterParams;

  const nodesFlat = tags.map((n) => n.tagid);

  let parentContextList = {}; // nodeclass

  const storeNodes = store.state.filterParams.tags;
  store.state.contexts.selected.filters.map((f) => {
    if (f.taggroupid != taggroupid) {
      const childNodes = storeNodes.filter((cn) => cn.filterid == f.filterid);
      if (childNodes.length > 0) {
        childNodes.map((singleMainSubContext) => {
          if (!parentContextList[f.taggroupid]) {
            parentContextList[f.taggroupid] = {
              parenttaggroupid: f.taggroupid,
              mainsubcontextidList: [singleMainSubContext.tagid],
            };
          } else {
            parentContextList[f.taggroupid]['mainsubcontextidList'].push(singleMainSubContext.tagid);
          }
        });
      }
    }
  });

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      id,
      filterSearch: search,
      filters: nodesFlat,
      searchString,
      modifiedFrom,
      modifiedBefore,
      parentContextList,
      dependentContext: taggroupid,
      contextid: store.state.contexts.selected.contextid,
    }),
  };

  return fetch(`${config.apiUrl}${apiUrls.FILTER_VALUES_WITHOUT_COUNT}`, requestOptions).then(handleApiResponse);
}

function getValuesWithCounts(id, filterSearch) {
  const { tags, searchString, modifiedFrom, modifiedBefore } = store.state.filterParams;
  const nodesFlat = tags.map((n) => n.tagid);

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      id,
      filterSearch,
      filters: nodesFlat,
      searchString,
      modifiedFrom,
      modifiedBefore,
    }),
  };

  return fetch(`${config.apiUrl}${apiUrls.FILTER_VALUES_WITHOUT_COUNT}`, requestOptions).then(handleApiResponse);
}

function getResourceCountPerNode(tagid) {
  const { tags, searchString, modifiedFrom, modifiedBefore } = store.state.filterParams;
  const nodesFlat = tags.map((n) => n.tagid);

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      tagid,
      filters: nodesFlat,
      searchString,
      modifiedFrom,
      modifiedBefore,
    }),
  };

  return fetch(`${config.apiUrl}/private/filter/resourcecountpernode`, requestOptions).then(handleApiResponse);
}

function getResourceCounts() {
  const { tags, searchString, modifiedFrom, modifiedBefore } = store.state.filterParams;
  const nodesFlat = tags.map((n) => n.tagid);

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      filters: nodesFlat,
      searchString,
      modifiedFrom,
      modifiedBefore,
    }),
  };

  return fetch(`${config.apiUrl}/private/filter/resourcecounts`, requestOptions).then(handleApiResponse);
}

function createFilter(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}${apiUrls.FILTER_CREATE}`, requestOptions).then(handleApiResponse);
}

function updateFilter(data) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}${apiUrls.FILTER_UPDATE}`, requestOptions).then(handleApiResponse);
}

function deleteFilter(data) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}${apiUrls.FILTER_DELETE}`, requestOptions).then(handleApiResponse);
}
