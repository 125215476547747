<template>
  <main role="main">
    <div class="container-fluid welcomemsg-wrapper">
      <div v-if="loading" class="wlc-card">Please wait</div>
      <div v-else class="wlc-card">
        <div class="wlc-body" :style="`height: ${this.bodyHeight - 5}px`" v-html="this.body"></div>
        <div class="wlc-right" :style="`height: ${this.bodyHeight - 5}px`" v-html="this.right_sidebar"></div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  created() {
    this.$store.dispatch('welcomePage/getWelcomeMessage');
  },
  computed: {
    loading() {
      return this.$store.state.welcomePage.loading;
    },
    body() {
      return this.$store.state.welcomePage.body;
    },
    bodyHeight() {
      return this.$store.state.app.bodyHeight;
    },
    right_sidebar() {
      return this.$store.state.welcomePage.right_sidebar;
    },
  },
};
</script>

<style>
.welcomemsg-wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 90px);
}
.welcomemsg-wrapper img {
  max-width: 100%;
}
.wlc-card {
  display: flex;
  flex-grow: 1;
}
.wlc-card .wlc-body {
  flex-basis: 100%;
  padding: 20px;
  padding-top: 0px;
  margin-top: 20px;
  padding-left: 0px;
  overflow-y: auto;
}
.wlc-card .wlc-right {
  flex-basis: 450px;
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 0px;
  margin-top: 20px;
}
.wlc-title {
  margin-bottom: 20px;
}
.wlc-card p {
  font-family: inherit;
  margin-bottom: 5px;
}
</style>
