<template>
  <div class="goupbox-wrapper">
    <div class="top">
      <div class="act-button">
        <div class="btn-wrapper">
          <img src="@/assets/dots.svg" alt="" />
        </div>
      </div>
      <div class="act-button">
        <div class="btn-wrapper close-btn" @click.prevent="close">
          <img src="@/assets/cross.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="content">{{ this.filter.name }}</div>
  </div>
</template>

<script>
export default {
  props: ['filter'],
  methods: {
    close() {
      this.$store.dispatch('contextManager/remove', {
        type: 'filter',
        id: this.filter.taggroupid,
      });
    },
  },
};
</script>

<style scoped>
.goupbox-wrapper {
  background-color: #fff;
  position: relative;
}
.top {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 5px;
  /* border: 1px solid green; */
}
.content {
  padding: 15px 20px;
  font-size: 14px;
  color: #23125f;
}
.act-button {
  /* border: 1px solid red; */
  padding: 0px 4px;
  display: inline-block;
}
.btn-wrapper img {
  height: 11px;
  width: 9px;
}
.close-btn {
  z-index: 9;
  cursor: pointer;
}
</style>
