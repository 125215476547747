<template>
  <!-- progress sources -->
  <div class="mb-4" v-if="sources">
    <div class="row">
      <div class="col-11 offset-1">
        <h6 class="title px-4">Processing Sources</h6>
      </div>
    </div>
    <div class="row mb-4 mt-2 px-4">
      <div class="col-1 d-flex align-items-center">
        <a
          v-if="sources.sources && sources.sources.length > 0"
          href="#"
          class="sources-collapse-btn"
          @click.prevent="sourcesCollapseVisible = !sourcesCollapseVisible"
        >
          <font-awesome-icon size="lg" icon="chevron-down" v-if="sourcesCollapseVisible" />
          <font-awesome-icon size="lg" icon="chevron-right" v-else />
        </a>
      </div>
      <div class="col-11">
        <div class="row">
          <div class="col-10">
            <div class="progress rounded-0 bg-white">
              <div
                class="progress-bar bg-success"
                role="progressbar"
                :style="{ width: sources.progress + '%' }"
                :aria-valuenow="sources.progress"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div class="col-2 pl-4 d-flex justify-content-end align-items-center">{{ sources.progress }}%</div>
        </div>
      </div>
    </div>
    <div
      id="sources-container"
      v-show="sourcesCollapseVisible"
      class="mt-2 p-4"
      v-if="sources.sources && sources.sources.length > 0"
    >
      <div class="row">
        <div class="col-12">
          <div class="row source-item-row" v-for="(source, index) in sources.sources" :key="'source_' + index">
            <div class="col-10">
              <div class="card source-item">
                <div class="row align-items-center">
                  <div class="col-4">
                    <div class="source-title">
                      {{ source.name }}
                    </div>
                  </div>
                  <div class="col-3 text-right">
                    <div class="source-progress">{{ source.progress ? source.progress : 0 }}%</div>
                  </div>
                  <div class="col-5">
                    <div class="source-info">
                      <template v-if="source.startdate">
                        <div>Last updated {{ parseDate(source.startdate) }}</div>
                        <div>{{ source.newcount ? source.newcount : 0 }} New</div>
                        <div>
                          {{ source.updatedcount ? source.updatedcount : 0 }}
                          Updated
                        </div>
                        <div>
                          {{ source.samecount ? source.samecount : 0 }}
                          Unchanged
                        </div>
                      </template>
                      <div v-else>Never crawled</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2 source-status">
              <img
                class="img-fluid"
                src="@/assets/check.svg"
                alt=""
                v-if="source.startdate && source.enddate && !source.error"
              />
              <img
                class="img-fluid spin"
                src="@/assets/running.svg"
                alt=""
                v-else-if="source.startdate && !source.enddate && !source.error"
              />
              <img class="img-fluid" src="@/assets/error.svg" alt="" v-else-if="source.startdate && source.error" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment-timezone';
import { BCollapse } from 'bootstrap-vue-3';

window.moment = moment;
export default {
  components: { BCollapse },
  props: ['sources'],
  data() {
    return {
      sourcesCollapseVisible: false,
      visible: true,
    };
  },
  methods: {
    parseDate(date) {
      return moment(date - (moment().tz('Europe/Oslo').utcOffset() / 60) * 60 * 60 * 1000).fromNow();
    },
  },
};
</script>

<style scoped>
#sources-container {
  margin-left: 4.17%;
  padding-left: 8.33333% !important;
  padding-top: 8.33333% !important;
  margin-top: 8.33333% !important;
  padding-bottom: 8.33333% !important;
  border-left: 1px solid rgb(204, 204, 204);
  border-top: 1px solid rgb(204, 204, 204);
  border-bottom: 1px solid rgb(204, 204, 204);
  border-image: linear-gradient(to right, rgb(204, 204, 204) 15%, transparent 14%) 1 / 1 / 0 stretch;
}
.sources-collapse-btn {
  font-size: 16px;
  color: #343a40;
}
.source-item {
  padding: 0.75rem 1.25rem;
  border: 0;
  border-radius: 0;
}
.source-item-row {
  margin-bottom: 1.5rem;
}
.source-item-row:last-child {
  margin-bottom: 0;
}
.source-title {
  font-weight: bold;
  font-size: 1rem;
}
.source-progress {
  font-weight: 500;
  color: #23125f;
}
.source-info {
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
  color: #23125f;
}
.source-status {
  display: flex;
  justify-content: flex-end;
}
.source-status img {
  width: 2rem;
}

.spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
</style>
