<template>
  <div v-if="showAlert">
    <b-alert v-model="showAlert" :variant="alert.type" dismissible>{{ alert.message }}</b-alert>
  </div>
</template>

<script>
export default {
  computed: {
    alert() {
      return this.$store.state.alert;
    },
    showAlert: {
      get() {
        return !!this.$store.state.alert.message;
      },
      set() {
        this.$store.dispatch('alert/clear');
      },
    },
  },
  watch: {
    $route(to, from) {
      this.$store.dispatch('alert/clear');
    },
  },
};
</script>
