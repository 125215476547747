<template>
  <div :class="`column-label ${this.size} ${this.column ? 'move-cursor' : 'disabled-move-cursor'}`">
    <span class="label">{{ this.label }}</span>
    <div class="actions">
      <div class="actbtn">
        <img src="@/assets/dots.svg" alt="" />
      </div>
      <div :class="`actbtn ${this.column ? 'close-btn' : 'disabled-cursor'}`" @click.prevent="close">
        <img src="@/assets/cross.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    size: { type: String, default: 'small' },
    column: null,
  },
  methods: {
    close() {
      if (this.column) {
        this.$store.dispatch('contextManager/remove', {
          type: 'column',
          id: this.column.taggroupid,
        });
      }
    },
  },
};
</script>

<style scoped>
.column-label {
  padding-left: 0px;
  display: flex;
  align-items: center;
}
.move-cursor {
  cursor: move;
}
.disabled-move-cursor {
  cursor: no-drop;
}
.actions {
  display: flex;
}
.actions .actbtn {
  padding: 0px 2px;
}
.actions .actbtn img {
  width: 10px;
  height: 10px;
  display: block;
  margin: 0px;
  padding: 0px;
}
span.label {
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #23125f;
}
.large {
  width: 240px;
}
.small {
  margin-right: 5px;
  min-width: 80px;
}
.close-btn {
  z-index: 9;
  cursor: pointer;
}
.disabled-cursor {
  cursor: not-allowed;
}
</style>
