<template>
  <div
    class="column-drag-drop-wrapper"
    @dragover.prevent="() => handledragOver('column')"
    @dragleave="handleDragLeave"
    @drop="handleDrop"
  >
    <div
      :class="columnDragActive ? 'column-labels-wrapper dragging' : 'column-labels-wrapper'"
      :style="'width: calc(100vh - ' + columnsContainerWidth + 'px);'"
    >
      <div class="initial-drag-on" @dragover="() => handleIndexDragOver(-1, 'column')">
        <div :class="columnDragActive && hoveredIndex === -1 ? 'append-item-side-active' : 'append-item-side'" />
      </div>
      <div v-if="this.columns.length > 0" class="items-scroll">
        <div
          class="single-column"
          v-for="(column, index) in this.columns"
          :key="index"
          @dragover="() => handleIndexDragOver(index, 'column')"
        >
          <div
            class="filter-item-draggable"
            :draggable="true"
            @dragstart="() => handleDragStart({ tagGroup: column, groupName: 'column' })"
          >
            <div class="column-label-wrapper">
              <ColumnLabel :column="column" :label="column.name" />
            </div>
            <div :class="columnDragActive && hoveredIndex === index ? 'append-item-side-active' : 'append-item-side'" />
          </div>
        </div>
      </div>
    </div>
    <div class="drag-handler-wrapper">
      <DragDropHandler :active="columnDragActive" entity="columns" :lineBreak="true" />
    </div>
  </div>
</template>

<script>
import DragDropHandler from '@/components/v2/DragDropHandler';
import ColumnLabel from '@/components/admin/v2/ColumnLabel';

export default {
  components: {
    ColumnLabel,
    DragDropHandler,
  },
  data() {
    return {
      columnsContainerWidth: 300,
    };
  },
  created() {
    window.addEventListener('resize', this.resizeColumnsWrapper);
  },
  mounted() {
    this.resizeColumnsWrapper();
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeColumnsWrapper);
  },
  computed: {
    hoveredIndex() {
      return this.$store.state.contextManager.hoveredIndex;
    },
    columns() {
      return this.$store.state.contextManager.columns;
    },
    activeDragGroup() {
      return this.$store.state.contextManager.activeDragGroup;
    },
    columnDragActive() {
      return this.activeDragGroup === 'column' ? true : false;
    },
  },
  methods: {
    resizeColumnsWrapper() {
      const columnWrapper = document.querySelector('.right .column-wrapper');
      const staticColumnWidth = columnWrapper.querySelector('.default-columns-wrapper').scrollWidth;
      const dragHandlerWidth = columnWrapper.querySelector('.drag-handler-wrapper').scrollWidth;
      this.columnsContainerWidth = staticColumnWidth + dragHandlerWidth;
    },
    handledragOver(activeDragGroup) {
      if (!this.activeDragGroup) {
        this.$store.dispatch('contextManager/updater', {
          activeDragGroup,
        });
        if (this.hoveredIndex === null) {
          this.$store.dispatch('contextManager/updater', {
            hoveredIndex: this.columns.length - 1,
          });
        }
      }
    },
    handleDragLeave() {
      this.$store.dispatch('contextManager/updater', {
        activeDragGroup: '',
      });
    },
    handleDragStart({ tagGroup, groupName }) {
      this.$store.dispatch('contextManager/updater', {
        itemBeingDragged: { tagGroup, groupName },
      });
    },
    handleDrop() {
      this.$store.dispatch('contextManager/addDraggedItem', {
        itemDropedOn: 'column',
      });
    },
    handleIndexDragOver(index, groupName) {
      if (this.hoveredIndex !== index) {
        this.$store.dispatch('contextManager/updater', {
          hoveredIndex: index,
          activeDragGroup: groupName,
        });
      }
    },
    handleDragToSortStart({ tagGroup, groupName }) {
      this.$store.dispatch('contextManager/updater', {
        itemBeingDragged: { tagGroup, groupName },
      });
    },
  },
};
</script>

<style scoped>
.column-drag-drop-wrapper {
  width: 100%;
  display: flex;
  margin-left: -80px;
}
.column-drag-drop-wrapper .drag-on-me {
  position: absolute;
  top: 0;
  right: 0;
}
.column-labels-wrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  position: relative;
}
.drag-handler-wrapper {
  padding-left: 10px;
}
.items-scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;
  margin-left: 80px;
}
.initial-drag-on {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  margin-left: -20px;
  margin-top: 3px;
  width: 20px;
  display: flex;
  height: 43px;
}
.filter-item-draggable {
  display: flex;
  flex-direction: row;
}
.single-column {
  flex: 0 0 auto;
}
.append-item-side {
  background-color: transparent;
  width: 6px;
  margin: 6px 0px;
  border-radius: 6px;
}
.append-item-side-active {
  background-color: #23125f;
  width: 6px;
  margin: 6px 0px;
  border-radius: 6px;
}
.dragging .append-item-side {
  background-color: #ddd;
}
.column-label-wrapper {
  padding: 10px;
}
</style>
