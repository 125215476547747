<template>
  <div class="row search-result-container">
    <div class="col-md-12" v-if="!showingMore">
      <a
        href="#"
        class="badge search-result-badge"
        v-for="string of showlessSearchStrings"
        :key="'search_' + string"
        @click.prevent="handleEdit(string)"
      >
        {{ string }}
        <span class="clear-search-btn" @click.prevent="(e) => handleClear(e, string)">
          <font-awesome-icon class="clear-icon" icon="times" />
        </span>
      </a>
      <a href="#" class="badge" @click.prevent="showingMore = true" v-if="searchStrings.length > 5"> Show more </a>
    </div>
    <div class="col-md-12" v-if="showingMore">
      <a
        href="#"
        class="badge search-result-badge"
        v-for="string of searchStrings"
        :key="'search_' + string"
        @click.prevent="handleEdit(string)"
      >
        {{ string }}
        <span class="clear-search-btn" @click.prevent="(e) => handleClear(e, string)">
          <font-awesome-icon class="clear-icon" icon="times" />
        </span>
      </a>
      <a href="#" class="badge" @click.prevent="showingMore = false" v-if="searchStrings.length > 5"> Show less </a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showingMore: false,
    };
  },
  computed: {
    searchStrings() {
      return this.$store.state.filterParams.searchString || [];
    },
    showlessSearchStrings() {
      return this.searchStrings.slice(0, 5);
    },
  },
  methods: {
    handleEdit(search) {
      this.$store.dispatch('resource/updater', {
        searchOptions: [],
        searchString: search,
        searchToggleOptions: true,
      });
      this.$store.dispatch('filterParams/removeSearchKeyword', search);
      this.$store.dispatch('resource/getSearchSuggestions');
    },
    handleClear(event, search) {
      event.stopPropagation();
      this.$store.dispatch('filterParams/removeSearchKeyword', search);
    },
  },
};
</script>
<style scoped>
.clear-search-btn {
  padding: 5px;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  display: inline-block;
  top: 0.5rem;
  right: 4px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clear-search-btn:hover {
  background: #6264a7;
  color: #fff;
}
.clear-search-btn .clear-icon {
  font-size: 0.6rem;
}
.team-app-container .search-result-badge {
  padding-right: 1.75rem;
  position: relative;
}
</style>
