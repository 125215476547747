<template>
  <AdminBaseUi title="Fileshare Mapping" create="/manage/filesharemapping/new">
    <div class="fileshare-map-data-table">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell value="Server Location" sortField="serverlocation" />
            <TableHeaderCell value="Local Location" sortField="locallocation" />
            <TableHeaderCell width="70px" />
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow v-for="(row, index) in this.data" :key="index">
            <TableBodyCell :value="row.serverlocation" />
            <TableBodyCell :value="row.locallocation" />
            <TableBodyCell>
              <TableOptions
                :id="row.filesharemappingid"
                identifier="filesharemappingid"
                :endpoint="apiUrls.FILESHARE_OFFICEMAPPING"
                page="manage/filesharemapping"
                deleteModalEntityName="sharepoint office document map"
              />
            </TableBodyCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  </AdminBaseUi>
</template>

<script>
import Table from '@/components/dataTable/Table';
import TableHeader from '@/components/dataTable/TableHeader';
import TableRow from '@/components/dataTable/TableRow';
import TableHeaderCell from '@/components/dataTable/TableHeaderCell';
import TableBody from '@/components/dataTable/TableBody';
import TableBodyCell from '@/components/dataTable/TableBodyCell';
import TableOptions from '@/components/dataTable/TableOptions';
import AdminBaseUi from '@/components/admin/v2/base-ui/AdminBaseUi';
import apiUrls from '../../../../config/apiUrls';

export default {
  components: {
    AdminBaseUi,
    Table,
    TableRow,
    TableBody,
    TableHeader,
    AdminBaseUi,
    TableOptions,
    TableBodyCell,
    TableHeaderCell,
  },
  created() {
    this.$store.dispatch('dataTable/setTable', apiUrls.FILESHARE_OFFICEMAPPING);
    this.$store.dispatch('dataTable/fetch');
  },
  data() {
    return {
      apiUrls: apiUrls,
    };
  },
  computed: {
    response() {
      return this.$store.state.dataTable.data;
    },
    data() {
      const { data } = this.response;
      return (data || []).map((item) => ({
        filesharemappingid: item.filesharemappingid,
        locallocation: item.locallocation,
        serverlocation: item.serverlocation,
      }));
    },
  },
};
</script>

<style scoped>
.fileshare-map-data-table {
  padding: 0px 40px;
}
</style>
