<template>
  <div id="app">
    <Alert></Alert>
    <router-view />
  </div>
</template>

<script>
import Alert from '@/components/Alert';
export default {
  name: 'App',
  components: {
    Alert,
  },
  computed: {
    loginConfig() {
      return this.$store.getters['loginConfig/getConfig'];
    },
  },
  async beforeCreate() {
    await this.$store.dispatch('app/setTeamContext');
    if (!this.loginConfig) {
      await this.$store.dispatch('loginConfig/getConfig');
    }
  },
};
</script>

<style>
html,
body {
  color: #262261;
}
.alert {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 9999;
}
.app-p5 {
  padding: 5px;
}
.admin-form-footer {
  text-align: right;
  border-top: 1px solid #ddd;
  margin: 30px -16px 3px -16px;
  padding: 16px 16px 0px 16px;
}

[role='main'] {
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  [role='main'] {
    margin-left: 250px;
    width: 100%;
  }
}

img.--table-sort-icon {
  width: 18px;
}

.--table-sort-icon-up {
  margin-left: 5px;
  margin-bottom: -5px;
}

.--table-sort-icon-down {
  margin-left: 5px;
  margin-top: -10px;
  margin-bottom: 2px;
}

.--table-sort-icon-active {
  color: #3c7ff3;
}
.btn {
  margin-right: 5px;
  margin-left: 5px;
}
button:disabled,
button[disabled],
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.pointer {
  cursor: pointer;
}

.no-padding {
  padding: 0px !important;
}

.dark-color {
  color: #24125f;
}
.dark-color-hover:hover {
  color: #24125f;
}

.dark-bg {
  background-color: #24125f;
}

.light-bg {
  background-color: #f7f7f7;
}

.light-blue-bg {
  background-color: #5290cc;
}

.fade-on-hover:hover {
  opacity: 0.75;
}

.light-input {
  background-color: #f7f7f7 !important;
  border-radius: 0 !important;
  border: none !important;
}

.pl-40 {
  padding-left: 40px;
}

.pl-50 {
  padding-left: 50px;
}

/* #1732 popper.js tooltip position issue fixed https://github.com/bootstrap-vue/bootstrap-vue/issues/1732 */
.tooltip {
  top: 0;
}

.tooltip .tooltip-inner {
  border-radius: 0;
}

.text-break-all {
  word-break: break-all !important;
  word-wrap: break-word !important;
}
</style>
