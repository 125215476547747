<template>
  <main role="main">
    <div class="responsive-resource-wrapper pt-2 teams-resources-table-wrapper">
      <b-row>
        <b-col>
          <div class="resource-table-body-wrapper">
            <Datagrid
              id="intop-resource-table"
              :height="style.height"
              :tablefields="fieldDefinitions"
              :tabledata="tableData"
              @sortBy="setSortParam"
              :contextColumns="contextColumns"
            >
              <template v-slot:body-name="props">
                <div class="d-flex align-items-center">
                  <div>
                    <img width="20" class="me-3" :src="getFileIconFromMapper(props.data.FileType)" alt="" />
                  </div>
                  <div>
                    <a
                      :href="props.data.openurl ? props.data.openurl : props.data.url"
                      target="_blank"
                      class="intop-table-url me-2"
                      >{{ props.data.name }}</a
                    >
                    <div v-if="isContentExcerptShown" class="intop-table-snippet">
                      {{ props.data.contentexcerpt }}...
                    </div>
                  </div>
                </div>
              </template>

              <template v-slot:body-dropdown-menu="props">
                <div class="float-right dropdown-container">
                  <b-dropdown
                    variant="link"
                    class="resource-item-menu"
                    toggle-class="text-decoration-none"
                    right
                    no-caret
                    :dropup="isDropdownMenuInTheUpperSide(props.index)"
                  >
                    <template #button-content>
                      <i class="fas fa-ellipsis-h"></i>
                    </template>
                    <b-dropdown-item @click="onPreviewClicked(props.data)"> Text preview </b-dropdown-item>
                    <ClickToCopy v-if="props.data.url" :text="props.data.url" />
                  </b-dropdown>
                </div>
              </template>
            </Datagrid>
          </div>
        </b-col>
      </b-row>
      <div class="loader" v-if="loading && !isInfiniteScrollEventActive">
        <i class="fas fa-circle-notch fa-spin spinner"></i>
      </div>
      <div class="infinte-scroll-loader text-center" v-if="loading && isInfiniteScrollEventActive">
        <small>
          <i class="fas fa-circle-notch fa-spin spinner"></i>
          <span class="ms-2">Loading...</span>
        </small>
      </div>
    </div>
    <b-modal v-model="documentPreview" id="documentPreview" size="lg" ok-only ok-title="Close">
      <template class="intop-resource-header-container" v-slot:header>
        <div class="modal-content-wrapper">
          <div class="__text_prev">Text Preview</div>
          <div class="modal-close-btn" @click="hideResourceModal">
            <font-awesome-icon class="close-btn" size="lg" icon="times" />
          </div>
          <div class="document-preview-tags">
            <span v-for="(tag, index) in tagsOfCurrentContext" :key="index">
              <b-badge pill v-if="index < 3 || showAllContexts" variant="primary" class="me-1 intop-resource-badge">{{
                tag.label
              }}</b-badge>
            </span>
            <b-button
              v-if="tagsOfCurrentContext.length > 3"
              class="btn-resource-badge-toggler"
              @click.prevent="toggleAllContexts"
              variant="outline-primary btn-sm"
              >{{ showAllContexts ? 'Show few Tags' : 'Show all Tags' }}</b-button
            >
          </div>
          <h3 class="resource-name">{{ activeResource.name }}</h3>
        </div>
      </template>
      <div class="document-preview-body">
        <p class="card-text">{{ activeResource.fulltext }}</p>
      </div>
    </b-modal>
  </main>
</template>

<script>
import FieldsDef from '@/config/resourcesTableFieldsDef.js';
import { resourceService } from '@/services';
import { generateTableFieldDef, authHeader, getFileIcon } from '@/helpers';
import Datagrid from '@/components/datagrid/Datagrid';
import ClickToCopy from './ClickToCopy';
import _ from 'lodash';

export default {
  name: 'ResourcesTable',
  components: {
    ClickToCopy,
    Datagrid,
  },
  data() {
    return {
      fields: FieldsDef,
      sortOrder: '',
      activeResource: {
        name: '',
        fulltext: '',
        id: 0,
        tags: [],
      },
      showAllContexts: false,
      style: {
        height: '100vh',
      },
      isInfiniteScrollEventActive: false,
      tableData: [],
      documentPreview: false,
    };
  },
  watch: {
    params: {
      handler(val, oldVal) {
        this.getResources();
      },
      deep: true,
    },
    bodyHeight(height) {
      this.style['height'] = height + 'px';
    },
  },
  computed: {
    contextColumns() {
      return this.selectedContextColumns.map((item) => item.label);
    },
    isContentExcerptShown() {
      return this.$store.state.users.displayContentExcerpts;
    },
    fieldDefinitions() {
      const fields = generateTableFieldDef();
      fields.push({
        name: 'dropdown-menu',
        title: `${
          this.paginationData ? `Showing ${this.paginationData.to} of ${this.paginationData.total}` : `Showing 0 of 0`
        }`,
        titleClass: 'resource-count',
        dataClass: 'resource-count',
      });
      return fields;
    },
    params() {
      const { columns, contextid } = this.renderPreview
        ? this.$store.getters['contextManager/demoContext']
        : this.$store.state.contexts.selected;
      const { tags, searchString, modifiedFrom, modifiedBefore } = this.$store.state.filterParams;

      // Collection of filters with its selected tag id's
      const filters = tags.reduce(function (filter, tag) {
        filter[tag.taggroupid] = filter[tag.taggroupid] || [];
        filter[tag.taggroupid].push(tag.tagid);
        return filter;
      }, Object.create(null));

      const { settings } = this.$store.state.app;
      let titleSearch = false;
      if (searchString.length > 0) {
        titleSearch = this.$store.state.app.searchTitleOnly;
      }

      return {
        filters,
        contextid,
        titleSearch,
        modifiedFrom,
        modifiedBefore,
        searchString: searchString || [],
        columns: columns.map((col) => col.taggroupid),
        sortByRelevance: settings.hasOwnProperty('SORT_RESULT_BY_RELEVANCE')
          ? settings.SORT_RESULT_BY_RELEVANCE
          : false,
      };
    },
    paginationData() {
      return this.$store.state.pagination;
    },
    currentPage() {
      return this.$store.state.pagination.currentPage;
    },
    loading() {
      return this.$store.state.pagination.loading;
    },
    isContentExcerptShown() {
      return this.$store.state.users.displayContentExcerpts;
    },
    tagsOfCurrentContext() {
      const activeTagGroups = this.$store.state.contexts.selected.filters.map((f) => f.taggroupid);
      return this.activeResource.tags.filter((vt) => activeTagGroups.includes(vt.taggroupid));
    },
    renderPreview() {
      return this.$store.state.contextManager.renderPreview;
    },
    selectedContext() {
      return this.$store.getters['contexts/getSelected'];
    },
    bodyHeight() {
      return this.$store.state.app.bodyHeight - 15;
    },
    selectedContextColumns() {
      return this.selectedContext.columns;
    },
  },
  mounted() {
    const listElm = document.querySelector('#intop-resource-table');
    listElm.addEventListener('scroll', _.debounce(this.handleScroll, 100));
  },
  methods: {
    handleScroll() {
      const listElm = document.querySelector('#intop-resource-table');
      if (listElm.scrollTop + listElm.clientHeight + 200 >= listElm.scrollHeight && !this.isInfiniteScrollEventActive) {
        this.isInfiniteScrollEventActive = true;
        if (this.paginationData && this.currentPage < this.paginationData.lastPage) {
          this.getResources(+this.currentPage + 1);
        } else {
          if (this.isInfiniteScrollEventActive) {
            this.isInfiniteScrollEventActive = false;
          }
        }
      }
    },
    keepColumnItemsBasedOnSelectedFilterItems(cell) {
      const { taggroupid, label, value } = cell;
      const filteringColumn = this.$store.state.filterParams.tags.find((t) => t.taggroupid === taggroupid);
      if (filteringColumn) {
        const isAccepted = this.$store.state.filterParams.tags.filter(
          (t) => t.taggroupid === taggroupid && t.label.trim() === value.trim()
        );
        return isAccepted.length > 0;
      }
      return true;
    },
    getResources(page = 1) {
      const params = { ...this.params, page };
      if (this.sortOrder) {
        params['sort'] = this.sortOrder;
      }
      this.onLoading();
      resourceService
        .get(params)
        .then((res) => {
          this.onPaginationData(res);
          this.numberOfResourcesInThisPage = res.data.length;
          // Flatten the columns property /into keys on the row itself
          const data = res.data.map((d) => {
            let multiCols = d.filtercolumns;
            if (multiCols) {
              const multiColsGrouped = multiCols
              /*
               * Disable #774 for now -  Filter out the columns that are not selected in the filter
               *
                .filter((cell) => {
                  const keepCell = this.keepColumnItemsBasedOnSelectedFilterItems(cell);
                  return keepCell ? cell : false;
                })
               */
                .reduce((item, col) => {
                  item[col.label] = item[col.label] || [];
                  item[col.label].push({
                    contextcolumn: col.value,
                    label: col.label,
                  });
                  return item;
                }, Object.create(null));

              Object.keys(multiColsGrouped).map((i) => {
                const labels = multiColsGrouped[i].map((mc) => mc.contextcolumn);
                d[i] = i === 'FileType' ? labels[0] : labels;
              });
            }
            delete d.columns;
            return d;
          });

          delete res.data;
          res.data = data;

          if (res.current_page === 1) {
            document.querySelector('#intop-resource-table').scrollTo(0, 0);
          }

          this.$store.dispatch('resource/setResources', { ...res });
          const resources = this.$store.getters['resource/getResources'];
          let allResourcesData = [];
          for (let i of Object.keys(resources)) {
            allResourcesData = [...allResourcesData, ...resources[i].data];
          }
          this.tableData = allResourcesData;
          this.onLoaded();
          if (this.isInfiniteScrollEventActive) {
            this.isInfiniteScrollEventActive = false;
          }
          this.$nextTick(() => {
            this.style['height'] = this.bodyHeight + 'px';
          });
        })
        .catch((error) => {
          this.onLoaded();
          if (this.isInfiniteScrollEventActive) {
            this.isInfiniteScrollEventActive = false;
          }
        });
    },
    onPaginationData(paginationData) {
      this.$store.dispatch('pagination/setPaginationData', paginationData);
    },
    onLoading() {
      this.$store.dispatch('pagination/setLoading', true);
    },
    onLoaded() {
      this.$store.dispatch('pagination/setLoading', false);
    },
    onPreviewClicked(rowData) {
      this.activeResource = {
        name: '',
        fulltext: '',
        id: 0,
        tags: [],
      };

      resourceService.getOne(rowData.resourceid).then((result) => {
        this.activeResource = {
          ...result.data,
          fulltext: result.data.fulltext ? result.data.fulltext : '',
        };
      });
      this.showAllContexts = false;
      this.documentPreview = true;
    },
    shortenUrl(str, maxLength) {
      if (!!str) {
        if (str.length <= maxLength) {
          return str;
        }
        const reg = new RegExp('.{1,' + maxLength + '}', 'g');
        let parts = str.match(reg);
        parts = parts.map((v) => `${v.trim()}</br>`);
        return parts.join('').trim();
      } else {
        return '';
      }
    },
    toggleAllContexts() {
      this.showAllContexts = !this.showAllContexts;
    },
    handleToggleExcerpt() {
      this.$store.dispatch('users/controlContentExcerpts');
    },
    hideResourceModal() {
      this.documentPreview = false;
    },
    getFileIconFromMapper(filetype) {
      try {
        return require('@/assets/' + getFileIcon(filetype));
      } catch (e) {
        return '';
      }
    },
    setSortParam(sortOrder) {
      this.sortOrder = sortOrder.field + '|' + sortOrder.order;
      this.getResources();
    },
    isDropdownMenuInTheUpperSide(rowIndex) {
      return this.numberOfResourcesInThisPage > 1 && rowIndex + 1 === this.numberOfResourcesInThisPage;
    },
  },
  created() {
    this.getResources();
  },
};
</script>
<style lang="scss">
.team-app-container {
  .intop-datepicker {
    width: 100% !important;
  }

  .loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.6);
  }
  .loader .spinner {
    font-size: 100px;
    color: #6264a7;
  }
  .infinte-scroll-loader {
    color: #242424;
    font-weight: 100;
    position: absolute;
    bottom: 0;
    width: 82%;
    background: #f5f5f5;
  }

  @media only screen and (max-width: 1600px) {
    .responsive-resource-wrapper * {
      font-size: 0.9rem;
    }
  }
}
</style>

<style lang="scss">
.teams-resources-table-wrapper {
  .datagrid {
    .datagrid-item {
      padding: 0.85rem 1rem;
    }
    .datagrid-head-item {
      color: #242424;
      border-bottom: 2px solid #f0f0f0;
      font-weight: 700;
    }
    .datagrid-head-item.resource-name {
      padding-left: calc(20px + 2.25rem);
    }
    .datagrid-head-item .sort-link {
      color: #242424;
    }
    .resource-count {
      display: flex;
      justify-content: flex-end;
      text-align: right;
    }
    .resource-count .dropdown-container .dropdown-toggle {
      padding: 2px;
      color: #242424;
      font-weight: 400;
    }
    .intop-table-url {
      color: #242424;
    }
  }
}
</style>
