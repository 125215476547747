export default [
  // {
  //   name: 'actions',
  //   title: '',
  //   width: '15px',
  // },
  {
    name: 'name',
    title: 'Name',
    sortField: 'name',
    width: '*',
  },
  {
    name: 'modifieddate',
    title: 'Last modified',
    sortField: 'modifieddate',
    width: '180px',
    formatter: (val) => {
      if (!val) {
        return '';
      }
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date(val);
      return date.toLocaleDateString('en-US', options);
    },
  },
];
