<template>
  <div>
    <div v-if="options.length > 0">
      <Multiselect
        v-if="options"
        :key="componentId"
        :title="filter.name"
        :multiselect="true"
        :items="options"
        inputLabelKey="label"
        inputValueKey="tagid"
        :propSelected="selectedOptions"
        :itemlistvisible="false"
        accordion="filter-accordion"
        @select="handleSelect"
      />
    </div>
  </div>
</template>

<script>
import Multiselect from './Multiselect';
import { filterService } from '@/services';

export default {
  name: 'FilterBox',
  props: {
    filter: {},
    showInlineFilters: false,
    oldStyle: false,
    updateBookmarkUrl: true,
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      selectedFilterValues: [],
      options: [],
      isLoading: false,
    };
  },
  methods: {
    async asyncFind(search, filterName) {
      this.isLoading = true;

      // fiterName comes in search param when search param is undefined
      search = search && search !== this.componentId ? search : '';

      // replace with getValuesWithoutCounts to turn on resource count per tag
      const data = await filterService.getValuesWithoutCounts(this.filter.taggroupid, {
        search,
        taggroupid: this.filter.taggroupid,
      });

      const activeTags = this.$store.state.filterParams.tags.filter((t) => t.filterid == this.filter.filterid);

      const updatedOptions = data.data.map((el) => {
        const selected = activeTags.find((t) => t.tagid == el.tagid) ? true : false;
        return {
          ...el,
          selected,
        };
      });
      this.options = updatedOptions;
      this.isLoading = false;
    },
    handleSelect(val) {
      if (val.length > this.selectedOptions.length) {
        const selectedElement = val.find((item) => !this.selectedOptions.includes(item.tagid));
        this.$store.dispatch('filterParams/updateTag', {
          ...selectedElement,
          count: selectedElement.count,
          updateBookmarkUrl: this.updateBookmarkUrl,
        });
        this.$store.dispatch('filterParams/handleMainViewOnFilterChange');
      } else {
        const selectedElements = val.map((item) => item.tagid);
        const removedElement = this.selectedOptions.find((item) => !selectedElements.includes(item));
        this.$store.dispatch('filterParams/removeTag', {
          tagid: removedElement,
          updateBookmarkUrl: this.updateBookmarkUrl,
        });
      }
    },
    removeValue(val) {
      this.$store.dispatch('filterParams/removeTag', {
        tagid: val.tagid,
        updateBookmarkUrl: this.updateBookmarkUrl,
      });
    },
  },
  computed: {
    componentId() {
      return `filter${this.filter.taggroupid}`;
    },
    selectedOptions() {
      const tags = this.$store.state.filterParams.tags;
      return tags.filter((t) => t.taggroupid === this.filter.taggroupid).map((tag) => tag.tagid);
    },
  },
  mounted() {
    this.asyncFind('');
  },
};
</script>
