<template>
  <div class="tags">
    <div class="label-wrapper">
      <div class="_label">Tags</div>
      <div class="searcharea">
        <SearchForm name="Tags" />
      </div>
    </div>
    <div class="card-containers">
      <drag
        v-for="(tag, index) in tags"
        :key="index"
        :transfer-data="tag"
        @dragstart="dragging = true"
        @dragend="dragging = null"
      >
        <drop
          @drop="handleTagDrop(tag.tagid, $event)"
          class="drop"
          :key="index + 't'"
          :id="'tag' + tag.tagid"
          @dragover="handleDragover(tag.tagid, $event)"
          @dragleave="handleDragleave(tag.tagid, $event)"
        >
          <ImCard
            :label="tag.label"
            :key="index + 'tcard'"
            :variant="!tag.parenttagid ? 'orange' : 'stripe'"
            :fill="false"
            name="tags"
            :item="tag"
            :isStickyCard="tag.isSticky ? tag.isSticky : false"
            :shiftClickActionHandler="(tagid) => handleStickyClick(tagid)"
          >
          </ImCard>
        </drop>
      </drag>
      <ImPagination name="tags" />
      <ImAddButton name="tags" />
    </div>
  </div>
</template>
<script>
import ImCard from './ImCard';
import ImAddButton from './ImAddButton';
import SearchForm from './forms/SearchForm';
import ImPagination from './ImPagination';
import { genericService } from '@/services';
import apiUrls from '@/config/apiUrls';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { Drop, Drag } from '@/components/dragDrop';

export default {
  components: {
    ImCard,
    ImAddButton,
    SearchForm,
    ImPagination,
    Drag,
    Drop,
  },
  data() {
    return {
      dragging: null,
    };
  },
  created() {
    this.$store.dispatch('imStructure/updateTag');
  },
  computed: {
    ...mapGetters({
      tags: 'entities/getTags',
    }),
  },
  methods: {
    async handleTagDrop(targetTagid, data) {
      const srcTag = { ...data };
      const srcTagid = srcTag.tagid;
      this.removeDragEffect(targetTagid);
      if (srcTagid === targetTagid) {
        this.$store.dispatch('alert/error', `Can not add same tag as tag as pattern`);
        return;
      }
      await this.createTagAsPattern(srcTagid, targetTagid);
    },
    async createTagAsPattern(sourceTagid, targetTagid) {
      this.$store.dispatch('loader/setLoadingStatus', true);
      const model = await genericService.create(apiUrls.DROP_TAGASPATTERN);
      model({
        sourceTagid: sourceTagid,
        targetTagid: targetTagid,
      })
        .then((r) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('imStructure/updateTag');
          this.$store.dispatch('entities/fetchStickyTagAndTagGroups');
          this.$store.dispatch('alert/successWithTimer', {
            message: `Successfully added as a tag as pattern`,
            seconds: 2,
          });
        })
        .catch((e) => {
          this.$store.dispatch('alert/error', e || `Unable to add tag as pattern, please try again!`);
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
    handleDragover(tagid, data) {
      this.addDragEffect(tagid);
    },
    handleDragleave(tagid, data) {
      this.removeDragEffect(tagid);
    },
    addDragEffect(tagid) {
      document.getElementById(`tag${tagid}`).classList.add('over');
    },
    removeDragEffect(tagid) {
      document.getElementById(`tag${tagid}`).classList.remove('over');
    },
    handleStickyClick(tagid) {
      let tag = _.cloneDeep(this.tags.find((item) => item.tagid === tagid));
      if (tag.isSticky === true) {
        this.$store.dispatch('entities/removeStickyTag', tag);
      } else {
        tag.isSticky = true;
        this.$store.dispatch('entities/updateStickyTag', tag);
      }
    },
  },
};
</script>

<style scoped>
.tags {
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

._label {
  font-size: 14px;
  line-height: 26px;
  color: #688197;
  margin-right: 30px;
}

.card-containers {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.label-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.drop.over {
  background-color: red;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
}
</style>
