<template>
  <div class="search-container">
    <div class="row">
      <div class="col-7 title-only-search">
        <b-form-checkbox v-model="titleSearch" name="check-button" switch> TITLE ONLY </b-form-checkbox>
      </div>
      <div class="col-5 clear-search">
        <a href="#" @click.prevent="handleClear">CLEAR ALL</a>
      </div>
    </div>
    <div class="row search-input-container">
      <div class="col position-relative">
        <form @submit.prevent="handleResourceSearch" v-click-outside="clickedOutside">
          <b-form-input
            v-model="searchKeyword"
            placeholder="Search"
            @input="debounceHandleSearch"
            class="input-resource-search"
            ref="fetchSearchField"
            autocomplete="off"
          />
          <button type="submit" class="btn p-0 mb-3 search-icon-container">
            <font-awesome-icon size="lg" class="search-icon" icon="search" />
          </button>
          <SearchOptions v-if="showOptions" :handleResourceSearch="handleResourceSearch" />
        </form>
      </div>
    </div>
    <Searched :handleResourceSearch="handleResourceSearch" />
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import Searched from './Searched';
import SearchOptions from './SearchOptions';

export default {
  components: { Searched, SearchOptions },
  data() {
    return {
      options: [],
    };
  },
  computed: {
    titleSearch: {
      get() {
        return this.$store.state.app.searchTitleOnly;
      },
      set: function (titleSearch) {
        this.$store.dispatch('app/setSearchByTitle', titleSearch);
      },
    },
    searchKeyword: {
      set(searchString) {
        this.$store.dispatch('resource/updater', {
          searchString,
        });
        if (searchString.trim() === '') {
          this.$store.dispatch('resource/updater', {
            searchOptions: [],
            searchSpellingError: '',
          });
        }
      },
      get() {
        return this.$store.state.resource.searchString;
      },
    },
    showOptions() {
      return this.$store.state.resource.searchToggleOptions;
    },
    showResetIcon() {
      return !!this.searchKeyword;
    },
  },
  methods: {
    handleSearch() {
      this.$store.dispatch('resource/getSearchSuggestions');
      if (!this.showOptions && this.searchKeyword) {
        this.$store.dispatch('resource/updater', {
          searchToggleOptions: true,
        });
      }
    },
    handleResourceSearch() {
      this.$store.dispatch('filterParams/searchString', this.searchKeyword);
      this.$store.dispatch('resource/updater', {
        searchToggleOptions: false,
        searchString: '',
        searchOptions: [],
        searchSpellingError: '',
      });
      this.$refs.fetchSearchField.blur();
    },
    handleClear() {
      // this.searchKeyword = "";
      // this.$store.dispatch("resource/updater", {
      //   searchToggleOptions: false,
      // });
      this.$store.dispatch('filterParams/resetFilters');
    },
    clickedOutside() {
      this.$store.dispatch('resource/smartToggleOptions');
    },
  },
  created() {
    this.debounceHandleSearch = debounce(this.handleSearch, 600);
  },
};
</script>

<style scoped>
.search-container {
  font-size: 0.85rem;
  padding: 0 1.5rem;
}
.clear-search {
  text-align: right;
  vertical-align: middle;
}
.clear-search a {
  color: #6264a7;
  text-decoration: none;
  display: inline-block;
  padding-top: 2px;
}
.search-input-container {
  margin-top: 10px;
  margin-bottom: 15px;
}
.input-resource-search {
  border-radius: 0;
  border-color: #fff;
  font-size: 14px;
}
.input-resource-search::placeholder {
  color: #484644;
}
.search-icon-container {
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 25px;
  background: #fff;
  color: #484644;
  padding-left: 0.5rem !important;
}
.btn.focus,
.btn:focus {
  box-shadow: none;
}
</style>
<style>
.title-only-search .custom-control-label {
  padding-top: 6px;
  cursor: pointer;
  font-size: 11px;
}
</style>
