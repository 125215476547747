import * as msal from '@azure/msal-browser';

function msalInstance(clientId, tenantId) {
  const redirectUri = `${window.location.origin}/callback/`;

  const msalConfig = {
    auth: {
      clientId,
      authority: `https://login.microsoftonline.com/${tenantId}/`,
      redirectUri,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
  };

  const msalObj = new msal.PublicClientApplication(msalConfig);

  return msalObj;
}

export const azureSSO = {
  msalInstance,
};
