<template>
  <div class="mb-5" v-if="filetypeData && chartData && chartData.labels.length > 0">
    <h6 class="title mb-3">Filetype</h6>
    <div class="row">
      <div class="col-5">
        <PieChart v-if="chartData && isLoaded" :chartData="chartData" />
      </div>
      <div class="col-6 flex-row align-items-left mt-4">
        <PieChartLegend :chartData="legendData" :colors="colors" />
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from './PieChart';
import PieChartLegend from './PieChartLegend';
import { getColors } from '../../../../helpers/chartColors';

export default {
  name: 'FiletypeChart',
  props: ['filetypeData'],
  components: {
    PieChart,
    PieChartLegend,
  },
  data() {
    return {
      isLoaded: true,
    };
  },
  watch: {
    filetypeData: {
      handler() {
        this.isLoaded = false;
        this.$nextTick(() => {
          this.isLoaded = true;
        });
      },
      deep: true,
    },
  },
  computed: {
    legendData() {
      const legendData = {};
      for (let label in this.chartData.labels) {
        legendData[this.chartData.labels[label]] = this.chartData.datasets[0].data[label] + ' files';
      }
      return legendData;
    },
    colors() {
      if (this.chartData) {
        return this.chartData.datasets[0].backgroundColor;
      }
      return [];
    },
    chartData() {
      if (this.filetypeData) {
        const labels = this.filetypeData.map((item) => item.filetype);
        const data = this.filetypeData.map((item) => item.count);
        const colors = getColors(labels.length);

        return {
          labels: labels,
          datasets: [
            {
              data: data,
              borderWidth: 0,
              backgroundColor: colors,
              hoverBackgroundColor: colors,
            },
          ],
        };
      }
      return null;
    },
  },
};
</script>
