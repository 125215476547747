<template>
  <div :class="className">
    <span v-if="lineBreak" class="label label-multiline">
      <div>Drag &amp; Drop</div>
      <div>To add or rearrange {{ this.entity }}</div>
    </span>
    <span v-else class="label">Drag &amp; drop to add or rearrange {{ this.entity }}</span>
  </div>
</template>

<script>
export default {
  props: {
    active: false,
    entity: '',
    lineBreak: false,
  },
  computed: {
    className() {
      let _class = 'dragdrop-container';
      if (this.active) _class += ' entered';
      if (this.lineBreak) _class += ' sm';
      else _class += ' lg';
      return _class;
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.dragdrop-container {
  border: 2px dashed #688197;
  text-align: center;
  padding: 10px 20px;
}
.lg {
  min-width: 300px;
}
.sm {
  min-width: 230px;
}
.label {
  color: #688197;
  font-size: 14px;
}
.entered {
  border-color: #23125f;
}
.label-multiline {
  line-height: 10px;
}
.label-multiline div:first-child {
  padding-bottom: 5px;
}
</style>
