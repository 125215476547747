<template>
  <div>
    <span>{{ isShowingMore ? expandableValue : defaultValue }}</span>
    <template v-if="expandableValue">
      <a href="#" @click.prevent="isShowingMore = !isShowingMore"> ({{ isShowingMore ? 'less' : 'more' }}) </a>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ResourceTableExpandableSnippet',
  props: ['values'],
  data() {
    return {
      defaultValue: '',
      expandableValue: '',
      isShowingMore: false,
    };
  },
  methods: {
    setExpandableSnippet() {
      if (this.values) {
        if (typeof this.values === 'object') {
          const data = this.values.join(', ');
          const snippet = data.replace(/^(.{90}[^\s]*).*/, '$1').replace(/,+$/, '');

          if (data.length >= 90 && data.length !== snippet.length) {
            this.defaultValue = snippet;
            this.expandableValue = data;
          } else {
            this.defaultValue = data;
          }
        } else {
          this.defaultValue = this.values;
        }
      }
    },
  },
  mounted() {
    this.setExpandableSnippet();
  },
};
</script>
