<template>
  <div class="container-fluid team-app-container">
    <div class="row overflow-hidden">
      <div
        id="team-sidebar-container"
        class="col-md-2 col-sm-3 border-right team-app-height px-0 scroll"
        :style="style"
      >
        <Sidebar />
      </div>
      <div class="col-md-10 col-sm-9 pr-md-0">
        <ResourcesTable />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/teams/Sidebar';
import ResourcesTable from '@/components/teams/ResourcesTable';

export default {
  components: {
    Sidebar,
    ResourcesTable,
  },
  data() {
    return {
      style: {
        height: '100vh',
      },
    };
  },
  methods: {
    resizeIntOpUniverseHeight() {
      this.$store.dispatch('app/calculateBody', 'team-app-container');
    },
  },
  computed: {
    bodyHeight() {
      return this.$store.state.app.bodyHeight;
    },
  },
  watch: {
    bodyHeight(height) {
      this.style['height'] = height - 5 + 'px';
    },
  },
  created() {
    window.addEventListener('resize', this.resizeIntOpUniverseHeight);
  },
  mounted() {
    this.resizeIntOpUniverseHeight();
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeIntOpUniverseHeight);
  },
};
</script>

<style scoped>
.team-app-container {
  background-color: #f5f5f5;
}
</style>
<style>
.team-app-container * {
  font-family: 'Segoe UI', system-ui, 'Apple Color Emoji', 'Segoe UI Emoji', sans-serif !important;
}
.team-app-container .btn.focus,
.team-app-container .btn:focus {
  box-shadow: none;
}
.team-app-container .resource-search-options-wrapper {
  z-index: 2;
  margin-left: 15px;
  margin-top: 40px;
}
.team-app-container .badge {
  font-weight: 300;
  border-radius: 0;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 0.5em 1em;
  color: #6264a7;
}
.team-app-container .search-result-badge {
  background-color: #e9eaf6;
  color: #000000;
  font-size: 0.85rem;
  line-height: 1.2;
}
.team-app-container .search-badge {
  background-color: #6264a7;
  color: #fff;
}
.scroll {
  overflow: auto;
}
</style>
