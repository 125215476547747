import config from '../config/config';
import apiUrls from '../config/apiUrls';
import { authHeader, handleApiResponse } from '../helpers';

function post(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}${apiUrls.BACKEND_SERVICE}`, requestOptions).then(handleApiResponse);
}

export const backendService = {
  post,
};
