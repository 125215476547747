import { filterService } from '@/services';

export const resource = {
  namespaced: true,
  state: {
    searchString: '',
    searchLoading: '',
    searchToggleOptions: false,
    searchSpellingError: '',
    searchOptions: [],
    resources: {},
  },
  getters: {
    getResources(state) {
      return state.resources;
    },
  },
  actions: {
    updater({ commit }, nextState) {
      if (typeof nextState === 'object' && !nextState.length && nextState !== null) {
        commit('updateState', nextState);
      } else {
        console.error('nextState needs to be an object: resource.module.js');
      }
    },
    async getSearchSuggestions({ commit, state, rootState, dispatch }) {
      const { searchString } = state;
      if (!searchString.trim()) return;
      const tagGroups = rootState.contexts.selected.filters.map((f) => f.taggroupid);
      commit('updateState', { searchLoading: true });
      if (tagGroups.length) {
        const { data, hasSpellingError, spelled } = await filterService.getValues(tagGroups, searchString.trim());
        const searchSpellingError = hasSpellingError ? spelled : '';
        commit('updateState', {
          searchOptions: data,
          searchLoading: false,
          searchSpellingError,
        });
      } else {
        commit('updateState', { searchLoading: false });
      }
    },
    smartToggleOptions({ commit }) {
      commit('updateState', {
        searchToggleOptions: false,
      });
    },
    setResources({ commit }, resources) {
      commit('setResources', resources);
    },
  },
  mutations: {
    updateState(state, nextState) {
      for (const index in nextState) {
        state[index] = nextState[index];
      }
    },
    setResources(state, resources) {
      if (resources.current_page === 1) {
        state.resources = {};
      }
      state.resources[resources.current_page] = resources;
    },
  },
};
