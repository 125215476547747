<template>
  <div class="base-paginator-wrapper" v-if="totalPage > 1">
    <div class="paginator-info">Showing {{ this.from }} - {{ this.to }} of {{ this.total }}</div>
    <b-button-group :class="this.loading ? 'intop-pagination disabled-pagination' : 'intop-pagination'">
      <b-button
        :class="`pagbtn nav-btn ${currentPage <= 1 && ' disabled'}`"
        variant="default"
        @click="paginate(1, false)"
        title="Go to first page"
      >
        <font-awesome-icon size="xs" icon="angle-double-left" />
      </b-button>
      <b-button
        :class="`pagbtn nav-btn ${currentPage <= 1 && ' disabled'}`"
        variant="default"
        @click="paginate(currentPage - 1, false)"
        title="Go to previous page"
      >
        <font-awesome-icon icon="chevron-left" />
      </b-button>
      <b-button
        :variant="getVariant(n)"
        v-for="n in maxWindowSize"
        :key="n"
        @click="paginate(n)"
        class="paginator-counts pagbtn"
        >{{ getPageNum(n) }}</b-button
      >
      <b-button
        :class="`pagbtn nav-btn ${currentPage >= totalPage && ' disabled'}`"
        variant="default"
        @click="paginate(currentPage + 1, false)"
        title="Go to next page"
      >
        <font-awesome-icon icon="chevron-right" />
      </b-button>
      <b-button
        :class="`pagbtn nav-btn ${currentPage >= totalPage && ' disabled'}`"
        variant="default"
        @click="paginate(totalPage, false)"
        title="Go to last page"
      >
        <font-awesome-icon size="xs" icon="angle-double-right" />
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  data() {
    return {
      onEachSide: 2,
    };
  },
  computed: {
    perPage() {
      return this.$store.state.dataTable.data.per_page;
    },
    currentPage() {
      return this.$store.state.dataTable.data.current_page;
    },
    totalPage() {
      return this.$store.state.dataTable.data.last_page;
    },
    from() {
      return this.$store.state.dataTable.data.from + 1;
    },
    to() {
      return this.$store.state.dataTable.data.to;
    },
    total() {
      return this.$store.state.dataTable.data.total;
    },
    hasOffset() {
      return this.totalPage <= this.onEachSide * 2;
    },
    maxWindowSize() {
      return this.totalPage === 1 ? 1 : Math.min(this.totalPage, this.windowSize);
    },
    windowSize() {
      if (this.hasOffset) {
        return this.totalPage;
      } else {
        return this.onEachSide * 2 + 1;
      }
    },
    windowStart() {
      if (this.hasOffset) {
        return 1;
      }
      if (this.currentPage <= this.onEachSide) {
        return 1;
      } else if (this.currentPage >= this.totalPage - this.onEachSide) {
        return this.totalPage - this.onEachSide * 2;
      }
      return this.currentPage - this.onEachSide;
    },
    loading() {
      return false;
    },
  },
  methods: {
    getVariant(num) {
      return this.getPageNum(num) === this.currentPage ? 'active-paginated' : 'default';
    },
    getPageNum(num) {
      return this.windowStart - 1 + num;
    },
    paginate(page, relative = true) {
      if (relative) {
        page = this.getPageNum(page);
      }
      page = Math.max(1, Math.min(this.totalPage, page));
      this.$store.dispatch('dataTable/setPage', page);
      document.querySelector('.base-ui-body').scrollTop = 0;
    },
  },
};
</script>

<style scoped>
.intop-pagination .btn-default {
  background-color: transparent;
}

.paginator-info {
  font-size: 15px;
  color: #29235c;
  padding-right: 5px;
}

.intop-pagination {
  box-shadow: none;
  border-radius: none;
}

.intop-pagination .btn {
  box-shadow: none;
}

.disabled-pagination {
  opacity: 0.7;
  pointer-events: none;
}
.pagbtn {
  padding: 0px 6px;
}
.intop-pagination .btn-default {
  color: #999;
}
.btn-active-paginated {
  font-weight: bold;
  color: #262261;
}
.intop-pagination .nav-btn {
  color: #262261;
  border-radius: 0px;
  padding: 0px 3px;
}
.base-paginator-wrapper {
  padding-bottom: 10px;
  padding-right: 35px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
