<template>
  <div class="searchsettingswrapper">
    <div class="sstitle">TITLE ONLY</div>
    <div class="ms-3">
      <b-form-checkbox @update:modelValue="handleToggle" :checked="titleSearch" switch size="lg" />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    titleSearch() {
      return this.$store.state.app.searchTitleOnly;
    },
  },
  methods: {
    handleToggle(titleSearch) {
      this.$store.dispatch('app/setSearchByTitle', titleSearch);
    },
  },
};
</script>

<style scoped>
.searchsettingswrapper {
  border-right: 1px solid rgb(194, 194, 194);
  padding-right: 8px;
}
.sstitle {
  color: #262261;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 20px;
  padding-left: 15px;
  padding-top: 5px;
  padding-right: 10px;
}
</style>
