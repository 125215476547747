<template>
  <div class="clickcopywrapper">
    <b-dropdown-item href="#" @click.prevent="copyText"> Copy link </b-dropdown-item>
  </div>
</template>

<script>
const initialToolTip = 'Click to copy URL to clipboard';
const successTooltip = 'Copied!';
export default {
  props: ['text'],
  data: function () {
    return {
      tooltip: initialToolTip,
    };
  },
  methods: {
    copyText() {
      const _inputField = document.createElement('textarea');
      _inputField.innerText = this.text;
      document.body.appendChild(_inputField);
      _inputField.select();
      _inputField.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.tooltip = successTooltip;
      _inputField.remove();
      setTimeout(() => {
        this.tooltip = initialToolTip;
      }, 2000);
    },
  },
};
</script>
