import { entityService, genericService } from '@/services';
import _ from 'lodash';
import apiUrls from '@/config/apiUrls';

export const entities = {
  namespaced: true,
  state: {
    dimensions: [],
    areasOfInterests: [],
    tagGroups: [],
    tags: [],
    lookupfields: [],
    stickyTagGroups: [],
    stickyTags: [],
  },
  getters: {
    getTagGroups(state) {
      if (state.stickyTagGroups.length > 0) {
        return _.unionBy(state.stickyTagGroups, state.tagGroups.data, 'taggroupid');
      }
      return state.tagGroups.data || [];
    },
    getTags(state) {
      if (state.stickyTags.length > 0) {
        state.stickyTags = state.stickyTags.map((item) => {
          if (state.tags.data) {
            const i = state.tags.data.findIndex((_element) => _element.tagid === item.tagid);
            if (i > -1) {
              return { ...item, ...state.tags.data[i] };
            }
            return item;
          }
          return item;
        });
        return _.unionBy(state.stickyTags, state.tags.data, 'tagid');
      }
      return state.tags.data || [];
    },
  },
  actions: {
    async fetchList({ dispatch }, endpoints) {
      endpoints.map(async (el) => {
        const { url, entity } = el;
        const fetchedData = await entityService.index({
          dataType: url,
        });
        dispatch('updater', {
          [entity]: fetchedData,
        });
      });
    },
    async updateStickytagGroup({ commit, dispatch }, payload) {
      commit('updateStickyTagGroup', payload);
      await dispatch('fetchStickyTagAndTagGroups');
    },
    async removeStickyTagGroup({ commit, dispatch }, payload) {
      commit('removeItemFromStickyTagGroup', payload);
      await dispatch('fetchStickyTagAndTagGroups');
    },
    async updateStickyTag({ commit, dispatch }, payload) {
      commit('updateStickyTags', payload);
      await dispatch('fetchStickyTagAndTagGroups');
    },
    async removeStickyTag({ commit, dispatch }, payload) {
      commit('removeItemFromStickyTags', payload);
      await dispatch('fetchStickyTagAndTagGroups');
    },
    async fetchStickyTagAndTagGroups({ state }) {
      const tagToSearch = state.stickyTags.map((item) => item.tagid).join(',');
      if (tagToSearch) {
        const fetchedTag = await entityService.index({
          dataType: apiUrls.TAG_LIST,
          options: {
            get_in: tagToSearch,
          },
        });
        state.stickyTags = fetchedTag.data;
        state.stickyTags = state.stickyTags.map((item) => {
          return {
            ...item,
            isSticky: true,
          };
        });
      } else {
        state.stickyTags = [];
      }

      const tagGroupToSearch = state.stickyTagGroups.map((item) => item.taggroupid).join(',');
      if (tagGroupToSearch) {
        const fetchedTagGroup = await entityService.index({
          dataType: apiUrls.TAGGROUP_LIST,
          options: {
            get_in: tagGroupToSearch,
          },
        });
        state.stickyTagGroups = fetchedTagGroup.data;
        state.stickyTagGroups = state.stickyTagGroups.map((item) => {
          return {
            ...item,
            isSticky: true,
          };
        });
      } else {
        state.stickyTagGroups = [];
      }
    },
    updater({ commit }, nextState) {
      if (typeof nextState === 'object' && !nextState.length && nextState !== null) {
        commit('updateState', nextState);
      } else {
        console.error('nextState needs to be an object: entities.module.js');
      }
    },
    merger({ commit }, nextState) {
      if (typeof nextState === 'object' && !nextState.length && nextState !== null) {
        commit('mergeState', nextState);
      } else {
        console.error('nextState needs to be an object: entities.module.js');
      }
    },
  },
  mutations: {
    updateState(state, nextState) {
      for (const index in nextState) {
        state[index] = nextState[index] ? nextState[index] : [];
      }
    },
    mergeState(state, nextState) {
      for (const stateName in nextState) {
        const { data = [] } = state[stateName];
        const mergedRows = [...data, ...nextState[stateName]['data']];
        state[stateName] = {
          ...nextState[stateName],
          data: mergedRows,
        };
      }
    },
    updateStickyTagGroup(state, tagGroup) {
      const i = state.stickyTagGroups.findIndex((_element) => _element.taggroupid === tagGroup.taggroupid);
      if (i > -1) state.stickyTagGroups[i] = tagGroup;
      else state.stickyTagGroups.push(tagGroup);
    },
    removeItemFromStickyTagGroup(state, taggroupid) {
      state.stickyTagGroups = state.stickyTagGroups.filter((item) => item.taggroupid !== taggroupid);
    },
    updateStickyTags(state, tag) {
      const i = state.stickyTags.findIndex((_element) => _element.tagid === tag.tagid);
      if (i > -1) state.stickyTags[i] = tag;
      else state.stickyTags.push(tag);
    },
    removeItemFromStickyTags(state, element) {
      state.stickyTags = state.stickyTags.filter((item) => item.tagid !== element.tagid);
    },
  },
};
