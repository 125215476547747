<template>
  <div class="checkbox-wrapper">
    <div
      :class="`intop-checkbox ${this.isEnabled ? 'intop-checkbox-checked' : 'intop-checkbox-unchecked'}
        
        ${this.disabled && ' disabled'}`"
      @click.prevent="handleChange"
    >
      <div v-if="this.isEnabled" :class="`${this.modelValue !== undefined && 'checkbox-clickable'}`">
        <img src="@/assets/tick-white.svg" />
      </div>
    </div>
    <div
      :class="`${this.modelValue !== undefined && 'checkbox-clickable'}`"
      @click.prevent="handleChange"
      class="checkbox-label"
      v-if="this.label"
    >
      {{ this.label }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isChecked: false,
    };
  },
  emits: ['change', 'update:modelValue'],
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      default: undefined,
    },
    disabled: {
      default: false,
    },
  },
  created() {
    this.isChecked = this.checked;
    if (this.modelValue) {
      this.isChecked = this.modelValue;
    }
  },
  computed: {
    isEnabled() {
      return !!this.checked || !!this.modelValue;
    },
  },
  methods: {
    handleChange() {
      if (this.disabled) return;
      if (this.modelValue !== undefined) {
        this.isChecked = !this.modelValue;
        this.$emit('update:modelValue', this.isChecked);
      }
      this.$emit('change', this.isChecked);
    },
  },
};
</script>

<style scoped>
.checkbox-wrapper {
  display: flex;
  align-items: center;
}
.checkbox-label {
  padding-left: 5px;
  color: #262261;
  font-size: 15px;
}
.intop-checkbox {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #262261;
}
.intop-checkbox-checked {
  background-color: #262261;
}
img {
  width: 12px;
  height: 12px;
}
.checkbox-clickable {
  cursor: pointer;
}
</style>
