import { genericService } from '@/services';

const stateSchema = {
  table: '',
  sortables: {}, // field, order
  per_page: 10,
  page: 1,
  data: [],
  search: '',
};

export const dataTable = {
  namespaced: true,
  state: Object.assign({}, stateSchema),
  actions: {
    async fetch({ state, commit, dispatch }) {
      dispatch('loader/setLoadingStatus', true, { root: true });
      dispatch('loader/setFullpageLoaderStatus', true, { root: true });
      let queryParams = {};
      if (state.sortables.order && state.sortables.field) {
        queryParams['sort'] = `${state.sortables.field}|${state.sortables.order}`;
      }
      if (state.search.trim()) {
        queryParams['search'] = state.search;
      }
      queryParams.page = state.page;
      await new Promise((res) => setTimeout(() => res(), 500));
      const data = await genericService.index(state.table, state.per_page, {})(queryParams);
      commit('updateData', data);
      dispatch('loader/setLoadingStatus', false, { root: true });
      dispatch('loader/setFullpageLoaderStatus', false, { root: true });
    },
    setTable({ commit }, table) {
      commit('reset');
      commit('updateTable', table);
    },
    setSortableField({ commit, dispatch }, sortable) {
      commit('updateSortables', sortable);
      dispatch('fetch');
    },
    setSearchField({ commit, dispatch }, keyword) {
      commit('updateSearch', keyword);
      dispatch('fetch');
    },
    setPage({ commit, dispatch }, page) {
      commit('updatePage', page);
      dispatch('fetch');
    },
    resetPage({ commit }) {
      commit('updatePage', 1);
    },
    reset({ commit }) {
      commit('reset');
    },
  },
  mutations: {
    reset(state) {
      Object.keys(stateSchema).forEach((prop) => {
        state[prop] = stateSchema[prop];
      });
    },
    updateData(state, data) {
      state.data = data;
    },
    updateTable(state, table) {
      state.table = table;
    },
    updateSortables(state, { field, order }) {
      state.sortables = {
        field,
        order,
      };
    },
    updateSearch(state, keyword) {
      state.search = keyword;
    },
    updatePage(state, page) {
      state.page = page;
    },
  },
};
