<template>
  <div class="datagrid-container scrollbar" :style="getContainerStyle">
    <div class="datagrid" :style="getGridStyle">
      <div
        class="datagrid-item datagrid-head-item"
        v-for="field in tablefields"
        :key="'head-' + field.name"
        :class="field.titleClass ? field.titleClass : ''"
      >
        <a class="d-flex sort-link" href="#" v-if="field.sortField" @click.prevent="sortByField(field)">
          <slot :name="'head-' + field.name" v-bind:field="field">
            <span class="mr-1">{{ getTitle(field) }}</span>
          </slot>
          <span class="sort-icon" :class="{ disabled: field.name !== sortBy }">
            <img src="@/assets/arrow-down.svg" alt="" v-if="field.name === sortBy && sortOrder === 'desc'" />
            <img src="@/assets/arrow-up.svg" alt="" v-else />
          </span>
        </a>
        <slot :name="'head-' + field.name" v-bind:field="field" v-else>
          <span class="mr-1">{{ getTitle(field) }}</span>
        </slot>
      </div>

      <template v-if="tabledata && tabledata.length > 0">
        <template v-for="(data, index) in tabledata">
          <div
            class="datagrid-item"
            v-for="field in tablefields"
            :key="'body-' + randKey() + index + field.name"
            :class="field.dataClass ? field.dataClass : ''"
          >
            <slot :name="'body-' + field.name" v-bind:data="data" v-bind:index="index">
              <div v-if="contextColumns && contextColumns.includes(field.name)">
                <ResourceTableExpandableSnippet :key="`${field.name}`" :values="data[field.name]" />
              </div>
              <div v-else>
                <span>{{ getRowData(data, field) }}</span>
              </div>
            </slot>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="datagrid-item text-center grid-full-width">
          <slot name="body-nocontent"> No Data Available </slot>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ResourceTableExpandableSnippet from '@/components/ResourceTableExpandableSnippet';

export default {
  name: 'datagrid',
  props: {
    height: String,
    tablefields: [Object, Array],
    tabledata: [Object, Array],
    contextColumns: Array,
  },
  components: {
    ResourceTableExpandableSnippet,
  },
  data() {
    return {
      sortBy: '',
      sortOrder: '',
      gridheaderheight: '0px',
    };
  },
  computed: {
    getContainerStyle() {
      let style = {};
      style['height'] = this.height;
      style['--scroll-track-margin-top'] = this.gridheaderheight;
      return style;
    },
    getGridStyle() {
      const style = {};
      style['grid-template-columns'] = '';
      for (let field of this.tablefields) {
        if (field.width) {
          style['grid-template-columns'] += `${field.width} `;
        } else {
          style['grid-template-columns'] += 'minmax(100px, auto) ';
        }
      }
      return style;
    },
  },
  methods: {
    getTitle(field) {
      return field.title ? field.title : field.name;
    },
    getRowData(data, field) {
      if (field.formatter) {
        return field.formatter(data[field.name]);
      }
      return data[field.name];
    },
    sortByField(field) {
      if (this.sortBy === field.name && this.sortOrder !== 'desc') {
        this.sortOrder = 'desc';
      } else {
        this.sortOrder = 'asc';
      }
      this.sortBy = field.name;
      this.$emit('sortBy', { field: this.sortBy, order: this.sortOrder });
    },
    randKey() {
      return Math.random().toString(36).substr(2, 8);
    },
  },
  updated() {
    const headelm = document.querySelector('.datagrid-head-item');
    this.gridheaderheight = headelm.getBoundingClientRect().height + 'px';
  },
};
</script>

<style lang="scss" scoped>
.datagrid-container {
  width: 100%;
  overflow: auto;
}
.datagrid {
  border-collapse: collapse;
  font-size: 0.9rem;
  line-height: 1.5;
  margin-bottom: 0;
  position: relative;
  display: grid;
  // grid-template-columns: repeat(6, minmax(100px, auto));
  grid-auto-rows: auto;

  .datagrid-item {
    background: #fff;
    border-bottom: 1px solid #ededed;
    display: flex;
    padding: 0.65rem 1rem;
    align-items: center;
  }

  .datagrid-head-item {
    position: relative;
    position: sticky;
    top: 0;
    z-index: 5;
    align-items: flex-end;

    .sort-link {
      text-decoration: none;
      color: #29235c;
    }

    .sort-icon {
      cursor: pointer;
      display: flex;
    }

    .sort-icon img {
      width: 1.2rem;
    }

    .sort-icon.disabled {
      opacity: 0;
      pointer-events: unset;
    }
  }
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    margin-top: var(--scroll-track-margin-top);
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.9);
    background-color: #999;
  }
}
.datagrid-body.scrollbar:hover {
  padding-right: 0;
  overflow-y: scroll !important;
}

.grid-full-width {
  grid-column: 1/-1;
}
</style>
