<template>
  <div class="app-editor">
    <QuillEditor ref="editor" v-model:content="value" :options="options" v-bind="$attrs" content-type="html" />
  </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
export default {
  components: {
    QuillEditor,
  },

  props: {
    modelValue: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      options: {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, false] }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ align: [] }],
            [{ color: [] }, { background: [] }],
            ['clean'],
            [{ font: [] }],
            ['image'],
          ],
        },
        placeholder: '...',
        theme: 'snow',
      },
      internalValue: null,
    };
  },

  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.internalValue = newValue;
        this.$emit('update:modelValue', newValue);
      },
    },
  },

  watch: {
    modelValue(newValue) {
      if (newValue === this.internalValue) {
        return;
      }
      this.$refs.editor.setHTML(this.modelValue);
    },
  },
};
</script>
