<template>
  <div>
    <b-button variant="primary" class="intop-support-menu pointer" @click.prevent="handleMenuPopUp(true)">
      <font-awesome-icon class="support-menu-icon" size="lg" icon="question" />
    </b-button>

    <b-modal v-model="modalActive" :hide-header="true" :hide-footer="true" :centered="true">
      <div class="loading-container" v-if="data.loading">Loading ...</div>
      <div v-else class="support-modal-body">
        <div class="modal-close-btn" @click="handleMenuPopUp(false)">
          <font-awesome-icon class="close-btn" size="lg" icon="times" />
        </div>
        <div class="support-modal-title">{{ data.title }}</div>
        <div class="support-modal-description" v-html="data.description" />
        <div class="support-modal-footer">
          <a v-if="data.internal_support.link" :href="data.internal_support.link" target="_blank" class="support-btn"
            >{{ data.internal_support.label }}
          </a>
          <a v-if="data.intop_support" class="support-btn" :href="data.intop_support.link" target="_blank"
            >{{ data.intop_support.label }}
          </a>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  methods: {
    handleMenuPopUp(modalActive = true) {
      this.$store.dispatch('supportPage/updater', { modalActive });
      if (modalActive) {
        this.$store.dispatch('supportPage/fetchSupport');
      }
    },
  },
  computed: {
    modalActive: {
      get() {
        return this.$store.state.supportPage.modalActive;
      },
      set: function (modalActive) {
        this.$store.dispatch('supportPage/updater', { modalActive });
      },
    },
    data() {
      return this.$store.state.supportPage;
    },
  },
};
</script>

<style scoped>
.intop-support-menu {
  background-color: #db8a06;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.intop-support-menu:hover {
  opacity: 0.8;
}
.support-menu-icon {
  width: 8px;
  color: #fff;
}
.loading-container {
  padding: 60px 30px;
  color: #888;
  text-align: center;
}
.support-modal-title {
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 25px;
  color: #24125f;
}
.support-modal-body {
  padding: 40px;
  font-size: 15px;
  line-height: 22px;
  position: relative;
}
.modal-close-btn {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: -5px;
  color: #698298;
  cursor: pointer;
  opacity: 0.6;
  padding: 5px 10px;
}
.modal-close-btn:hover {
  opacity: 1;
}
.support-modal-footer {
  margin-top: 50px;
}
.support-modal-footer .support-btn {
  background-color: #688197;
  color: #ddd;
  font-size: 15px;
  padding: 12px 25px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
  display: flex;
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
  word-break: break-word;
}
.support-modal-footer .support-btn:hover {
  opacity: 0.9;
  color: #fff;
}
</style>
