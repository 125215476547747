export const loader = {
  namespaced: true,
  state: {
    isLoading: false,
    showFullpageLoader: false,
  },
  getters: {
    getLoadingStatus(state) {
      return state.isLoading;
    },
    getFullpageLoaderStatus(state) {
      return state.showFullpageLoader;
    },
  },
  actions: {
    setLoadingStatus({ commit }, isLoading) {
      commit('setLoadingStatus', isLoading);
    },
    setFullpageLoaderStatus({ commit }, status) {
      commit('setFullpageLoaderStatus', status);
    },
  },
  mutations: {
    setLoadingStatus(state, isLoading) {
      state.isLoading = isLoading;
    },
    setFullpageLoaderStatus(state, status) {
      state.showFullpageLoader = status;
    },
  },
};
