<template>
  <div :class="getClass">
    <div class="imc-content" @click.prevent="handleItemSelection">
      <span>{{ this.label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    variant: { type: String, default: 'red' },
    name: { type: String, default: '' },
    item: { default: null },
    isLoading: { type: Boolean, default: false },
    clickHandler: { default: false },
  },
  computed: {
    selectedId() {
      if (this.name === 'dimension') {
        return this.$store.state.typeAheadSearch.dimension ?? [];
      } else {
        return this.$store.state.typeAheadSearch.areasOfInterests ?? [];
      }
    },
    fill() {
      let _id = null;
      if (this.name === 'dimension') {
        _id = this.item.dimensionid;
      } else if (this.name === 'areasOfInterests') {
        _id = this.item.areaofinterestid;
      }
      return this.selectedId ? this.selectedId.includes(_id) : false;
    },
    getClass() {
      let _class = 'imc-card-wrapper';
      _class += ` ${this.variant}`;
      if (this.fill) {
        _class += ` bg_is_filled fill_${this.variant}`;
      } else {
        _class += ` bg_not_filled`;
      }
      return _class;
    },
  },
  methods: {
    async handleItemSelection() {
      if (this.clickHandler) {
        this.clickHandler(this.item);
      }
    },
  },
};
</script>

<style scoped>
.imc-card-wrapper {
  border-left: 10px solid transparent;
  display: inline-block;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.imc-card-wrapper:hover {
  box-shadow: 0px 6px 12px -6px #bbb;
}

.imc-content {
  font-size: 14px;
  padding: 15px;
  padding-right: 15px;
}

.bg_not_filled .imc-content {
  color: #24125f;
}

.bg_is_filled .imc-content {
  color: #fff;
}

.red {
  border-left-color: #e03c31;
}

.yellow {
  border-left-color: #bfb800;
}

.blue {
  border-left-color: #5f8dda;
}

.orange {
  border-left-color: #db8a06;
}

.fill_red {
  background-color: #e03c31;
}

.fill_yellow {
  background-color: #bfb800;
}

.fill_blue {
  background-color: #5f8dda;
}

.fill_orange {
  background-color: #db8a06;
}

.bg_is_filled .imc-content {
  color: #fff;
}

.bg_not_filled {
  background-color: #ededed;
}
</style>
