// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from 'vue';
import App from './App';
import router from './router';
import 'babel-polyfill'; // IE11
import 'whatwg-fetch'; // fetch polyfill.
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import 'vue-multiselect/dist/vue-multiselect.css';
import './assets/css/design2.css';
import '@vuepic/vue-datepicker/dist/main.css';
import BootstrapVue3 from 'bootstrap-vue-3';
import { store } from './store';
import appInsights from './appInsights';
window.appInsights = appInsights;

import { library, dom } from '@fortawesome/fontawesome-svg-core';
dom.watch();
import {
  faSortUp,
  faQuestionCircle,
  faQuestion,
  faSortDown,
  faSearch,
  faCheck,
  faInfoCircle,
  faInfo,
  faChevronLeft,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faNetworkWired,
  faThList,
  faUsersCog,
  faChartArea,
  faExclamationCircle,
  faCog,
  faExclamationTriangle,
  faTrash,
  faEdit,
  faPlug,
  faPlus,
  faGenderless,
  faEllipsisH,
  faBars,
  faLink,
  faFilter,
  faTimes,
  faPencilAlt,
  faClipboard,
  faTimesCircle,
  faCopy,
  faStar,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faCalendar,
  faCircleNotch,
  faMapPin,
} from '@fortawesome/free-solid-svg-icons';
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faSortUp,
  faQuestionCircle,
  faQuestion,
  faSortDown,
  faSearch,
  faCheck,
  faInfoCircle,
  faInfo,
  faChevronLeft,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faNetworkWired,
  faThList,
  faUsersCog,
  faChartArea,
  faExclamationCircle,
  faCog,
  faExclamationTriangle,
  faTrash,
  faEdit,
  faPlug,
  faPlus,
  faGenderless,
  faEllipsisH,
  faBars,
  faLink,
  faFilter,
  faTimes,
  faPencilAlt,
  faClipboard,
  faCopy,
  faStar,
  faTimesCircle,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faCalendar,
  faMicrosoft,
  faCircleNotch,
  faMapPin
);

const app = createApp(App);

app.directive('click-outside', {
  mounted(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

app.use(store).use(router).use(BootstrapVue3).component('font-awesome-icon', FontAwesomeIcon).mount('#app');
