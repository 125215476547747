import apiUrls from '@/config/apiUrls';
import { imStructureService } from '../services';
export const typeAheadSearch = {
  namespaced: true,
  state: {
    items: [],
    areasOfInterests: [],
    dimension: [],
    searchKeyword: '',
    loading: false,
  },
  getters: {
    isLoading: (state) => state.loading,
  },
  actions: {
    setLoading({ commit }, status) {
      commit('updateLoading', status);
    },
    async init({ commit, dispatch, state }) {
      commit('updateState', { areasOfInterests: [], dimension: [], searchKeyword: '' });
      dispatch('setLoading', true);
      const tagGroups = await imStructureService.filterItems(apiUrls.IMS_TAGGROUP);
      commit('updateItems', tagGroups);
      dispatch('setLoading', false);
    },
    setSearchKeyword({ commit, dispatch }, keyword) {
      dispatch('setLoading', true);
      commit('setSearchKeyword', keyword);
    },
    async setDimension({ commit, dispatch, state }, dimension) {
      if (state.dimension.includes(...dimension)) {
        commit('updateState', { dimension: [] });
      } else {
        commit('updateState', { dimension: dimension });
      }

      dispatch('setLoading', true);
      const tagGroups = await imStructureService.filterItems(apiUrls.IMS_TAGGROUP, {
        dimension: state.dimension,
        areasOfInterests: state.areasOfInterests,
        search: state.searchKeyword,
      });
      commit('updateItems', tagGroups);
      dispatch('setLoading', false);
    },
    async setAoi({ commit, dispatch, state }, aoi) {
      if (state.areasOfInterests.includes(...aoi)) {
        commit('updateState', { areasOfInterests: [] });
      } else {
        commit('updateState', { areasOfInterests: aoi });
      }

      dispatch('setLoading', true);
      const tagGroups = await imStructureService.filterItems(apiUrls.IMS_TAGGROUP, {
        dimension: state.dimension,
        areasOfInterests: state.areasOfInterests,
        search: state.searchKeyword,
      });
      commit('updateItems', tagGroups);
      dispatch('setLoading', false);
    },
    async search({ commit, dispatch, state }, searchValue) {
      dispatch('setLoading', true);
      commit('setSearchKeyword', searchValue);
      const tagGroups = await imStructureService.filterItems(apiUrls.IMS_TAGGROUP, {
        dimension: state.dimension,
        areasOfInterests: state.areasOfInterests,
        search: searchValue,
      });
      commit('updateItems', tagGroups);
      dispatch('setLoading', false);
    },
    async updateItems({ commit, state, dispatch }, options = {}) {
      const { dimension, areasOfInterests, searchKeyword } = state;

      if (!options.limit) options.limit = 20;
      if (!options.offset) options.offset = 0;

      const updater = async () => {
        dispatch('setLoading', true);
        const nextState = await imStructureService.filterItems(apiUrls.IMS_TAGGROUP, {
          dimension,
          areasOfInterests,
          search: searchKeyword,
          ...options,
        });
        if (typeof nextState === 'object' && !nextState.length && nextState !== null) {
          commit('mergeState', { items: nextState });
        }
        dispatch('setLoading', false);
      };
      await updater();
    },
  },
  mutations: {
    updateLoading(state, status) {
      state.loading = status;
    },
    updateItems(state, items) {
      state.items = items;
    },
    setSearchKeyword(state, keyword) {
      state.searchKeyword = keyword;
    },
    updateState(state, nextState) {
      for (const index in nextState) {
        state[index] = nextState[index];
      }
    },
    mergeState(state, nextState) {
      for (const stateName in nextState) {
        const { data = [] } = state[stateName];
        const mergedRows = [...data, ...nextState[stateName]['data']];
        state[stateName] = {
          ...nextState[stateName],
          data: mergedRows,
        };
      }
    },
  },
};
