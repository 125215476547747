<template>
  <AdminBaseUi
    title="Support Page Manager"
    :showSearchBox="false"
    :showPagination="false"
    :handleSubmit="this.handleSubmit"
  >
    <div class="support-pages-container">
      <div class="left">
        <div class="support-form-wrapper">
          <div class="mb-4">
            <InputBox variant="dim" label="Pop-up title" :required="true" v-model="title" />
          </div>
          <div class="mb-4">
            <InputBox variant="dim" label="Support button title" :required="true" v-model="internal_support_label" />
          </div>
          <div class="mb-4">
            <InputBox
              variant="dim"
              label="Support button link"
              :required="true"
              v-model="internal_support_link"
              placeholder="https://"
            />
          </div>
        </div>
      </div>
      <div class="right">
        <div class="wpc-label text-blue">Description *</div>
        <div class="editor-wrapper">
          <vue-editor class="editor" v-model="description"></vue-editor>
        </div>
      </div>
    </div>
  </AdminBaseUi>
</template>

<script>
import InputBox from '@/components/admin/v2/InputBox';
import AdminBaseUi from '@/components/admin/v2/base-ui/AdminBaseUi';
import Editor from '@/components/admin/v2/Editor';

export default {
  components: {
    InputBox,
    'vue-editor': Editor,
    AdminBaseUi,
  },
  created() {
    this.$store.dispatch('dataTable/reset');
    this.$store.dispatch('supportPage/fetchSupport');
  },
  computed: {
    initialized() {
      return this.$store.state.supportPage.initialized;
    },
    title: {
      get() {
        return this.$store.state.supportPage.title;
      },
      set: function (title) {
        this.$store.dispatch('supportPage/updater', { title });
      },
    },
    description: {
      get() {
        return this.$store.state.supportPage.description;
      },
      set: function (description) {
        this.$store.dispatch('supportPage/updater', { description });
      },
    },
    internal_support_label: {
      get() {
        return this.$store.state.supportPage.internal_support.label;
      },
      set: function (label) {
        const internal_support = {
          label,
          link: this.$store.state.supportPage.internal_support.link,
        };
        this.$store.dispatch('supportPage/updater', { internal_support });
      },
    },
    internal_support_link: {
      get() {
        return this.$store.state.supportPage.internal_support.link;
      },
      set: function (link) {
        const internal_support = {
          link,
          label: this.$store.state.supportPage.internal_support.label,
        };
        this.$store.dispatch('supportPage/updater', { internal_support });
      },
    },
  },
  methods: {
    handleSubmit() {
      this.$store.dispatch('supportPage/updateSupport');
    },
  },
};
</script>

<style>
.support-pages-container {
  display: flex;
  background-color: #fff;
  height: 100%;
  margin: 0px 40px;
}
.support-pages-container .left {
  flex-basis: 40%;
}
.support-pages-container .right {
  flex-basis: 60%;
  border-left: 1px solid rgb(240, 240, 240);
}
.support-pages-container .wpc-label {
  padding: 15px 16px;
  font-weight: 500;
  font-size: 14px;
}
.support-pages-container .editor-wrapper {
  height: calc(100% - 90px);
}
.support-pages-container .ql-toolbar.ql-snow {
  border: none;
  padding-top: 0px !important;
}
.support-pages-container .ql-container.ql-snow {
  border: none;
}
.support-pages-container .quillWrapper.editor {
  height: 100%;
}
.support-pages-container .support-form-wrapper {
  margin: 40px;
}
</style>
