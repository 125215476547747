<template>
  <div class="ims-search">
    <form>
      <div class="searchfield-icon">
        <Loading v-if="loading" />
        <font-awesome-icon v-else size="lg" class="search-icon" icon="search" />
      </div>
      <input :placeholder="`Search Tag Group`" v-model="searchKeyword" @input="debounceHandleSearch" />
    </form>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import Loading from '@/components/Loading';

export default {
  components: {
    Loading,
  },
  created() {
    this.debounceHandleSearch = debounce(this.handleSearch, 1200);
  },
  computed: {
    searchKeyword: {
      set(keyword) {
        this.$store.dispatch('typeAheadSearch/setSearchKeyword', keyword);
      },
      get() {
        return this.$store.state.typeAheadSearch.searchKeyword;
      },
    },
    loading() {
      return this.$store.state.typeAheadSearch.loading;
    },
    isEmptyKeyword() {
      return !!!this.searchKeyword.trim();
    },
  },
  methods: {
    handleSearch() {
      this.$store.dispatch('typeAheadSearch/search', this.searchKeyword);
    },
    resetKeyword() {
      this.$store.dispatch('typeAheadSearch/setSearchKeyword', '');
      this.handleSearch();
    },
  },
};
</script>

<style scoped>
.ims-search {
  width: 300px;
  position: relative;
}
.ims-search input {
  border: 0px;
  border-radius: 0px;
  border-bottom: 2px solid #ededed;
  display: block;
  width: 100%;
  padding-left: 30px;
  font-size: 14px;
  line-height: 26px;
  outline: none;
}
.search-icon {
  font-size: 14px;
  color: #698298;
  margin-left: 6px;
}
.searchfield-icon {
  display: inline-block;
  position: absolute;
}
</style>
