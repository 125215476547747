import config from '../config/config';
import apiUrls from '../config/apiUrls';
import { authHeader, handleApiResponse } from '../helpers';

export const contextService = {
  getAll,
  search,
  getHydratedContexts,
  createContext,
  updateContext,
};

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}${apiUrls.CONTEXT_LIST}`, requestOptions).then(handleApiResponse);
}

function search(term) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}${apiUrls.CONTEXT_LIST}?search=${term}`, requestOptions).then(handleApiResponse);
}

function getHydratedContexts(data) {
  let url = `${config.apiUrl}${apiUrls.HYDRATED_CONTEXT}?visibilityFilter=${data.visibilityFilter}`;

  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(url, requestOptions).then(handleApiResponse);
}

function createContext(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}${apiUrls.CONTEXT_CREATE}`, requestOptions).then(handleApiResponse);
}

function updateContext(data) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}${apiUrls.CONTEXT_UPDATE}`, requestOptions).then(handleApiResponse);
}
