import { store } from '../store';
import { userService } from '../services';
import router from '../router';

export const handleApiResponse = (response, options = {}) => {
  const { hideErrorAlert, returnResponseOnError } = options;
  let responseData = null;
  store.dispatch('alert/clear');
  // if (response.status === 201) {
  //   const operation =
  //     response.url.split("/public")[1] || router.history.current.name;
  //   store.dispatch("alert/success", `Created: ${operation}`);
  // }
  return response
    .text()
    .then((text) => {
      const data = text && JSON.parse(text);
      responseData = data;
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          userService.logout(false);
          location.href = '/';
        }

        const error = (data && data.message) || response.statusText;

        if (!hideErrorAlert) {
          store.dispatch('alert/error', `API request failed: ${error}`);
        }
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      let messageLabel = response.statusText;
      if (error.includes('node_nodeclassid_fkey')) {
        messageLabel = `Context can't be deleted as it is being used in one or more sub-contexts!`;
      }
      if (error.includes('invalid input syntax for type json')) {
        messageLabel = `Invalid JSON was found, please try again with valid JSON`;
      }

      if (!hideErrorAlert && messageLabel) {
        store.dispatch('alert/error', `${messageLabel}`);
      }
      if (returnResponseOnError) {
        return Promise.reject({ error, payload: responseData });
      }
      return Promise.reject(error);
    });
};
