export default {
  // Auth
  LOGIN: '/public/login',
  LOGIN_AZURE_AD: '/public/login/azure-ad',
  LOGIN_TEAMS: '/public/login/teams',
  LOGIN_INTO_TENANT: '/public/login/tenant',
  LOGOUT: '/private/logout',

  // Login config
  LOGIN_CONFIG: '/public/login/config',
  LOGIN_CONFIG_STORE: '/private/admin/login/config',

  // Resource
  RESOURCE: '/private/resource/',
  RESOURCE_DETAILS: '/private/resource/:id',

  // Filters
  FILTER_COLLECTIONS: '/private/admin/filtercollections',
  FILTER_LIST: '/private/admin/filter',
  FILTER_CREATE: '/private/admin/filter',
  FILTER_UPDATE: '/private/admin/filter',
  FILTER_DELETE: '/private/admin/filter',
  FILTER_VALUES: '/private/filter/values',
  FILTER_VALUES_WITHOUT_COUNT: '/private/filter/valuesWithoutCounts',

  // Context
  CONTEXT_LIST: '/private/context/',
  CONTEXT_CREATE: '/private/admin/context/',
  CONTEXT_UPDATE: '/private/admin/context/',
  CONTEXT_DELETE: '/private/admin/context/',
  HYDRATED_CONTEXT: '/private/hydratedcontexts/',

  // Favorite context
  USER_SAVED_CONTEXT: '/private/user/savedcontext/',

  // Advance settings
  CONFIG_LIST: '/private/config/',
  CONFIG_UPDATE: '/private/admin/config/',

  // Fileshare office mapping
  FILESHARE_OFFICEMAPPING: '/private/admin/fileshare/officemapping/',

  // Connector
  CONNECTOR: '/private/admin/connector/',
  CONNECTORS_LIST: '/private/admin/connectors/',
  CONNECTORTYPE: '/private/connectortype/',

  // Ingestion methods
  INGESTIONMETHODS: '/private/admin/ingestionmethods/',

  // IMS
  IMS_DIMENSION: '/private/admin/ims/dimension/',
  IMS_AREAOFINTEREST: '/private/admin/ims/areaofinterest/',
  IMS_TAGGROUP: '/private/admin/ims/taggroup/',
  IMS_TAG: '/private/admin/ims/tag/',
  IMS_LOOKUPFIELDS: '/private/admin/ims/lookupfields/',
  IMS_TAG_TAGGROUP_UNIQUE_CHECK: '/private/admin/ims/tag/checkTagAndTaggroupidUnique',
  IMS_TAG_PATTERN_MUTUAL: '/private/admin/ims/tag/patterns/mutual/',
  TAGGROUP_LIST: '/private/taggroup/',
  TAG_LIST: '/private/tag',
  PATTERNTYPE: '/private/patterntype/',

  TAG_BY_GROUP: '/private/admin/ims/tag_group',
  DROP_TAG: '/private/admin/ims/drop/tag',
  DROP_TAGASPATTERN: '/private/admin/ims/drop/tagaspattern',

  // User
  ROLE: '/private/role/',
  USER: '/private/admin/user/',
  USERS: '/private/admin/users/',

  // Static pages
  SUPPORT_DETAILS: '/private/support',
  SUPPORT_UPDATE: '/private/admin/support',
  WELCOMEPAGE_DETAILS: '/private/welcomepage',
  WELCOMEPAGE_UPDATE: '/private/admin/welcomepage',

  // Backend services
  BACKEND_SERVICE: '/private/admin/backendSeviceApi/',
  CRAWL_CONNECTOR: '/private/admin/connector/:connectorId/crawl/',
  VERIFY_CONNECTOR: '/private/admin/connector/:connectorId/verify/',

  // Dashboard services
  DASHBOARD_SOURCES: '/private/admin/dashboard/sources',
  DASHBOARD_RESOURCES_COUNT_BY_FILETYPE: '/private/admin/dashboard/resourcescountbyfiletype',
  DASHBOARD_PROCESSING_STATUS: '/private/admin/dashboard/processingStatus',
  DASHBOARD_USERS_STATUS: '/private/admin/dashboard/usersStatus',
};
