import cronValidator from 'cron-expression-validator';

export const validCronExpresstionChecker = (jsonConfig) => {
  if (jsonConfig.hasOwnProperty('schedule')) {
    try {
      return cronValidator.isValidCronExpression(jsonConfig['schedule']);
    } catch {
      return false;
    }
  }
  return true;
};
