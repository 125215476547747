import config from '../config/config';
import apiUrls from '../config/apiUrls';
import { authHeader, handleApiResponse } from '../helpers';
import { store } from '../store';
import { azureSSO } from './azureSSO.service';
import appInsights from '../appInsights';

export const userService = {
  login,
  logout,
  getAll,
  loginViaAzure,
  loginViaTeams,
  loginIntoTenant,
};

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    },
    body: JSON.stringify({ username, password }),
  };

  return fetch(`${config.apiUrl}${apiUrls.LOGIN}`, requestOptions)
    .then((res) =>
      handleApiResponse(res, {
        hideErrorAlert: true,
      })
    )
    .then((user) => {
      // login successful if there's a jwt token in the response
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));

        // update app insights context with logged in user
        if (appInsights) {
          const username = user.username;
          let tenant = 'default';
          if (user.customer) {
            tenant = user.customer.tenant;
          }
          appInsights.context.user.setAuthenticatedUserContext(username, tenant, true);
        }
      }

      return user;
    });
}

function loginViaAzure(accessToken) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    },
    body: JSON.stringify({ accessToken }),
  };

  return fetch(`${config.apiUrl}${apiUrls.LOGIN_AZURE_AD}`, requestOptions)
    .then((res) =>
      handleApiResponse(res, {
        hideErrorAlert: true,
      })
    )
    .then((user) => {
      // login successful if there's a jwt token in the response
      setUserHasMultipleTenant(user);
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));

        // update app insights context with logged in user
        if (appInsights) {
          const username = user.username;
          let tenant = 'default';
          if (user.customer) {
            tenant = user.customer.tenant;
          }
          appInsights.context.user.setAuthenticatedUserContext(username, tenant, true);
        }
      }

      return user;
    });
}

function loginViaTeams(token) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    },
    body: JSON.stringify({ id_token: token }),
  };

  return fetch(`${config.apiUrl}${apiUrls.LOGIN_TEAMS}`, requestOptions)
    .then((res) =>
      handleApiResponse(res, {
        hideErrorAlert: true,
      })
    )
    .then((user) => {
      setUserHasMultipleTenant(user);
      // login successful if there's a jwt token in the response
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
      }
      return user;
    });
}

function loginIntoTenant(accessToken, tenant) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    },
    body: JSON.stringify({ accessToken, tenant }),
  };

  return fetch(`${config.apiUrl}${apiUrls.LOGIN_INTO_TENANT}`, requestOptions)
    .then((res) =>
      handleApiResponse(res, {
        hideErrorAlert: true,
      })
    )
    .then((user) => {
      // login successful if there's a jwt token in the response
      if (user.token) {
        if (localStorage.getItem('hasMultipleTenant') !== null) {
          const hasTenant = JSON.parse(localStorage.getItem('hasMultipleTenant')) ?? false;
          localStorage.removeItem('hasMultipleTenant');
          user.hasMultipleTenant = hasTenant;
        }

        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));

        // update app insights context with logged in user
        if (appInsights) {
          const username = user.username;
          let tenant = 'default';
          if (user.customer) {
            tenant = user.customer.tenant;
          }
          appInsights.context.user.setAuthenticatedUserContext(username, tenant, true);
        }
      }

      return user;
    });
}

async function logout(checkForMicrosoftLogout = true) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      ...authHeader(),
    },
  };
  if (checkForMicrosoftLogout) {
    const msalInstance = getMsalInstance();
    if (msalInstance) {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
        await msalInstance.logoutPopup(accounts[0]);
      }
    }
  }
  fetch(`${config.apiUrl}${apiUrls.LOGOUT}`, requestOptions);
  localStorage.removeItem('user');

  // clear app insights context with user info on logout
  if (appInsights) {
    appInsights.context.user.clearAuthenticatedUserContext();
  }
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}${apiUrls.USERS}`, requestOptions).then(handleApiResponse);
}

function getMsalInstance() {
  const loginConfig = store.getters['loginConfig/getConfig'];
  if (loginConfig && loginConfig.loginType && loginConfig.loginType.includes('microsoft')) {
    return azureSSO.msalInstance(loginConfig.microsoftConfig.clientId, loginConfig.microsoftConfig.tenantId);
  } else {
    return null;
  }
}

function setUserHasMultipleTenant(user) {
  if (user.tenants) {
    user.hasMultipleTenant = true;
    localStorage.setItem('hasMultipleTenant', user.hasMultipleTenant);
  }
}
