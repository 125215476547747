const filetypeIconMapper = {
  Presentation: 'powerp_icon.svg',
  Spreadsheet: 'excel_icon.svg',
  Document: 'word_icon.svg',
  PDF: 'pdf_icon.svg',
  Image: 'img_icon.svg',
  'Raw Text': 'txt_icon.svg',
  Binary: 'etc_icon.svg',
  Zip: 'archive_icon.svg',
  Email: 'etc_icon.svg',
  Video: 'film_icon.svg',
  Audio: 'snd_icon.svg',
  Database: 'etc_icon.svg',
  HTML: 'html_icon.svg',
  XML: 'etc_icon.svg',
  Project: 'etc_icon.svg',
  Geospatial: 'etc_icon.svg',
  Drawing: 'etc_icon.svg',
};

export const getFileIcon = (fileType) => {
  return filetypeIconMapper[fileType] ? 'file_icons/' + filetypeIconMapper[fileType] : 'file_icons/etc_icon.svg';
};
