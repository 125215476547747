<template>
  <thead>
    <slot></slot>
  </thead>
</template>

<script>
export default {};
</script>

<style scoped></style>
