<template>
  <AdminFormBaseUi backText="User" backTo="/manage/user" :currentPage="`${this.edit ? 'Update' : 'Add new'} user`">
    <div class="user-form-wrapper d-flex flex-row">
      <div class="the-form">
        <h3>{{ this.edit ? 'Update' : 'Create' }}</h3>
        <form class="form-wrapper">
          <div class="field-wrapper">
            <InputBox variant="dim" label="Name" :required="true" v-model="name" />
          </div>
          <div class="field-wrapper">
            <InputBox variant="dim" label="Username" :required="true" v-model="username" />
          </div>
          <div class="field-wrapper">
            <InputBox
              variant="dim"
              label="Password"
              :required="true"
              v-model="password"
              type="password"
              autocomplete="new-password"
            />
          </div>
          <div class="roles-wrapper mb-4">
            <div class="label-field">Role(s)</div>
            <div class="d-flex flex-row mb-2">
              <div
                v-for="(role, index) in filteredRoles"
                :class="`role-box ${roleSelected(role.roleid) ? 'role-box-selected' : 'role-box-unselected'}`"
                :key="index"
                @click="toggleRoleSelect(role.roleid)"
              >
                {{ role.name }}
              </div>
            </div>
          </div>
          <div>
            <b-form-checkbox v-model="active" name="check-button" switch> Enabled </b-form-checkbox>
          </div>
          <div class="text-right">
            <Button
              :label="this.edit ? 'Update' : 'Save'"
              background="dark"
              :showLoader="true"
              :disabled="isLoading"
              :isLoading="isLoading && !showFullpageLoader"
              :clickHandler="handleSubmit"
            />
          </div>
        </form>
      </div>
      <div class="connectors-list-wrapper">
        <h3>Connectors</h3>
        <div>
          <div class="connector-wrapper" v-for="(connector, index) in allConnectors.data" :key="index">
            <Checkbox
              :label="connector.name"
              :checked="connectorSelected(connector.connectorid)"
              @change="toggleConnector(connector.connectorid)"
            />
          </div>
        </div>
      </div>
    </div>
  </AdminFormBaseUi>
</template>

<script>
import { genericService } from '@/services';
import Button from '@/components/v2/Button';
import Checkbox from '@/components/Checkbox';
import DynamicSelect from '@/components/DynamicSelect';
import InputBox from '@/components/admin/v2/InputBox';
import AdminFormBaseUi from '@/components/admin/v2/base-ui/AdminFormBaseUi';
import apiUrls from '../../../../config/apiUrls';

const PASSWORD_FIELD_PLACEHOLDER = `*********`;

export default {
  async created() {
    this.$store.dispatch('loader/setLoadingStatus', true);
    this.$store.dispatch('loader/setFullpageLoaderStatus', true);
    const editUser = this.$route.params.id === 'new' ? -1 : this.$route.params.id;
    if (editUser !== -1) {
      this.edit = true;
      const editableUserModel = genericService.find(`${apiUrls.USER}${this.$route.params.id}`);
      const editableUserData = await editableUserModel();

      if (editableUserData.data.length > 0) {
        const [user] = editableUserData.data;
        this.name = user.name;
        this.username = user.username;
        this.password = user.password || PASSWORD_FIELD_PLACEHOLDER;
        this.active = user.active;
        this.selectedRoles = user.roleids;
        this.selectedConnectors = user.connectorids;
      }
    } else {
      this.edit = false;
    }

    const rolesModel = genericService.index(apiUrls.ROLE, 999);
    const connectorsModel = genericService.index(apiUrls.CONNECTOR, 999);

    this.allRoles = await rolesModel();
    this.allConnectors = await connectorsModel();

    this.$store.dispatch('loader/setLoadingStatus', false);
    this.$store.dispatch('loader/setFullpageLoaderStatus', false);
  },
  data: function () {
    return {
      edit: false,
      active: true,

      name: '',
      username: '',
      password: '',

      selectedConnectors: [],
      allConnectors: [],

      selectedRoles: [],
      allRoles: [],
    };
  },
  computed: {
    filteredRoles() {
      return (this.allRoles.data || []).filter((r) => r.roleid != 3);
    },
    isLoading() {
      return this.$store.getters['loader/getLoadingStatus'];
    },
    showFullpageLoader() {
      return this.$store.getters['loader/getFullpageLoaderStatus'];
    },
  },
  watch: {
    selectedConnectors() {
      if (this.selectedConnectors.length) {
        if (!this.selectedRoles.includes(3)) {
          this.selectedRoles.push(3);
        }
      } else {
        if (this.selectedRoles.includes(3)) {
          this.selectedRoles = this.selectedRoles.filter((r) => r !== 3);
        }
      }
    },
  },
  components: {
    Button,
    InputBox,
    Checkbox,
    DynamicSelect,
    AdminFormBaseUi,
  },
  methods: {
    create() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      const model = genericService.create(apiUrls.USER);
      model({
        name: this.name,
        username: this.username,
        password: this.password,
        roleid: this.selectedRoles,
        connectorid: this.selectedConnectors,
        active: this.active,
      })
        .then((r) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$router.push('/manage/user');
        })
        .catch((e) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('alert/error', e || `Unable to create the user, please try again!`);
        });
    },
    modify() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      const model = genericService.update(apiUrls.USER);
      model({
        userid: this.$route.params.id,
        name: this.name,
        username: this.username,
        password: this.password.trim() !== PASSWORD_FIELD_PLACEHOLDER ? this.password : '',
        roleid: this.selectedRoles,
        connectorid: this.selectedConnectors,
        active: this.active,
      })
        .then((r) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$router.push({
            path: '/manage/user',
            query: { redirect: this.$store.state.dataTable.page },
          });
        })
        .catch((e) => {
          this.$store.dispatch('alert/error', e || `Unable to update the user, please try again!`);
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
    handleSubmit(event) {
      event.preventDefault();
      if (!this.edit) {
        this.create();
      } else {
        this.modify();
      }
    },
    onUserTypeOpen() {
      return this.allUserTypes;
    },
    roleSelected(roleid) {
      return this.selectedRoles.includes(roleid);
    },
    toggleRoleSelect(roleid) {
      if (this.roleSelected(roleid)) {
        this.selectedRoles = this.selectedRoles.filter((r) => r != roleid);
      } else {
        this.selectedRoles = [roleid, ...this.selectedRoles];
      }
    },
    connectorSelected(connectorid) {
      return this.selectedConnectors.includes(connectorid);
    },
    toggleConnector(connectorid) {
      if (this.connectorSelected(connectorid)) {
        this.selectedConnectors = this.selectedConnectors.filter((r) => r != connectorid);
      } else {
        this.selectedConnectors = [connectorid, ...this.selectedConnectors];
      }
    },
  },
};
</script>

<style scoped>
h3 {
  font-weight: 300;
  font-size: 22px;
  padding: 30px;
  padding-left: 40px;
  padding-bottom: 10px;
  color: #24125f;
}
.label-field {
  font-size: 14px;
  color: #24125f;
  padding-bottom: 6px;
}
.role-box {
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}
.role-box:hover {
  opacity: 0.9;
}
.role-box-selected {
  background-color: #24125f;
  color: #fff;
}
.role-box-unselected {
  background-color: #ededed;
  color: #24125f;
}
.form-wrapper {
  max-width: 600px;
  padding: 0px 30px 40px 40px;
}
.field-wrapper {
  margin-bottom: 25px;
}
.invalid-json {
  font-size: 12px;
  font-weight: 500;
  color: rgb(245, 95, 69);
  padding-top: 2px;
}
.the-form {
  flex-grow: 1;
  max-width: 600px;
}
.connectors-list-wrapper {
  flex-grow: 1;
}
.connector-wrapper {
  padding-bottom: 10px;
  padding-left: 45px;
}
</style>
