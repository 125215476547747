import { insertAtIndex } from '@/helpers';

export const contextManager = {
  namespaced: true,
  state: {
    name: '',
    filters: [],
    columns: [],
    editing: null,

    activeDragGroup: '', // filter, column
    itemBeingDragged: null, // tagGroup, groupName
    hoveredIndex: null,

    renderPreview: false,
  },
  getters: {
    demoContext: (state) => {
      return {
        name: state.name,
        filters: state.filters,
        columns: state.columns,
        contextid: null,
        defaulttagids: [],
        ordering: 1,
        tags: [],
        visible: true,
      };
    },
  },
  actions: {
    updater({ commit }, nextState) {
      if (typeof nextState === 'object' && !nextState.length && nextState !== null) {
        commit('updateState', nextState);
      } else {
        console.error('nextState needs to be an object: contextManager.module.js');
      }
    },
    addDraggedItem({ state, commit }, { itemDropedOn }) {
      const { tagGroup } = state.itemBeingDragged;
      const { hoveredIndex } = state;
      let updatable = {};
      if (itemDropedOn === 'filter') {
        const filteredItems = state.filters.filter((tg) => tg.taggroupid !== tagGroup.taggroupid);
        updatable['filters'] = insertAtIndex(filteredItems, (hoveredIndex ? hoveredIndex : 0) + 1, tagGroup);
      } else if (itemDropedOn === 'column') {
        const filteredItems = state.columns.filter((tg) => tg.taggroupid !== tagGroup.taggroupid);
        updatable['columns'] = insertAtIndex(filteredItems, (hoveredIndex ? hoveredIndex : 0) + 1, tagGroup);
      }
      commit('updateState', {
        ...updatable,
        itemBeingDragged: null,
        activeDragGroup: '',
        hoveredIndex: null,
      });
    },
    remove({ dispatch, state, commit }, { type, id }) {
      if (type === 'filter') {
        commit('updateState', {
          filters: state.filters.filter((tg) => tg.taggroupid !== id),
        });
        dispatch('filterParams/removeTag', { taggroupid: id }, { root: true });
      } else if (type === 'column') {
        commit('updateState', {
          columns: state.columns.filter((tg) => tg.taggroupid !== id),
        });
      }
    },
  },
  mutations: {
    updateState(state, nextState) {
      for (const index in nextState) {
        state[index] = nextState[index];
      }
    },
  },
};
