export const pagination = {
  namespaced: true,
  state: {
    currentPage: 0,
    from: 0,
    lastPage: 0,
    to: 0,
    total: 0,
    loading: false,
  },
  actions: {
    setPaginationData({ commit }, paginationData) {
      commit('paginationData', paginationData);
    },
    setCurrentPage({ commit }, currentPage) {
      commit('currentPage', currentPage);
    },
    setLoading({ commit }, status) {
      commit('loadingStatus', status);
    },
  },
  mutations: {
    paginationData(state, paginationData) {
      state.currentPage = paginationData.current_page;
      state.from = paginationData.from;
      state.lastPage = paginationData.last_page;
      state.to = paginationData.to;
      state.total = paginationData.total;
    },
    currentPage(state, currentPage) {
      state.currentPage = currentPage;
    },
    loadingStatus(state, status) {
      state.loading = status;
    },
  },
};
