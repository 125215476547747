import apiUrls from '../config/apiUrls';
import config from '../config/config';
import { authHeader, handleApiResponse } from '../helpers';

export const nodeService = {
  getAll,
  getTypes,
  getClasses,
  createNode,
  createNodeClass,
  checkTagAndTaggroupidCombinationIsUnique,
  getLinkedTagCountByParentTag,
};

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/private/tags`, requestOptions).then(handleApiResponse);
}

function getTypes() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/private/tags/types`, requestOptions).then(handleApiResponse);
}

function getClasses() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/private/tags/classes`, requestOptions).then(handleApiResponse);
}

function createNode(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}/private/tags`, requestOptions).then(handleApiResponse);
}

function createNodeClass(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}/private/tags/classes`, requestOptions).then(handleApiResponse);
}

function checkTagAndTaggroupidCombinationIsUnique(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}${apiUrls.IMS_TAG_TAGGROUP_UNIQUE_CHECK}`, requestOptions).then(handleApiResponse);
}

function getLinkedTagCountByParentTag(parentTagId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}${apiUrls.IMS_TAG}${parentTagId}/getLinkedTagCount`, requestOptions).then(
    handleApiResponse
  );
}
