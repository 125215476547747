<template>
  <div v-if="!renderPreview" class="context-manager">
    <div class="left">
      <div class="sidebar-top-option">
        <InputBox
          :getFunc="() => this.$store.state.contextManager.name"
          :setFunc="(name) => this.$store.dispatch('contextManager/updater', { name })"
          placeholder="Context Name (click to edit)"
        />
      </div>
      <div class="livePreviewWrapper">
        <SwitchToggle />
      </div>
      <Filters />
    </div>
    <div class="right">
      <div class="column-wrapper">
        <div class="default-columns-wrapper">
          <ColumnLabel label="Name" size="small" />
          <div class="divspacer"></div>
          <ColumnLabel label="Last modified" size="large" />
        </div>
        <Columns />
      </div>
      <div class="context-options-body">
        <DimensionsFilter />
        <AreasOfInterestFilter />
        <div>
          <div class="tags mt-2">
            <div class="label-wrapper">
              <div class="text-label">Tag groups</div>
              <div class="searcharea">
                <TypeAheadSearch />
              </div>
            </div>
            <div v-if="this.tagGroups.length">
              <div class="single-tagbox" v-for="(tagGroup, index) in this.tagGroups" :key="index">
                <div class="tagbox-container" :draggable="true" @dragstart="() => handleDragStart({ tagGroup })">
                  <TagBox :tagGroup="tagGroup" />
                </div>
              </div>
              <TagGroupPagination />
            </div>
            <div v-else>No tags found</div>
          </div>
        </div>
      </div>
      <div class="sticky-footer d-flex justify-content-end">
        <div class="me-3">
          <Button label="Cancel" background="light" :clickHandler="() => this.$router.push('/manage/context')" />
        </div>
        <Button
          :label="this.edit ? 'Update' : 'Publish'"
          background="dark"
          :showLoader="true"
          :isLoading="isLoading"
          :disabled="isLoading"
          :clickHandler="this.handleSubmit"
        />
      </div>
    </div>
  </div>
  <div v-else class="context-manager cm-preview-header">
    <div class="top-options">
      <div class="to-left">
        <h4>Context Preview</h4>
        <div class="mt-3">
          <SwitchToggle />
        </div>
      </div>
    </div>
    <Main :preview="renderPreview" />
  </div>
</template>

<script>
import { genericService } from '@/services';
import InputBox from '@/components/admin/v2/InputBox';
import ColumnLabel from '@/components/admin/v2/ColumnLabel';
import TypeAheadSearch from '@/components/v2/TypeAheadSearch';
import TagBox from '@/components/v2/TagBox';
import Button from '@/components/v2/Button';
import DragDropHandler from '@/components/v2/DragDropHandler';
import TagGroupBox from '@/components/admin/v2/TagGroupBox';
import SwitchToggle from '@/components/admin/v2/SwitchToggle';
import Filters from '@/components/admin/v2/context/Filters';
import Columns from '@/components/admin/v2/context/Columns';
import Main from '@/pages/Main';
import apiUrls from '../../../config/apiUrls';
import AreasOfInterestFilter from '@/components/admin/v2/context-manager/AreasOfInterestFilter';
import DimensionsFilter from '@/components/admin/v2/context-manager/DimensionsFilter';
import TagGroupPagination from './context-manager/TagGroupPagination.vue';

export default {
  name: 'ContextManager',
  components: {
    Main,
    Button,
    TagBox,
    Filters,
    Columns,
    InputBox,
    ColumnLabel,
    SwitchToggle,
    TagGroupBox,
    TypeAheadSearch,
    DragDropHandler,
    AreasOfInterestFilter,
    DimensionsFilter,
    TagGroupPagination,
  },
  created() {
    const editContext = this.$route.params.id === 'new' ? -1 : this.$route.params.id;
    if (editContext !== -1) {
      this.$store
        .dispatch('contexts/getHydratedContexts', {
          visibilityFilter: false,
        })
        .then(async (allContextItems) => {
          const currentHydratedContext = allContextItems.find((c) => c.contextid == this.$route.params.id);
          this.$store.dispatch('contextManager/updater', {
            name: currentHydratedContext.name,
            filters: currentHydratedContext.filters,
            columns: currentHydratedContext.columns.map((c) => {
              return {
                ...c,
                name: c.label,
              };
            }),
            editing: this.$route.params.id,
          });
        });
    } else {
      this.$store.dispatch('contextManager/updater', {
        name: '',
        filters: [],
        columns: [],
        editing: null,
      });
    }
    this.$store.dispatch('typeAheadSearch/init');
  },
  computed: {
    edit() {
      return !!this.$store.state.contextManager.editing;
    },
    tagGroups() {
      return this.$store.state.typeAheadSearch.items.data ?? [];
    },
    renderPreview() {
      return this.$store.state.contextManager.renderPreview;
    },
    isLoading() {
      return this.$store.getters['loader/getLoadingStatus'];
    },
  },
  methods: {
    handleDragStart({ tagGroup, groupName }) {
      this.$store.dispatch('contextManager/updater', {
        itemBeingDragged: { tagGroup, groupName },
      });
    },
    handleSubmit() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      const { name, filters, columns } = this.$store.state.contextManager;
      const data = {
        name,
        filters: filters.map((f) => f.taggroupid),
        columns: columns.map((f) => f.taggroupid),
        contextdependencies: {},
        defaulttagids: [],
        ordering: 0,
        visible: true,
      };
      if (!this.edit) {
        const model = genericService.create(apiUrls.CONTEXT_CREATE);
        model(data)
          .then((r) => {
            this.$store.dispatch('loader/setLoadingStatus', false);
            this.$router.push('/manage/context');
          })
          .catch((e) => {
            this.$store.dispatch('loader/setLoadingStatus', false);
            this.$store.dispatch('alert/error', e || `Unable to create the context, please try again!`);
          });
      } else {
        const model = genericService.update(apiUrls.CONTEXT_UPDATE);
        const updatable = {
          ...data,
          contextid: this.$store.state.contextManager.editing,
        };
        model(updatable)
          .then((r) => {
            this.$store.dispatch('loader/setLoadingStatus', false);
            this.$store.dispatch('alert/success', `The context has been updated successfully`);
            this.$router.push({
              path: '/manage/context',
              query: { redirect: this.$store.state.dataTable.page },
            });
          })
          .catch((e) => {
            this.$store.dispatch('loader/setLoadingStatus', false);
            this.$store.dispatch('alert/error', e || `Unable to update the context, please try again!`);
          });
      }
    },
  },
};
</script>

<style scoped>
.context-manager {
  display: flex;
  height: calc(100vh - 120px);
  min-width: auto;
}
.default-columns-wrapper {
  display: flex;
}
.cm-preview-header {
  flex-direction: column;
}
.cm-preview-header .to-left {
  flex-grow: 1;
  justify-content: center;
}
.cm-preview-header h4 {
  line-height: 0px;
  display: inline-block;
}
.left {
  min-width: 400px;
  padding: 20px 40px 10px 40px;
}
.right {
  flex-grow: 1;
}
.top-options {
  display: flex;
  padding: 40px;
  padding-bottom: 40px;
  /* justify-content: flex-end; */
}
.sidebar-top-option {
  margin-bottom: 15px;
}
.context-options-body {
  background-color: #fff;
  overflow: scroll;
  height: calc(100vh - 250px);
  position: relative;
}
.column-wrapper {
  padding: 20px;
  /* border: 1px solid red; */
  display: flex;
  padding-bottom: 2px;
}
.tags {
  padding: 0px 40px;
}
.text-label {
  font-size: 14px;
  color: #688197;
  margin-bottom: 5px;
  margin-right: 30px;
}
.sticky-footer {
  text-align: right;
  margin-right: 40px;
}
.column-wrapper .drag-drop-wrapper {
  margin-left: 30px;
}
.sided-items {
  display: flex;
  overflow: hidden;
  flex-direction: row;
}
.single-tagbox {
  display: inline-block;
  padding: 0px 10px 10px 0px;
}
.tagbox-container {
  cursor: move;
}
.divspacer {
  width: 15px;
}
.label-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.livePreviewWrapper {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 50px;
}
</style>
