<template>
  <div class="taggroup-form">
    <b-container>
      <b-row>
        <b-col>
          <div>
            <TextInput v-if="enableEditingTitle" reference="taggroupname" v-model="name" placeholder="Tag group" />
            <div v-else class="form-header">
              <div class="toplabel">{{ name }}</div>
              <div class="actions">
                <b-dropdown id="dropdown-dropright" dropright variant="default" no-caret>
                  <template v-slot:button-content>
                    <img src="@/assets/dots.svg" alt="" />
                  </template>
                  <b-dropdown-item @click.prevent="showNameField" href="#">Edit tag group name</b-dropdown-item>
                  <b-dropdown-item-button>
                    <DeleteConfirmation
                      displayButtonLabel="Delete"
                      entityLabel="tag group"
                      :handleConfirm="handleDelete"
                      classAttr="no-margin-dc-menu"
                    />
                  </b-dropdown-item-button>
                </b-dropdown>
              </div>
            </div>
          </div>
        </b-col>
        <b-col class="text-right"> </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col :sm="4">
          <div>
            <div class="label">Dimension</div>
            <DynamicSelect
              id="dimensionid"
              class="text-break-all"
              title="Dimension"
              label="name"
              :singleSelect="true"
              :handleOpen="this.onDimensionOpen"
              v-model="dimensionid"
            />
          </div>
        </b-col>
        <b-col :sm="4">
          <div>
            <div class="label">Areas Of Interest</div>
            <DynamicSelect
              id="areaofinterestid"
              class="text-break-all"
              title="Areas Of Interest"
              label="name"
              :handleOpen="this.onAOTOpen"
              v-model="areasOfInterests"
            />
          </div>
        </b-col>
        <b-col :sm="4"></b-col>
      </b-row>
    </b-container>

    <b-form class="form-wrapper">
      <div class="row mt-5">
        <div class="col-8 d-flex align-items-center">
          <b-form-checkbox v-model="autoFill" name="check-button" class="mx-3 auto-fill-checkbox" switch>
            Auto Fill
          </b-form-checkbox>
          <LookupfieldSelect v-if="autoFill" v-model="selectedLookupField" :selected="selectedLookupField" />
        </div>
        <div class="col-4">
          <div class="text-right">
            <span class="me-3">
              <Button label="Cancel" background="light" :disabled="isLoading" :clickHandler="handleCancel" />
            </span>
            <Button
              :label="this.edit ? 'Update' : 'Save'"
              background="dark"
              :showLoader="true"
              :isLoading="isLoading"
              :disabled="isLoading || !isFormChanged"
              :clickHandler="handleSubmit"
            />
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { genericService, entityService } from '@/services';
import InputBox from '@/components/admin/v2/InputBox';
import Button from '@/components/v2/Button';
import DynamicSelect from '../../../DynamicSelect';
import TextInput from './TextInput';
import DeleteConfirmation from './DeleteConfirmation';
import apiUrls from '@/config/apiUrls';
import LookupfieldSelect from './LookupfieldSelect';
import { isEqual, cloneDeep } from 'lodash';
export default {
  components: {
    Button,
    InputBox,
    DynamicSelect,
    TextInput,
    DeleteConfirmation,
    LookupfieldSelect,
  },
  data() {
    return {
      taggroupid: null,
      name: '',
      dimensionid: null,
      areasOfInterests: [],
      enableEditingTitle: true,
      autoFill: false,
      selectedLookupField: '',
      dbName: '',
      dbAreasOfInterests: [],
      dbDimensionid: null,
      dbAutoFill: false,
      dbSelectedLookupField: '',
      isDimensionidChanged: false,
      isAreasOfInterestsChanged: false,
      isNameChanged: false,
      isAutoFillChanged: false,
    };
  },
  watch: {
    name(newVal) {
      this.isNameChanged = newVal !== this.dbName;
    },
    dimensionid(newVal) {
      this.isDimensionidChanged = newVal !== this.dbDimensionid;
    },
    areasOfInterests: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.isAreasOfInterestsChanged = !isEqual(newVal, this.dbAreasOfInterests);
      },
    },
    autoFill(newVal) {
      this.isAutoFillChanged = newVal !== this.dbAutoFill;
    },
    selectedLookupField(newVal) {
      this.isAutoFillChanged = newVal !== this.dbSelectedLookupField;
    },
  },
  created() {
    if (this.edit) {
      this.enableEditingTitle = false;
      const { dimensionid, name, taggroupid } = this.editableData;
      this.taggroupid = taggroupid;
      this.name = name;
      this.dbName = name;
      this.dimensionid = dimensionid;
      this.dbDimensionid = dimensionid;
      const areaofinterests = this.editableData.areaofinterests || [];
      this.areasOfInterests = areaofinterests.map((aot) => aot.areaofinterestid);
      this.dbAreasOfInterests = cloneDeep(this.areasOfInterests);
      this.selectedLookupField = this.editableData.lookupfield;
      this.dbSelectedLookupField = this.selectedLookupField;
      this.autoFill = !!this.editableData.lookupfield;
      this.dbAutoFill = this.autoFill;
    } else {
      this.enableEditingTitle = true;
      const [selectedDimension] = this.$store.state.imStructure.dimension;
      const selectedAots = this.$store.state.imStructure.areasOfInterests;
      this.dimensionid = selectedDimension;
      this.areasOfInterests = selectedAots;
    }
  },
  computed: {
    edit() {
      return this.$store.state.imStructure.form.mode === 'edit';
    },
    editableData() {
      return this.$store.state.imStructure.form.editable;
    },
    isLoading() {
      return this.$store.getters['loader/getLoadingStatus'];
    },
    tagGroups() {
      return this.$store.state.entities.tagGroups || [];
    },
    isFormChanged() {
      return this.edit
        ? this.isDimensionidChanged || this.isAreasOfInterestsChanged || this.isNameChanged || this.isAutoFillChanged
        : true;
    },
  },
  methods: {
    create() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      const model = genericService.create(apiUrls.IMS_TAGGROUP);
      model({
        name: this.name.trim(),
        dimensionid: this.dimensionid,
        areasOfInterests: this.areasOfInterests,
        lookupfield: this.autoFill ? this.selectedLookupField : '',
        autofill: this.autoFill,
      })
        .then((r) => {
          // this.$store.dispatch('alert/success', `The tag group has been created successfully`)
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('imStructure/updater', {
            form: { showModal: false },
          });
          this.$nextTick(() => {
            this.$store.dispatch('imStructure/updateTagGroup', {
              offset: 0,
              limit: this.tagGroups.data.length + 1,
            });
          });
          this.$store.dispatch('entities/fetchStickyTagAndTagGroups');
        })
        .catch((e) => {
          this.$store.dispatch('alert/error', e || `Unable to create the tag group, please try again!`);
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
    modify() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      const model = genericService.update(apiUrls.IMS_TAGGROUP);
      model({
        taggroupid: this.taggroupid,
        name: this.name.trim(),
        dimensionid: this.dimensionid,
        areasOfInterests: this.areasOfInterests,
        lookupfield: this.autoFill ? this.selectedLookupField : '',
        autofill: this.autoFill,
      })
        .then((r) => {
          // this.$store.dispatch('alert/success', `The tag group has been updated successfully`)
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('imStructure/updater', {
            form: { showModal: false },
          });
          this.$nextTick(() => {
            this.$store.dispatch('imStructure/updateTagGroup', {
              offset: 0,
              limit: this.tagGroups.data.length,
            });
          });
          this.$store.dispatch('entities/fetchStickyTagAndTagGroups');
        })
        .catch((e) => {
          this.$store.dispatch('alert/error', e || `Unable to update the tag group, please try again!`);
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
    handleSubmit(event) {
      event.preventDefault();
      if (this.dimensionid) {
        if (!this.edit) {
          this.create();
        } else {
          this.modify();
        }
      } else {
        this.$store.dispatch('alert/error', `Dimension can not be empty`);
      }
    },
    handleDelete() {
      this.$store.dispatch('loader/setLoadingStatus', true);
      const model = genericService.remove(apiUrls.IMS_TAGGROUP);
      model({
        taggroupid: this.taggroupid,
      })
        .then(async (r) => {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('entities/removeStickyTagGroup', this.taggroupid);
          this.$store.dispatch('imStructure/updater', {
            form: { showModal: false },
          });
          await this.$store.dispatch('imStructure/updateTagGroup', {
            offset: 0,
            limit: this.tagGroups.data.length - 1,
          });
          if (this.$store.state.imStructure.tagGroup.length) {
            this.$store.dispatch('imStructure/updater', {
              tagGroup: [],
            });
          }
          await this.$store.dispatch('imStructure/updateTag');
        })
        .catch((e) => {
          this.$store.dispatch('alert/error', e || `Unable to remove the tag group, please try again!`);
          this.$store.dispatch('loader/setLoadingStatus', false);
        });
    },
    async onDimensionOpen() {
      const dimensions = await entityService.index({
        dataType: apiUrls.IMS_DIMENSION,
      });
      return dimensions.data;
    },
    async onAOTOpen() {
      const aot = await entityService.index({
        dataType: apiUrls.IMS_AREAOFINTEREST,
      });
      return aot.data;
    },
    handleCancel() {
      this.$store.dispatch('imStructure/updater', {
        form: { showModal: false },
      });
    },
    showNameField() {
      this.enableEditingTitle = true;
      setTimeout(() => {
        document.querySelector('div.textinput input').focus();
      }, 1);
    },
  },
};
</script>

<style>
.no-margin-dc-menu .btn {
  border-radius: 0px;
  width: 100%;
  text-align: left;
  margin: 0px;
  padding: 0px;
  color: #e86d67;
  font-size: 15px;
}
.auto-fill-checkbox.custom-switch .custom-control-label {
  font-size: 0.9rem;
  line-height: 2;
  font-weight: 600;
  color: #23125f;
  cursor: pointer;
}
.auto-fill-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #23125f;
  background-color: #23125f;
}
.auto-fill-checkbox .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #23125f;
}
.auto-fill-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.auto-fill-checkbox.custom-switch .custom-control-label::after {
  background-color: #23125f;
}
.auto-fill-checkbox .custom-control-label::before {
  border: #23125f solid 2px;
}
</style>

<style scoped>
.taggroup-form {
  padding: 30px;
}
.label {
  font-size: 14px;
  color: #24125f;
  margin-bottom: 6px;
}
.form-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.toplabel {
  font-size: 22px;
  color: #24125f;
}
.actions {
  margin-left: 5px;
  cursor: pointer;
}
.actions img {
  height: 21px;
}
</style>
