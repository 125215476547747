<template>
  <div class="admin-base-form-ui-wrapper">
    <div class="admin-base-form-header">
      <span class="back">
        <router-link :to="this.backTo">
          <span>{{ this.backText }}</span>
        </router-link>
      </span>
      <span class="seperator">/</span>
      <span class="currentpage">{{ this.currentPage }}</span>
    </div>
    <div class="admin-form-base-ui">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['backText', 'backTo', 'currentPage'],
};
</script>

<style scoped>
.admin-base-form-ui-wrapper {
  height: 100%;
  padding: 20px 40px 0px 40px;
  display: flex;
  flex-direction: column;
}
.admin-form-base-ui {
  background-color: #fff;
  height: 100%;
  overflow: auto;
}
.admin-base-form-header {
  font-size: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.back a {
  color: #24125f;
}
.back a {
  text-decoration: none;
}
.back a:hover {
  opacity: 0.7;
}
.seperator {
  color: #bcb9c2;
  padding: 0px 4px;
  font-size: 14px;
  font-weight: bold;
}
.currentpage {
  color: #688197;
}
</style>
