<template>
  <div>
    <ContextBox />
    <hr class="mb-0" />
    <div id="favoriteBoxWrapper" class="pt-3">
      <div class="filter-label"><b>Select favorites</b></div>
      <FavoriteBox />
    </div>
    <hr />
    <div class="filter-label mt-3 mb-2"><b>Free text search</b></div>
    <SearchBox />
    <hr />
    <div>
      <div class="filter-label mt-3"><b>Select filters</b></div>
      <div v-for="filter in selectedContextFilters" :key="filter.name">
        <FilterBox :key="'filter_' + filter.name" :filter="filter" />
      </div>
    </div>
    <DateFilterBox title="Modified From Date" filter="modifiedFrom" />
    <DateFilterBox title="Modified To Date" filter="modifiedBefore" />
  </div>
</template>

<script>
import ContextBox from './ContextBox';
import FilterBox from './FilterBox';
import SearchBox from './SearchBox';
import DateFilterBox from './DateFilterBox';
import FavoriteBox from './FavoriteBox.vue';

export default {
  components: {
    FilterBox,
    SearchBox,
    DateFilterBox,
    FavoriteBox,
    ContextBox,
  },
  data() {
    return {};
  },
  computed: {
    selectedContextFilters: {
      get() {
        return this.$store.state.contexts.selected.filters || [];
      },
    },
  },
  created() {
    this.$store.dispatch('contexts/getHydratedContexts');
  },
};
</script>

<style scoped>
hr {
  border-color: #898686;
}
.filter-label {
  font-size: 0.85rem;
  margin-left: 0.75rem;
}
</style>
