<template>
  <nav class="col d-none d-md-block bg-light sidebar">
    <div class="sidebar-sticky" :style="`height: ${this.bodyHeight - 20}px`">
      <ul class="nav flex-column">
        <li class="px-2 py-1 pb-2 select-context" v-if="contexts && contexts.length">
          <multiselect
            v-if="!renderPreview"
            id="context_options"
            :model-value="selectedContext"
            :options="contexts"
            :searchable="true"
            :close-on-select="true"
            :allow-empty="false"
            :multiple="false"
            @update:model-value="handleSelect"
            selectLabel=""
            deselectLabel=""
            label="name"
            :placeholder="'Select Filter Collection'"
            track-by="contextid"
            :class="
              this.hasSelectedCollection
                ? 'intop-multiselect-input intop-filter-collection-dropdown--active'
                : 'intop-multiselect-input intop-filter-collection-dropdown'
            "
          >
            <template v-slot:caret="{ toggle }">
              <img @mousedown.prevent.stop="toggle()" class="caret-svg caret-dark" src="@/assets/down-arrow.svg" />
              <img
                @mousedown.prevent.stop="toggle()"
                class="caret-svg caret-white"
                src="@/assets/down-arrow-white.svg"
              />
            </template>
          </multiselect>
          <div v-else class="demoContextName">
            <InputBox
              :getFunc="() => this.$store.state.contextManager.name"
              :setFunc="(name) => this.$store.dispatch('contextManager/updater', { name })"
              placeholder="Context Name (click to edit)"
            />
          </div>
        </li>
        <li v-if="hasFilters" class="PX-2 text-right">
          <b-button class="clear-all-btn" variant="link" @click.prevent="handleClear">CLEAR ALL</b-button>
        </li>
        <li v-else class="PX-2 clear-all-placeholder"></li>
        <li class="px-2">
          <div v-for="filter in selectedContextFilters" :key="filter.filterid" class="py-2">
            <filter-box :filter="filter" />
          </div>
        </li>

        <li v-if="this.hasSelectedCollection" class="px-2 py-2">
          <div class="py-0">
            <!-- <h6 class="sidebar-heading my-1">Modified After Date</h6> -->
            <date-filter placeholder="Modified From Date" class="intop-sidebar-config-input" filter="modifiedFrom" />
          </div>
        </li>
        <li v-if="this.hasSelectedCollection" class="px-2">
          <div class="py-0">
            <!-- <h6 class="sidebar-heading my-1">Modified Before Date</h6> -->
            <date-filter placeholder="Modified To Date" class="intop-sidebar-config-input" filter="modifiedBefore" />
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import FilterBox from './FilterBox';
import DateFilter from './DateFilter';
import Multiselect from 'vue-multiselect';
import InputBox from '@/components/admin/v2/InputBox';

export default {
  name: 'Sidebar',
  components: {
    FilterBox,
    DateFilter,
    Multiselect,
    InputBox,
  },
  methods: {
    handleSelect(context) {
      if (context) {
        // A context has been selected by user.
        this.$store.dispatch('filterParams/resetFilters');
        this.$store.dispatch('contexts/setSelected', context.contextid);
      } else {
        // This method called for the live context preview from context editor.
        this.$store.dispatch('contexts/setDemoContext');
      }
      if (context.tags) {
        this.$store.dispatch('contexts/setDefaultFilters', context.tags);
      }
    },
    handleClear() {
      this.$store.dispatch('filterParams/resetFilters');
    },
  },
  computed: {
    contexts: {
      get() {
        return this.$store.state.contexts.all.items;
      },
    },
    hasSelectedCollection() {
      return !!this.$store.state.contexts.selected.contextid;
    },
    bodyHeight() {
      return this.$store.state.app.bodyHeight;
    },
    selectedContext() {
      if (this.hasSelectedCollection) {
        return this.$store.state.contexts.selected;
      } else {
        return [];
      }
    },
    selectedContextFilters() {
      return this.$store.state.contexts.selected.filters || [];
    },
    hasFilters() {
      return this.$store.getters['filterParams/hasFilters'];
    },
    renderPreview() {
      return this.$store.state.contextManager.renderPreview;
    },
  },
  created() {
    this.$store.dispatch('contexts/getHydratedContexts').then((r) => {
      if (this.renderPreview) {
        this.handleSelect(false);
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sidebar {
  width: 320px;
  /* position: fixed;
  top: 0;
  bottom: 0;
  left: 0; */
  z-index: 100; /* Behind the navbar */
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 5px;
  border: none;
  /* box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15); */
  background-color: #ededed !important;
}

@media only screen and (max-width: 1000px) {
  .sidebar {
    width: 250px;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .filter-wrapper {
  padding: 0 10px;
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar .multiselect__option--selected.multiselect__option--highlight {
  background-color: #92a3b5;
}

.clear-all-btn {
  margin: 3px 0px;
  font-weight: 500;
  color: #5290cc;
}

.clear-all-placeholder {
  height: 43px;
}
</style>
