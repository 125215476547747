<template>
  <AdminBaseUi title="Connector" create="/manage/connector/new">
    <div v-if="!this.emptySearchResult" class="connector-data-table">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell value="Name" sortField="name" width="300" />
            <TableHeaderCell value="Connector Type" width="300" />
            <TableHeaderCell value="Enabled" width="200" />
            <TableHeaderCell width="50" />
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow v-for="(row, index) in this.data" :key="index">
            <TableBodyCell :value="row.name" />
            <TableBodyCell :value="getConnectorType(row)" />
            <TableBodyCell>
              <Checkbox :checked="row.enabled" />
            </TableBodyCell>
            <TableBodyCell>
              <TableOptions
                :id="row.connectorid"
                identifier="connectorid"
                :endpoint="apiUrls.CONNECTOR"
                page="manage/connector"
                deleteModalEntityName="connector"
                :hideDeleteConfirmationModalForm="row.enabled ? true : false"
                deleteConfirmationModalFormHideMessage="Enabled connectors can not be deleted!"
              >
                <VerifyConnector :connector="row" />
                <CrawlConnector :connector="row" />
              </TableOptions>
            </TableBodyCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
    <div class="connector-data-table text-center pt-5" v-else>No results found</div>
  </AdminBaseUi>
</template>

<script>
import apiUrls from '../../../../config/apiUrls';
import Checkbox from '@/components/Checkbox';
import AdminBaseUi from '@/components/admin/v2/base-ui/AdminBaseUi';
import Table from '@/components/dataTable/Table';
import TableHeader from '@/components/dataTable/TableHeader';
import TableRow from '@/components/dataTable/TableRow';
import TableHeaderCell from '@/components/dataTable/TableHeaderCell';
import TableBody from '@/components/dataTable/TableBody';
import TableBodyCell from '@/components/dataTable/TableBodyCell';
import TableOptions from '@/components/dataTable/TableOptions';
import VerifyConnector from '@/components/admin/v2/connectors/VerifyConnector';
import CrawlConnector from '@/components/admin/v2/connectors/CrawlConnector';

export default {
  components: {
    AdminBaseUi,
    Table,
    TableRow,
    TableHeader,
    TableHeaderCell,
    TableBody,
    TableBodyCell,
    Checkbox,
    TableOptions,
    VerifyConnector,
    CrawlConnector,
  },
  created() {
    this.getConnectors();
  },
  mounted() {
    window.onpopstate = this.onBackButtonPressed;
  },
  watch: {
    page(page) {
      this.$router.push({ path: '/manage/connector', query: { page } });
    },
  },
  computed: {
    emptySearchResult() {
      if (!!this.$store.state.dataTable.search.trim() && !this.$store.state.dataTable.data.data.length) {
        return true;
      } else {
        return false;
      }
    },
    response() {
      return this.$store.state.dataTable.data;
    },
    data() {
      const { data } = this.response;
      return (data || []).map((item) => ({
        connectorid: item.connectorid,
        name: item.name,
        connectortype: item.connectortypeid,
        enabled: item.enabled,
        connector_types: item.connector_types,
      }));
    },
    page() {
      return this.$store.state.dataTable.page;
    },
  },
  data() {
    return {
      apiUrls: apiUrls,
    };
  },
  methods: {
    getConnectors() {
      const queries = this.$router.currentRoute.value.query;
      const { page = 1 } = queries;
      this.$router.replace({ path: '/manage/connector', query: { page } });
      this.$store.dispatch('dataTable/setTable', apiUrls.CONNECTORS_LIST);
      this.$store.dispatch('dataTable/setPage', page);
    },
    getConnectorType(row) {
      if (row.connector_types && row.connector_types.length) {
        return row.connector_types.join(', ');
      }
      return '';
    },
    onBackButtonPressed() {
      this.getConnectors();
    },
  },
};
</script>

<style scoped>
.connector-data-table {
  padding: 0px 40px;
}
</style>
