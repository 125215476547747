import { genericService } from '@/services';
import { updateBookmarks, updateUrl, updateBookmarkUrl } from '@/helpers/bookmark';
import apiUrls from '../config/apiUrls';

export const filterParams = {
  namespaced: true,
  state: {
    modifiedFrom: '',
    modifiedBefore: '',
    searchString: [],
    tags: [],
  },
  getters: {
    hasFilters: (state) => {
      return !(
        state.modifiedFrom.trim() == '' &&
        state.modifiedBefore.trim() == '' &&
        state.searchString.length == 0 &&
        state.tags.length == 0
      );
    },
    validateDateRange: (state) => {
      const fromDate = state.modifiedFrom;
      const toDate = state.modifiedBefore;
      if (!(fromDate && toDate)) return true;
      if (fromDate === toDate) return false;
      return new Date(fromDate) < new Date(toDate);
    },
  },
  actions: {
    searchString({ dispatch, commit, state }, searchString) {
      if (
        !state.searchString.map((s) => s.toLowerCase()).includes(searchString.toLowerCase().trim()) &&
        searchString.trim() !== ''
      ) {
        const searchTerm = [...state.searchString, searchString];
        commit('searchString', searchTerm);
        updateBookmarks({
          paramKey: 's',
          paramValue: searchTerm,
        });
        dispatch('handleMainViewOnFilterChange');
      }
    },
    removeSearchKeyword({ dispatch, commit, state }, keyword) {
      const nextSearchState = state.searchString.filter((searchLabel) => searchLabel != keyword);
      commit('searchString', nextSearchState);
      updateBookmarks({
        paramKey: 's',
        paramValue: nextSearchState,
      });
      dispatch('handleMainViewOnFilterChange');
    },
    setModifiedFrom({ dispatch, commit, getters, state }, date) {
      commit('modifiedFrom', date);
      if (!getters.validateDateRange) {
        commit('modifiedBefore', '');
      }
      updateBookmarkUrl([
        { key: 'a', value: date },
        { key: 'b', value: state.modifiedBefore },
      ]);
      dispatch('handleMainViewOnFilterChange');
    },
    setModifiedBefore({ dispatch, commit, getters, state }, date) {
      commit('modifiedBefore', date);
      if (!getters.validateDateRange) {
        commit('modifiedFrom', '');
      }
      updateBookmarkUrl([
        { key: 'a', value: state.modifiedFrom },
        { key: 'b', value: date },
      ]);
      dispatch('handleMainViewOnFilterChange');
    },
    updateTag({ dispatch, commit, state }, tags) {
      const { tagid, label, taggroupid, count = null, updateBookmarkUrl = true } = tags;
      commit('updateTag', { tagid, label, taggroupid, count });
      if (updateBookmarkUrl) {
        updateBookmarks({
          paramKey: 'f',
          paramValue: state.tags || [],
        });
      }
      dispatch('handleMainViewOnFilterChange');
    },
    async updateTagFromBookmark({ commit }, nodeFilters) {
      const _tagsToFetch = (nodeFilters.nodeFilters || []).map((t) => t.tagid);
      let tags = [];
      if (_tagsToFetch.length) {
        const _tags = await genericService.index(apiUrls.TAG_LIST, 999, {
          get_in: _tagsToFetch.join(', '),
        })();
        tags = _tags.data;
      }
      nodeFilters.tags = tags;
      commit('updateTagFromBookmark', nodeFilters);
    },
    removeTag({ dispatch, commit, state }, { tagid = false, taggroupid = false, updateBookmarkUrl = true }) {
      commit('removeTag', { tagid, taggroupid });
      if (updateBookmarkUrl) {
        updateBookmarks({
          paramKey: 'f',
          paramValue: state.tags || [],
        });
      }
      dispatch('handleMainViewOnFilterChange');
    },
    handleMainViewOnFilterChange({ getters, dispatch, rootState }) {
      const _resourceTable = rootState.users.displayResourceTable;
      if (getters.hasFilters) {
        // Show navigations.
        dispatch('users/toggleResourceTablePagination', true, {
          root: true,
        });
        // Show resource table.
        if (!_resourceTable) {
          dispatch('users/toggleResourceTable', true, { root: true });
          dispatch('users/toggleWelcomeMessage', false, { root: true });
          dispatch('welcomePage/toggleUniqness', false, { root: true });
        }
      } else {
        const welcomePageIsUnique = rootState.welcomePage.welcomePageIsUnique;
        if (welcomePageIsUnique) {
          // Show welcome page.
          dispatch('users/toggleResourceTable', false, { root: true });
          dispatch('users/toggleWelcomeMessage', true, { root: true });
        } else {
          // Hide navigations.
          dispatch('users/toggleResourceTablePagination', false, {
            root: true,
          });
        }
      }
    },
    resetFilters({ commit, rootState, dispatch }) {
      commit('resetFilters');
      dispatch('app/setSearchByTitle', false, { root: true });
      updateUrl({
        selectedContext: rootState.contexts.selected.contextid,
      });
    },
  },
  mutations: {
    searchString(state, searchString) {
      state.searchString = searchString;
    },
    modifiedFrom(state, date) {
      state.modifiedFrom = date;
    },
    modifiedBefore(state, date) {
      state.modifiedBefore = date;
    },
    updateTag(state, tag) {
      const tags = [...state.tags];
      const existsOnIndex = tags.findIndex((t) => t.tagid === tag.tagid);
      if (existsOnIndex > -1) {
        tags.splice(existsOnIndex, 1, tag);
      } else {
        tags.push(tag);
      }
      state.tags = tags;
    },
    updateTagFromBookmark(state, nodeFilters) {
      state.tags = nodeFilters.tags;
      state.searchString = nodeFilters.searchString;
      state.modifiedFrom = nodeFilters.modifiedFrom;
      state.modifiedBefore = nodeFilters.modifiedBefore;
    },
    removeTag(state, { tagid, taggroupid }) {
      const tags = [...state.tags];
      if (tagid) {
        state.tags = tags.filter((tag) => tag.tagid !== tagid);
      } else {
        state.tags = tags.filter((tag) => tag.taggroupid !== taggroupid);
      }
    },
    resetFilters(state) {
      state.tags = [];
      state.modifiedFrom = '';
      state.modifiedBefore = '';
      state.searchString = [];
    },
  },
};
